.textfieldPassword-container {
	display: flex;
	flex-direction: column;
}
.textfieldPassword {
	position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.textfieldPassword input {
	width: 100%;
	height: 50px;
	padding: 10px 12px;
	border-radius: 24px;
	box-sizing: border-box;
	outline: none;
	border: 0px solid var(--black-15);
	background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
	text-overflow: ellipsis;

	font-family: 'Faktum-Medium';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 20px;
	color: #000000;
}

.textfieldPassword input::placeholder { 
	color: #000000;
}

.textfieldPassword-title {
	pointer-events: none;
	position: absolute;
	left: 20px;
	top: 50%;
	color: #000000;
	transform: translateY(-50%);
	transition: 
	top 0.3s ease,
	font-size 0.3s ease,
	color 0.3s ease;
}

.textfieldPassword input:focus + .textfieldPassword-title {
	top: 15px;
	font-weight: normal;
	font-size: 0.75rem;
	line-height: 15px;
	color: var(--gray-1);
}

.textfieldPassword input:focus::placeholder {
	opacity: 1;
	color: #000000;
	font-weight: 500;
	font-size: 1rem;
	line-height: 20px;
}

.textfieldPassword input::placeholder {
	/* opacity: 0; */
}

.textfieldPassword input:disabled {
	border: 1px solid rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	backdrop-filter: blur(40px);
	border-radius: 12px;
	background-color: transparent;
}

.textfieldPassword input:focus {
	padding: 22.5px 20px 7.5px 20px;
	border: 1px solid var(--blue-2);
	color: var(--gray-1);
}

.textfieldPassword input:focus ~ .textfieldPassword-title,
.textfieldPassword input:not(:placeholder-shown).textfieldPassword-input:not(:focus) ~ .textfieldPassword-title {
	top: 15px;
	font-weight: normal;
	font-size: 0.75rem;
	line-height: 15px;
	color: var(--gray-1);
}

.textfieldPassword input:not(:placeholder-shown).textfieldPassword-input:not(:focus) {
    padding: 22.5px 70px 7.5px 20px;
	font-size: 16px;
	line-height: 20px;
	color: var(--gray-1);
}

.textfieldPassword-disabled {
	border: 1px solid var(--black-25) !important;
	background-color: transparent !important;
}

.textfieldPassword-disabled + span {
	color: var(--gray-3);
	font-weight: 500;
}

.textfieldPassword-error {
	width: fit-content;
	margin-top: 5px;
	margin-left: 10px;
	color: var(--red);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.textfieldPassword-number input::-webkit-input-placeholder  {
    text-align: end;
}

.textfieldPassword-icon {
    cursor: pointer;
    position: absolute;
    right: 15px;
}

.textfieldPassword-icon div {
	display: flex;
}