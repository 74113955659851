.lclcd {
	max-width: 760px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;

	border-radius: 24px;
	background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
	box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
	/* backdrop-filter: blur(20px); */
}

.lclcd-container {
	display: flex;
	align-items: center;
	gap: 20px;
}

.lclcd-container .checkbox-container input[type="checkbox"] {
	width: 39px;
    height: 39px;
}

.lclcd-container .checkbox-container label::before {
	width: 39px;
    height: 39px;
}

.lclcd-container .checkbox-container .checkbox-check {
	left: calc(50% - 9px);
    height: 1rem;
    width: 1rem;
}

.lclcd-title {
	display: flex;
	flex-direction: column;
	/* gap: 5px; */
}

.lclcd h2,
.lclcd p {
	margin: 0;
	color: var(--gray-1);
}

.lclcd-bottom {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.lclcd-first-row-section-dropdown {
	width: 100%;
    max-width: 350px;
}

.lclcd-first,
.lclcd-second {
	display: flex;
	/* align-items: center; */
	gap: 20px;
}

.lclcd-first-row {
	display: flex;
	flex-direction: row;
	gap: 20px;
	width: 100%;
}

.lclcd-first-row-section {
	display: flex;
	flex-direction: row;
	gap: 20px;
	width: 160%;
}

.lclcd-first .textfield {
	width: 100%;
}

.lclcd-last {
	display: flex;
	align-items: center;
	gap: 10px;
	background: var(--black-05);
	border-radius: 12px;
	padding: 20px;
	flex-wrap: wrap;
}

.lclcd-paquete {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;
}


.lclcd-add-list {
	flex: 1;
	display: flex;
	align-items: center;
	gap: 10px;
}

.lclcd-add-list p span {
	color: var(--black-25);
}

.lclcd-fileupload {
	display: flex;
	align-items: center;
	gap: 20px;
}

.lcl-fileupload-container {
	display: flex;
	flex-direction: column;
}

.lcl-fileupload--error {
	color: var(--red);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.lclcd .lclcd-bottom .lclcd-first .textFieldMoney input,
.lclcd .lclcd-bottom .lclcd-first .textFieldMoney p {
	text-align: start;
}
.lclcd-container-mobile {
	display: none;
}

/* .lclcd-first-row .dropdown .dropdown-select .select-title {
	transform: translate(15px, 50%);
} */

.lclcd-container .checkbox-container {
	background: var(--black-05);
	border: 0px solid var(--gray-3)
}

.lcl-row-description {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
}

.lcl-row-description-check {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 12px;
}

.lclcd-colorText {
	color: var(--gray-1)
}

.lclcd-paquete-container {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 10px;
}

.lclcd-paquete-container .popupConfirm {
	top: 60px;
}

.lclcd-paquete--total {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
}

.lclcd-paquete-error {
	font-size: 12px;
	margin-top: -10px;
}

.lclcd-row-message {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	align-items: center;
}

.lclcd-paquete-msg {
	width: 207px;
}

.lclcd-row-message--alert {
	font-size: 0.68rem;
	color: var(--red);
	display: none;
}

.lclcd-row-message  .popupConfirm {
	top: 6vh;
	left: 2rem;
}

.lclcd-first-row .dropdown {
	width: 50%;
}

.lclcd-first-row-section-content {
	display: flex;
    flex-direction: row;
    width: 100%;
	gap: 20px;
}

.lclcd-first-row-section-dropdown .dropdown {
	width: 100%;
}

.lclcd-first-row-section-dropdown {
	width: 100%;
   }

/* .lclcd-first-row .dropdown .dropdown-list::-webkit-scrollbar {
	width: 7px;
	height: 4px;
	background-color: #F5F5F5;
}

.lclcd-first-row .dropdown .dropdown-list::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--gray-1);
  }
    */

@media (max-width: 909px) { 
	.lclcd .lclcd-bottom .lclcd-first .textFieldMoney {
		max-width: 170px;
		width: 100%;
		min-width: 150px;
	}
	.lclcd .lclcd-bottom .lclcd-first .textFieldMoney input {
		max-width: 170px;
		width: 100%;
		min-width: 150px;
	}
	.lclcd .lclcd-bottom .lclcd-first .textFieldMoney-input {
		width: 170px;
	}
	.lclcd .lclcd-bottom .lclcd-first .textFieldMoney p {
		padding: 22.5px 20px 7.5px 15px;
	}
	.lclcd .lclcd-bottom .lclcd-first .textFieldMoney-focus {
		left: 15px;
	}
	.lclcd .lclcd-bottom .lclcd-first, .lclcd .lclcd-bottom .lclcd-second {
		gap: 15px;
	}
}

@media (max-width: 873px) {
	.lclcd-first-row, .lclcd-first-row-section,
	.lclcd-first-row-section-content {
		gap: 15px;
		width: 100%;
	}
}

@media (max-width: 830px) { 
	.lclcd-first-row .dropdown {
		width: 40%;
	}
	.lclcd-first-row-section-dropdown .dropdown {
		width: 100%;
	}
}

@media (max-width: 805px) { 
	.lclcd .lclcd-bottom .lclcd-first, .lclcd .lclcd-bottom .lclcd-second {
		gap: 10px;
	}
	.lclcd .lclcd-bottom .lclcd-first .dropdown .dropdown-select .move-text {
		left: 5px;
	}
	.lclcd .lclcd-bottom .lclcd-first .dropdown .dropdown-select .move-text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100px;
		width: 100%;
	}
}

@media (max-width: 791px) { 
	.lclcd-first-row .dropdown {
		width: 30%;
	}
	.lclcd-first-row-section-dropdown .dropdown {
		width: 100%;
	}

	.lclcd-first-row .dropdown .dropdown-list {
		height: fit-content;
	}

}

@media (max-width: 747px) { 
	.lclcd-first-row .dropdown {
		width: 100%;
	}

	.lclcd-first-row-section-dropdown .dropdown {
		width: 101%;
	}
}

@media (max-width: 738px) {
	.lclcd-row-message  .popupConfirm {
		left: 14rem;
	}
}

@media (max-width: 730px) {
	.lclcd-first {
		flex-wrap: wrap;
	}
	.lclcd-first .inputElement-container{
		width: 100%;
	}
	.lclcd .lclcd-bottom .lclcd-first, .lclcd .lclcd-bottom .lclcd-second {
		gap: 20px;
	}
	.lclcd-first__two .dropdown .dropdown-select .move-text {
		width: 100%;
		max-width: 200px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.lclcd .lclcd-bottom .lclcd-first .textFieldMoney,
	.lclcd .lclcd-bottom .lclcd-first .textFieldMoney p {
		width: 100%;
		max-width: 100%;
	}
	.lclcd .lclcd-bottom .lclcd-first .textFieldMoney-input,
	.lclcd .lclcd-bottom .lclcd-first .textFieldMoney input {
		width: 100%;
		max-width: 100%;
	}
	.lclcd .lclcd-bottom .lclcd-first .dropdown .dropdown-select .move-text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 500px;
		width: 100%;
	}

	/* .lclcd-second {
		flex-wrap: wrap;
	} */

	.lclcd-last {
		flex-wrap: wrap;
	}

	.lclcd-fileupload {
		flex-wrap: wrap;
		justify-content: center;
	}
}

@media (max-width: 700px) { 
	.lclcd {
		border-radius: 12px;
	}
}

@media (max-width: 668px) { 
	.lclcd-second .dropdown .dropdown-select .move-text {
		width: 60%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@media (max-width: 626px) {
	.lclcd-row-message  .popupConfirm {
		left: 10rem;
	}
}

@media (max-width: 600px) { 
	/* .lclcd-first-row .dropdown {
		width: 60%;
	} */
	.lclcd {
		gap: 20px;
		padding: 10px;
	}
	.lclcd-last .lcl-fileupload-container .updateText-content .updateText-content-button-hover 
	.tinytext-header {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 120px;
	}
	.lclcd-title h2,
	.lclcd-colorText {
		font-size: 0.875rem;
	}
	.lclcd-title p {
		font-size: 0.75rem;
	}
	.lclcd-paquete .packages-container .packages-row-second-mobile .packages-row-second-mobile-1 
	.inputElement-container .inputElement input {
		width: 100%;
	}
	.lclcd-paquete-colorBlack {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 200px;
	}
	.lclcd-first-row-section-content {
		display: flex;
		flex-direction: column;
	}
	.lcl-row-description-check {
		gap: 10px
	}
	.lcl-row-description-check .checkbox-container input[type="checkbox"],
	.lcl-row-description-check .checkbox-container label::before {
		width: 24px;
		height: 24px;
	}
	.lcl-row-description-check .checkbox-container .checkbox-check {
		left: calc(50% - 6px);
		height: 0.6rem;
		width: 0.6rem;
	}
	.lcl-row-description-check .lclcd-colorText {
		font-size: 0.75rem;
	}
	.lclcd-first-row-section .lclcd-row-message .inputElement-container .inputElement 
	input {
		width: 280px;
	}
	.lclcd-paquete--total_send {
		font-size: 1rem;
		font-family: 'Faktum-Medium';
	}
}

@media (max-width: 490px) {
	.lclcd-row-message  .popupConfirm {
		left: 5rem;
	}
}

@media (max-width: 488px) {
	.lclcd-first-row-section .lclcd-row-message .inputElement-container .inputElement 
	input {
		width: 260px;
	}
}

@media (max-width: 464px) {
	.lclcd-first-row-section .lclcd-row-message .inputElement-container .inputElement 
	input {
		width: 240px;
	}
}

@media (max-width: 444px) {
	.lclcd-first-row-section .lclcd-row-message .inputElement-container .inputElement 
	input {
		width: 220px;
	}
}

@media (max-width: 422px) { 
	.lclcd-paquete-colorBlack {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 150px;
	}
	.lclcd-first-row-section .lclcd-row-message .inputElement-container .inputElement 
	input {
		width: 200px;
	}
}

@media (max-width: 403px) { 
	.lclcd .lclcd-bottom .lclcd-second .dropdown .dropdown-select .move-text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 230px;
		width: 100%;
	}
	.lclcd-first-row-section .lclcd-row-message .inputElement-container .inputElement 
	input {
		width: 180px;
	}
}


@media (max-width: 400px) { 
	/* .lclcd-container {
		display: none;
	} */
	.lclcd-title-mobile {
		flex-direction: row;
		display: flex;
		text-align: center;
		gap: 15px;
		align-items: center;
	}
	/* .lclcd-container-mobile {
		display: flex;
		flex-direction: column;
		gap: 10px
	} */
	.lclcd-container-mobile  .lclcd-title-mobile h2 {
		align-self: center;
		text-align: start;
	}
	.lclcd-title-mobile .checkbox-container {
		width: 25px;
		height: 25px;
	}
	.packages-row .packages-row-content .dropdown .dropdown-select .move-text,
	.packages-row-second-mobile .dropdown .dropdown-select .move-text {
		left: 0px;
	}
}


@media (max-width: 390px) { 
	.lclcd .lclcd-bottom .lclcd-second .dropdown .dropdown-select .move-text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 200px;
		width: 100%;
	}
}

@media (max-width: 381px) {
	.lclcd-row-message  .popupConfirm {
		left: 2rem;
	}
	.lclcd-first-row-section .lclcd-row-message .inputElement-container .inputElement 
	input {
		width: 160px;
	}
}

@media (max-width: 361px) {
	.lclcd-first-row-section .lclcd-row-message .inputElement-container .inputElement 
	input {
		width: 140px;
	}
}

@media (max-width: 350px) { 
	.lclcd .lclcd-bottom .lclcd-second .dropdown .dropdown-select .select-title {
		max-width: 150px;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.lclcd .lclcd-bottom .lclcd-second .dropdown .dropdown-select .move-text {
		max-width: 190px;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

@media (max-width: 300px) { 
	.lclcd .lclcd-bottom .lclcd-second .dropdown .dropdown-select .move-text {
		max-width: 150px;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}