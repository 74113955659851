.order-supplier {
    width: 100%;
    min-height: 100%;
    /* height: 95vh; */
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    align-self: center;
    /* margin-bottom: 15rem; */
}
.order-supplier-loader {
    display: flex;
    height: 100vh;
}
.order-supplier-content {
    width: 100%;
    max-width: 1500px;
    height: calc(100vh - 50px);
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 40px;
    padding: 45px 77.5px 0px 77.5px;
    overflow: hidden;
}

/** Derecha */
.order-supplier-left {
    width: 100%;
    /* max-width: 880px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
    overflow: hidden;
}
.order-supplier-left .searchTextField-container {
    margin-left: 0.2rem;
    margin-right: 0.2rem
}
.order-supplier-left-row {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0.5rem;
}

.order-supplier-left-row .order__nav .subheader-clicked {
    color: var(--black);
}

.order-supplier-left-row div .custom-button {
    box-shadow: 0px 8px 20px var(--black-15);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 24px;
    margin-right: 0.9rem;
}
.order__nav {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}
.order__nav .subheader-clicked {
    color: var(--black);
}
.order-supplier-search-content {
    height: 55px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.5rem;
    gap: 20px;
}
.order-supplier-search-content .filterSelectorExtended .filterSelectorExtended-main {
    width: auto;
}
.order-supplier_search {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    gap: 20px;
}
.order-supplier .order-supplier-content .order-supplier-left .order-supplier_search .order-supplier-filters{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%; /*eliminar despues de la primera version*/
}
/*eliminar despues de la primera version*/
.order-supplier .order-supplier-content .order-supplier-left .order-supplier_search .order-supplier-filters .searchTextField-container {
    border: 1px solid #BDBDBD;
    width: 100%;
}
/*eliminar despues de la primera version*/
.order-supplier-filters .custom-button, 
.order-supplier_search .btn-normal {
    width: 40%;
    box-shadow: 0px 0px 0px var(--black-15);
}
.order-supplier .order-supplier-left .order-supplier_search .order-supplier-filters .filterSelectorMiniature-main {
    background: var(--gray-6);
}
.order-supplier .order-supplier-left .order-supplier_search .order-supplier-filters .searchTextField-min {
    border-radius: 24px;
    background: var(--white-75);
    height: 36px;
    width: 36px;
}
.order-supplier_search .searchTextField-min {
    width: 0px;
    height: 0px;
    background: transparent;
    backdrop-filter: blur(0px);
}
.order-supplier_search .btn-normal{
    min-width: auto;
    height: 36px;
    padding: 0px 30px;
}
.order-supplier-dashboard {
    gap:20px;
    width: 100%;
    height: 100%;
    display: flex;
    max-height: 700px;
    padding-top: 10px;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: flex-start;
}
.order-supplier_search .order-supplier-filters .filterSelectorMiniature-main .filterSelectorMiniature-item,
.order-supplier_search .order-supplier-filters .filterSelectorMiniature-main .filterSelectorMiniature-item span,  
.order-supplier_search button {
    font-size: 0.875rem;
}

/** SCROLL */
.order-supplier-dashboard::-webkit-scrollbar,
.order-supplier-right-calendar-event 
.eventView-container .eventView-list::-webkit-scrollbar,
.order-supplier-quote::-webkit-scrollbar  { 
    width: 0px;
}

/** Cotizacion */
.order-supplier-quote {
    /* width: 100%;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    max-height: calc(100vh - 235px);
    /* max-height: ('window.innerHeight-350px'); */
    /*padding-top: 10px;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: flex-start;
    display: block;
    scrollbar-width: none; */
    gap:20px;
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    justify-content: flex-start;
    display: block;
    max-height: calc(100vh - 172px);
    /* max-height: calc(100vh - 440px); */
    overflow: hidden;
    overflow-y: scroll;
    padding: 0.5rem;
}

.order-supplier-quote .dashboardElements-supplier { 
    margin-bottom: 10px;
}
.order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header .dashboardElements-supplier-content-left {
    max-width: 470px;
}
.order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header .dashboardElements-supplier-content-right {
    max-width: 335px;
}
.order-supplier .order-supplier-content .order-supplier-left .order-supplier-dashboard 
.dashboardElements-supplier .dashboardElements-supplier-content 
.dashboardElements-supplier-content-header .dashboardElements-supplier-content-title,
.order-supplier  .order-supplier-content .order-supplier-left .order-supplier-quote 
.dashboardElements-supplier .dashboardElements-supplier-content 
.dashboardElements-supplier-content-header .dashboardElements-supplier-content-title{
    font-size: 0.9rem;
}
.order-supplier-left .order-supplier-quote-desktop .dashboardElements-supplier .dashboardElements-supplier-header .dashboardElements-supplier-content-right 
.content-quote .content-quote-header-number {
    display: none;
}
.order-supplier-left .order-supplier-quote-desktop .dashboardElements-supplier .dashboardElements-supplier-header .dashboardElements-supplier-content-right div {
    display: flex;
}

.orders-notFound {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
}


/** Calendar */
.order-calendar-detail {
    display: flex;
    flex-direction: column;
    gap: 41px;
}
.order-calendar-detail-list-today {
   /*background: var(--white);*/
   background: var(--background);
   backdrop-filter: blur(40px);
   border-image-source: var(--border-image-source);
   flex-direction: column;
   border-radius: 20px;
   overflow-y: scroll;
   display: flex;
   padding: 20px;
   width: 880px;
   height: 100%;
   max-height: 46.5rem;
   /*max-height: 36.4rem;*/
   gap: 14px;
    
}
.order-calendar-detail-list-today-scroll {
    display: flex;
    flex-direction: column;
    gap: 10px
}
.order-calendar-detail-list-today::-webkit-scrollbar  { 
    width: 0px;
}
.order-calendar-month {
    display: none;
}
.order-calendar-detail .CalendarDetail-content .calendarDetail 
.calendarDetail-container::-webkit-scrollbar  {
    width: 0px;
}
.order-calendar-detail-list-today .order-calendar-detail-list-today-scroll .today .today-list-event .eventCard-container
.eventCard-marker{
    border-radius: 0px;
}

.ordersPage-orders_search .custom-button {
    display: none;
}



/** Izquierda */
.order-supplier-right {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
    overflow: hidden;
    padding: 0.5rem;
}
.order-supplier-right-calendar-event 
.eventView-container .eventView-list {
    height: 100%;
    max-height: 410px;
    overflow-y: scroll;
}
.order-supplier-right 
.calendar-container{
    box-sizing: border-box;
    height: auto;
}
.order-supplier-right
.order-supplier-right-calendar-event 
.eventView-container .eventView-list 
.eventCard-container .eventCard-marker {
    border-radius: 0px;
}

/** BOTTOM */ 
.order-supplier .absolute-container {
    display: none;
}

.order-calendar-detail-month {
    display: none;
}


/* NOT FOUND */
.orderProvider-NotFound {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-self: center;
    width: 100%;
    text-align: center;
}

.orderProvider-NotFound-text {
    color: var(--black);
}

.order-supplier .modalQuoteSupplier-container .modal-main .modal-container {
    height: auto;
    padding-bottom: 30px;
    padding-top: 30px;
    max-width: 860px;
    width: 100%;
    backdrop-filter: blur(20px);
}


/** RESPONSIVE */

/* @media (max-width: 3500px) and (max-height: 1816px) { 
    .order-supplier-content {
        height: calc(100vh - 81px);
    }
} */

@media (max-width: 1024px) and (max-height: 1816px) { 
    /* .order-supplier-content {
        height: calc(100vh - 70px);
    } */
    .order-supplier-quote {
        max-height: calc(100vh - 161px);
    }
}

/* @media (max-width: 960px) and (max-height: 1816px) { 
    .order-supplier-content {
        height: calc(100vh - 73px);
    }
} */

@media (max-width: 1440px){ 
    .order-calendar-detail-list-today {
        height: 100%;
        max-height: 40.8rem;
    }
    .order-supplier-content {
        padding: 30px 40px 0px 40px;
    }
}
@media (max-width: 1240px){ 
    .order-supplier-content {
        gap: 20px;
    }
    .order-supplier-left {
        width: 100%;
        max-width: 770px;
    }
    .order-supplier_search .order-supplier-filters .filterSelectorMiniature-main{
        gap: 15px
    }
    .order-calendar-detail-list-today {
        max-width: 770px;
    }
}
@media (max-width: 1200px){
    .order-supplier-content {
        padding: 30px 30px 0px 30px;
    }
}
@media (max-width: 1195px){
    /**Cotizaciones*/ 
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left, 
    .order-supplier-quote .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left {
        max-width: 395px;
    }
}
@media (max-width: 1130px){ 
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header, 
    .order-supplier-quote .dashboardElements-supplier 
    .dashboardElements-supplier-header {
        padding: 10px;
    }
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left, 
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-right, 
    .order-supplier-quote .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left,
    .order-supplier-quote .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-right{
        gap: 10px;
    }
    .order-supplier-left {
        width: 100%;
        max-width: 650px;
    }
    .order-supplier-right {
        width: 100%;
        max-width: 360px;
    }
    .order-supplier-right .calendar-container{
       /* height: 316px;*/ /*descomentar */
        padding: 20px;
        grid-gap: 0px;
        gap: 0px;
    }
    .order-supplier-right .calendar-container .calendarRow-cell 
    .calendarRow-colorCell {
        font-size: 0.9rem;
    }
    .order-calendar-detail-list-today {
        max-width: 650px;
    }
}
@media (max-width: 1092px){ 
    /**Cotizaciones*/
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right .content-quote{
        gap: 10px;
    }
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-right, 
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right {
        max-width: 300px;
    }
    /**Calendario*/
    .order-supplier-right .calendar-container {
        min-width: 320px;
    }
}
@media (max-width: 1030px){ 
    .order-supplier-left {
        width: 100%;
        max-width: 600px;
    }
    .order-supplier_search .order-supplier-filters .filterSelectorMiniature-main{
        max-width: 350px;
        padding: 7px 12px;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left {
        max-width: 300px;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier-title {
        max-width: 200px;
    }
    .order-supplier-right {
        width: 100%;
        max-width: 300px;
    }
    .order-supplier-right .calendar-container {
        min-width: 0px;
    }
    .order-calendar-detail-list-today {
        max-width: 600px;
    }
}
@media (max-width: 1024px){
    .order-supplier-content {
        padding: 20px 20px 0px 20px;
    }
}
@media (max-width: 960px){
    .order-supplier-right {
        display: none;
    }
    .order-supplier-left {
        max-width: 100%;
    }
    .order-supplier-search-content .filterSelectorExtended .filterSelectorExtended-categorie {
        display: none;
    }
    .order-supplier-search-content .filterSelectorExtended-main .filterSelectorExtended-item-content-dropdown 
    .filterSelectorExtended-item .filterSelectorExtended-text-filter {
        max-width: fit-content;
    }
}
@media (max-width: 952px){
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header .dashboardElements-supplier-content-right {
        max-width: 370px;
    }
}
@media (max-width: 950px){
    .order-supplier_search .order-supplier-filters .filterSelectorMiniature-main
    .filterSelectorMiniature-item, .order-supplier_search .order-supplier-filters 
    .filterSelectorMiniature-main .filterSelectorMiniature-item span {
        font-size: 0.8rem;
    }
    .order-calendar-detail-list-today {
        max-width: 100%;
    }
}
/* @media (max-width: 925px){ 
    .order-supplier-left {
        width: 100%;
        max-width: 580px;
    }
    .order-supplier-right {
        width: 100%;
        max-width: 280px;
    }
} */
@media (max-width: 915px){ 
    /* .order-supplier-left {
        width: 100%;
        max-width: 555px;
    } */
    .order-supplier_search .btn-normal {
        padding: 0px 10px;
    }
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left, 
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left {
        min-width: auto;
        max-width: 215px;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier-title {
        min-width: auto;
        max-width: 170px;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier-title .dashboardElements-supplier__title, 
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier__detail {
        max-width: 180px;
    }
    .order-supplier .order-supplier-content .order-supplier-left .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-content .dashboardElements-supplier-content-header .dashboardElements-supplier-content-title {
        font-size: 0.8rem;
    }
}
@media (max-width: 900px) {
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-content .dashboardElementsRow-container
    .elementRow-container .elementRow-middle .elementRow-row__detail {
        flex-direction: none;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-content .dashboardElements-supplier-content-header {
        padding: 5px 10px;
    }
}
@media (max-width: 890px) { 
    .order-supplier-left {
        max-width: 100%;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left {
        max-width: 300px;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier-title {
        max-width: 250px;
    }
    .order-supplier-right {
        display: none;
    }
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left 
    .dashboardElements-supplier-title,  
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left 
    .dashboardElements-supplier-title .dashboardElements-supplier__title, 
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left 
    .dashboardElements-supplier-title .dashboardElements-supplier__detail{
        max-width: 300px;
    }
}
@media (max-width: 768px) {
    .order-supplier-left-row div .custom-button {
        display: none;
    }
    .order-supplier-left-row div .custom-button,
    .order-supplier .absolute-container {
        display: none;
    }
    .order-calendar-month {
        display: block;
    }
    /* .order-supplier {
        margin-bottom: 5rem;
    } */
    .order-supplier-quote {
        max-height: calc(100%);
        margin-bottom: 4rem;
    }
}

@media (max-width: 700px) {
    .order-supplier .modalFilterMobile-container {
        align-self: center;
    }
}
@media (max-width: 655px) { 
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left {
        max-width: 230px;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier-title {
        max-width: 200px;
    }
}
@media (max-width: 600px) { 
    .order-supplier .modalQuoteSupplier-container .modal-main .modal-container .modal__header 
    .modal__heade-left .modal-header__text .modal-subtitle {
        display: flex;
    }
    .order-supplier-search-content {
        height: 98px;
        flex-direction: column;
        margin-top: 10px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
    }
    .order-supplier-search-content .searchTextField-container {
        max-width: 100%;
        width: 100%;
    }
    .order-supplier-search-content .filterSelectorExtended .filterSelectorExtended-main .filterSelectorExtended-item-content-dropdown 
    .filterSelectorExtended-item-selected .filterSelectorExtended-text-filter {
        max-width: 180px;
        min-width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flow;
        padding-top: 0.2rem;
    }
    .order-supplier-left {
        gap: 10px
    }
    .order-supplier-content {
        padding: 0px;
    }
    .order-supplier-left-row {
        padding: 20px 20px 0px 20px;
        margin-left: 0rem;
    }
    .order-supplier-search-content {
        margin-top: 10px;
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 0rem;
    }
    .order-supplier-left .searchTextField-container {
        margin-left: 0px;
        margin-right: 0px;
    }
    .order-supplier-quote {
        padding: 0.5rem 20px 0px 20px;
    }
}
@media (max-width: 585px) { 
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left {
        max-width: 200px;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier-title {
        max-width: 170px;
        min-width: auto;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right {
        /* max-width: 240px; */
        gap: 5px
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right .content-quote {
        gap: 5px
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right .content-quote .content-quote-header {
        gap: 5px
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left {
        max-width: 200px;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier-title .dashboardElements-supplier__title, 
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier__detail {
        max-width: 170px;
        min-width: 150px;
        font-size: 0.8rem;
    }
}
@media (max-width: 554px) {
    .order-supplier_search .btn-normal {
        display: none;
    }
    
    .order-calendar-detail {
        padding: 0px;
        display: flex;
        gap: 20px;
        width: 100%;
    }
    .order-calendar-month {
        padding: 0px 25px;
        flex-direction: column;
        display: flex;
        gap: 20px;
    }
    .order-calendar-month  .calendar-container{
        max-width: 100%;
    }
    .order-calendar-month  .order-calendar-event .eventView-container{
        max-width: 100%;
    }
    /*.order__nav {
        justify-content: center;
    }*/
    .order-calendar-detail-list-today {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        background: var(--white);
    }
    /* .order-supplier_search, .order-supplier-dashboard, .order-supplier-quote,
    .order-supplier-left-row {
        padding: 0px 25px;
    } */

    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right .content-quote .content-quote-header 
    .content-quote-header-text {
        max-width: 70px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left, 
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left {
        min-width: auto;
        max-width: 205px;
    }
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left 
    .dashboardElements-supplier-title {
        max-width: 256px;
        min-width: auto;
    }
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left 
    .dashboardElements-supplier-title .dashboardElements-supplier__title, 
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left 
    .dashboardElements-supplier-title .dashboardElements-supplier__detail {
        min-width: auto;
    }
    .order-supplier-content {
        /* height: 100%; */
        height: 95vh;
        overflow: hidden;
    }
    .order-calendar-detail-list-today {
        overflow-y: visible;
        max-height: 100%;
    }
    .order-supplier-left-row .order__nav {
        gap: 20px
    }
    .order-supplier-left-row .order__nav .subheader {
        font-size: 0.9rem;
    }
}
@media (max-width: 551px) { 
    .order-supplier-search-content .filterSelectorExtended .filterSelectorExtended-main .filterSelectorExtended-item-content-dropdown 
    .filterSelectorExtended-item-selected .filterSelectorExtended-text-filter {
        max-width: 160px;
    }
}
@media (max-width: 536px) { 
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right .content-quote {
        min-width: 77%;
    }
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left, 
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left {
        min-width: auto;
        max-width: 200px;
    }
}
@media (max-width: 530px) { 
    .order-supplier-search-content .filterSelectorExtended .filterSelectorExtended-main .filterSelectorExtended-item-content-dropdown 
    .filterSelectorExtended-item-selected .filterSelectorExtended-text-filter {
        max-width: 140px;
    }
}
@media (max-width: 506px) { 
    .order-supplier-search-content .filterSelectorExtended .filterSelectorExtended-main .filterSelectorExtended-item-content-dropdown 
    .filterSelectorExtended-item-selected .filterSelectorExtended-text-filter {
        max-width: 130px;
    }
}
@media (max-width: 500px) { 
    .order-supplier-content .order-supplier-left .order-supplier-quote-mobile .dashboardElements-mobile-container 
    .dashboardElements-mobile--header .dashboardElements-mobile--header-bottom .content-quote .content-quote-header-number {
        display: none;
    }
    .order-supplier-content .order-supplier-left .order-supplier-quote .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-right .content-quote 
    .dropdown .dropdown-list .dropdown-list__item {
        background: var(--white-90);
    }
    .order-supplier-content .order-supplier-left .order-supplier-quote .dashboardElements-supplier 
    .dashboardElements-supplier-content .dashboardElements-supplier-content-header-mobile {
        display: none;
    }

    .order-supplier .order-supplier-content .order-supplier-left .order-supplier_search 
    .filterButton-container .filterButton-content .filterButton-icon {
        display: none;
    }
    .order-supplier .order-supplier-content .order-supplier-left .order-supplier_search 
    .filterButton-container {
        display: block;
        width: 80px;
    }
    .order-supplier .order-supplier-content .order-supplier-left .order-supplier_search 
    .filterButton-container .filterButton-content {
        padding: 6px 0px;
        justify-content: space-around;
    }
    .order-supplier .order-supplier-content .order-supplier-left .order-supplier_search 
    .order-supplier-filters .filterSelectorMiniature-main {
        display: none;
    }
    /* .order-supplier-dashboard .dashboardElements-supplier .dashboardElements-supplier-header,  
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
    } */

    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right .content-quote {
        min-width: auto;
    }
    .order-supplier-dashboard .dashboardElements-supplier .dashboardElements-supplier-header
    .dashboardElements-supplier-content-right .cargoStatus-container .cargoStatus-icon img{
        display: block;
    }
    .order-supplier-dashboard .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier-title, 
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left 
    .dashboardElements-supplier-title .dashboardElements-supplier__title, 
    .order-supplier-dashboard .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-left 
    .dashboardElements-supplier-title .dashboardElements-supplier__detail {
        max-width: 365px;
    }
    .order-supplier-dashboard .dashboardElements-supplier .dashboardElements-supplier-header
    .dashboardElements-supplier-content-left,
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left {
        max-width: 100%;
        justify-content: space-between;
    }
    .order-supplier-dashboard .dashboardElements-supplier .dashboardElements-supplier-header
    .dashboardElements-supplier-content-right, 
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right {
        gap: 10px;
        max-width: 100%;
        justify-content: flex-start;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right .content-quote {
        justify-content: flex-start;
        /*max-width: fit-content;*/
        width: 100%;
        gap: 10px;
        align-self: start;
    }
    /* .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right .dashboardElements-date-text {
        display: none;
    } */
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right .content-quote .content-quote-header 
    .content-quote-header-text {
        max-width: 100%;
    }
    .order-supplier-dashboard .dashboardElements-supplier .dashboardElements-supplier-header
    .dashboardElements-supplier-content-right .content-quote .dropdown,
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right .content-quote .dropdown {
        /* flex-direction: column;
        align-items: flex-start; */
        justify-content: flex-start;
        width: auto;
    }
    .order-supplier-dashboard .dashboardElements-supplier .dashboardElements-supplier-header
    .dashboardElements-supplier-content-right .content-quote,
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right .content-quote {
        width: 100%;
    }
    .order-supplier-dashboard .dashboardElements-supplier .dashboardElements-supplier-header
    .dashboardElements-supplier-content-right .cargoStatus-container .cargoStatus-text {
        flex-direction: row;
        gap: 5px;
    }
    .order-supplier-dashboard .dashboardElements-supplier .dashboardElements-supplier-header
    .dashboardElements-supplier-content-right .cargoStatus-container .cargoStatus-text 
    .cargoStatus-color {
        font-size: 0.75rem;
        font-family: 'Faktum';
        padding-top: 1px;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier-title {
        max-width: 100%;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier-title .dashboardElements-supplier__title, 
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-left .dashboardElements-supplier__detail {
        max-width: 90%;
        font-size: 0.8rem;
    }
    .order-supplier-dashboard .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right  .dashboardElements-date-text {
        display: none;
    }
    .order-supplier-dashboard .dashboardElements-supplier .dashboardElements-supplier-header 
    .dashboardElements-supplier-content-right  .cargoStatus-text-column .cargoStatus-color-date{
        display: block;
        font-size: 0.75rem;
    }
    .order-supplier-left .order__nav .subheader {
        font-size: 0.9rem;
    }

    /* .order-supplier-quote-desktop {
        display: none;
    } */

    .order-supplier-quote-mobile  {
        grid-gap: 20px;
        gap: 20px;
        width: 100%;
        /* height: 100%; */
        display: flex;
        /* max-height: 700px; */
        padding-top: 10px;
        overflow-y: scroll;
        flex-direction: column;
        justify-content: flex-start;
        display: block;
        /* padding: 0px 25px; */
    }

    .order-supplier-quote-mobile .dashboardElements-mobile-container {
        margin-bottom: 10px;
    }
}

@media (max-width: 486px) { 
    .order-supplier-search-content .filterSelectorExtended .filterSelectorExtended-main .filterSelectorExtended-item-content-dropdown 
    .filterSelectorExtended-item-selected .filterSelectorExtended-text-filter {
        max-width: 110px;
    }
}

@media (max-width: 450px) {
    .order-supplier-quote .dashboardElements-mobile-container .dashboardElements-mobile--header .dashboardElements-mobile--header-bottom .content-quote .dropdown {
        width: 30%;
    }
}

@media (max-width: 446px) { 
    .order-supplier-search-content .filterSelectorExtended .filterSelectorExtended-main .filterSelectorExtended-item-content-dropdown 
    .filterSelectorExtended-item-selected {
        padding: 10px 15px;
    }
    .order-supplier-search-content .filterSelectorExtended .filterSelectorExtended-main .filterSelectorExtended-item-content-dropdown 
    .filterSelectorExtended-item-selected .filterSelectorExtended-text-filter {
        max-width: 110px;
    }
}

@media (max-width: 425px) {
    .order-supplier-left .order__nav {
        gap: 20px;
        justify-content: center;
    }
}

@media (max-width: 407px) { 
    .order-supplier-search-content .filterSelectorExtended .filterSelectorExtended-main {
        gap: 10px;
    }
    .order-supplier-search-content .filterSelectorExtended .filterSelectorExtended-main .filterSelectorExtended-item-content-dropdown 
    .filterSelectorExtended-item-selected .filterSelectorExtended-text-filter {
        max-width: 100px;
    }
}

@media (max-width: 405px) { 
    .order-supplier-left .order__nav .subheader {
        font-size: 14px;
    }
    .order-calendar-month {
        padding: 0px 15px;
        flex-direction: column;
        display: flex;
        gap: 20px;
    }
}
@media (max-width: 372px) { 
    .order-supplier .order-supplier-content .order-supplier-left .order-supplier_search .order-supplier-filters .searchTextField-container {
        padding: 5px 10px;
    }
    .order-supplier-quote .dashboardElements-mobile-container .dashboardElements-mobile--header .dashboardElements-mobile--header-bottom .content-quote .dropdown {
        width: 35%;
    }
}
@media (max-width: 360px) { 
    .order-supplier-left .order__nav {
        gap: 10px;
    }
    .order-supplier-left .order__nav .subheader {
        font-size: 0.8rem;
    }
    .order-supplier-content .order-supplier-left .order-supplier-quote .dashboardElements-supplier 
    .dashboardElements-supplier-header .dashboardElements-supplier-content-right 
    .dashboardElements-date-text {
        display: none;
    }
}
@media (max-width: 350px) { 
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header .dashboardElements-supplier-content-right .content-quote {
        align-self: initial;
    }
    .order-supplier-quote .dashboardElements-supplier .dashboardElements-supplier-header .dashboardElements-supplier-content-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}