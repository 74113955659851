.container-info-column {
	display: flex;
	flex-direction: column;
	gap: 20px
}

.container-info-column .btn-normal {
	display: none;
}

.container-info-row {
	display: flex;
	flex-direction: row;
	gap: 20px
}

.container-info {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
	/* padding: 0px 20px; */
}

.container-info p {
	margin: 0;
}

.container-info-title {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.container-info-title p {
	color: var(--gray-1);
}

.container-body {
	width: 100%;
}

.container-specs {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.container-spec {
	width: 100%;
	min-width: 50px;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.img-container-responsive {
	display: none;
}

.img-container {
	width: 100%;
    max-width: 223px;
	max-height: auto;
    height: auto;
	object-fit: contain;
    border: 0px solid rgba(0, 0, 0, 0.25);
    border-radius: 8px;

	border-radius: 24px;
	background: lightgray 17.207px -23.245px / 86.831% 133.586% no-repeat, #FFF;
	box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

@media (max-width: 789px) { 
	.img-container {
		max-width: 200px;
	}
}

@media (max-width: 757px) { 
	.img-container {
		max-width: 200px;
	}
}

@media (max-width: 744px) { 
	.img-container {
		max-width: 150px;
	}
	.container-specs {
		gap: 4px;
		display: flex;
	}
	.container-spec {
		width: 100%;
	}
}

@media (max-width: 684px) {
	.container-specs {
		flex-direction: column;
		gap: 15px;
	}
	.container-info-row {
		justify-content: center;
	}
	.container-info-title {
		display: none;
	}
	.img-container {
		max-width: 48%;
		border: 0px solid rgba(0, 0, 0, 0.25);
	}
	.container-info-column .btn-normal {
		display: block;
	}
}

@media (max-width: 656px) {
	.container-body {
		display: flex;
		flex-direction: row;
		gap: 20px
	}
	.container-specs img {
		display: none;
	}
}

@media (max-width: 604px) {
	.img-container-responsive {
		display: flex;
		width: 100%;
	}
	.container-body {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		gap: 20px
	}
	.container-specs img {
		display: none;
	}
	.container-specs {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		display: flex;
    	gap: 5px;
	}
}

.container-info-column .btn-normal {
	font-family: 'Faktum';
}

@media (max-width: 502px) {
	.container-specs {
		flex-direction: column;
		/* display: flex; */
		gap: 10px;
		/* width: 100%; */
		align-self: flex-start;
		align-items: flex-start;
	}
}

@media (max-width: 424px) { 
	.container-body {
		flex-direction: column;
	}
}

@media (max-width: 375px) {
	.container-specs {
		flex-wrap: wrap;
		gap: 10px;
	}

	.container-specs img {
		width: 181px;
		height: 108px;
	}

	.container-spec {
		width: 100%;
	}
}