/* contenedor principal*/
.companyList-main {
    /* max-width: 840px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    color: var(--gray-1);
    /*gap: 20px; se descomentara cuando ya se pueda utilizar los filtros*/
}

.companyList-requestQuoteButton {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

/* titulo del componente */
.companyList-title{
    color: var(--gray-1);
}

/* descripcion del componente */
.companyList-description{
    color: var(--gray-1);
}

/* lista de componenestees OrderMiniature*/
.companyList-list{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    max-width: 1500px;
    width: 100%;
    /* margin: 0 auto; */
    /* align-items: center; */
    align-items: self-start;
    justify-content: center;
    align-self: center;
    margin-bottom: 5rem;
}

.companyList-min-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    margin-bottom: 15rem;
    width: 100%;
    justify-content: start;
}

/* contenedeor del componente footerNumber*/
.companyList-footer-number {
    width: 100%;
    display: flex;
    justify-content: center;
}

/* ocultar componente filter mobile */
.companyList-filter-mobile {
    display: none;
}

.companyList-NotFound {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-self: center;
    width: 100%;
    text-align: center;
}

.companyList-NotFound-text {
    color: var(--black);
}

.companyList-list .service-minuature-main{
    /* max-width: 100%; */
    max-width: 350px;
    /*   */
}

.companyList-min-list .service-minuature-main {
    max-width: 350px;
}

.companyList-min-list .service-minuature-main .service-minuature-body img{
    max-width: 100%;
} 

.companyList-list .service-minuature-main .service-minuature-body img {
    max-width: 100%;
}

/* Laptop */
@media (max-width: 1159px) {  
    .companyList-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 1060px) { 
    .companyList-list {
        /*justify-content: center;*/
        width: 100%;
    }
}

@media (max-width: 899px) {  
    .companyList-list,
    .companyList-min-list {
        grid-row-gap: 40px;
        grid-column-gap: 24px;
        grid-template-columns: repeat(2, 1fr);
    }
    .companyList-list .service-minuature-main {
        max-width: 100%;
    }
}

@media (max-width: 780px) { 
    .companyList-main { 
        align-items: center;
    }
    .companyList-list { 
        /* justify-content: flex-start; */
        justify-content: center;
    }
}

@media (max-width: 768px) { 
    .companyList-list { 
        margin-bottom: 5rem;
    }
}


@media (max-width: 710px) {
    .companyList-filter-desktop .filterSelectorExtended-main {
        flex-direction: row;
        background: transparent;
        align-items: center;
        align-self: center;
        display: flex;
        justify-content: flex-start;
        gap: 10px
    }
}

@media (max-width: 650px) { 
    .companyList-requestQuoteButton .btn-normal {
        min-width: 70px;
        padding: 10px 25px;
    }
}

@media (max-width: 623px) { 
    .companyList-requestQuoteButton .btn-normal {
        padding: 10px 20px;
    }
}

@media (max-width: 600px) { 
    .companyList-list {
        grid-row-gap: 10px;
        grid-column-gap: 20px;
    }
}

@media (max-width: 400px) { 
    .companyList-list {
        grid-row-gap: 15px;
        grid-column-gap: 15px;
    }
    .companyList-list .service-minuature-main {
        min-width: 165px;
    }
}