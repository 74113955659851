.service-seller__main {
    width: 99.9%;
    min-height: 100%;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    align-self: center;
}
.service-seller-title {
    font-family: 'Faktum-SemiBold';
    font-size: 34px;
    line-height: 43px;
    display: flex;
}
.service-seller__content {
    flex-direction: row;
    display: flex;
    justify-content: center;
    padding: 30px 20px;
    gap: 40px;
}

/** Derecha */
.service-seller__left {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
}
.service-seller__row, .service-seller__row__1 {
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/*SubHeader--Services-opcion*/
.service-seller___services {
    display: flex;
    gap: 20px;
    flex-direction: column;
}
.service-seller___services .option .option-content {
    flex-direction: column;
    width: 100%;
}
.service-seller___services .option .option-content .option-tabs {
    gap: 30px;
    justify-content: space-between;
    width: 100%;
}
.service-seller___services .option .option-content .option-tabs .btnIcon-normal {
    backdrop-filter: blur(49px);
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
}
.service-seller___services .option .option-content .option-range {
    width: 100%;
    background: var(--white-50);
    border-radius: 24px;
    padding: 0px 10px;
    height: 30px;
}

.service-seller___services .option .option-content .option-mobile {
    width: 60%;
}

.service-seller___services .option .option-new-event {
    display: none;
}

/*Card*/
.service-seller__row .cardBalance__main {
    flex-direction: column-reverse;
    gap: 10px;
    padding: 10px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 8px 20px rgb(0 0 0 / 15%);
}
.service-seller__row__1 .cardBalance__main {
    flex-direction: column-reverse;
    gap: 10px;
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 8px 20px rgb(0 0 0 / 15%);
}
.service-seller__row .cardBalance__main div {
    text-align: center;
}
.service-seller__row .cardBalance__main .cardBalance__row div {
    font-family: 'Faktum-SemiBold';
    font-size: 1.375rem;
}
.service-seller__row__1 .cardBalance__main .cardBalance__row div {
    font-family: 'Faktum-SemiBold';
}
.service-seller__row__1 .cardBalance__main div {
    font-family: 'Faktum';
}

/** Nuevo servicio*/
.service-seller___new-services {
    display: flex;
    gap: 20px;
    flex-direction: column;
}
.service-seller___new-services__content-text {
    display: flex;
    gap: 10px;
    flex-direction: column;    
}
hr.split, .split_mobile {
    border: 1px solid var(--black-15);
    width: 100%;
}

.service-seller___new-services__content-subcategorie {
    display: flex;
    gap: 20px;
    flex-direction: column;
}
.service-seller___new-services__content-subcategorie .cardBalance__main {
    cursor: pointer;
}

/** Nuevo servicio --- card*/
.service-seller__row .cardBalance__main {
    flex-direction: column-reverse;
    gap: 10px;
    padding: 10px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 8px 20px rgb(0 0 0 / 15%);
}
.service-seller___new-services .cardBalance__main {
    border-radius: 24px;
}

/** Nuevo servicio --- order-miniature*/
.service-seller___new-services .service-seller___new-services-slope 
.order-minuature-main .order-miniature-buttons .order-minuature-button-left {
    display: none;
}
.service-seller___new-services .service-seller___new-services-slope 
.order-minuature-main .order-miniature-buttons .order-minuature-button-rigth {
    width: 100%;
    border-radius: 0px 0px 12px 12px;
}

/** Izquierda */
.service-seller__right {
    justify-content: flex-start;
    display: flex;
    max-width: 840px;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    padding-top: 44px;
    padding-bottom: 20px;
}

.service-seller__right-center {
    display: flex;
    align-items: center;
}
.service-seller__right-grid,  .service-seller___new-services-slope{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    padding: 0px;
}

/**Nueva categoria -- subcategoria*/ 
.service-seller___sub-categorie {
    gap: 10px;
    display: flex;
    flex-direction: row;
}
.service-seller___sub-categorie .services-main {
    min-width: auto;
    cursor: pointer;
}

@media (max-width: 1259px){ 
    .service-seller___sub-categorie .services-main {
        padding: 10px;
    }
    .service-seller___sub-categorie .services-main .services-icon img {
        width: 35px;
        height: 35px;
    }
    .service-seller___sub-categorie .services-main .service-title{
        font-size: 0.85rem;
    }
}
@media (max-width: 1130px){ 
    .option-content {
        gap: 30px;
    }
}
@media (max-width: 1066px){ 
    .service-seller___services .option .option-tabs .btnIcon-normal {
        padding: 0px 25px;
    }
}
@media (max-width: 1030px){ 
    .service-seller__right-grid {
        justify-content: center;
    }
}

@media (max-width: 971px){ 
    .service-seller__right .service-seller__row div {
        font-size: 1.2rem;
    }
    .service-seller__right .service-seller__row .btn-normal {
        font-size: 0.9rem;
        min-width: 120px;

    }
}
@media (max-width: 934px){ 
    .service-seller__content {
        gap: 20px;
    }
}
@media (max-width: 919px){ 
    .service-seller__right .service-seller__row div {
        font-size: 1.1rem;
    }
    .service-seller__right .service-seller__row .btn-normal {
        padding: 0px 12px;
        font-size: 0.8rem;
        height: 30px;
    }
}
@media (max-width: 910px){ 
    .service-seller___sub-categorie {
        flex-direction: column;
    }
}
@media (max-width: 815px){ 
    .service-seller__left {
        max-width: 340px;
    }
}
@media (max-width: 785px){ 
    .service-seller___sub-categorie .services-main {
        display: flex;
    }
}
@media (max-width: 768px){ 
    .service-seller__content {
        padding: 0px 20px;
        flex-direction: column;
    }
    .service-seller__left {
        max-width: 100%;
    }
    .service-seller___services .option {
        justify-content: center;
    }
 
    .service-seller__row__1 {
        width: 100%;
    }
    .service-seller__row .cardBalance__main .cardBalance__row div,  
    .service-seller__row__1 .cardBalance__main .cardBalance__row div{
        font-size: 1rem;
    }
    .service-seller__row .cardBalance__main div, 
    .service-seller__row__1 .cardBalance__main div{
        font-size: 0.8rem;
    }
    .service-seller___new-services__content-subcategorie .cardBalance__main {
        width: 100%;
        justify-content: center;
    }

    .service-seller__right {
        padding-top: 0px;
    }
    .service-seller__right .service-seller__row div {
        font-size: 1.375rem;
    }
    .service-seller__right .service-seller__row .btn-normal {
        min-width: 132px;
        height: 40px;
        padding: 10px 40px;
    }
    .service-seller___sub-categorie {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
    }
    .service-seller___sub-categorie .services-main {
        min-width: 267px;
    }
    .service-seller___new-services__content-subcategorie {
        flex-direction: row;
    }
    .service-seller__right-grid {
        justify-content: center;
    }
}
@media (max-width: 605px) {
    .service-seller__left .service-seller___services .option .option-tabs {
        gap: 15px;
    }
}
@media (max-width: 554px){ 
    .service-seller___services .option .option-tabs .btnIcon-normal {
        /* padding: 0px 20px; */
        font-size: 1rem;
    }
    
}
@media (max-width: 505px){ 
    .service-seller__right .service-seller__row div {
        font-size: 1.125rem;
    }
    .service-seller__right .service-seller__row .btn-normal {
        min-width: 120px;
        height: 30px;
        padding: 0px 12px;
    }
}

@media(max-width: 425px) {
    .service-seller___services .option .option-content .option-tabs {
        gap: 15px;
    }
}

@media (max-width: 415px){ 
    .service-seller___new-services__content-subcategorie {
        flex-direction: column;
    }
}
@media (max-width: 405px){ 
    .service-seller__right .service-seller__row div {
        font-size: 1rem;
    }
}