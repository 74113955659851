.eventSeccion {
    height: fit-content;
    min-height: 300px;
    max-height: 656px;
    max-width: 330px;
    display: flex;
    padding-bottom: 20px;
}

.eventSeccion-cardContent {
    padding: 20px 0px 20px 20px
}

.eventSeccion-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 20px;
}

.eventSeccion-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    padding-right: 20px;
}

.eventSeccion-text-blue {
    color: var(--blue-2);
}

.eventSeccion-progress {
    border-radius: 24px;
}

.eventSeccion-progress div  {
    border-radius: 0px 19.755px 19.755px 0px;
    background: rgba(51, 51, 51, 0.10);
}

.eventSeccion-eventcard {
    max-width: 290px;
    min-width: 270px;
    width: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    gap: 20px;
}

.eventSeccion-eventcard::-webkit-scrollbar {
    display: none;
}

@media (max-width: 900px) {
    .eventSeccion {
        max-width: 850px;
    }
    .eventSeccion-column {
        display: none;
    }
    .eventSeccion-row {
        display: none;
    }
    .eventSeccion-eventcard {
        max-width: 850px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .eventSeccion-cardContent {
        padding: 0px;
        background: none;
        backdrop-filter: none;
        box-shadow: none;
    }
    .eventSeccion {
        max-width: 500px;
    }
    .eventSeccion-eventcard {
        grid-template-columns: repeat(1, 1fr);
        max-width: 500px;
        padding-right: 7px;
        padding-left: 7px;
        padding-bottom: 10px;
        padding-top: 0px;
    }
}