.modal-TyC {
    width: 100%;
}

.modal-TyC .modal-container {
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.81) 0%, rgba(255, 255, 255, 0.9) 100%), #F3F3F3;
    border-radius: 12px;
}

.modal-TyC .modal-container .modal__header .modal__heade-left {
    width: 100%;
    justify-content: center;
}

.modal-tyc-container {
    height: 100%;
    max-height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: auto;
    scrollbar-width: none;
}

.modal-tyc-container::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.modal-tyc-p {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 10px 0px 10px 0px;
    text-align: justify;
}

.modal-tyc-ul {
    display: flex;
    gap: 10px;
    flex-direction: column;
    text-align: justify;
}

.modal-link {
    color: var(--black);
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
}

@media (max-width: 600px) { 
    .modal-tyc-container {
        max-height: 70vh;
    }
    .modal-TyC {
        margin: 0px;
    }
    .modal-TyC .modal-container {
        height: 100%;
        border-radius: 0px;
    }
}

@media (max-width: 375px) { 
    .modal-tyc-container {
        max-height: 70vh;
    }
}

@media (max-width: 330px) { 
    .modal-TyC {
        padding: 10px;
    }
}

@media (max-width: 320px) { 
    .modal-TyC {
        padding: 0px;
    }
    .modal-tyc-container {
        max-height: 50vh;
        height: 100%;
    }
}

@media (max-width: 290px) { 
    .modal-tyc-container {
        max-height: 70vh;
    }
}