.modalOperationHeader .modal-main {
    padding-left: 20px;
    padding-right: 20px;
}

.modalOperationHeader .modal-main .modal-container  {
    width: 100%;
}
.modalOperationHeader .modal-main .modal-container .modal__header .modal__heade-left .modal-logo {
    display: none;
}

/* .modalOperationHeader-textField .textfield input:not(:placeholder-shown).textfield-input:not(:focus),
.modalOperationHeader-textField  .textfield input:focus {
    padding: 4px 20px 7.5px 20px;
} */

.modalOperationHeader-delete, .modalOperationHeader-textField {
    display: flex;
    flex-direction: column;
    gap: 30px
}

.modalOperationHeader-delete div {
    line-height: 25px;
}

.modalOperationHeader-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 15px
}

.modalOperationHeader-edit {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-content: flex-start;
}

.modalOperationHeader-countLetters {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media (max-width: 375px) { 
    .modalOperationHeader-footer .btn-normal {
        padding: 10px 6px;
        font-size: 0.8rem;
        min-width: 100px;
    }
}