.textfield {
	position: relative;
}

.textfield input {
	width: 100%;
	height: 100%;
	padding: 10px 12px;
	/* box-sizing: border-box; */
	outline: none;
	/* border: 1px solid var(--black-15); */
	border: 0px solid var(--black-15);
	border-radius: 24px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
	/* background: var(--white); */
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15) !important;
    /* backdrop-filter: blur(20px); */
	
	text-overflow: ellipsis;
	font-family: 'Faktum-Medium';
	font-style: normal;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 20px;
}

.textfield-title {
	pointer-events: none;
	position: absolute;
	left: 20px;
	top: 50%;
	/* color: var(--gray-3); */
	color: var(--black);
	transform: translateY(-50%);
	transition: 
	top 0.3s ease,
	font-size 0.3s ease,
	color 0.3s ease;
}

.textfield input:focus + .textfield-title {
	top: 15px;
	font-weight: normal;
	font-size: 0.75rem;
	line-height: 15px;
	color: var(--gray-1);
}

.textfield input:focus::placeholder {
	opacity: 1;
	color: var(--gray-3);
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 20px;
}

.textfield input::placeholder {
	/* opacity: 0; */
}

.textfield input:disabled {
	border: 1px solid var(--black-25);
	box-sizing: border-box;
	backdrop-filter: none;
	border-radius: 24px;
	background: transparent !important;
	/* background: rgba(0, 0, 0, 0.06) !important; */
	/* background-color: transparent; */
	color: var(--gray-1) !important;
	box-shadow: 0 3px 9px 0 rgba(0,0,0,0)!important;
}

.textfield input:focus {
	padding: 22.5px 20px 7.5px 20px;
	border: 1px solid var(--blue-2);
	color: var(--gray-1);
}

.textfield input:focus ~ .textfield-title,
.textfield input:not(:placeholder-shown).textfield-input:not(:focus) ~ .textfield-title {
	top: 15px;
	font-family: "Faktum";
	font-weight: normal;
	font-size: 0.75rem;
	line-height: 15px; 
	color: var(--gray-1);
}

.textfield input:not(:placeholder-shown).textfield-input:not(:focus) {
	padding: 22.5px 20px 7.5px 20px;
	font-size: 0.875rem;
	line-height: 20px;
	color: var(--gray-1);
}

.textfield-disabled {
	border: 1px solid var(--black-25) !important;
	background-color: transparent !important;
	color: var(--gray-3) !important; 
}

.textfield-disabled + span {
	color: var(--gray-3);
	font-weight: 500;
}

.textfield-error {
	width: fit-content;
	margin-top: 5px;
	margin-left: 10px;
	color: var(--red);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.textfield-number input::-webkit-input-placeholder  {
    text-align: end;
}

.textfield-error--outline {
	border: 1px solid var(--red) !important;
} 

.textfield-title-error {
	top: 37%;
}

/* Chrome, Safari, Edge, Opera */
.textfield input::-webkit-outer-spin-button,
.textfield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 600px) {
	.textfield input:focus ~ .textfield-title, 
	.textfield input:not(:placeholder-shown).textfield-input:not(:focus) ~ .textfield-title {
		font-size: 0.75rem;
	}
	.textfield input:not(:placeholder-shown).textfield-input:not(:focus) {
		font-size: 0.875rem;
	}
}