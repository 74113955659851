.modalConfirm .modal-main .modal-container {
	max-width: 850px;
	width: 100%;
	gap: 15px;
	background: var(--white);
}

.modalConfirm-container {
	display: flex;
	flex-direction: column;
	gap: 20px
}

.modalConfirm-colorText {
	color: var(--gray-1);
	font-size: 1.125rem;
}

.modalConfirm-button {
    width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 10px; 
}