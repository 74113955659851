.cardHoverExpand {
    overflow: hidden;
    width: 240px; /* Ancho inicial */
    height: 490px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    transition: width 0.8s ease; /* Transición suave */
    padding: 20px 20px 20px 20px;
    cursor: pointer;

    border-radius: 12px;
    background: linear-gradient(167deg, #181818 0%, #333 100%), #333;

    backdrop-filter: blur(15px);
}

.cardHoverExpand-mobile {
    width: 610px;
    height: 726px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 30px;

    padding: 20px 20px 20px 20px;

    border-radius: 12px;
    background: linear-gradient(167deg, #181818 0%, #333 100%), #333;

    backdrop-filter: blur(15px);
}

.cardHoverExpand-left {
    width: 240px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    transition: width 1s ease; /* Transición suave */
}

.cardHoverExpand-title {
    color: #FFF;    
}

.cardHoverExpand-list {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.cardHoverExpand-item {
    display: flex;
    flex-direction: row;
    gap: 10px
}

.cardHoverExpand-img {
    height: 20px;
    width: 20px;
}

.cardHoverExpand-text {
    color: var(--white);
}

.cardHoverExpand-right {
    width: 0px;
    display: none;
    flex: 1;
    opacity: 0; /* Inicialmente invisible */
    transition: opacity 20s ease; /* Transición suave para la opacidad */
    transition-delay: 20s; /* Retraso para que coincida con la transición de third-section-card-left */
}

.cardHoverExpand:hover {
    width: 924px; /* Ancho al hacer hover */
}

.cardHoverExpand:hover .cardHoverExpand-left {
    width: 420px; /* Ancho de third-section-card-left al hacer hover */
}

.cardHoverExpand:hover .cardHoverExpand-right {
    display: flex;
    opacity: 1; /* Hacer visible */
    transition-delay: 20s; /* Asegurar que la opacidad cambie después de la transición de third-section-card-left */
}


.cardHoverExpand.active {
    width: 924px; /* Ancho al hacer hover */
    overflow: hidden;
}

.cardHoverExpand.active .cardHoverExpand-left {
    width: 420px; /* Ancho de third-section-card-left al hacer hover */
}

.cardHoverExpand.active .cardHoverExpand-right {
    display: flex; /* Mostrar al hacer hover */
    opacity: 1; /* Hacer visible */
    transition-delay: 1s; /* Asegurar que la opacidad cambie después de la transición de third-section-card */
}

.cardHoverExpand-1 {
    background: linear-gradient(119deg, rgba(247, 226, 70, 0.01) 20.4%, rgba(255, 197, 37, 0.20) 68%), #333;;
}


@media (max-width: 1320px) {
    .cardHoverExpand.active,
    .cardHoverExpand:hover {
        width: 750px !important;
    }
    .cardHoverExpand.active .cardHoverExpand-left,
    .cardHoverExpand:hover .cardHoverExpand-left,
    .cardHoverExpand.active .cardHoverExpand-left {
        width: 240px;
    }
}

@media (max-width: 1194px) {
    .six-seccion-history {
        padding: 40px 30px 60px 30px;
    }
    .cardHoverExpand.active,
    .cardHoverExpand:hover {
        width: 730px !important;
    }
    .cardHoverExpand {
        gap: 15px
    }
}

@media (max-width: 1045px) {
    .cardHoverExpand-mobile {
        width: 610px!important;
        gap: 20px;
        flex-direction: column-reverse;
    }
    .cardHoverExpand-mobile .cardHoverExpand-left {
        width: 100%;
        gap: 20px
    }
    .cardHoverExpand-mobile .cardHoverExpand-left .cardHoverExpand-list {
        display: none;
    }
    .cardHoverExpand-mobile .cardHoverExpand-right {
        width: 570px !important;
        display: flex;
        opacity: initial !important;
    }
    .cardHoverExpand-mobile .cardHoverExpand-right img {
        height: 578px;
        width: 570px;
    }
    .cardHoverExpand-mobile-1 {
        background: linear-gradient(119deg, rgba(247, 226, 70, 0.01) 20.4%, rgba(255, 197, 37, 0.20) 68%), #333;
    }
}

@media (max-width: 665px) {
    .cardHoverExpand-mobile {
        width: 500px !important;
        height: 620px;
        gap: 20px;
        flex-direction: column-reverse;
    }
    .cardHoverExpand-mobile .cardHoverExpand-right {
        width: 100% !important;
        height: 100% !important;
    }
    .cardHoverExpand-mobile .cardHoverExpand-right img {
        width: 100% !important;
        height: 100% !important;
    }
    .cardHoverExpand-mobile .cardHoverExpand-left {
        gap: 10px
    }
}

@media (max-width: 560px) {
    .cardHoverExpand-mobile {
        width: 390px !important;
        height: 523.26px;
        gap: 20px;
    }
    .cardHoverExpand-mobile .cardHoverExpand-right {
        width: 100% !important;
        height: 100% !important;
    }
    .cardHoverExpand-mobile .cardHoverExpand-right img {
        width: 100% !important;
        height: 100% !important;
    }
}

@media (max-width: 430px) {
    .cardHoverExpand-mobile {
        width: 339px !important;
        height: 490.26px;
        gap: 20px;
    }
}