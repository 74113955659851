.table-container {
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
    border-radius: 13px;
}

.table-radius--top {
    border-radius: 13px 13px 0px 0px;
    padding: 17px 20px !important;
    background-color: var(--white-90) !important;
}

.table-raduis--bottom {
    border-radius: 0px 0px 13px 13px;
    padding: 17px 20px !important;
}

.table-color--text {
    color: var(--gray-1);
}

.imageClass {
    cursor: pointer;
}

.table-container .table-content-body:nth-child(odd) {
    background-color: var(--white-25) !important;
}

@media (max-width: 630px) {
    .table-radius--top {
        padding: 10px !important;
    }
}

@media (max-width: 420px) {
    
    .table-raduis--bottom {
        padding: 17px 10px !important;
    }  
}

