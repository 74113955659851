.signUpFullData-container {
    max-width: 29.438rem;
    /* padding: 0px 20px   ; */
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.signUpFullData-password {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.signUpFullData-password .textfieldPassword-container {
    width: 100%;
}

.signUpFullData-row {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.signUpFullData-goBack {
    margin-top: -20px;
    margin-bottom: -20px;
}

@media (max-width: 470px) {
    .signUpFullData-container {
        padding: 0px;
    }
}