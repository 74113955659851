.modalToken-main {
    gap: 30px;
}
.modalToken-main .modal-main .modal-container .modal__header 
.modal__heade-left {
    gap: 0px;
}
.modalToken-subtitle {
    text-align: justify
}
.modalToken-column {
    display: flex;
    flex-direction: column;
    gap: 5px
}
.modalToken-content-card {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

/**Detalle de la compra*/
.modalToken-payment {
    display: flex;
    flex-direction: column;
    gap: 30px
}

.modalToken-payment-detail {
    gap: 20px;
    display: flex;
    flex-direction: column;
}

@media (max-width: 783px){ 
    .modalToken-content-card {
        gap: 8px
    }
}

@media (max-width: 770px){ 
    .modalToken-content-card {
        gap: 20px;
        justify-content: center;
    }
}

@media (max-width: 576px){ 
    .modalToken-content-card {
        gap: 10px;
    }
}

@media (max-width: 482px){ 
    .modalToken-content-card {
        gap: 5px;
    }
}

@media (max-width: 457px){ 
    .modalToken-content-card {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}