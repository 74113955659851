.productInvoceTranslation-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.productInvoceTranslation-text {
    color: var(--gray-1);
}


.productInvoceTranslation-row-product{
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;

}

.productInvoceTranslation-row-product-m {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row !important;
    gap: 20px;
    width: 100%;

}

/* .productInvoceTranslation-product-text {
    width: 11.094rem !important;
} */

/* .productInvoceTranslation-field {
    width: 22.1rem !important;
} */

.product-2 {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.productInvoceTranslation-sizeText {
    font-size: 0.75rem;
}

.productInvoceTranslation-trashIcon {
    display: flex;
    justify-content: flex-end;
}

.product-1, .product-3 {
    display: none;
}

/* Dropdown */
.productInvoceTranslation-row-product .dropdown .dropdown-select {
    padding: 20px;
}

.productInvoceTranslation-row-product .textfield .textfield-title {
    top: 50%;
}

.productInvoceTranslation-row-product .textfield input {
    min-width: 11.094rem !important;
}

.productInvoceTranslation-move {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.productInvoceTranslation-move div {
    max-width: 100%;
}

.productInvoceTranslation-move .dropdown {
    max-width: 100%;
}

.productInvoceTranslation-move .dropdown .move-text {
    left: 0px;
}

.productInvoceTranslation-main .productInvoceTranslation-row-product .dropdown .move-text {
    left: 0px;
}

.productInvoceTranslation-photos {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

@media (max-width: 1322px) { 
    .productInvoceTranslation-field {
        width: 100%;
    }
}

@media (max-width: 1283px) { 
    /* .product-4 {
        display: none;
    }
    .product-1, .product-3 {
        display: block;
    } */
    .product-1 .textfield .productInvoceTranslation-product-text {
        width: 100% !important;
    }
    /* .product-3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
    
    } */
    .product-3 .textfield .productInvoceTranslation-product-text { 
        width: 100% !important;
    }
    .productInvoceTranslation-row-product .textfield 
    .productInvoceTranslation-field {
        width: 100% !important;
    }
}

@media (max-width: 1016px) { 
    /* .invoceTranslationSupplier-product-container .productInvoceTranslation-main .product-3 .textfield input {
        min-width: 100%;
    } */
}


@media (max-width: 976px) { 
    .productInvoceTranslation-row-product {
        flex-direction: column;
    }
    
    .productInvoceTranslation-product-text {
        width: 100% !important;
    }

    .productInvoceTranslation-field {
        width: 100% !important;
    }

    .productInvoceTranslation-row-product .textfield {
        width: 100%;
    }
}

@media (max-width: 880px) { 
    .productInvoceTranslation-row-product,
    .productInvoceTranslation-main {
        flex-direction: column !important;
        gap: 10px
    }
}

@media (max-width: 768px) { 
    .productInvoceTranslation-photos {
        gap: 5px
    }
}

@media (max-width: 700px) { 
    .productInvoceTranslation-row-product .textfield .paragraph {
        font-size: 0.9rem;
    }
}