.calendar-month-container {
    width: 100%;
    /*padding: 0px 20px;*/
    display: flex;
    flex-direction: column;
}
.calendar-month-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    cursor: pointer;
}
.calendar-month-year {
    font-size: 1.625rem;
}
.calendar-month-body {
    /*border-left: 1px solid var(--black-15);*/
    position: relative;
    /*background: var(--white-50);*/
    border-radius: 0px 0px 12px 12px;
}
.calendar-month-container .calendar-month-body .body-month-container .body-month-cell {
    height: 111px;
}
.calendar-month-container .calendar-month-body .body-month-container .body-month-event .event-month-container {
    gap: 2px
}
.calendar-month-container .calendar-month-body .body-month-container .body-month-event .event-month-container .event-month-marker {
    min-width: 3px;
}
@media (max-width: 554px) {
    .calendar-month-container {
        display: none;  
    }   
}