.faq-one-question {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 10px 15px;
	backdrop-filter: blur(40px);
	border-radius: 12px;
    background: var(--background);
    border: 1px solid;
    border-image-source: var(--border-image-source);
	max-width: 840px;
	width: 100%;
}

.faq-one-question-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.faq-one-question-title img {
	cursor: pointer;
}

.faq-one-question h2 {
	margin: 0;
	color: var(--gray-1);
	font-size: 0.95rem;
}
.faq-one-question p {
	margin: 0;
	/* padding-top: 15px; */
	color: var(--gray-1);
}

.faq-one-question-body {
	display: flex;
	flex-direction: column;
	gap: 20px
}

.question-text {
	text-align: justify;
}

.faq-one-question h4 {
	margin: 0;
}

.question-text ul {
	margin: 0px;
	padding-left: 30px;
}

.question-text ul li {
	font-size: 0.9rem;
}

@media (max-width: 600px) { 
	.faq-one-question h2 {
		font-size: 0.9rem;
	}
	.faq-one-question-body .question-text {
		font-size: 0.875rem;
	}
}

@media (max-width: 428px) { 
	.faq-one-question-body .question-text {
		text-align: start;
	}
}