@-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(calc(-250px * 7));
      transform: translateX(calc(-250px * 7));
    }
  }
  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(calc(-250px * 7));
      transform: translateX(calc(-250px * 7));
    }
  }
  .slider {
    background: pr;
    /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .slider::before,
  .slider::after {
    /* background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%); */
    content: "";
    height: 100%;
    position: absolute;
    width: 200px;
    z-index: 2;
  }
  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
  }
  .slider::before {
    left: 0;
    top: 0;
  }
  .slider .slide-track {
    -webkit-animation: scroll 30s linear infinite;
    animation: scroll 30s linear infinite;
    display: flex;
    width: calc(250px* 14);
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  /* .slider .slide {
    height: 100px;
    width: 250px;
  } */