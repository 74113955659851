.cardContainer {
    width: 100%;
    max-width: fit-content;
    height: 106px;
    display: flex;
    border-radius: 12px;
    background: var(--background);
    backdrop-filter: blur(40px);
    border-image-source: var(--border-image-source);
    overflow: hidden;

    cursor: pointer;
}

.cardContainer:hover {
    background: var(--black-15);
}

.cardContainer-content-img-service {
    width: 106px;
    height: 105.203px;
    background: var(--white);
}

.cardContainer-service-img {
    width: 106px;
    height: 105.203px;
}

.cardContainer-fact-service-header .cardContainer-fact-service-header-img {
    display: none;
}

.cardContainer-detail {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    padding: 20px;
}

/*Dato del servicio*/
.cardContainer-fact-service {
    width: 100%;
    max-width: 260px;
    display: flex;
    flex-direction: column;
    gap: 10px
}

.cardContainer-fact-service-header {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.cardContainer-fact-service-title {
    color: var(--gray-1);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.875rem;
}

.cardContainer-fact-service-check,
.cardContainer-fact-service-check div img {
    width: 14.888px;
    height: 14.723px;
}

.cardContainer-fact-service-detail {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.cardContainer-fact-service-detail .rating,
.cardContainer-fact-service-detail .commentCount,
.cardContainer-fact-service-detail .city-country-display {
    border-radius: 6px;
}

.cardContainer-fact-service-detail .rating span,
.cardContainer-fact-service-detail .commentCount span,
.cardContainer-fact-service-detail .city-country-display span {
    font-size: 0.75rem;
}

.cardContainer-fact-service-detail .city-country-display .city-country--image img {
    width: 22.009px;
    height: 11.005px;
}

/*Badget*/
.cardContainer-badget {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

.cardContainer-badget-content {
    width: 82px;
    display: flex;
    padding: 7.5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    backdrop-filter: blur(20px);    
}

/*Programation*/
.cardContainer-row-1,
.cardContainer-row-2 {
    display: flex;
    flex-direction: row;
    gap: 40px
}

.cardContainer-row-2 .cardContainer-badget {
    display: none;
}

.cardContainer-programation {
    width: 100%;
    max-width: 150px;
    min-width: 125px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardContainer-programation-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cardContainer-text-gray {
    color: var(--gray-3);
}

/*Progress*/
.cardContainer-progress {
    width: 150px;
    height: 50px;
    display: flex;
}

.cardContainer-progress .progressAnimation {
    height: 100%;
    width: 150px;
    border-radius: 6px;
}

.cardContainer-progress .progressAnimation .progressAnimation-bar {
    height: 100%;
    border-radius: 6px 0px 0px 6px;
}

/*Button*/
.cardContainer-button {
    width: 100%;
    max-width: 210px;
    min-width: 145px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cardContainer-button--active {
    background: #0989D2;
}


.cardContainer-button--disabled {
    background: var(--gray-3);
    cursor: not-allowed;
}

.cardContainer-text-white {
    color: var(--white);
}

.cardContainer-origin-destination {
    height: 19px;
    display: none;
    flex-direction: row;
    gap: 5px;
    border: 1px solid var(--black);
    padding: 0px 10px;
    border-radius: 20px;
}

.cardContainer-origin-destination div img {
    width: 11.57px;
    height: 12px;
}

.cardContainer-row-2_ {
    display: none;
}

@media (max-width: 1388px) { 
    .cardContainer-detail,
    .cardContainer-row-1,
    .cardContainer-row-2 {
        gap: 30px;
    }
}

@media (max-width: 1266px) { 
    .cardContainer-programation {
        max-width: 150px;
        min-width: 100px;
    }
    .cardContainer-progress {
        width: 130px;
    }
}

@media (max-width: 1165px) { 
    /* .cardContainer {
        height: 90px;
    } */
    /* .cardContainer-content-img-service,
    .cardContainer-service-img {
        width: 95px;
        height: 90px;
    } */
    .cardContainer-detail,
    .cardContainer-row-1,
    .cardContainer-row-2 {
        gap: 15px;
        justify-content: space-between;
    }
    /* .cardContainer-button {
        min-width: 109px;
        padding: 10px;
        border-radius: 6px;
    } */
    .cardContainer-button .small-header-medium {
        font-size: 1.1rem;
        line-height: 20px;
    }
    .cardContainer-button .smalltext {
        font-size: 0.75rem;
        line-height: 11px;
    }
}

@media (max-width: 1086px) { 
    .cardContainer-button {
        min-width: 109px;
        padding: 10px;
        border-radius: 6px;
    } 
}

@media (max-width: 1050px) { 
    .cardContainer-fact-service {
        max-width: 240px;
    }
}

@media (max-width: 1040px) {
    .cardContainer-progress {
        width: 115px;
    }
    .cardContainer-button {
        min-width: 92px;
    }
    .cardContainer-fact-service {
        max-width: 220px;
    }
}

@media (max-width: 1035px) {
    .cardContainer {
        max-width: 100%;
    }
    .SearchContainer-content-direction {
        width: 100%;
    }
}

@media (max-width: 993px) {
    .cardContainer-detail {
        padding: 20px 10px;
    }
}

@media (max-width: 978px) {
    .cardContainer-detail, 
    .cardContainer-row-1, 
    .cardContainer-row-2 {
        gap: 12px;
    }
}

@media (max-width: 972px) {
    .cardContainer-progress {
        width: 100px;
    }
    .cardContainer-progress .progressAnimation {
        width: 100%;
    }
}

@media (max-width: 960px) { 
    .cardContainer {
        max-width: 900px;
        height: fit-content;
        padding: 20px;
        gap: 10px;
        min-height: 195px;
    }
    .cardContainer-content-img-service {
        display: none;
    }
    .cardContainer-fact-service-header .cardContainer-fact-service-header-img {
        display: flex;
        background: var(--white);
        border-radius: 999px;
        width: 100%;
        max-width: 30.227px;
        height: 30px;
        overflow: hidden;
    }
    .cardContainer-service-img {
        object-fit: contain;
        width: 30.227px;
        height: 30px;
    }
    .cardContainer-detail {
        padding: 0px;
        flex-direction: column;
        justify-content: flex-start;
    }
    .cardContainer-fact-service {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        justify-content: space-between;
    }
    .cardContainer-fact-service-header {
        width: 100%;
        max-width: 500px;
    }
    .cardContainer-fact-service-title {
        width: 100%;
        max-width: fit-content;
    }
    .cardContainer-row-1 {
        width: 100%;
        gap: 40px;
    }
    .cardContainer-progress {
        display: none;
    }
    .cardContainer-badget {
        display: none;
    }
    .cardContainer-programation {
        justify-content: flex-start;
    }
    .cardContainer-row-2 {
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .cardContainer-row-2 .cardContainer-badget {
        display: flex;
    }
    .cardContainer-origin-destination {
        display: flex;
    }
    .cardContainer-programation-content_ {
        display: none;
    }
}

@media (max-width: 792px) { 
    .cardContainer-fact-service-header {
        max-width: 350px;
    }
}

@media (max-width: 662px) { 
    .cardContainer-fact-service-header {
        max-width: 300px;
    }
}

@media (max-width: 600px) { 
    .cardContainer-fact-service-detail .commentCount {
        display: none;
    }
    .cardContainer-button {
        background: none;
        flex-direction: row;
        justify-content: end;
        gap: 10px;
        padding: 0px;
    }

    .cardContainer-button--disabled > div {
        color : var(--gray-3);
    }
    .cardContainer-text-white {
        color: var(--black);
    }
    .cardContainer-button .small-header-medium {
        font-size: 1.375rem;
    }
    .cardContainer-button .smalltext {
        font-size: 0.875rem;
    }
    .cardContainer {
        min-height: 164px;
    }
    .SearchContainer-column_error {
        width: 100%;
    }
    .cardContainer-fact-service-header {
        max-width: 350px;
    }
}

@media (max-width: 580px) { 
    .cardContainer-row-1 {
        gap: 20px;
    }
}

@media (max-width: 550px) { 
    .cardContainer-fact-service-header {
        max-width: 300px;
    }
}

@media (max-width: 510px) { 
    .cardContainer-fact-service-header {
        max-width: 270px;
    }
    .cardContainer-row-1 {
        gap: 20px;
    }
}

@media (max-width: 465px) { 
    .cardContainer-fact-service-header {
        max-width: 240px;
    }
    .cardContainer-row-1 {
        gap: 8px;
    }
}

@media (max-width: 434px) { 
    .cardContainer-fact-service-header {
        max-width: 220px;
    }
}

@media (max-width: 415px) { 
    .cardContainer-fact-service-header {
        max-width: 200px;
    }
}

@media (max-width: 396px) { 
    .cardContainer-fact-service-header {
        max-width: 170px;
    }
}