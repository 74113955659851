.modalRQ {
    position: fixed;
    z-index: 4;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.15); /* Black w/ opacity */
    backdrop-filter: blur(2px);
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
}


.modalRQ-container {
    position: fixed;
    right: calc(50% - 300px);
    max-width: 600px;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    background: var(--white);
    border-radius: 24px;
}

.modalRQ-container-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.modalRQ-container-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modalRQ-container-nav--close {
    cursor: pointer;
}

.modalRQ-container-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

@media (max-width: 735px) {
    .modalRQ-container {
        position: relative;
        max-width: 100%;
        
        border-radius: 0px;
        right: 0;
    }
}

@media (max-width: 600px) {
    .modalRQ-container-buttons {
        justify-content: center;
    }

    .modalRQ-container-buttons .custom-button {
        padding: 10px 0px;
        min-width: 90px;
        max-width: 100%;
        width: 100%;
        height: 45px;
    }
}