.HeaderResponsive {
    display: flex;
    flex-direction: column;
    gap: 12px
}
.HeaderResponsive-content {
    background: var(--white);
    width: 69px;
    height: 59px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
}
.HeaderResponsive-content-now {
    background: var(--black-75);
    width: 69px;
    height: 59px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
}
.HeaderResponsive-day-now {
    color: var(--white);
    font-size: 0.7rem;
}
.HeaderResponsive-day {
    color: var(--black);
    font-size: 0.7rem;
}
.HeaderResponsive-date-now {
    color: var(--white-75);
    font-size: 0.7rem;
}
.HeaderResponsive-date {
    color: var(--gray-3);
    font-size: 0.7rem;
}