.tableBody-container {
    /* padding: 10px; */
}

.tableBody {
    min-height: 54px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 20px;
    height: fit-content;
    justify-content: space-between;
    border-radius: 24px;
    border: 1px solid #D9D9D9;
    background: linear-gradient(111deg, rgba(255, 255, 255, 0.50) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);
    -webkit-backdrop-filter: blur(4.5px);
    backdrop-filter: blur(4.5px);
}

.tableBody-text {
    width: 102.86px;
    font-size: 0.75rem;
}

.tableBody-img {
    width: 102.86px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
}

@media (min-width: 1200px) { 
    .tableBody-text {
        font-size: 0.875rem;
    }
}