.quotationPermitsComponent {
	width: 100%;
	display: flex;
	flex-direction: column;
	/* gap: 15px; */
	gap: 20px;
}

.quotationPermitsComponent-title {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	justify-content: flex-start;
	color: var(--gray-1);
}

.quotationPermitsComponent-title div {
	display: flex;
}

.quotationPermitsComponent-title--span {
	color: rgba(51, 51, 51, 0.45);
}

.quotationPermitsComponent-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 15px;
	justify-content: center;
	align-items:flex-start;
}

.quotationPermitsComponent-container .quotationPermitsComponent-attach 
.quotationPermitsComponent-attach-row .hover .hover-content .hover-text,
.quotationPermitsComponent-container .quotationPermitsComponent-attach
.quotationPermitsComponent-attach-row .hover .hover-text {
	width: 250px;
}

.quotationPermitsComponent-attach .quotationPermitsComponent-attach-container div .box-shadow-card{
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15) !important;
}

.quotationPermitsComponent-header {
	display: flex;
	gap: 10px;
	align-items: center;
	cursor: pointer;
}

.quotationPermitsComponent-title p {
	color: var(--gray-1) ;
}

.quotationPermitsComponent-title img {
	cursor: unset !important;
}

.quotationPermitsComponent-header img {
	cursor: pointer;
}

.quotationPermitsComponent-title p span {
	color: var(--gray-3) !important;
}

.quotationPermitsComponent-container p span {
	color: var(--red);
}

.quotationPermitsComponent p {
	margin: 0;
}

.quotationPermitsComponent-colorText {
	color: var(--gray-1);
}

.quotationPermitsComponent-attach-container{
	display: flex;
	width: 100%;
}

.quotationPermitsComponent-attach-container > div {
	width: 100%;
}

.quotationPermitsComponent-attach {
	width: 100%;
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: self-start;

	background: var(--black-05);
	border-radius: 24px;
	flex-wrap: wrap;
	gap: 12px;
}


.quotationPermitsComponent-attach .UploadFileArray {
	width: 100%;
	backdrop-filter: none;
	padding: 0px;
	border-radius: none;
	background: none;
	border: none;
	border-image: none;
}

.quotationPermitsComponent-attach-container .UploadFileArray .UploadFileArray-container .UploadFileArray-bottom .uploadText-nathing {
	display: none;
}

.quotationPermitsComponent-attach-row {
	/* display: flex;
	flex-direction: row;
	gap: 10px */
	flex: 1 1;
    display: flex;
    align-items: center;
    gap: 10px;
}

.quotationPermitsComponent div div .textarea {
	min-height: 80px;
	align-items: center;
    display: flex;
}

.quotationPermitsComponent div div .textarea[contenteditable]:empty::before {
    justify-content: start;
	height: 19px;
}

.quotationPermitsComponent-message {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 20px
}

.quotationPermitsComponent-message-note {
	width: 50%;
	display: flex;
	flex-direction: column;
	gap: 20px
}

.quotationPermitsComponent-note-list {
	width: 50%;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.quotationPermitsComponent-note-list ul {
	margin: 0;
}

@media (max-width:768px) {
	.quotationPermitsComponent-message {
		flex-direction: column;
	}
	.quotationPermitsComponent-message-note,
	.quotationPermitsComponent-note-list {
		width: 100%;
	}
}

@media (max-width:720px) {
	.quotationPermitsComponent-container {
		display: flex;
		flex-direction: column;
		padding-bottom: 5px;
	}
}

@media (max-width: 600px) {
	.quotationPermitsComponent-title {
		font-size: 0.875rem;
	}
	.quotationPermitsComponent {
		gap: 15px
	}
	.quotationPermitsComponent .quotationPermitsComponent-container .quotationPermitsComponent-attach 
	.quotationPermitsComponent-attach-container div .UploadFileArray {
		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15)
	}
	.quotationPermitsComponent-note-list .paragraph-header,
	.quotationPermitsComponent-note-list .paragraph {
		font-size: 0.875rem;
	}
}

@media (max-width: 492px) {
	.quotationPermitsComponent-attach-container .UploadFileArray .UploadFileArray-container .UploadFileArray-bottom 
	.updateText-content .updateText-content-button-hover .tinytext-header {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 120px;
	}
}