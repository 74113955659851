/* Contenedor principal */
.list-collection-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 12px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 310px;
    width: 100%;
    margin-bottom: 16px;
}

/* Imagen del producto */
.product-image {
    width: 35px;
    height: 35px;
    object-fit: cover;
}

/* Contenedor de texto para nombre y talla */
.product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-right: 7px;
}

/* Nombre del producto (truncado si es muy largo) */
.product-name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
}

/* Talla del producto */
.product-size {
    font-size: 12px;
    color: #888;
}

/* Selector de cantidad */
.quantity-selector {
    margin: 0 8px;
}

/* Precio del producto */
.product-price {
	font-family: 'Faktum-Medium';
	font-style: normal;
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 15px;
	color: var(--black);
}


.list-collection-container .Dropdwon .dropdown  {
    width: 50px;             
    height: 15px;        
    border-radius: 10px; 
    padding: 10px;
    margin-top: -30px;
}

.list-collection-container .Dropdwon .dropdown-list__item {
    height: 33px;
}

.column-contenedor{
    display: flex;
    flex-direction: column;
}