.liner-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.linear {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--black);
  box-shadow: 32px 0 var(--black), -32px 0 var(--black);
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: rgba(67, 65, 65, 0.133);
    box-shadow: 32px 0 rgba(67, 65, 65, 0.133), -32px 0 var(--black);
  }
  50% {
    background-color: var(--black);
    box-shadow: 32px 0 rgba(67, 65, 65, 0.133), -32px 0 rgba(67, 65, 65, 0.133);
  }
  100% {
    background-color: #FFF2;
    box-shadow: 32px 0 var(--black), -32px 0 rgba(67, 65, 65, 0.133);
  }
}