.SearchContainer {
    display: flex;
    flex-direction: column;
    padding: 20px 30xp;
}

.SearchContainer-content-text {
    display: flex;
    flex-direction: row;
    padding: 5px;
    gap: 10px;
}

.SearchContainer-text-mobile,
.SearchContainer-content-row-text {
    display: none;
}

.SearchContainer-content-text-1 {
    width: 125px;
}

.SearchContainer-content-text-2 {
    width: 240px;
}

.SearchContainer-content-text-3 {
    width: 150px;
}

.SearchContainer-content-text-4 {
    width: 160px;
}

.SearchContainer-content-card {
    width: fit-content;
    height: 70px;

    display: flex;
    flex-direction: row;
    gap: 10px;
    border-radius: 12px;
    background: var(--white);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
    padding: 5px;
}

.SearchContainer-content-row {
    display: flex;
    flex-direction: row;
    gap: 5px
}

.SearchContainer-content-row-1 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    z-index: 2;
}

.SearchContainer-content-row-1.z-index-1 {
    z-index: 1;
  }

.SearchContainer-content-row_2 {
    display: flex;
    flex-direction: row;
    gap: 10px
}

.SearchContainer-content-row .buttonTypeTransport-container {
    display: flex;
    width: 60px;
    height: 60px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.SearchContainer-column_error {
    display: flex;
    flex-direction: column;
    position: relative;
}

.SearchContainer-content-row_2_weight,
.SearchContainer-content-row_2_calendar {
    position: relative;
}

.SearchContainer-column_error .text-error {
    margin-top: 66px;
    position: absolute;
    margin-left: 23px;
}

.icon-plane {
    display: flex;
}

.SearchContainer-content-row .buttonTypeTransport-container 
.buttonTypeTransport-icon .icon-boat img {
    width: 21.021px;
    height: 23px;
    display: flex;
}

.SearchContainer-content-row .buttonTypeTransport-container 
.buttonTypeTransport-icon .icon-plan img {
    width: 30.605px;
    height: 16px;
    display: flex;
}

.SearchContainer-content-direction {
    min-width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 240px;
    height: 60px;
    padding: 20px;
    border-radius: 12px;
    border: 1.5px solid var(--gray-5);
    cursor: pointer;

    background: linear-gradient(111deg, rgba(255, 255, 255, 0.60) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);
}

.SearchContainer-content-direction .shipment .ports-content {
    width: 100%;
    max-width: 235px;
    min-width: 235px;
    left: -48px;
    z-index: 4;
}

.SearchContainer-width_xs {
    width: 100%;
    max-width: 300px;
}

.SearchContainer-width_s {
    width: 150px;
    /* max-width: 160px; */

    background: var(--black-05);
    border: 0px;
}

.SearchContainer-content-direction div img {
    width: 21px;
    height: 21px;
}

.SearchContainer-content-direction .shipment {
    width: 100%;
    max-width: 100%;
}

.SearchContainer-content-direction .shipment .textfield input {
    border-radius: 0px;
    padding: 10px 0px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15) !important;
    background: none;
}

.SearchContainer-content-direction .shipment .textfield 
input:not(:placeholder-shown).textfield-input:not(:focus) {
    padding: 22.5px 10px 7.5px 0px;
}

.SearchContainer-content-direction .shipment .textfield input:focus {
    padding: 22.5px 20px 7.5px 0px;
    border: 0px solid var(--blue-2); /* border del color cuando se selecciona para poner el texto */
}

.SearchContainer-content-direction .shipment .textfield .textfield-title {
    left: 0px;
}

/* Cambiar borde a amarillo cuando el campo de Shipment esté en foco */
.SearchContainer-content-direction:focus-within {
    border-color: var(--yellow);
}

.SearchContainer-content-direction.selected {
    border-color: var(--yellow);
}

.SearchContainer-content-direction.selected {
    border-color: var(--yellow);
}

.SearchContainer-content-direction.selected .text-error {
    display: none;
}

.SearchContainer-text-date {
    font-size: 0.875rem;
    font-family: 'Faktum-Medium';
}

.SearchContainer-button {
    width: 60px;
    height: 60px;
    background: var(--blue-2);
    border-radius: 12px;
    padding: 19.57px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: center;
    cursor: pointer;
}

.SearchContainer-button-text {
    display: none;
    color: var(--white);
}

.SearchContainer-button div img {
    width: 22px;
    height: 21px;
}

/* Modal de la carga*/
.SearchContainer-lcl {
    width: 100%;
    max-width: 850px;
    min-width: 850px;
    position: absolute;
    margin-top: 0.5rem;
    right: -15rem;
    z-index: 5;
}

.SearchContainer-modal-lcl {
    width: 100%;
    border-radius: 24px;
    background: var(--white);
}

.SearchContainer-modal-lcl .lclcd {
    max-width: 100%;
    width: 100%;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
}

.SearchContainer-modal-lcl .lclcd .lclcd-container {
    display: none;
}

.SearchContainer-content-row__display {
    display: flex;
    z-index: 0;
    position: relative;
}
.SearchContainer-content-row__display.z-index-12{
    z-index: 12;
}
.SearchContainer-line {
    display: none;
}

/* Modal calendar */
.SearchContainer-calendar {
    position: absolute;
    margin-top: 0.5rem;
    margin-left: -8rem;
    z-index: 5;
}

.SearchContainer-modal-lcl {
    border: 0.5px solid var(--gray-3);

}

.SearchContainer-modal-lcl--button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 20px 20px;
}


/*Tablet*/
@media (max-width: 1090px) {
    .SearchContainer-content-direction {
        width: 200px;
        padding: 20px 10px;
    }
    .SearchContainer-width_xs {
        width: 120px;
    }
    .SearchContainer-width_s {
        width: 140px;
    }
    .SearchContainer-content-text-2 { 
        width: 200px;
    }
    .SearchContainer-content-text-3 { 
        width: 152px;
    }
    .SearchContainer-content-text-4 { 
        width: 140px;
    }
}

@media (max-width: 989px) {
    .SearchContainer-content-text-2 {
        width: 100%;
        max-width: 190px;
    }

}

@media (max-width: 980px) {
    .SearchContainer-content-text-2 {
        width: 100%;
        max-width: 180px;
    }

}

@media (max-width: 960px) {
    .SearchContainer-content-text {
        display: none;
    }
    .SearchContainer-text-mobile,
    .SearchContainer-content-row-text {
        display: flex;
        flex-direction: row;
        gap: 20px;
        z-index: 0;
    }
    .SearchContainer-content-text-3 {
        width: 215px;
    }
	.SearchContainer-content-card {
        width: 100%;
        flex-direction: column;
        height: auto;
        background: none;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
	}
    .SearchContainer-content-direction {
        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(12px);
        border: 0;
    }
    .SearchContainer-width_s {
        max-width: 350px;
        background: linear-gradient(111deg, rgba(255, 255, 255, 0.60) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);
    }
    .SearchContainer-content-direction {
        width: 240px;
        padding: 20px;
    }
    .SearchContainer-width_xs {
        max-width: 350px;
        min-width: 220px;
    }
    .SearchContainer-width_s {
        max-width: 350px;
        min-width: 220px;
    }
    .SearchContainer-button {
        width: 125px;
        height: 60px;
    }
    .SearchContainer-button-text {
        display: flex;
    }
    .SearchContainer-calendar {
        margin-left: -4rem;
    }
    .SearchContainer-column_error .text-error {
        margin-top: 4px;
        position: relative;
        margin-left: 23px;
    }
    .SearchContainer-lcl {
        left: 0rem;
        max-width: fit-content;
        min-width: 800px;
    }
}

@media (max-width: 866px) { 
    .SearchContainer-lcl { 
        min-width: 700px;
    }
}

@media (max-width: 817px) { 
    .SearchContainer-lcl { 
        left: -1rem;
    }
}

@media (max-width: 773px) { 
    .SearchContainer-lcl { 
        min-width: 670px;
    }
}

@media (max-width: 733px) { 
    .SearchContainer-lcl { 
        min-width: 650px;
    }
}

@media (max-width: 698px) {
    .SearchContainer-content-row-1 {
        flex-direction: column;
    }
    .SearchContainer-button {
        width: 490px;
        height: 48px;
    }
    .SearchContainer-modal-lcl .lclcd .lclcd-bottom .lclcd-first-row,
    .SearchContainer-modal-lcl .lclcd .lclcd-bottom .lclcd-first-row 
    .lclcd-first-row-section-content {
        flex-direction: column;
    }
    .SearchContainer-modal-lcl .lclcd .lclcd-bottom .lclcd-first-row 
    .lclcd-first-row-section-content .lclcd-first-row-section 
    .lclcd-first-row-section-dropdown {
        max-width: 100%;
    }
    .SearchContainer-lcl {
        max-width: 98%;
        margin-top: 0.5rem;
    }
    .SearchContainer-width_s {
        max-width: 220px;
        min-width: 215px;
    }
    .SearchContainer-button {
        width: 450px;
    }
}

@media (max-width: 687px) {
    .SearchContainer-lcl {
        min-width: 600px;
    }
}

@media (max-width: 637px) {
    .SearchContainer-lcl {
        min-width: 560px;
    }
}

@media (max-width: 600px) {
    .SearchContainer-lcl {
        width: 100%;
        height: 100%;
        max-width: 100%;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background: rgba(0, 0, 0, .15);
        left: 0;
        position: fixed;
        margin-top: 0;
        top: 0;
        z-index: 10;
    }
    
    .SearchContainer-modal-lcl {
        width: 100vw;
    }

    .SearchContainer-modal-lcl .lclcd {
        max-height: 85vh;
        border: 0px;
        overflow-y: scroll;
    }
    .SearchContainer-content-text-3 {
        width: 100%;
        max-width: 100%;
    }
    .SearchContainer-content-text-4 {
        width: 100%;
        max-width: 100%;
    }
    .SearchContainer-content-row_2_ {
        flex-direction: column;
    }
    .SearchContainer-content-direction {
        width: 100%;
        z-index: 2;
    }
    .SearchContainer-button {
        width: 100%;
    }
    .SearchContainer-modal-lcl {
        bottom: 0;
        display: flex;
        position: absolute;
        border-radius: 12px 12px 0px 0px;
        padding: 20px;
    }

    .SearchContainer-modal-lcl--button {
        padding: 20px;
    }
    .SearchContainer-content-row_2_weight {
        width: 100%;
    }
    .SearchContainer-content-row_2_calendar {
        width: 100%;
    }
    .SearchContainer-calendar  {
        width: 100%;
        height: 100%;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background: rgba(0, 0, 0, .15);
        left: 0;
        margin-left: 0px;
        position: fixed;
        margin-top: 0;
        top: 0;
        z-index: 10;
    }
    .SearchContainer-modal-calendar {
        width: 100%;
        border-radius: 24px;
        background: var(--white);
        bottom: 0;
        display: flex;
        position: absolute;
        justify-content: center;
        border-radius: 12px 12px 0px 0px;
        padding: 20px;
    }
    .SearchContainer-modal-calendar .calendar-container {
        background: rgb(201,201,201);
        background: linear-gradient(0deg, rgba(201,201,201,0.46964723389355745) 0%, rgba(242,242,242,0.38561362044817926) 68%);
    }
    .SearchContainer-line {
        display: flex;
        flex-direction: row;
        width: 40px;
        height: 4px;
        border-radius: 20px;
        background: #D9D9D9;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-self: center;
        margin-bottom: 1rem;
    }
    .SearchContainer-width_xs {
        width: 100%;
        max-width: 280px;
    }
    .SearchContainer-width_s {
        max-width: 278px;
    }
    .SearchContainer-text-mobile, 
    .SearchContainer-content-row-text {
        gap: 0px
    }
}

@media (max-width: 475px) {
    .SearchContainer-width_xs,
    .SearchContainer-width_s {
        min-width: 150px;
    }
}