.recoverAccount-container {
    /* min-height: 400px; */
    min-height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.recoverAccount-confirm {
    width: 100%;
    padding: 10px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--white);
 
    background: rgba(0, 0, 0, 0.75);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
 
    border-radius: 24px;
 }

 /* .recoverAccount-container .textfield input {
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #333333;
    border-radius: 24px;
 } */

 .recoverAccount-container .textfield .textfield-title {
    color: var(--gray-1)
 }

 .recoverAccount-container .recoverAccount-confirm {
   height: 45px;
 }