.responsive-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto;
}

.responsive-table-header {
    padding: 4px 20px;
    background-color: var(--white-50);
    color: var(--gray-1);
    height: 100%;
    min-height: 30px;
    align-items: center;
}

.responsive-table-row {
    padding: 4px 20px;
    height: 100%;
    min-height: 39px;
    align-items: center;
    cursor: pointer;
}

.responsive-table-header, .responsive-table-row {
    display: flex;
    width: 100%;
    gap: 0.3rem;
}

.responsive-table-header-cell, .responsive-table-cell {
    flex: 1;
    /* padding: 8px; */
}

.service-column {
    flex: 1 !important;
}

.responsive-table-header-cell:last-child,
.responsive-table-cell:last-child {
    flex: 2; /* Hacer que la última celda ocupe más espacio */
}

.responsive-table-cell {
    width: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.responsive-table-row:nth-child(2n) {
    background: var(--white-15);
}

.responsive-redirect {
    padding: 4px 20px;
    height: 39px;
    align-items: center;
    display: flex;
}

@media (max-width: 690px) {
    .responsive-table-header-cell:last-child, 
    .responsive-table-cell:last-child {
        flex: 1;
    }

    .responsive-table-header-cell, 
    .responsive-table-cell {
        font-size: 0.75rem;
    }
    .responsive-table-cell {
        width: auto;
        overflow: visible;
        text-overflow: initial;
        white-space: normal;
    }
}

@media (max-width: 450px) {
    .responsive-table-header-cell, 
    .responsive-table-cell {
        font-size: 0.7rem;
    }
}