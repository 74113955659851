.planSection-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px
}

.planSection-container {
    max-width: 1050px;
    width: 100%;    
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
}

.planSection-switch {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.planSection-switch__text {
   font-size: 0.785rem;
   background: linear-gradient(90deg, #FFD018 44.44%, #FFC525 74.74%);
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}
.planSection-switch__text_black {
    font-size: 0.785rem;
    color: var(--black);
}

.planSection-list {
    /* max-width: 840px; */
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.planSection-row {
    display: flex;
    flex-direction: row;
    gap: 15px;
}


.planSection-chackout {
    max-width: 600px;
    width: 100%;
    margin: auto;
}

@media (max-width: 1200px) {
    .planSection-switch .switch-label {
        margin-right: 30px;
    }
}

@media (max-width: 1180px) {
    
}

@media (max-width: 1045px) {
    .planSection-container {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .planSection-list {
        max-width: 800px;
    }
}

@media (max-width: 900px) {
    .planSection-row-scroll {
        width: 100%;
        padding: 4px 30px 12px 30px;
        overflow-y: hidden;
    }
    .planSection-row {
        padding: 14px 30px 12px 0px;
        overflow: scroll;
        overflow-y: hidden;
        width: 900px;
    }
    .planSection-row::-webkit-scrollbar,
    .planSection-row-scroll::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width: 768px) {
    .planSection-row-scroll {
        padding: 4px 20px 12px 20px;
    }
    .planSection-row {
        padding: 14px 20px 12px 20px;
    }
}

@media (max-width: 600px) {
    /* .planSection-list{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    } */
    .planSection-content {
        gap: 10px
    }
    .planSection-row {
        display: flex;  
        gap: 20px;      /* height: 100vh; */
        scroll-snap-type: x mandatory;
        overflow-x: scroll;
        overflow-y: hidden;
        padding-top: 0.5rem;
        padding-bottom: 0.8rem;
        justify-content: flex-start;
        padding: 14px 20px 12px 0px;
    }

}