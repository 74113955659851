.uploadphotoShared {
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(40px);
    padding: 20px;
    border-radius: 24px;
    background: var(--background);
    border: 1px solid;
    border-image-source: var(--border-image-source);
    max-width: 840px;
}

.uploadphotoShared-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.uploadphotoShared-photos {
	max-width: fit-content;
	display: flex;
	align-items: center;
	gap: 20px;
	padding: 10px 20px;
	border-radius: 24px;
	background: var(--white-25);
}

.uploadphotoShared h1,
.uploadphotoShared p,
.uploadphotoShared span {
    margin: 0;
	color: var(--gray-1);
}

.uploadphotoShared-data {
    background: var(--white);
    border-radius: 24px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
}

.uploadphotoShared-data-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.uploadphotoShared-data-item {
    width: 100%;
}

@media (max-width: 920px) {
	.uploadphotoShared-data-row {
		flex-direction: column;
	}
}

@media (max-width: 880px) {
	.uploadphotoShared-container {
		gap: 10px
	}
	.-container .paragraph-header,
	.uploadphotoShared-container .paragraph {
		font-size: 0.9rem;
	}
	.uploadphotoShared-photos {
		gap: 5px
	}
}
@media (max-width: 700px) {
	.uploadphotoShared-container {
		gap: 10px
	}
	.uploadphotoShared-container .paragraph-header,
	.uploadphotoShared-container .paragraph {
		font-size: 0.9rem;
	}
	.uploadphotoShared-photos {
		gap: 20px
	}
}

@media (max-width: 699px) {
	.uploadphotoShared-data-row {
		flex-direction: row;
	}
}

@media (max-width: 530px) {
	.uploadphotoShared-data-row {
		flex-direction: column;
	}
}

@media (max-width: 450px) {
	.uploadphotoShared-photos {
		gap: 10px;
		padding: 10px 10px;
	}
}