.quoteResume-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* max-height: 65vh;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth; */
}

.quoteResumer-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* max-height: 65vh; */
  height: 100%;
  overflow-y: scroll;
  /* padding-left: 0.5rem;
  padding-right: 0.5rem; */
  padding: 0rem 0.5rem 7rem 0.5rem;
}

.quoteResumer-content .quoteResume-customs .quoteResume-row .textfield {
  max-width: 100%;
}

.quoteResume-row .quoteResume-row--transport- .dropdown .dropdown-select .move-text {
  max-width: 300px;
}

.quoteResume-row--transport_1,
.quoteResume-row--transport_2 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 190%;
}

.quoteResumer-content::-webkit-scrollbar {
  width: 0px;
}

.quoteResume-transport {
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* padding: 20px; */
  background-color: var(--white-90);
  border-radius: 12px;
}

.quoteResume-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.quoteResume-column {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}

.quoteResume-row-mobile_ {
  display: none;
}

.quoteResume--file {
  padding: 15px;
  background-color: var(--black-05);
  border-radius: 24px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
}

.quoteResume-customs--attached .btnIcon-extra-small {
  height: auto; 
}

.quoteResume-customs .quoteResume-row .quoteResume--file .quoteResume-customs--attached 
.btnIcon-extra-small .custom-button-icon-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  width: 100%;
}

.quoteResume--file-hover {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.quoteResume--file-hover .quoteResume--colorText {
  padding-top: 0px;
}

.quoteResume-row .dropdown {
  max-width: 370px;
  max-height: 50px;
}

.quoteResume-row .dropdown .dropdown-select .select-title {
  transform: translate(20px, 50%);
}

/* .quoteResume-row .textfield {
  max-width: 825px;
} */

.quoteResume-row .textfield .textfield-input {
  padding: 22.5px 20px 7.5px 20px;
}
.quoteResume-row
  .textfield
  input:not(:placeholder-shown).textfield-input:not(:focus) {
  padding: 22.5px 20px 7.5px 20px;
}
.quoteResume-row .textfield .textfield-title {
  left: 20px;
}

.quoteResume-row .dropdown .dropdown-select {
  max-height: 50px;
}

.quoteResume-row .textfield {
  /* max-width: 760px; */
  max-width: 825px;
  width: 100%;
  max-height: 50px;
  height: 100%;
}

.quoteResume--colorText {
  color: var(--gray-1);
  padding-top: 5px;
}
.quoteResume--file .btnIcon-disabled {
  color: var(--white) !important;
}

.quoteResume-customs {
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* padding: 20px; */
  background-color: var(--white-90);
  border-radius: 24px;
}

.quoteResume-row .dropdown {
  /* max-width: 760px; */
  max-width: 100%;
  width: 100%;
}

.quoteResume-row .insurance-container {
  /* max-width: 760px; */
  max-width: 100%;
  width: 100%;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.06);
  /* 
  backdrop-filter: blur(20px);
  padding: 10px 20px;
  height: 54px; */
}

.quoteResume-row .insurance-container .insurance-content {
  box-shadow: none;
  backdrop-filter: none;
}

.quoteResume-row .insurance-container .insurance-info,
.quoteResume-row .insurance-container .insurance-content .insurance-question .insurance-icon {
  display: none;
}

.quoteResume-products {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.quoteResume-container
  .quoteResume-transport
  .quoteResume-row
  .dropdown
  .dropdown-select
  .move-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* max-width: 200px; */
  width: 100%;
}

.quoteResume-row--transport .dropdown .dropdown-select .move-text {
  max-width: 200px;
}

.quoteResume-row--transport_ .dropdown .dropdown-select .move-text {
  max-width: 200px;
}

.quoteResume-row-width {
  /* max-width: 505px; */
  max-width: 100%;
  width: 100%;
}

.quoteResume-column .quoteResume-row--transport {
  width: 100%;
  max-width: 34%;
}

.quoteResume-row-mobile {
  display: none;
}

.quoteResume-customs--attached {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.quoteResume-row-air_ .dropdown {
  width: 50%;
}

.quoteResume-row__ div {
  width: 100%;
}

.quoteResume-row__ div .textarea {
  width: 100%;
  max-width: 100%;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(20px);
}

.quoteResume-row__ {
  margin-bottom: 1rem;
}

.quoteResume-row .quoteResume-row .insurance-container,
.quoteResume-row .quoteResume--file {
  background: var(--transparent);
  border: 1px solid var(--black-25);
}

.quoteResume-row .quoteResume-row .insurance-container .insurance-content 
.insurance-question div {
  display: none;
}
.quoteResume-row .quoteResume-row .insurance-container .insurance-content 
.insurance-question .insurance-colorText {
  display: flex;
}

.quoteResume-row-aduana {
  display: flex;
  width: 55rem;
}

.quoteResume-row--transport_column {
  width: 100%;
  border: 1px solid var(--black-25);
  border-radius: 24px;
  padding: 6px 20px;
  min-height: 49.5px;
  height: -moz-fit-content;
  height: fit-content;
}

.quoteResume-row-title {
  font-family: "Faktum";
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: var(--gray-1);
}

.quoteResume-row_value {
  font-family: 'Faktum-Medium';
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word;
  white-space: normal;
  -webkit-box-orient: vertical;
}

@media (max-width: 853px) {
  .quoteResume-row,
  .quoteResume-row--transport_1,
  .quoteResume-row--transport_2 {
    gap: 10px;
  }
  .quoteResume-column {
    gap: 10px;
  }
}

@media (max-width: 835px) {
  .quoteResume-row .quoteResume-row--transport- .dropdown .dropdown-select .move-text {
    max-width: 250px;
  }
}


@media (max-width: 828px) {
  /* .quoteResume-row .dropdown .dropdown-select .select-title {
    transform: translate(10px, 50%);
  } */
  .quoteResume-row .dropdown .dropdown-select .move-text {
    font-size: 0.9rem;
  }
  .quoteResume-row .textfield .textfield-input {
    padding: 22.5px 10px 7.5px 10px;
  }
}

@media (max-width: 803px) {
  .quoteResume-customs .quoteResume-row .quoteResume--file .quoteResume-customs--attached 
  .btnIcon-extra-small .custom-button-icon-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 130px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  /* .quoteResume-row {
        flex-direction: column;
    }   */
  .quoteResume--file {
    align-items: flex-start;
  }

  /* .quoteResume-row .insurance-container {
        max-width: 95%;
    }
    .quoteResume-row .insurance-container {
        max-width: 90%;
    } */
}

@media (max-width: 764px) {
  .quoteResume-customs .quoteResume-row .quoteResume--file .quoteResume-customs--attached 
  .btnIcon-extra-small .custom-button-icon-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
    width: 100%;
  }
}

@media (max-width: 738px) {
  .quoteResume-row-width {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 736px) {
  .quoteResume-row-width {
    width: 66%;
  }
}

@media (max-width: 727px) {
  .quoteResume-row-width {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 710px) {
  .quoteResume-row--transport- {
    flex-direction: column;
  }
  .quoteResume-row--transport_1, 
  .quoteResume-row--transport_2 {
    width: 100%;
  }
}

@media (max-width: 688px) {
  .quoteResume-row-desktop_file {
    display: flex;
    flex-direction: column;
  }
  .quoteResume-customs .quoteResume-row .quoteResume--file .quoteResume-customs--attached 
  .btnIcon-extra-small .custom-button-icon-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 350px;
    width: 100%;
  }
}

@media (max-width: 666px) {
  .quoteResume-row .quoteResume-row--transport- .dropdown .dropdown-select .move-text {
    max-width: 220px;
  }
}


@media (max-width: 612px) {
  /* .quoteResume--file .custom-button-icon .custom-button-icon-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 350px;
    width: 100%;
  }
  .quoteResume-row-desktop {
    display: none;
  }
  .quoteResume-row-mobile_ {
    display: flex;
  } */
}

@media (max-width: 600px) {
  .quoteResumer-content {
    height: 100%;
    max-height: 100%;
    overflow-y: unset;
  }
  .quoteResume-row-aduana {
    width: 100%;
  }
  .quoteResume-row__ {
    margin-bottom: 0rem;
  }
  .quoteResume--colorText {
    font-size: 0.875rem
  }
  .quoteResume-column, 
  .quoteResume-row-width {
    flex-direction: column;
    gap: 10px;
  }
  .quoteResume-row--transport-direction {
    margin-top: -5px;
  }
  .quoteResume-column .quoteResume-row--transport {
    max-width: 100%;
  }
  .quoteResume-row-air {
    flex-direction: column;
  }
  .quoteResume-row-air_mobile {
    flex-direction: column !important;
  }
  .quoteResume-row-air_mobile .quoteResume-row-air {
    flex-direction: row;
  } 
  .quoteResume-row-air_mobile .dropdown {
    width: 100%;
  }
  .quoteResume-row-air_ {
    gap: 10px;
    display: flex;
    flex-direction: row;
  }
  .quoteResume-row-desktop {
    display: none;
  }
  .quoteResume-row-mobile__ {
    display: flex;
    gap: 10px;
  }
  .quoteResume-row-air_movile-four {
    display: flex;
    flex-direction: row;
    margin-top: -0.rem;
  }
  .quoteResume-row_value { 
    font-size: 0.875rem;
  }

  /* .quoteResume-container {
    padding: 0rem 0.5rem;
  }
  .quoteResume-row {
    gap: 20px;
  }
 
  .quoteResume-row .insurance-container {
    padding: 0px;
    background: none;
  }
  .quoteResume-column .quoteResume-row--transport {
    width: 100%;
    max-width: 100%;
  }
  .quoteResume-row-mobile {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .quoteResume-row-mobile_ {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .quoteResume-row-desktop {
    display: none;
  }
  .quoteResume-row .textfield .textfield-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 75%;
  }
  .quoteResume-row .textfield .textfield-title {
    left: 20px;
  }
  .quoteResume-row .dropdown .dropdown-select .move-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 75%;
  }
  .quoteResume-row-mobile .textfield .textfield-input {
    padding: 22.5px 6px 7.5px 10px;
  }
  .quoteResume-row-mobile .textfield .textfield-title {
    left: 10px;
  }
  .quoteResume-row-mobile_ .dropdown .dropdown-select .select-title {
    transform: translate(10px, 50%);
  }
  .quoteResume-row-mobile_ .dropdown .dropdown-select .move-text {
    left: 0px;
  } */
  /* .quoteResume-transport .textfield .textfield-title {
    left: 10px;
  } */
  /* .quoteResume-transport,
  .quoteResume-customs {
    background-color: transparent;
  } */
  /* .quoteResume-row--transport_ .textfield {
    width: 180%;
  } */
  /* .quoteResume-customs--attached {
    flex-direction: column;
    justify-content: flex-start;
  }
  .quoteResume-column .quoteResume-row--transport_ {
    max-width: 100%;
    width: 100%;
    flex-direction: column;
  }
  .quoteResume-transport .textfield input:not(:placeholder-shown).textfield-input:not(:focus) {
    padding: 31.5px 20px 7.5px 20px;
  }
  .quoteResume--file {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .quoteResume-customs--attached .custom-button-icon .custom-button-icon-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    max-width: 200px;
  }
  .quoteResume-row-width .dropdown {
    max-width: 100%;
    width: 100%;
  }
  .quoteResume-row--transport- .quoteResume-row--transport_1 .dropdown,
  .quoteResume-row--transport- .quoteResume-row--transport_2 .dropdown  {
    width: 60%;
  }
  .quoteResume-row--transport_1, .quoteResume-row--transport_2 {
    gap: 20px;
  }
  .quoteResume-row-aduana {
    display: flex;
    width: 30rem;
  }
  .quoteResume--file  {
    gap: 10px
  }
  .quoteResume-row { 
    gap: 10px;
  }
  .quoteResumer-content {
    max-height: 100%;
  }
  .quoteResume-row .dropdown .dropdown-select .select-title {
    transform: translate(21px, 50%);
  }
  .quoteResume-row .textfield input:not(:placeholder-shown).textfield-input:not(:focus) {
    padding: 22.5px 10px 7.5px 20px;
  }
  .quoteResume--colorText {
    font-size: 0.875rem;
  } */
}

@media (max-width: 478px) { 
  .quoteResume-customs .quoteResume-row .quoteResume--file .quoteResume-customs--attached 
  .btnIcon-extra-small .custom-button-icon-text{
    max-width: 250px;
    
  }
 
}

@media (max-width: 400px) { 
  .quoteResume-row-aduana-input .textfield {
    width: 80%;
  }
}

@media (max-width: 395px) { 
  .quoteResume-row-air_movile-four,
  .quoteResume-row-air_movile-four .quoteResume-row-air_ {
    gap: 5px
  }
}

@media (max-width: 383px) { 
  .quoteResume-customs .quoteResume-row .quoteResume--file .quoteResume-customs--attached 
  .btnIcon-extra-small .custom-button-icon-text {
    max-width: 200px;
  }
  .quoteResume-row .textfield {
    max-width: 43%;
  }
  .quoteResume-row--transport .textfield {
    max-width: 100%;
  }
  .quoteResume-column .quoteResume-row-width .textfield {
    max-width: 100%;
  }
  .quoteResume-row-air_movile-four .quoteResume-row-air_ .dropdown .dropdown-select {
    padding: 20px 12px;
  }
  .quoteResume-row-air_movile-four .quoteResume-row-air_ .dropdown .dropdown-select 
  .select-title {
    transform: translate(12px, 50%);
  }
}
/* 
@media (max-width: 415px) {

  .quoter-content:has(.quoteResume-container) {
    max-height: 67vh;
  }
}  */