.goBack-back {
    width: 100%; 
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 10px;

    cursor: pointer;
}

.goBack-back div {
    display: flex;
}

.goBack-text {
    color: var(--black);
}