.go-back-button {
	display: flex;
	align-items: center;
	gap: 10px;
	height: 43px;
	max-width: fit-content;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

@media (max-width: 600px) {
	.go-back-button-text {
		display: none;
	}
}