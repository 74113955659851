.new-quote-customs-data {
    /* background: var(--white-50);
    border-radius: 24px; */
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* backdrop-filter: blur(40px); */
}

.new-quote-customs-data-title {
    color: var(--gray-1);
}

.new-quote-customs-data-row__, .new-quote-customs-data-row__check,
.new-quote-customs-data-row__check_  {
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}

.new-quote-customs-data-row__upload {
    /* justify-content: flex-start; */
    justify-content: space-between;
    align-items: flex-start;
}

.new-quote-customs-data-row__m, .new-quote-customs-data-row__1, 
.new-quote-customs-data-row__0, .new-quote-customs-data-row__m1 {
    display: none;
}

.new-quote-customs-data-center {
    align-items: center;
}

.new-quote-customs-column__ {
    gap: 6px;
    display: flex;
    flex-direction: column;
}

.new-quote-customs-data-title___ {
    justify-content: center;
    display: flex;
}

.new-quote-customs-data-check {
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.new-quote-customs-data-upload {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;
    border: 1px solid var(--black-25);
    /* background: var(--black-05); */
    border-radius: 24px;
    padding: 12px;
    align-items: flex-start;
    max-width: 253px;
    width: 100%;
}

.new-quote-customs-data-upload--courier {
    max-width: 100%;
    width: 100%;
}

.new-quote-customs-data-upload-text {
    color: var(--gray-1);
}

.new-quote-customs-data-upload--attached {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}

.new-quote-customs-column__seguro {
    border: 1px solid var(--black-25);
    padding: 15px 11px;
    border-radius: 24px;
}

.new-quote-customs-data-title_situation {
    padding-top: 15px;
}

/* Responsive  */
@media (max-width: 1325px) { 
    .new-quote-customs-data-row__ {
        gap: 10px
    }
    .new-quote-customs-data-upload {
        max-width: 260px;
    }
}
@media (max-width: 1312px) { 
    .new-quote-customs-data-row__ .move-text {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
}
@media (max-width: 1218px) { 
    .new-quote-customs-data-upload {
        max-width: 250px;
    }
}
@media (max-width: 1189px) { 
    .new-quote-customs-data-upload {
        max-width: 237px;
    }
}
@media (max-width: 1158px) { 
    .new-quote-customs-data-upload {
        max-width: 227px;
    }
}
@media (max-width: 1150px) { 
    .new-quote-customs-data-row__upload{
        flex-direction: column;
    }
    .new-quote-customs-data-upload {
        max-width: 100%;
    }
}
@media (max-width: 1045px) { 
    .new-quote-customs-data-row__ .dropdown .dropdown-select .move-text {
        max-width: 92.6px;
    }

    .new-quote-customs-data-row__upload{
        display: flex;
    } 

    .new-quote-customs-data-row__m .dropdown .dropdown-select .move-text {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }

    .new-quote-customs-data-row__1, .new-quote-customs-data-row__m {
        display: flex;
        gap: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (max-width: 1020px) {
    .new-quote-customs-data-upload-text {
        width: 136px;
    }
    /* .new-quote-customs-data .new-quote-customs-data-upload .new-quote-customs-data-upload--attached 
    .updateText-content .updateText-content-button-file {
        max-width: 300px;
    } */
}
@media (max-width: 968px) {
    .new-quote-customs-data-row__ {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 944px) {
    .new-quote-customs-data-upload-text {
        width: 136px;
    }
    /* .new-quote-customs-data .new-quote-customs-data-upload .new-quote-customs-data-upload--attached 
    .updateText-content .updateText-content-button-file {
        max-width: 200px;
    } */
}
@media (max-width: 885px) { 
    .new-quote-customs-data-row__check_ {
        gap: 10px
    }
}
@media (max-width: 880px) { 
    .new-quote-customs-data {
        gap: 10px
    }
    .new-quote-customs-data-title_situation {
        padding-top: 0rem;
    }
    .new-quote-customs-data-row__upload{
        flex-direction: column;
    }
    .new-quote-customs-data-upload {
        max-width: 100%;
    }
    .new-quote-customs-data-row__ .textfield .textfield-input {
        font-size: 0.9rem !important;
    }
    .new-quote-customs-data-row__  .textfield .textfield-title {
        font-size: 0.65rem !important;
    }
    .new-quote-customs-column__ div .textarea-disabled {
        font-size: 0.9rem !important;
    }
}

@media (max-width: 850px) { 
    .new-quote-customs-data-row__1, .new-quote-customs-data-row__m {
        display: none;
    }
    .new-quote-customs-data-row__upload {
        justify-content: flex-start;
    }
}

@media (max-width: 800px) { 
    .new-quote-customs-data-upload {
        max-width: 100%;
    }
    .new-quote-customs-data-row__upload {
        justify-content: flex-start;
        flex-direction: column;
    }
}

@media (max-width: 768px) { 
    .new-quote-customs-data-row__check {
        gap: 10px
    }
    .new-quote-customs-data-row__check_ {
        gap: 5px
    }
    .new-quote-customs-data-check {
        gap: 5px;
    }
    .new-quote-customs-data-row__check_answer {
        font-size: 0.75rem;
    }
}


@media (max-width: 719px) { 
    .new-quote-customs-data-row__upload{
        flex-direction: column;
    }
    .new-quote-customs-data-upload {
        max-width: 100%;
    }
    /* .new-quote-customs-data .new-quote-customs-data-upload .new-quote-customs-data-upload--attached 
    .updateText-content .updateText-content-button-file {
        max-width: 300px;
    } */
}

@media (max-width: 700px) { 
    .new-quote-customs-data-check,
    .new-quote-customs-data-row__check_ {
        gap: 10px
    }
    .new-quote-customs-data-row__check_answer {
        font-size: 0.875rem;
    }
}

@media (max-width: 660px) { 
    .new-quote-customs-data-row__1, .new-quote-customs-data-row__m {
        display: flex;
        gap: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (max-width: 600px) { 
    .new-quote-customs-data-row__, .new-quote-customs-data-row__check, .new-quote-customs-data-row__check_ {
        gap: 10px;
    }
    .new-quote-customs-data-row__check {
        flex-direction: row;
    }
    .new-quote-customs-data-title {
        font-size: 0.875rem;
    }
    .new-quote-customs-data-upload-text,
    .new-quote-customs-data-row__check_answer {
        font-size: 0.875rem;
    }
    .new-quote-customs-data-row__check_ .checkbox-container input[type="checkbox"],
    .new-quote-customs-data-row__check_ .checkbox-container label::before {
        width: 27px;
        height: 27px;
    }
}

@media (max-width: 550px) { 
    /* .new-quote-customs-data-row__,  .new-quote-customs-data-row__m {
        display: flex;
    } */
    .new-quote-customs-data-row__1{
        display: flex;
        gap: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .new-quote-customs-data-title {
        width: 100%;
    }
    .new-quote-customs-data-check {
        justify-content: flex-end;
        width: 100%;
    }
 
}

@media (max-width: 475px) { 
    .new-quote-customs-data-row__1, .new-quote-customs-data-row__m {
        gap: 10px;
    }
}

@media (max-width: 447px) { 
    .new-quote-customs-data-upload .new-quote-customs-data-upload--attached 
    .updateText-content .updateText-content-button-file {
        max-width: 170px !important;
    }
}


@media (max-width: 427px) { 
    .new-quote-customs-data-upload{
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
}

@media (max-width: 404px) { 
    .new-quote-customs-data-upload {
        align-items: flex-start;
    }
    .new-quote-customs-data-upload--attached {
        justify-content: flex-start;
    }
}

@media (max-width: 390px) {  
    .new-quote-customs-data-center {
        gap: 10px;
    }
    .new-quote-customs-data-center .paragraph-header{ 
        font-size: 0.85rem;
    }
    .new-quote-customs-data-check {
        gap: 10px
    }
}