.whatsappWidget {
    z-index: 2;
}

.whatsapp_float {
    position: fixed;
    overflow: hidden;
    width: 70px;
    height: 70px;
    bottom: 5rem;
    right: 10px;
    background-color: #26ad60;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 9;
}

.whatsapp-icon {
    margin-top: 10px;
    margin-left: 2px;
    max-height: 48px;
    height: 100%;
}

@media (max-width: 767px) {
    .whatsapp_float {
        width: 60px;
        height: 60px;
        bottom: 5rem;
        right: 10px;
        font-size: 22px;
        /* top: 90vh; */
    }
    
    .whatsapp-icon {
        margin-top: 8px;
        height: 42px;
    }
}

@media (max-width: 600px) { 
    .whatsappWidget {
        z-index: 9;
    }
}

@media (max-width: 380px) {
    .whatsapp_float {
        bottom: 3rem;
    }
}