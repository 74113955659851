.updateText-content {
    cursor: pointer;
    align-items: center;
    width: 100%;
    max-width: fit-content;
}

.updateText-content-file {
    cursor: pointer;
}

.updateText-content-button {
    display: flex;
    min-width: 103px;
    height: 26px;
    /* padding: 5px 15px; */
    padding: 5px 10px 5px 15px;
    gap: 10px;
    background-color: var(--blue-2);
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    align-self: center;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}
.updateText-content-button-text {
    color: white;
}

.updateText-content-button-file {
    display: flex;
    /* min-width: 103px; */
    min-width: 50px;
    height: 26px;
    padding: 5px 15px;
    
    gap: 10px;
    border: 0px solid #333333;
    background: var(--negro-transparencia-black-25, rgba(0, 0, 0, 0.25));
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    border-radius: 12px;
    justify-content: center;
    flex-direction: row;
}

.updateText-content-text {
    width: 100%;
    max-width: 135px;
}

.updateText-content-text a {
    color: var(--white);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
    text-align: center;
    width: 100%;
    display: block;
}

.updateText-content-button-hover {
    display: flex;
    /* min-width: 103px; */
    min-width: 50px;
    /* height: 26px; */
    padding: 5px 15px;
    
    gap: 10px;
    border: 0px solid #333333;
    background: var(--negro-transparencia-black-25, rgba(0, 0, 0, 0.25));
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    border-radius: 12px;
    justify-content: center;
    flex-direction: row;
}

.updateText-a {
    text-decoration: none;
    color: var(--gray-1);
}

.updateText-icon {
    display: flex;
    align-items: center;
    align-self: center;
    cursor: pointer;
}

/* .updateText-icon div {
    display: flex;
} */

.updateText-content-button-file .tinytext-header {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    min-width: 35px;
    width: 100%;
    white-space: nowrap;
}

.updateText-icon-close {
    display: none;
}

/** Hover */
.updateText-content:hover > .updateText-content-button-hover {
    gap: 10px;
    /* padding: 5px 15px 5px 15px; */
}
.updateText-content-button-file:hover {
    padding: 5px 15px 5px 15px;
}

.updateText-content> .updateText-content-button-file {
    gap: 10px; max-width: fit-content;
} 
.updateText-content:hover > .updateText-content-button-hover .updateText-icon .updateText-icon-close {
    display: flex;
}

.updateText-icon div img {
    display: flex;
}

@media (max-width: 584px) {  
    .updateText-content-button-file .tinytext-header {
        max-width: 250px;
    }
}

@media (max-width: 505px) { 
    .updateText-content-button-file .tinytext-header {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
}