.buttonSignEmail-container {
    max-width: 350px;
    width: 100%;
    height: 45px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
   
    background: rgba(255, 255, 255, 0.9);
    /* border: 1px solid #333333; */
    border-radius: 24px;
    cursor: pointer;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}
.buttonSignEmail-container div {
    width: 26px;
    height: 26px;
}
.buttonSignEmail-container div img {
    width: 26px;
    height: 26px;
}
.buttonSignEmail-container .buttonSignEmail-text {
    width: fit-content;
}
.buttonSignEmail-text {
    color: var(--gray-1);
    align-items: center;
    align-self: center;
    display: flex;
}