.tools {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tools-first {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 180px 120px 30px 120px;
    align-items: center;
    background: linear-gradient(100deg, rgba(19, 19, 19, 0.05) 32.19%, rgba(255, 193, 87, 0.30) 96.43%);
}

.tools-content {
    width: 100%;
    max-width: 999px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px
}

.tools-title-min {
    font-size: 1.25rem;
}

.tools-title {
    font-size: 3.75rem;
    font-family: "Faktum-Bold";
    padding-bottom: 20px;
    text-align: center;
    text-shadow: -6px 9px 20px rgba(255, 197, 37, 0.36), 6px -9px 20px rgba(255, 197, 37, 0.36);
}

.tools-white {
    color: var(--white);
}

.tools-text-degradado {
    font-size: 3.75rem;
    font-family: 'Faktum-Bold';
    background: -webkit-linear-gradient(#F7E246, #FFC525);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.tools-button {
    width: 235px;
    display: flex;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    cursor: pointer;
    background: linear-gradient(94deg, #F7E246 0%, #FFC525 73.97%);

    backdrop-filter: blur(20px);
    margin-bottom: 20px;
}

.tools-img-main {
    width: 960px;
    height: 600px;
}

.tools-second {
    width: 100%;
    display: flex;
    padding: 60px 120px 30px 120px;
    flex-direction: column;
    align-items: center;
    gap: 40px
}

.tools-second-header {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.tools-second-title,
.tools-thrid-title {
    font-size: 2.813rem;
    font-family: "Faktum-Bold";
    text-align: center;
}

.tools-second-title {
    padding-bottom: 0rem;
}

.tools-thrid-title  {
    padding-bottom: 0rem;
    text-align: center;
}

.tools-text-second-degradado {
    font-size: 2.813rem;
    font-family: 'Faktum-Bold';
    background: -webkit-linear-gradient(#F7E246, #FFC525);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} 

.tools-subtitle {
    font-size: 1.5rem;
}

.tools-second-subtitle {
    font-family: "Faktum";
}

.tools-second-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    gap: 30px;
}

.tools-second-item {
    display: flex;
    width: 300px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.tools-second-img {
    height: 40px;
    width: 40px;
}

.tools-content-m,
.tools-content-s,
.tools-content-xs {
    display: none;
}

@media (max-width: 1245px) {
    .tools-first  {
        padding: 180px 30px 0px 30px;
    }
    .tools-second {
        padding: 60px;
    }
}

@media (max-width: 1200px) {
    .tools-title,
    .tools-second-title,
    .tools-text-degradado,
    .tools-text-second-degradado {
        font-size: 3.6rem;
    }
}

@media (max-width: 1045px) {
    
    .tools-title-min,
    .tools-second-subtitle,
    .tools-item-title {
        font-size: 1.1rem;
    }
    .tools-subtitle {
        font-size: 1.4rem;
    }
    .tools-img-main {
        width: 860px;
        height: 550px;
    }
    .tools-item-description {
        font-size: 0.9rem;
    }
    .tools-second-item {
        width: 250px;
    }
    .tools-thrid {
        padding: 0px 60px;
    }
}

@media (max-width: 956px) {
    .tools-first {
        padding: 115px 30px 0px 30px;
    }
    .tools-second {
        padding: 60px 0px;
        overflow: hidden;
    }
    .tools-second-header {
        padding: 0px 30px;
    }
    .tools-thrid {
        padding: 0px 30px;
    }
    .tools-title, 
    .tools-second-title,
    .tools-text-degradado,
    .tools-text-second-degradado {
        font-size: 2rem;
    }
    .tools-title-min, 
    .tools-second-subtitle, 
    .tools-item-title {
        font-size: 1rem;
    }
    .tools-subtitle {
        font-size: 1.3rem;
    }
    .tools-img-main {
        width: 760px;
        height: 500px;
    }
    .tools-second-body-content {
        width: 100%;
        max-width: 870px;
        padding-left: 30px;
        overflow: scroll;
    }
    .tools-second-body-content::-webkit-scrollbar {
        display: none;
    }
    .tools-second-body {
        max-width: 845px;
        min-width: 845px;
        display: flex;
        gap: 2rem;
        width: 100%;
        scroll-snap-type: x mandatory;
    }
    .tools-second-item {
        height: 280px;
        padding: 20px;
        border-radius: 24px;
        scroll-snap-type: x mandatory;
        background: rgba(51, 51, 51, 0.60);
        -webkit-backdrop-filter: blur(4.5px);
        backdrop-filter: blur(4.5px);
    }
}

@media (max-width: 820px) {
    .tools-img-main {
        width: 660px;
        height: 450px;
    }
}

@media (max-width: 771px) {
    .tools-subtitle {
        font-size: 1.3rem;
    }
    .tools-img-main {
        width: 100%;
        height: auto;
    }
}

@media (max-width: 772px) {
    .tools-content-m {
        display: flex;
        flex-direction: column;
    }
    .tools-content-w {
        display: none;
    }
}

@media (max-width: 683px) {
    .tools-content-s {
        display: flex;
        flex-direction: column;
    }
    .tools-content-m {
        display: none;
    }
}

@media (max-width: 665px) {
    .tools-first {
        padding: 115px 20px 0px 20px;
    }
    .tools-title,
    .tools-second-title,
    .tools-text-degradado,
    .tools-text-second-degradado {
        font-size: 2.125rem;
    }
    .tools-second-header {
        padding: 0px;
    }
    .tools-second-body-content {
        padding-left: 0px;
        gap: 1rem;
        display: flex;
    }
    .tools-second-body {
        width: 100%;
        gap: 1rem;
        max-width: 750px;
        min-width: 750px;
    }
    .tools-subtitle {
        font-size: 1.1rem;
        line-height: 30px;
    }
    .tools-second {
        padding: 30px 20px;
    }
    .tools-thrid {
        padding: 0px 20px;
    }
}

@media (max-width: 560px) {
    .tools-second-item {
        width: 400px;
    }
}

@media (max-width: 482px) {
    .tools-content-s {
        display: none;
    }
    .tools-content-xs {
        display: flex;
        flex-direction: column;
    }
    .tools-second-item {
        width: 300px;
    }
    .tools-subtitle {
        font-size: 1rem;
        line-height: 30px;
    }
}