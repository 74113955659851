.quoteCustoms-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.quoteCustoms-container__scroll  {
    /* max-height: 65vh; */
    height: 100%;
    overflow-y: scroll;
    /* padding-left: 0.5rem;
    padding-right: 0.5rem; */
    padding: 0rem 0.5rem 7rem 0.5rem;
}

.quoteCustoms-container__scroll::-webkit-scrollbar {
    width: 0px;
}

.customsRegime-container .dropdown__disabled {
    background: var(--black-05);
}

.customsRegime-container__scroll {
    max-height: 55vh;
    height: 100%;
    overflow-y: scroll;
}

.customsRegime-container__scroll::-webkit-scrollbar {
    width: 0px;
}

/* @media (max-height: 990px) and (max-width: 700px) {
    .quoteCustoms-container {
        max-height:  75vh;
    }
}

@media (min-height: 920px) and (min-width: 416px) {
    .quoteCustoms-container {
        max-height:  76vh;
    }
}

@media (max-height: 920px) and (max-width: 415px) {
    .quoteCustoms-container {
        max-height:  70vh;
    }
}

@media (max-height: 863px) and (max-width: 600px) {
    .quoteCustoms-container  {
        max-height:  66vh;
    }
}

@media (max-height: 900px) and (max-width: 600px) {
    .quoteCustoms-container {
        max-height:  60vh;
    }
}

@media (max-height: 667px) and (max-width: 600px) {
    .quoteCustoms-container {
        max-height:  70vh;
    }
} */

@media (max-height: 645px) {
    .quoteCustoms-container {
        max-height:  62vh;
    }
}

@media (max-width: 600px) {
    .quoteCustoms-container {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        gap: 20px;
        max-height: 100%;
    }
    .quoteCustoms-container__scroll .insurance-container {
        flex-direction: row-reverse;
    }
    .quoteCustoms-container__scroll .quotationPermitsComponent .quotationPermitsComponent-title
    .quotationPermitsComponent-title--span {
        display: none;
    }
    .quoteCustoms-container__scroll .insurance-container .insurance-info .hover .hover-text { 
        left: 0px;
    }
}

@media (max-width: 600px) {
    .modalFull-main:has(.quoteTransport-container) {
        height: 100%;
    }

}

@media (max-width: 384px) {
    .quoteCustoms-container__scroll .insurance-container .insurance-info .hover .hover-text { 
        left: 2rem;
    }
}
/* 
@media (max-width: 580px) {
    .customsRegime-container__scroll {
        max-height: 65vh;
        height: 100%;
        padding-bottom: 1rem;
        overflow-y: scroll;
    }
}

@media (max-height: 649px) {
    .customsRegime-container__scroll {
        max-height: 62vh;
        height: 100%;
        padding-bottom: 1rem;
    }
}

@media (max-height: 580px) {
    .customsRegime-container__scroll {
        max-height: 55vh;
        height: 100%;
        padding-bottom: 1rem;
    }
} */