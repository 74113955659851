.order-display {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* gap: 10px; */
	gap: 25px;	
	padding: 20px;
	backdrop-filter: blur(40px);
	border-radius: 24px;
    background: var(--background);
    border: 1px solid;
    border-image-source: var(--border-image-source);
}

.order-display-header-content {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.order-display-topp {
	padding-top: 1rem;
}

.order-display-top {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	padding-top: 0.4rem;
}

.order-display-header {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
}

.order-display-subheader {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 20px;
}

.order-display-subheader .order-display-ratings .rating {
	box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

.order-display-subheader .small-subheader {
	font-size: 1rem;
}

.order-display-ratings .city-country-display {
	min-width: fit-content;
}

.order-display-ratings {
	display: flex;
	align-items: center;
	gap: 10px;
}

.order-display-social {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
}

.order-display-mid {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;

	overflow: hidden;
}

.order-display-main-img {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	height: 400px;
}

.order-display-img {
	flex: 1;
	border-radius: 24px;
	min-width: 250px;
	max-height: 400px;
    min-height: 400px;
    aspect-ratio: 16 / 9;
	height: 100%;
	/* max-width: 764.44px; */
	max-width: 760px;
    /* min-width: 764.44px; */
	object-fit: cover;
}

.order-display-video {
	position: relative;
	max-width: 760px;
	width: 100%;
	max-height: 400px;
    min-height: 400px;
	height: 100%;
	border-radius: 24px;
	overflow: hidden;
}

.order-display-video-frame {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border: none;
}

.order-display-mini-imgs {
	display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 5px;
    padding: 0 20px;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;
}

.order-display-mini-imgs::-webkit-scrollbar {
    background: transparent;
	height: 6px;
}

.order-display-mini-imgs::-webkit-scrollbar:hover {
    background: white;
    border-radius: 24px;
	
}
/* Handle on hover */
.order-display-mini-imgs::-webkit-scrollbar-thumb:hover {
    background: var(--gray-2);
    border-radius: 24px; 
}

.order-display-mini-imgs img {
	width: 50px;
    height: 50px;
	border-radius: 12px;
	object-fit: cover;
	/* object-fit: scale-down; */
	aspect-ratio: 16 / 9;
	background: white;
	
}

.order-display-bot {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	justify-content: flex-start;
	text-align: justify
}

.order-display h5 {
	font-family: 'Faktum-SemiBold';
	font-style: normal;
	font-weight: 500;
	font-size: 1.1rem;
	line-height: 25px;
	margin: 0;
}

.order-display ul {
	line-height: 25px
}

.order-display h1 {
	margin: 0;
	color: var(--gray-1);
	font-size: 1.5rem;
	line-height: 35px;
	-webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}

.order-display p {
	margin: 0;
	color: var(--gray-1);
}

.order-display-main-btn {
	width: 100%;
}

.order-display-main-btn button {
	width: 100%;
}

.order-display-header-mobile {
	display: none;
}

.order-display-images-mobile {
	display: none;
}

.order-display-images-mobile-video {
	border-radius: 12px;
}

.order-display-mini-imgs--active {
	border-radius: 14px;
    border: 1.5px solid var(--gray-2);
    height: 53px;
    width: 53px;
}

.serviceDisplay-more-description {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
	font-size: 0.9rem;
}

/* .order-display-mini-imgs-horizontal-scroll {
	display: flex;
	gap: 5px;
	height: 20px;
	width: 100%;
	max-width: 100%;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
	scrollbar-width: none;
}
.horizontal-scroll__item  {
	align-items: center;
    background-color: red;
    color: white;
    border: 1px solid black;
    display: flex;
    flex: 0 0 320px;
    font-size: 10px;
    justify-content: center;
    scroll-snap-align: start;
} */

.order-display-keyboards {
	display: flex;
	flex-direction: row;
	gap: 5px;
	width: 100%;
    flex-wrap: wrap;
}

.order-display-main-btn {
	display: none;
}

@media (max-width: 1238px) {
	.order-display-main-img {
		height: 360px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 360px;
	}
}

@media (max-width: 1200px) {
	.order-display-main-img {
		height: 340px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 340px;
	}
}

@media (max-width: 1185px) {
	.order-display-main-img {
		height: 320px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 320px;
	}
}

@media (max-width: 1100px) {
	.order-display-main-img {
		height: 290px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 290px;
	}
}

@media (max-width: 1024px) {
	.order-display h1 {
		font-size: 1.3rem;
		line-height: 28px
	}
}

@media (max-width: 1010px) {
	.order-display-main-img {
		height: 260px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 260px;
	}
}

@media (max-width: 981px) {
	.order-display-mini-imgs::-webkit-scrollbar {
		background: transparent;
		height: 6px;
	}
	.order-display-mini-imgs::-webkit-scrollbar:hover {
		background: white;
		border-radius: 24px;	
	}
	/* Handle on hover */
	.order-display-mini-imgs::-webkit-scrollbar-thumb:hover {
		background: var(--gray-2);
		border-radius: 24px; 
	}
	.service-right .order-display .order-display-subheader {
		display: flex;
		/* flex-direction: column; */
		gap: 10px;
		/* justify-content: center; */
		align-items: center;
	}
	/* .service-right .order-display .order-display-top {
		text-align: center;
	} */
	.order-display-main-img {
		height: 250px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 250px;
	}
}

@media (max-width: 970px) { 
	.order-display-subheader .small-subheader {
		font-size: 0.9rem;
	}
}

@media (max-width: 945px) {
	.order-display-main-img {
		height: 225px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 225px;
	}
}

@media (max-width: 915px) { 
	.order-display-subheader .small-subheader {
		font-size: 0.875rem;
	}
}

@media (max-width: 906px) {
	.order-display-main-img {
		height: 210px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 210px;
	}
}

@media (max-width: 900px) { 
	.order-display-subheader .small-subheader {
		font-size: 0.75rem;
	}
}

@media (max-width: 890px) {
	.order-display h1 {
		font-size: 1.1rem;
    	line-height: 26px;
	}
}

@media (max-width: 885px) {
	.service-right-top .service-right-subheaders {
		gap: 2px
	}
	.service-right-top .service-right-subheaders .service-custom-button span {
		font-size: 0.8rem;
	}
}

@media (max-width: 879px) {
	.order-display-main-img {
		height: 200px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 200px;
	}
}

@media (max-width: 861px) {
	.order-display-main-img {
		height: 190px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 190px;
	}
}

@media (max-width: 810px) {
	.order-display h5 {
		line-height: 20px;
		font-size: 1.125rem;
		font-weight: 600;
		font-family: 'Faktum-SemiBold';
		margin: 0;
	}
}

@media (max-width: 794px) {
	.order-display-main-img {
		height: 180px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 180px;
	}
}

@media (max-width: 778px) {
	.order-display-main-img {
		height: 170px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 170px;
	}
}

@media (max-width: 768px) {
	.order-display-main-img {
		height: 400px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 400px;
	}
	.order-display-header {
		display: none;
	}
	.order-display-mini-imgs::-webkit-scrollbar-thumb:hover {
        display: none;
    }
    .order-display-mini-imgs::-webkit-scrollbar:hover {
        display: none;
    }
	.order-display { 
		padding: 20px 20px;
		width: 100%;
	}
	.order-display-main-btn {
		display: flex;
	}
	.service-right-top .service-right-subheaders {
		gap: 40px
	}
	.service-right-top .service-right-subheaders .service-custom-button span {
		font-size: 0.875rem;
	}
	.order-display h1 {
		font-size: 1.5rem;
		line-height: 35px;
	}
}

@media (max-width: 758px) {
	.order-display-main-img {
		height: 350px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 350px;
	}
}

@media (max-width: 739px) {
	.order-display-main-img {
		height: 330px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 330px;
	}
}

@media (max-width: 703px) {
	.order-display-main-img {
		height: 310px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 310px;
	}
}

@media (max-width: 667px) {
	.order-display-main-img {
		height: 290px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 290px;
	}
}

@media (max-width: 632px) {
	.order-display-main-img {
		height: 270px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 270px;
	}
}

@media (max-width: 600px) {
	.service-right .order-display .order-display-subheader {
		display: flex;
		flex-direction: row;
		gap: 10px;
		justify-content: flex-start;
		align-items: center;
	}
	.order-display h1 {
		font-size: 1.3rem;
		line-height: 28px
	}
	.order-display h5 {
		font-size: 0.9rem;
	}
}

@media (max-width: 597px) {
	.order-display-main-img {
		height: 250px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 250px;
	}
}

@media (max-width: 561px) {
	.order-display-main-img {
		height: 230px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 230px;
	}
}

@media (max-width: 525px) {
	.order-display-main-img {
		height: 210px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 210px;
	}
}

@media (max-width: 510px) {
	.order-display h1 {
		font-size: 1.1rem;
		line-height: 26px
	}
}

@media (max-width: 490px) {
	.order-display-main-img {
		height: 190px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 190px;
	}
}

@media (max-width: 476px) {
	/* .order-display-subheader .small-subheader {
		font-size: 0.8rem !important;
	} */
	.order-display-subheader .order-display-ratings .city-country-display {
		min-width: fit-content;
	}
}
@media (max-width: 460px) {
	.service-right .order-display .order-display-subheader {
		flex-direction: column;
		align-items: flex-start;
		gap: 5px
	}
}

@media (max-width: 458px) {
	.order-display-subheader .small-subheader {
		font-size: 0.75rem !important;
	}
}

@media (max-width: 454px) {
	.order-display-main-img {
		height: 170px;
	}
	.order-display-video,
	.order-display-img {
		min-height: 170px;
	}
}

@media (max-width: 449px) {
	.order-display-ratings .city-country-display {
		min-width: fit-content;
	}
}

@media (max-width: 445px) {
	.order-display h1 {
		font-size: 1rem;
		line-height: 23px;
	}
}

@media (max-width: 440px) {
	.service-right .order-display .order-display-subheader {
		flex-direction: column;
		align-items: flex-start;
	}
	.order-display-top {
		padding-top: 1rem;
	}
}

@media (max-width: 428px) {
	.order-display-header,
	.order-display-main-img,
	.order-display-mini-imgs {
		display: none;
	}

	.order-display-images-mobile::-webkit-scrollbar {
		width: 0;
	}

	.order-display-images-mobile img {
		border-radius: 24px;
	}
	.order-display-images-mobile {
		min-height: 300px;
	}

	.order-display-images-mobile-img {
		max-height: 100%;
		min-height: 100%;
		max-width: 100%;
		min-width: 300px;
		background: #eeeeee;
		object-fit: cover;
	}
	.order-display-images-mobile-imgs {
		max-height: 200px;
		min-height: 140px;
		max-width: 325px;
		min-width: 300px;
		width: 100%;
	}

	.order-display-header-mobile {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 20px;
		padding-bottom: 10px;
	}

	.order-display-header-mobile h1 {
		margin: auto;
	}

	.order-mobile-subheader {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.order-display-bot {
		text-align: start;
	}

	.order-display-images-mobile {
		min-height: 158px !important;
    	/* max-height: 160px; */
		display: flex;
		gap: 11px;
		overflow-x: scroll;
		max-width: 344px;
		overflow-y: hidden;
	}
	.order-display-images-mobile-video {
		border-radius: 24px;
	}
}

@media (max-width: 426px) { 
	.order-display {
		max-width: 380px;
	}
	.order-display .big-header {
		font-size: 1rem !important;
		line-height: 23px;
	}
	/* .order-display-top .big-header {
		font-size: 1.5rem!important;
	} */
}

@media (max-width: 402px) { 
	.order-display {
		max-width: 360px;
	}
}

@media (max-width: 392px) { 
	.order-display {
		max-width: 350px;
	}
}

@media (max-width: 385px) { 
	.order-display-images-mobile-imgs {
		min-width: 250px
	}
}

@media (max-width: 377px) { 
	.order-display {
		max-width: 345px;
	}
}

@media (max-width: 326px) { 
	.order-display-images-mobile-imgs {
		min-width: 230px;
	}
}