.review {
	backdrop-filter: blur(40px);
	border-radius: 12px;
	padding: 10px 15px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(20px);
    /* box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.06); 
    border-image-source: var(--border-image-source);*/
}

.review-top {
	display: flex;
	align-items: center;
	gap: 10px;
}

.review-top-userName {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0px;
}

.review-name-text {
	font-size: 1rem;
	font-family: 'Faktum-Medium';
}

.review-colorCompany {
	color: var(--black-50);
}

.review-top .useractive .useractive-text {
	font-size: 1.125rem;
}

.review-top .review-name-text {
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start;
    min-width: 0;
}

.review-bot {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
}

.review-bot_left {
	display: flex;
	flex-direction: row;
	gap: 10px
}

.review-bot_right {
	color: rgba(24, 24, 24, 0.5);
	font-size: 0.75rem;
}

.review-bot-likes {
	display: flex;
	align-items: center;
	gap: 5px;
}

.review-bot-likes span {
	color: var(--gray-1);
}

.review-top h1 {
	margin: 0;
	flex: 1;
	color: var(--gray-1);
}

.review-mid p {
	margin: 0;
	color: var(--gray-1);
	font-size: 0.875rem;
}

.review .review-comment {
	display: flex;
	flex-direction: column;
	gap: 10px
}

.review .review-comment 
.textfield .textfield-input  {
    padding: 10px 18px;
    border: 1px solid var(--black-25);
    background-color: transparent;
    padding-top: 1.7rem;
	height: 65px;
}

/**Placeholder */ 
.review .review-comment 
.textfield .textfield-input::placeholder { 
	color: var(--black);
	font-family: 'Faktum-Medium';
	font-size: 1rem;
}

.review .review-comment 
.textfield .textfield-title{
    font-size: 0.7rem;
	transition: none;
	top: 33%;
	color: var(--black);
	font-family: 'Faktum';
}

.review-top .useractive {
    width: 40px;
    height: 40px;
	max-width: 40px;
	max-height: 40px;
	min-width: 40px;
	min-height: 40px;
}

.review-right {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	justify-content: flex-end;
}

.review-right div {
	display: flex;
}

.review-bot-likes div {
	display: flex;
}

.review-bot .rating {
	display: flex;
	border-radius: 9px;
}

.review-content-detail {
	display: flex;
	flex-direction: column;
	gap: 0px
}

.review-top-userName-header .review-right {
	display: none;
   }

@media (max-width: 600px) {
	.review-right {
		width: fit-content;
		display: none;
	}
	.review-bot_right {
		width: 100%;
	}
	.review-right .rating {
		max-height: 23px;
		padding: 6px 6px;
    	justify-content: center;
	}
	.review-top-userName-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
   	.review-top-userName-header .review-right {
		display: flex;
   	}
}

@media (max-width: 450px) {
	.review-top .review-name-text {
		font-size: 0.875rem;
	}
}

@media (max-width: 390px) {
	.review-top .review-name-text {
		font-size: 0.875rem;
		line-height: 20px;
	}
	.review-right {
		gap: 0.5rem

	}
}