.ten-section {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 60px 120px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.ten-seccion-title {
    font-size: 2.813rem;
}

.ten-seccion-white {
    color: var(--white);
    text-shadow: 
        -6px 9px 20px rgba(255, 197, 37, 0.36),
        6px -9px 20px rgba(255, 197, 37, 0.36);

}

.ten-section-questions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-direction: column;
    gap: 30px
}

.ten-section-card {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;

    border-radius: 24px;
    border: 1px solid var(--bn-white, #FFF);
    backdrop-filter: blur(20px);
}

.ten-section-img {
    width: 70px;
}

@media (max-width: 1323px) {
    .ten-section {
        padding: 60px;
    }
}

@media (max-width: 1194px) {
    .ten-seccion-title {
        font-size: 2.6rem;
    }
}

@media (max-width: 1045px) {
    .ten-section {
        padding: 30px 20px;
        gap: 30px
    }
    .ten-seccion-title {
        font-size: 2rem;
    }
    .ten-section-card {
        flex-direction: column;
    }
    .ten-section-img {
        display: none;
    }
    .ten-section-questions {
        grid-template-columns: repeat(2, 1fr);
    }
    .faqCard-content, 
    .faqCard-show {
        width: 400px;
    }
}

@media (max-width: 960px) {
    .ten-section-questions .faqCard-content {
        display: flex;
        flex-direction: column;
        gap: 25px; 
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .faqCard-content, 
    .faqCard-show {
        width: 350px;
    }
}

@media (max-width: 773px) {
    .ten-section-questions {
        grid-template-columns: repeat(1, 1fr);
    }
    .faqCard-content, 
    .faqCard-show {
        width: 100%;
    }
    .ten-section-card {
        display: none;
    }
}

@media (max-width: 400px) {
    .ten-seccion-title {
        font-size: 1.9rem !important;
    }
}
