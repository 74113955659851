.profile {
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    padding: 20px 120px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.profile-content {
    height: 100%;
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
}

.profile-left {
    width: 100%;
    max-width: 330px;
    padding: 5px 5px 5rem 5px;
    overflow: scroll;
}

.profile-left .card-content .userCard .userVerify-container {
    position: absolute;
    z-index: 10;
    top: 4rem;
    right: 1rem;
}

.profile-right {
    width: 100%;
    max-width: 830px;
    overflow: scroll;
    height: 100%;
    padding: 5px 5px 5rem 5px;
}

.profile-left::-webkit-scrollbar,
.profile-right::-webkit-scrollbar {
    display: none;
}

.profilePage-receipts {
    display: flex;
    flex-direction: column;
    gap: 20px
}

@media (max-width: 1440px) {
    .profile {
        padding: 20px 60px;
    }
}

@media (max-width: 1200px) {
    .profile-content {
        gap: 20px
    }
}

@media (max-width: 1100px) {
    .profile {
        padding: 20px 30px;
    }
}

@media (max-width: 860px) {
    .profile-left {
        max-width: 280px;
    }
    .profile-left .card-content .userCard .userVerify-container 
    .userVerify-content {
        max-width: 240px;
        padding: 10px;
        border-radius: 12px;
    }
    .detail-profile__password-content {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .profile-left {
        display: none;
    }
}

@media (max-width: 600px) {
    .profile {
        padding: 10px;
    }
    .detail-profile__row___ {
        flex-direction: column;
        gap: 15px;
    }
    .detail-profile {
        gap: 15px
    }
}
