.transactionInvoceTranslation-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.transactionInvoceTranslation-text {
    color: var(--gray-1);
}

.transactionInvoceTranslation-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.transactionInvoceTranslation-row-m {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

.transactionInvoceTranslation-width {
    width: 24.375rem !important;
}

/* Dropdown */
.transactionInvoceTranslation-row .dropdown .dropdown-select,
.transactionInvoceTranslation-row-m .dropdown .dropdown-select {
    padding: 20px;
}

/* .transactionInvoceTranslation-row .textfield .textfield-title,
.transactionInvoceTranslation-main .textfield .textfield-title {
    top: 40%;
} */

/* .transactionInvoceTranslation-main .transactionInvoceTranslation-row .textfield input,
.transactionInvoceTranslation-main .textfield input {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
} */

@media (max-width: 976px) { 
    .transactionInvoceTranslation-row  {
        flex-direction: column;
    }

    .transactionInvoceTranslation-width {
        width: 100% !important;
    }
}

@media (max-width: 880px) { 
    .transactionInvoceTranslation-main,
    .transactionInvoceTranslation-row,
    .transactionInvoceTranslation-row-m {
        gap: 10px
    }
    .transactionInvoceTranslation-text {
        font-size: 0.9rem;
    }
    .transactionInvoceTranslation-row-m .dropdown .dropdown-select .select-title,
    .transactionInvoceTranslation-row .dropdown .dropdown-select .select-title {
        font-size: 0.65rem!important;
    }
    .transactionInvoceTranslation-row-m .dropdown .dropdown-select .dropdown_text,
    .transactionInvoceTranslation-row .dropdown .dropdown-select .dropdown_text {
        font-size: 0.9rem!important;
    }
    .transactionInvoceTranslation-row .textfield .paragraph,
    .transactionInvoceTranslation-main .textfield .paragraph {
        font-size: 0.9rem!important;
    }
}