.modalQuoteSupplier-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: -20px;
}

.modalQuoteSupplier-colorText {
    color: var(--gray-1);
}

.modalQuoteSupplier--button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
}

.modalQuoteSupplier-container .modal-main .modal-container {
    background: var(--white);
}

.modalQuoteSupplier-container .modal-main .modal-container .modal__header 
.modal__heade-left .modal-header__text {
    display: flex;
    flex-direction: column;
    gap: 3px
}

.modalQuoteSupplier-inputOriginDestiny-column {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.modalQuoteSupplier-inputOriginDestiny-column {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.modalQuoteSupplier-inputOriginDestiny-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--white-75);
    border-radius: 24px;
    padding: 20px
}

.modalQuoteSupplier-inputOriginDestiny-content_row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    /* box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15); */
}

.modalQuoteSupplier--ensure {
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15)
}

.modalQuoteSupplier-inputOriginDestiny-row-button {
    display: flex;
    flex-direction: row;
    gap: 20px
}
.modalQuoteSupplier-inputOriginDestiny-row-button .custom-button-icon {
    max-width: 100%;
    min-width: 50px;
    width: 100%;
}
.modalQuoteSupplier-inputOriginDestiny-content-min {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--white-75);
    border-radius: 12px;
    padding: 15px
}
.modalQuoteSupplier-inputOriginDestiny-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    width: 100%;
    background: var(--white-75);
    border-radius: 24px;
    padding: 20px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}
.modalQuoteSupplier-inputOriginDestiny-info {
    display: flex;
    flex-direction: column;
}
.modalQuoteSupplier-inputOriginDestiny-row .checkbox-container,
.modalQuoteSupplier-inputOriginDestiny-row_min .checkbox-container {
    width: 32px;
    height: 32px;
    background: var(--black-05);
    border: 0px solid var(--gray-3);
}
.modalQuoteSupplier-inputOriginDestiny-row_min {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.modalQuoteSupplier-quotationTransportOrigin-footer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
}

.modalQuoteSupplier--ensure {
    width: 100%;
    background: var(--white-75);
    border-radius: 24px;
    padding: 20px;
}

@media (max-width: 592px) {
    .modalQuoteSupplier-inputOriginDestiny-row {
        gap: 10px
    }
}

@media (max-width: 572px) { 
    .modalQuoteSupplier-inputOriginDestiny-content .paragraph-header,
    .modalQuoteSupplier-inputOriginDestiny-content .modalQuoteSupplier-inputOriginDestiny-row-button .custom-button-icon ,
    .modalQuoteSupplier-inputOriginDestiny-content-min .paragraph-header, 
    .modalQuoteSupplier-inputOriginDestiny-content_row .modalQuoteSupplier-inputOriginDestiny-row 
    .modalQuoteSupplier-inputOriginDestiny-info .paragraph-header, 
    .modalQuoteSupplier-inputOriginDestiny-content_row  .paragraph-header {
        font-size: 0.9rem;
    }
    .modalQuoteSupplier-inputOriginDestiny-content_row .modalQuoteSupplier-inputOriginDestiny-row 
    .modalQuoteSupplier-inputOriginDestiny-info .smalltext  {
        font-size: 0.75rem;
    }
}

@media (max-width: 535px) { 
    .modalQuoteSupplier-inputOriginDestiny-content_row {
        gap: 10px
    }
}

@media (max-width: 525px) { 
    .modalQuoteSupplier-inputOriginDestiny-content_row_ {
        flex-direction: column;
        gap: 15px
    }
}

@media (max-width: 444px) { 
    .modalQuoteSupplier-inputOriginDestiny-content_row {
        flex-direction: column;
    }
    .modalQuoteSupplier-inputOriginDestiny-row_min_ {
        gap: 30px
    }
}