.resumePay-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.resumePay-img {
    width: 100%;
    display: flex;
    justify-content: center;
}

.resumePay-content-first {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.resumePay-content-first_ {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.resumePay--colorText {
    color: #333;
    text-align: center;
}


.resumePay-content__row {
    width: 100%;
    max-width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.resumePay_questions {
    color: #333;
    font-family: 'Faktum-Medium';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.resumePay_answer {
    color: #333;
    font-family: 'Faktum';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.resumePay-content-first_text {
    color: #333;
    font-family: 'Faktum-Medium';
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.resumePay-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px
}