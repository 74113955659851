.finalDirection-container {
    width: 100%;
    padding: 18px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    border-radius: 24px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    cursor: pointer;
}



.finalDirection-container-placeholder {
    width: 100%;
    padding: 18px 20px;
    display: flex;
    flex-direction: column;

    gap: 0px;
    border-radius: 24px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    cursor: pointer;
}

.finalDirection-row {
    width: 100%;
}

.finalDirection-row .finalDirection-text .defineRoute-row__ div img {
    padding-top: 0rem;
}

.finalDirection-column {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center; 
}

.finalDirection-text {
    color: var(--gray-1);
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
}

.finalDirection-text span {
    color: var(--gray-3);
}

.finalDirection-placeholder {
    color: rgba(0, 0, 0, 0.50);
    font-family: 'Faktum';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
}
.finalDirection-input {
    border: 0px;
    color: var(--gray-1);
    font-family: 'Faktum';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    resize: none;
}

.finalDirection-input:focus {
    outline: none;
}

.finalDirection-textArea {
    width: 100%;
    /* width: 26vw; */
    display: flex;
    border-radius: 12px;
    outline: 0px solid transparent;
    text-overflow: ellipsis;

}
.finalDirection-placeholder-two {
    color: #333;
    leading-trim: both;
    text-edge: cap;
    font-family: Faktum;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    padding-top: 0.5rem;
}

.finalDirection-container--disabled {
    border-radius: 24px;
    border: 1px solid var(--black-25);
    box-shadow: none;
    backdrop-filter: none;
    cursor: auto;
 }