.servicesOptions-main {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 10px */
}

.servicesOptions-main .searchTextField-container {
    /* border: 1px solid var(--gray-4); */
    background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
    /* filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.15)); */
    backdrop-filter: blur(20px);
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
    border-image-source: var(--border-image-source);
    padding: 12px 21px;
}

.servicesOptions-text {
    display: flex;
    flex-direction: row;
    gap: 20px;
    color: var(--gray-1);
}

.servicesOptions-title {
    color: var(--gray-1);
}

.servicesOptions-description{
    margin-top: -10px;
    color: var(--gray-1);
    line-height: 16px;
}

.servicesOptions-text .dropdown .dropdown-list .dropdown-list__item{
    height: 40px;
}