.channelControl-main {
    max-width: 52.5rem;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    backdrop-filter: blur(20px);
	border-radius: 20px;
    background: var(--background);
    border: 0px solid;
    border-image-source: var(--border-image-source);
    z-index: 1;
}

.channelControl-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
}

.channelControl-status-green {
    max-width: 20px;
    width: 100%;
    height: 20px;
    background: #008564;
    border: 2px solid #66B6A2;
    border-radius: 20px;
    box-sizing: border-box;
    margin: 0px 10px;
}

.channelControl-status-amber {
    max-width: 20px;
    width: 100%;
    height: 20px;
    background:#FF9B66;
    border: 2px solid #FFCA63;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0px 10px;
}

.channelControl-status-red {
    max-width: 20px;
    width: 100%;
    height: 20px;
    background: #921414;
    border: 2px solid #FF0000; 
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0px 10px;
    border-radius: 20px;
}

.channelControl-colorText {
    color: var(--gray-1);
}

.channelControl-main button {
    font-family: 'Faktum';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    padding: 0px;
    color: var(--gray-1);
}

.channelControl-main .dropdown .dropdown-list .dropdown-list__item {
    background: var(--white-90);
}

.channelControl-main .dropdown .dropdown-list .dropdown-list__item:hover {
    background: var(--gray-3);
    color: var(--white-90);
  }

.channelControl-main .dropdown .dropdown-select {
    padding: 20px;
}

.channelControl-main .dropdown .dropdown-select .move-text {
    left: 0px;
}

@media (max-width: 880px) {
    .channelControl-main {
        gap: 10px
    }
    .channelControl-colorText,
    .channelControl-colorText .paragraph-header {
        font-size: 0.9rem;
    }
}

@media (max-width: 700px) {
    .channelControl-main {
        border-radius: 12px;
    }
}

@media (max-width: 425px) {
    .channelControl-main {
        padding: 10px;
    }
    .channelControl-row {
        gap: 5px
    }
    .channelControl-status-green {
        height: 15px;
        max-width: 15px;
    }
}