.hover {
    position: relative;
    display: flex; 
}

.hover-content {
    position: relative;
    cursor: pointer;
    color: var(--black)
}

.hover-text {
    width: 350px;
    position: absolute;
    bottom: 30px;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 12px;
    
    border-radius: 10px;
    backdrop-filter: blur(40px);
    z-index: 10;
    background: var(--white);
    box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.6);
}

.hover-color {
    color: var(--gray-2);
}

.hover-title {
    font-weight: 600;
    color: var(--black)
}

/* @media (max-width: 752px){
    .hover-text {
        left: -150px;
    }
 } */

@media (max-width: 563px){ 
    .hover-text {
        width: 300px;
    }
}
/* 
@media (max-width: 512px){ 
    .hover-text {
        left: -100px;
        width: 250px;
    }
} */

/* @media (max-width: 446px){ 
    .hover-text {
        width: 330px;
        left: -150px;
    }
} */

@media (max-width: 384px){ 
    .hover-text {
        width: 230px;
        /* left: -100px; */
        font-size: 0.75rem;
    }
}