.headerCalendar {
    background: var(--black-05);
    max-width: 880px;
    width: 100%;
    height: 62.47px;
    max-height: 100%;
    border-radius: 24px 24px 0px 0px;
    /*grillas*/
    grid-area: months;
    margin-bottom: 0;
}
.headerCalendar-header {
    flex-direction: row;
    height: 62.47px;
    display: grid;
    padding-left: 53px;
    background: var(--black-05);
    grid-template-columns: repeat(7, 1fr);
    border-radius: 24px 24px 0px 0px;
}
.headerCalendar-content {
    text-align: center;
    justify-content: center;
    align-items: center;
    max-width: 118.14px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    display: flex;
    height: 62.47px;
    cursor: pointer;
}
.headerCalendar-content-now {
    text-align: center;
    justify-content: center;
    align-items: center;
    max-width: 118.14px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    display: flex;
    height: 62.47px;
    background: var(--black-75); 
}
.headerCalendar-day-now {
    color: var(--white);
}
.headerCalendar-day {
    color: var(--black);
}
.headerCalendar-date-now {
    color: var(--white-75);
}
.headerCalendar-date {
    color: var(--gray-3);
}

/* Responsive tablet*/
@media (max-width: 620px) { 
    .headerCalendar-day{
        font-size: 0.8rem;
    }
    .headerCalendar-day-now {
        font-size: 0.8rem;
    }
    .headerCalendar-date {
        font-size: 0.8rem;
    }
    .headerCalendar-date-now{
        font-size: 0.8rem;
    }
}
