.card-token-main {
    border: 1px solid var(--black);
    border-radius: 12px;
    padding: 10px 20px;
    height: 121px;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    max-width: 253.33px;
    width: 100%;
}

.card-token-main__active {
    border: 1px solid var(--black);
    background: var(--black);
    border-radius: 12px;
    padding: 10px 20px;
    height: 121px;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    max-width: 253.33px;
    width: 100%;
}

.card-token_text {
    color: var(--white);
}

.text__footer {
    font-size: 0.75rem;
}

.card-token__column {
    display: flex;
    flex-direction: column;
}

.card-token-main__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.card-token__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 3px
}

.card-token-price-responsive {
    display: flex;
    flex-direction: column;
    gap: 2px
}

.card-token_text__ {
    display: none;
}

.card-token_text__offer {
    font-size: 0.875rem;
    font-family: 'Faktum-Medium';
}

@media (max-width: 770px){ 
    .card-token-main {
        height: 154px;
        max-width: 180px;
        width: 100%;
    }
    .card-token_text__responsive {
        display: none;
    }
    .card-token_text__ {
        display: block;
        font-size: 1.125rem;
        font-family: 'Faktum-SemiBold';
    }
    .card-token-price-responsive {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-end;
    }
    .card-token_text__offer {
        font-size: 0.8rem;
        font-family: 'Faktum';
    }

    .text__footer {
        font-size: 0.7rem;
    }

    /**Token Active*/
    .card-token-main__active {
        height: 154px;
        max-width: 180px;
        width: 100%;
    }
}

@media (max-width: 628px){ 
    .card-token-main, .card-token-main__active {
        padding: 10px 15px;
    }
}

@media (max-width: 598px){ 
    .card-token__row {
        flex-direction: column;
    }
}

@media (max-width: 555px){ 
    .card-token-main, .card-token-main__active {
        height: 190px;
    }
    .card-token_text__ {
        display: none;
    }
    .card-token_text__responsive {
        display: block;
        font-size: 0.9rem;
    }
    .card-token-main__header {
        flex-direction: column;
        align-items: start;
        gap: 2px;
    }
    .card-token__column {
        gap: 10px
    }
    .text____title {
        font-size: 1rem;
    }
    .text__font {
        font-size: 0.75rem;
    }
    .card-token_text__offer {
        font-family: 'Faktum-Medium';
    }
}
@media (max-width: 555px){ 
    .card-token-main, .card-token-main__active {
        padding: 10px;
    }
}

@media (max-width: 482px){ 
    .card-token-main, .card-token-main__active {
        padding: 10px;
    }
}

@media (max-width: 457px){ 
    .card-token-main, .card-token-main__active {
        height: 110px;
        min-width: 253.33px;
        width: 100%;
    }
    .card-token-main__header {
        flex-direction: row;
    }
    .card-token__column {
        gap: 0px;
    }
    .card-token__row {
        flex-direction: row;
    }
}