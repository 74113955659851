.landing-section {
    width: 100%;
    height: 760px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 60px;
    padding: 0px 40px;
    padding-top: 7rem;
}
.landing-first-section {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 40px;
}
.landing-first-detail {
    display: flex;
    flex-direction: column;
    color: var(--white);
    font-size: 1.375rem;
    font-family: 'Faktum';
}
.laning-second-detail {
    width: 450px;
    display: flex;
    flex-direction: column;
    gap: 30px
}
.landing-first-detail-tablet {
    display: none;
}
.laning-second-subdetail,
.laning-second-subdetail-tablet {
    font-size: 1.375rem;
    font-family: 'Faktum';
    color: var(--white);
}
.laning-second-subdetail-bold {
    font-size: 1.375rem;
    font-family: 'Faktum-SemiBold';
    color: var(--white);
}
.laning-second-subdetail-tablet {
    display: none;
}
.landing-first-section-text-degradado {
    font-size: 2.813rem;
    font-family: 'Faktum-SemiBold';
    background: -webkit-linear-gradient(#F7E246, #FFC525);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.landing-first-text {
    font-size: 2.813rem;
    font-family: 'Faktum-SemiBold';
    color: var(--white);
}
.landing-second-section {
    width: 100%;
    max-width: 700px;
    height: auto;
    position: relative;
    overflow: hidden;
    /* max-width: 555px; */
  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.landing-second-section::before {
    content: "";
    display: block;
    padding-top: 56.25%; /* Relación de aspecto 16:9 */
}

.landing-second-section-img {
    width: 100%;
}
.landing-second-section-video {
    position: absolute;
    top: 5rem;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 392px;
    border-radius: 12px;
    /* Agrega otros estilos según sea necesario */
}
.landing-first-button {
    width: 207px;
    display: flex;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    cursor: pointer;
    background: linear-gradient(94deg, #F7E246 0%, #FFC525 73.97%);

    backdrop-filter: blur(20px);
    font-size: 1.375rem;
    font-family: 'Faktum';
    box-shadow: 0 0 20px rgba(255, 194, 87, 0.4), 
                0 0 30px rgba(255, 194, 87, 0.5), 
                0 0 50px rgba(247, 226, 70, 0.4);
    animation: pulse 1.5s infinite ease-in-out, radioactive-glow 2.5s infinite alternate ease-in-out;
}
.landing-first-button-bold {
    font-size: 1.375rem;
    font-family: 'Faktum-SemiBold';
}
.landing-first-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.landing-first-row-tablet {
    display: none;
}
.landing-first-row-white {
    color: var(--white);
    font-size: 1.25rem;
    font-family: 'Faktum';
}
.landing-first-img {
    width: 142.22px;
    height: 80px;
}

/* @media (max-width: 1274px) {
    .landing-section {
        gap: 60px
    }
} */

@media (max-width: 1200px) {
    .landing-section {
        gap: 20px;
    }
    .landing-first-section-text-degradado,
    .landing-first-text {
        font-size: 2.6rem;
    }
    .laning-second-subdetail,
    .landing-first-button,
    .landing-first-button-bold,
    .laning-second-subdetail-bold,
    .laning-second-subdetail-tablet {
        font-size: 1.175rem;
    }
    .landing-second-section {
        max-width: 600px;
    }
    .landing-first-img {
        width: 120.22px;
        height: 70px;
    }
}

@media (max-width: 1045px) {
    .landing-section {
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 20px 0px 20px;
    }
    .landing-first-section {
        max-width: 800px;
        text-align: center;
        gap: 30px;
        padding-top: 3rem;
        align-items: center;
    }
    .landing-first-detail {
        display: none;
    }
    .landing-first-detail-tablet {
        display: flex;
        flex-direction: column;
    }
    .landing-second-section {
        height: 490px;
        max-width: 800px;
    }
    .laning-second-subdetail {
        display: none;
    }
    .laning-second-subdetail-tablet {
        display: flex;
        flex-direction: column;
    }
    .laning-second-detail {
        width: 600px;
        align-items: center;
    }
    .landing-first-row {
        display: none;
    }
    .landing-first-row-tablet {
        display: flex;
    }
    .landing-first-button {
        height: 54px;
    }
}

@media (max-width: 665px){
    .landing-first-section {
        padding-top: 6rem;
    }
    .landing-section {
        padding: 0px 20px 20px 20px; 
    }
    .landing-first-detail {
        display: flex;
    }
    .laning-second-subdetail {
        display: block;
    }
    .landing-first-detail-tablet,
    .laning-second-subdetail-tablet {
        display: none;
    }
    .landing-first-section-text-degradado, 
    .landing-first-text {
        font-size: 2.125rem;
    }
    .laning-second-subdetail, 
    .landing-first-button, 
    .landing-first-button-bold, 
    .laning-second-subdetail-bold, 
    .laning-second-subdetail-tablet {
        font-size: 1.25rem;
    }
    .laning-second-detail {
        width: 390px;
    }
    .landing-first-row-white {
        font-size: 1rem;
    }
    .landing-first-img {
        width: 88.889px;
        height: 50px;
    }
}

@media (max-width: 430px){
    .landing-first-section-text-degradado, 
    .landing-first-text {
        font-size: 1.8rem
    }
    .laning-second-subdetail,
    .laning-second-subdetail-bold {
        font-size: 1rem;
    }
    .laning-second-detail {
        width: 315px;
    }
    .landing-first-row-white {
        font-size: 0.9rem;
    }
}