.planDetailSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px
}

.planDetailSection-title {
    width: 100%;
    text-align: center;
    color: var(--white);
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    text-shadow: -6px 9px 20px rgba(255, 197, 37, 0.36), 6px -9px 20px rgba(255, 197, 37, 0.36);
}

.planDetailSection-detail {
    display: flex;
    justify-content: center;
}

.planDetailSection-detail::-webkit-scrollbar {
    display: none;
}

.planDetailSection-title-black {
    color: var(--black);
    width: 100%;
    text-align: center;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
}

@media (max-width: 921px) {
    .planDetailSection-detail {
        padding: 4px 40px 12px 30px;
        overflow: scroll;
        overflow-y: hidden;
        width: 100%;
        max-width: 1500px;
        justify-content: flex-start;
    }
}

@media (max-width: 600px) {
    .planDetailSection-detail {
        padding: 4px 20px 12px 20px;
        overflow: scroll;
        overflow-y: hidden;
        width: 100%;
        max-width: 1500px;
    }
    .planDetailSection-title {
        font-size: 2rem;
    }
    .planPage-title-section-border {
        font-size: 1.1rem;
    }
}

@media (max-width: 480px) {
    .planDetailSection-title {
        font-size: 2rem;
    }
}