.creditNumber {
    width: 100%;
    max-width: 300px;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    gap: 5px
}

.creditNumber-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px
}

.creditNumber-data {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: end;
    gap: 5px

}

.creditNumber-textColor {
    width: 100%;
    color: #333;
    /* font-family: "Poppins-Medium";
    font-size: 0.8rem; */
    font-family: 'Faktum';
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.creditNumber-icon {
    width: 28px;
    height: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--white);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.creditNumber-icon img {
    height: 10px;
}

.creditNumber-number {
    /* max-width: 115px;
    width: 100%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: black;

}

.creditNumber-text {
    color: black;
    font-size: 0.8rem;
    align-items: center;
    display: flex;
}

.creditNumber-detail {
    display: flex;
    flex-direction: row;
    gap: 5px
}