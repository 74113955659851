.phases-card-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.phases-card-row {
    /* display: flex;
    flex-direction: row;
    gap: 15px;
    max-width: 100%;
    width: 100%;
    align-items: center; */
    display: flex;
    flex-direction: row;
    gap: 15px;
    max-width: 100%;
    width: 100%;
    align-items: center;
    height: 34px;
}
.phases-card-number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 28px;
    flex: none;
    border: solid 4px;
    color: var(--gray-1);
    /* box-shadow: 0px 0px 15px var(--white); */
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    border-color: rgba(255, 255, 255, 0.25);
}

.phases-card-active-black {
    background-color: var(--black-75);
    border: 3px solid rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    color: white;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}
.phases-card-name {
    align-items: center;
    color: var(--gray-1);
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
}
.phases-card-line {
    margin-left: 15.5px;
    min-height: 15px;
    height: 100%;
    background: rgb(244 244 244);
    width: 4px;
    background: var(--white-25);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

.phases-card-number-number-active-black {
    color: var(--white);
    background: var(--black-15);
    border-radius: 24px;
    position: relative;
    height: 34px;
    width: 34px;
    min-height: 34px;
    min-width: 34px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    padding-top: 0.2rem;
    padding-left: 0.2rem;
}

.phases-card-number-number {
    color: var(--white);
    background: var(--white-25);
    border-radius: 24px;
    position: relative;
    height: 34px;
    width: 34px;
    min-height: 34px;
    min-width: 34px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    padding-top: 0.2rem;
    padding-left: 0.2rem;
}

@media (max-width: 1100px) { 
    .phases-card-row {
        gap: 10px
    }
    .phases-card-number {
        font-size: 0.9rem;
    }
    .phases-card-name {
        font-size: 0.9rem;
    }
}