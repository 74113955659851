.supplierSearchRequirements {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.answer {
    font-family: Faktum;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #333;
}

.answer-detail {
    color: var(--bn-gray-1, #333);
    /* 3.2 paragraph */
    font-family: "Faktum";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.supplierSearchRequirements-detail {
    display: flex;
    flex-direction: column;
    gap: 7px
}

.supplierSearchRequirements ul {
    margin: 0;
}

.supplierSearchRequirements-detail div .textarea[contenteditable]:empty::before {
    padding: 0px;
}

.supplierSearchRequirements--number {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.supplierSearchRequirements-detail-disable {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
}

.supplierSearchRequirements-provider {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 1rem;
}

.supplierSearchRequirements-provider-button {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-end;
    align-self: flex-end;
}

.supplierSearchRequirements-error {
    margin-top: -1.25rem;
}