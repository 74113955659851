.adminPaid {
    width: 100%;
    gap: 30px;
    display: flex;
    flex-direction: column;
}

.admin-content-card {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction:row;
    flex-wrap:wrap;
    row-gap: 20px;
    column-gap: 20px;
}

.adminPaid-card {
    width: 100%;
    min-width: 262px;
    max-width: fit-content;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid var(--black-25);
    backdrop-filter: blur(4.5px);    
    align-items: flex-start;
}

.adminPaid-card .creditNumber {
    width: auto;
    max-width: auto;
    min-width: auto;
}

.adminPaid-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
}

.adminPaid-button {
    background: #333333;
    border-radius: 40px;
    color: var(--white);
    padding: 0px 12px;
    height: 30px;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.adminPaid-button-none {
    border: 1px solid #333333;
    border-radius: 40px;
    color: var(--black);
    padding: 0px 12px;
    height: 30px;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.adminPaid-button-dot {
    width: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
}

.adminPaid-text-blue {
    display: flex;
    color: var(--blue-2);
    text-align: end;
    flex-direction: column;
    cursor: pointer;
}

.adminCardButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.adminPaid-row .creditNumber {
    width: 100%;
}

.adminPaid-row .creditNumber .creditNumber-input .creditNumber-data {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.adminPaid-row .creditNumber .creditNumber-input .creditNumber-data .creditNumber-detail {
    width: 100%;
}

@media (max-width: 600px) { 
    .adminPaid-card {
        padding: 10px;
        height: 50px;
    }
}