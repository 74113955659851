.ContentListProduct-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid rgba(51, 51, 51, 0.20);
}

.ContentListProduct-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.ContentListProduct-footer .ContentListProduct-footer-btn .btn-normal {
    color: var(--black);
    padding: 10px 24px;
}

.ContentListProduct-footer .btn-normal {
    padding: 10px 24px;
}