.chat {
    transform-origin: left top;
    flex-grow: 1;
    position: relative;
    height: 100%;
    overflow: hidden;
    transform: translateZ(0);
    padding-bottom: 1rem;
}
.chat-container {
    position: relative;
    height: 100%;
    max-width: 52.5rem;
    margin: 0.5rem;
    padding: 0px 15px 10px 15px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    backdrop-filter: blur(40px);
    border-radius: 12px;
    background: var(--background),
    linear-gradient(110.97deg, 
    rgba(255, 255, 255, 0.5) -4.87%, 
    rgba(255, 255, 255, 0) 103.95%);
    border-image-source: var(--border-image-source);

    transition: background-color .3s ease;
    transform-origin: left top;
}
.chat-personal  {
    position: relative;
    height: 100%;
    max-width: 52.5rem;
    margin: 0.5rem;
    padding: 0px 15px 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    backdrop-filter: blur(40px);
    border-radius: 12px;
    background: var(--background),
    linear-gradient(110.97deg, 
    rgba(255, 255, 255, 0.5) -4.87%, 
    rgba(255, 255, 255, 0) 103.95%);
    border-image-source: var(--border-image-source);

    transition: background-color .3s ease;
    transform-origin: left top;
}
.chat-message {
    height: fit-content;
    display: flex;
    /* height: calc(100vh - 375px); */
    
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    flex-direction: column;
    justify-content: flex-end;

    position: relative;
    z-index: 1;
    flex: 1 1 0px;
    order: 1;
}
.chat-message::-webkit-scrollbar {
    width: 0px;
    -webkit-appearance: none;
}

.chat-container form {
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    flex: none;
    order: 2;
    width: 100%;
    min-height: var(--height-pane-footer);
    padding: 0;
    width: 100%;
    left: 0px;
}

.chat-container {
    flex-direction: column;
}

.chat-personal form {
    /* position: sticky; */
    width: 100%;
    left: 0px;
    /* padding-right: 1.5rem;
    padding-left: 1.5rem; */

    position: relative;
    z-index: 2;
    box-sizing: border-box;
    flex: none;
    order: 2;
    width: 100%;
    min-height: var(--height-pane-footer);
    padding: 0;
}

.chat-list-message {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 100%;
    width: 100%;
    overflow: hidden; 
    overflow-y: scroll; 
    /* justify-content: end;
    align-items: center; */
    /* margin-bottom: 1rem; */
}
.chat-list-message::-webkit-scrollbar {
    width: 0px;
}
.chat-nothing {
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.scroll-up .chat-container .time-chat-container-relative,
.scroll-up .chat-personal .time-chat-container-relative {
    transform: none;
}

.animation-in {
    animation-duration: 1s;
    animation-name: global-animation;
    animation-fill-mode: forwards;
}
.animated {
    opacity: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
@keyframes global-animation {
    0% {
      opacity: 0;
      transform: translateY(32px) perspective(300px);
    }
    100% {
      opacity: 1;
      transform: translateY(0) perspective(0);
    }
}

@media (max-width: 600px) {
    .chat-container,
    .chat-personal {
        padding: 0px 5px 10px 5px;
    }
}