.modal-notification--main {
    position: fixed;
    z-index: 5;
    overflow: auto;
    /* background-color:  var(--white);  */
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(3px);
    top: 0;
    right: 0;
    max-width:100%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}
.modal-notification-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width:360px;
    width: 100%;
    background: white;
    padding: 20px;
}
.modal-notification__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 768px) { 
    .modal-notification--main {
        max-width: 100% !important;
    }
    .modal-notification-container {
        max-width: 100%;
    }
}