.circleTab-container {
    width: 10px;
    height: 10px;
    border-radius: 48px;
    background: rgba(51, 51, 51, 0.30);
    cursor: pointer;
}

.circleTab__active {
    background-color: #333;
    border: 2px solid #333;
}