.modalFilter {
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: rgba(0,0,0,.15);
    left: 0;
    position: fixed;
    top: 0;
    z-index: 11;
}

.modalFilter-container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 30px 20px;
    -webkit-backdrop-filter: blur(45px);
    backdrop-filter: blur(45px);
    background: #fff;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, .15);
}

.modalFilter-content {
    max-width: 600px;
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: flex-start;
}

.modalFilter-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modalFilter-button {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.modalFilter-delete {
    font-size: 0.875rem;
}
.modalFilter-button .btn-normal {
    padding: 0px 20px;
    font-size: 0.875rem;
}

.go-back-button-text {
    font-size: 1.125rem;
}

.modalFilter-body {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.modalFilter-body-filter {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modalFilter-body-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: fit-content;
}

.modalFilter-body .filterService {
    gap: 20px;
}

.modalFilter-body-text-filter {
    width: 100%;
    min-width: fit-content;
    max-width: fit-content; 
    height: 45px;
    align-items: center;
    display: flex;
}

.modalFilter-body .filterService .paragraph-header {
    width: 40%;
    width: 100%;
    min-width: fit-content;
    max-width: fit-content;
}

.modalFilter-body .filterService  .filterService-dropdown-content {
    width: 100%;
}

.modalFilter-body .filterService .filterService-dropdown-content .filterService-dropdown {
    width: 100%;
    max-width: 300px;
    justify-content: space-between;
}