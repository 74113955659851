.supplierInvoceTranslation-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.supplierInvoceTranslation-text {
    color: var(--gray-1);
}

.supplierInvoceTranslation-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.supplierInvoceTranslation-row-m {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

.supplierInvoceTranslation-width {
    width: 24.375rem !important;
}

/* Dropdown */
.supplierInvoceTranslation-row .dropdown .dropdown-select {
    padding: 20px;
}

/* .supplierInvoceTranslation-main .supplierInvoceTranslation-row .textfield input {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
} */

/* .supplierInvoceTranslation-row .textfield .textfield-title {
    top: 40%;
} */

.supplierInvoceTranslation-bt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.supplierInvoceTranslation-delete {
    display: flex;
    color: var(--red);
    cursor: pointer;
}

@media (max-width: 976px) { 
    .supplierInvoceTranslation-row  {
        flex-direction: column;
    }

    .supplierInvoceTranslation-width {
        width: 100% !important;
    }
}

@media (max-width: 880px) { 
    .supplierInvoceTranslation-row,
    .supplierInvoceTranslation-main,
    .supplierInvoceTranslation-row-m  { 
        gap: 10px
    }
    .supplierInvoceTranslation-row .textfield  .paragraph {
        font-size: 0.9rem !important;
    }
    .supplierInvoceTranslation-row .textfield .textfield-input {
        font-size: 0.9rem !important;
    }
}
