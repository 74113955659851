.modalQuote {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0,0,0,.15);
    backdrop-filter: blur(5px);
    z-index: 11;
}

.modalQuote-main {
    position:fixed;
    top: 10vh;
    right: calc(50% - 450px);
    width: 100%;
    max-width: 900px;
    padding: 30px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(45px);
}

.modalQuote-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modalQuote-header-title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center; 
 }
 .modalQuote-header-title div img {
    width: 37px;
    height: 26px;
  }

.modalQuote-header--text {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.modalQuote-header--color {
    color: var(--gray-1);
    font-size: 1.125rem;
}

.modalQuote-header--subtitle {
    color: #181818;
    font-size: 0.875rem;
}

.modalQuote-header--close {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
}

.modalQuote-body {
    width: 100%;
    max-width: 870px;
    /* height: 100%; */
    display: flex;
    /* overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none; Firefox */
 }

 .modalQuote-header-select {
    width: 100%;
    padding: 20px 0px;
    display: flex;
    flex-direction: column ;
    gap: 20px;
 }

  .modalQuote-header-select .selectServiceType-container {
    max-width: fit-content;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

 @media (max-width: 900px) { 
   .modalQuote-main {
      margin: auto;
      right: auto
    }
 }

 @media (max-width: 830px) { 
    .modalQuote-full .modalFull-body .quoter-container .quoter-content 
    .quoteTransport-container__scroll .quoteTransport-row .lclcd .lclcd-bottom 
    .lclcd-first-row .lclcd-first-row-section {
      width: 100%;
    }

    .modalQuote-full .modalFull-body .quoter-container .quoter-content 
    .quoteTransport-container__scroll .quoteTransport-row .lclcd .lclcd-bottom 
    .lclcd-first-row .lclcd-first-row-section .lclcd-row-message .inputElement-container {
      width: 100%;
    }
 }

 @media (max-width: 818px) { 
  .defineRoute-from {
    width: 86%;
  }
 }

 @media (max-width: 768px) {
  .defineRoute-from {
    width: 100%;
  } 
  .defineRoute-to .defineRoute-textColor {
    display: none;
  }
 }

 @media (max-width: 747px) { 
  .modalQuote-full .modalFull-body .quoter-container .quoter-content 
  .quoteTransport-container__scroll .quoteTransport-row .lclcd .lclcd-bottom 
  .lclcd-first-row {
    display: flex;
    flex-direction: column;
  }
  .modalQuote-full .modalFull-body .quoter-container .quoter-content 
  .quoteTransport-container__scroll .quoteTransport-row .lclcd .lclcd-bottom 
  .lclcd-first-row .lclcd-first-row-section .lclcd-row-message .inputElement-container 
  .inputElement {
    max-width: 389px;
    min-width: 200px;
 }  
}

@media (max-width: 737px) { 
  .modalQuote-full .modalFull-body .quoter-container .quoter-content 
  .quoteTransport-container__scroll .quoteTransport-row .lclcd .lclcd-bottom 
  .lclcd-first-row .lclcd-first-row-section .lclcd-row-message .inputElement-container 
  .inputElement {
    min-width: 180px;
 } 
}

 @media (max-width: 734px) {
  .modalQuote-full .modalFull-body .quoter-container .quoter-content 
  .quoteTransport-container__scroll .inputOriginDestiny_main .inputOriginDestiny 
  .defineRoute-container .defineRoute-row {
    flex-direction: column !important;
  }
  .modalQuote-full .modalFull-body .quoter-container .quoter-content 
  .quoteTransport-container__scroll .inputOriginDestiny_main .inputOriginDestiny 
  .defineRoute-container .defineRoute-row .defineRoute-from {
    width: 100%;
  }
 }

 @media (max-width: 709px) { 
  .modalQuote-full .modalFull-body .quoter-container .quoter-content 
  .quoteTransport-container__scroll .quoteTransport-row .lclcd .lclcd-bottom 
  .lclcd-first-row .lclcd-first-row-section .lclcd-row-message .inputElement-container 
  .inputElement {
    min-width: 160px;
 } 
}

@media (max-width: 673px) { 
  .modalQuote-full .modalFull-body .quoter-container .quoter-content 
  .quoteTransport-container__scroll .quoteTransport-row .lclcd .lclcd-bottom 
  .lclcd-first-row .lclcd-first-row-section .lclcd-row-message .inputElement-container 
  .inputElement {
    min-width: 140px;
 } 
}

@media (max-width: 632px) { 
  .modalQuote-full .modalFull-body .quoter-container .quoter-content 
  .quoteTransport-container__scroll .quoteTransport-row .lclcd .lclcd-bottom 
  .lclcd-first-row .lclcd-first-row-section .lclcd-row-message .inputElement-container 
  .inputElement {
    min-width: 130px;
 } 
}

@media (max-width: 632px) { 
  .modalQuote-full .modalFull-body .quoter-container .quoter-content 
  .quoteTransport-container__scroll .quoteTransport-row .lclcd .lclcd-bottom 
  .lclcd-first-row .lclcd-first-row-section .lclcd-row-message .inputElement-container 
  .inputElement {
    min-width: 127px;
 } 
 /* .modalQuote-main {
    top: 5vh !important;
  } */
}

@media (max-width: 600px) {
  .modalQuote-main {
    width: 100%;
    height: 100%;
    top: 0vh;
    border-radius: 0px;
    position: relative;
    padding: 20px;
  }
  .modalQuote-header--color {
    font-size: 1rem;
  }
  .modalQuote-header--description {
    font-size: 0.875rem;
  }
  .modalQuote-header--subtitle {
    display: none;
  }
}