.tableQuotation-container {
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
    border-radius: 13px;
}

.tableQuotation-radius--top {
    border-radius: 13px 13px 0px 0px;
    padding: 11.34px 20px !important;
}
.tableQuotation-raduis--bottom {
    border-radius: 0px 0px 13px 13px;
    padding: 17px 20px !important;
}

.tableQuotation-color--text {
    color: var(--gray-1);
}

.tableQuotation-radius--top .row__title {
     width: 200px; 
    /* width: 100%; */
}

.tableQuotation-container .rowGroup-container div .row .row__values .row__values-item {
    width: 100%;
    max-width: 200px;
    justify-content: center;
    text-align: center;
}

.tableQuotation-container .tableQuotation-raduis--bottom .tableQuotation-color--text .row__values-item {
    width: 100%;
    max-width: 200px;
    justify-content: center;
    text-align: center;
}

.table-container .row .row__values .row__values-item {
    width: 100%;
    max-width: 200px;
    justify-content: center;
    text-align: center;
}

.table-container .table-raduis--bottom .row__values .row__values-item {
    width: 100%;
    max-width: 200px;
    justify-content: center;
    text-align: center;
}

@media (max-width: 630px) { 
    .tableQuotation-raduis--bottom {
        padding: 10px !important;
    }
}