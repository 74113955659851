.textAreaInput {
    width: 100%;
    border: 1px solid var(--black-25);
    border-radius: 24px;
    padding: 6px 20px;
    min-height: 49.5px;
    height: fit-content;
}

.textAreaInput-title {
    top: 15px;
    font-family: "Faktum";
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: var(--gray-1);
}

.textAreaInput-answer {
    font-family: 'Faktum-Medium';
    font-style: normal;
    font-weight: 400;
    word-wrap: break-word;
    white-space: normal;
    -webkit-box-orient: vertical;
}

@media (max-width: 600px) {  
    .textAreaInput-answer {
        font-size: 0.875rem;
    }
}