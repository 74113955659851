.reviewCardSharedContainer-contrainer {
    max-width: 52.5rem;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    background: var(--background);
    border-image-source: var(--border-image-source);
    
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 24px;
}

.reviewCardSharedContainer-column {
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.reviewCardSharedContainer-right__ {
    display: flex;
    justify-content: flex-end;
}

.reviewCardSharedContainer-display {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.reviewCardSharedContainer-img img {
    background: var(--yellow);
    padding: 2px;
    border-radius: 50px;
}

.reviewCardSharedContainer-column .rating-display {
    backdrop-filter: none;
    padding: 0px;
}

.reviewCardSharedContainer-blockText {
    /* width: 16.875rem; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.reviewCardSharedContainer-colorTitle {
    color: var(--gray-1);
    font-size: 1rem;
}

.reviewCardSharedContainer-colorDetail {
    color: var(--gray-2);
    /* flex-direction: row;
    display: flex;
    gap: 5px */
}
.reviewCardSharedContainer-score-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    width: 100%;
}

.reviewCardSharedContainer-colorAmount {
    color: var(--gray-3);
}

.reviewCardSharedContainer-score {
    /* width: 20.625rem; */
    width: 100%;
    display: flex;  
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.reviewCardSharedContainer-score .textfield input {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
}

.reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container {
    display: flex;
    gap: 10px
}

.reviewCardSharedContainer-score-content .reviewCardSharedContainer-img {
    display: flex;
}

.reviewCardSharedContainer-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reviewCardSharedContainer-add {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-text {
    width: 150%;
}

.textfield {
    width: 100%;
}

.reviewCardSharedContainer-score .textfield  .textfield-input  {
    background: var(--white);
}

.reviewCardSharedContainer-row-button {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.reviewCardSharedContainer-row-button .custom-button {    
    border-radius: 12px;
    padding: 10px 15px;
    font-size: 0.75rem !important;
}

@media (max-width: 1025px) {
    .reviewCardSharedContainer-button {
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 15px;
    }
}

@media (max-width: 880px) {
    .reviewCardSharedContainer-colorTitle {
        font-size: 0.9rem !important;
    }
    .reviewCardSharedContainer-contrainer,
    .reviewCardSharedContainer-blockText,
    .reviewCardSharedContainer-score-content {
        gap: 10px
    }
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-text {
        font-size: 0.875rem !important;
    }
    .reviewCardSharedContainer-button .custom-button {
        font-size: 0.9rem;
    }
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-incial,
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-middle,
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-final {
        width: 20px;
    }
    .reviewCardSharedContainer-score .textfield .textfield-title {
        font-size: 0.875rem;
    }
}
@media (max-width: 750px) {
    .reviewCardSharedContainer-blockText {
        width: auto;
    }  

    .reviewCardSharedContainer-score {
        width: auto;
    }
}

@media (max-width: 713px) {
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-text {
        font-size: 0.75rem !important;
    }
}

@media (max-width: 700px) {
    .reviewCardSharedContainer-contrainer {
        border-radius: 12px;
    }
}

@media (max-width: 699px) {
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-incial,
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-middle,
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-final {
        width: 40px;
    }
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-text {
        font-size: 0.9rem !important;
    }
    .reviewCardSharedContainer-button {
        flex-direction: row;
        align-items: center;
    }
}

@media (max-width: 655px) {
    .reviewCardSharedContainer-button {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 526px) { 
    .reviewCardSharedContainer-colorDetail  {
        /* flex-direction: column; */
    } 
}

 @media (max-width: 520px) {
    .reviewCardSharedContainer-img {
        /* align-self: flex-start; */
    }
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating {
        width: 100%;
    }
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-incial, 
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-middle, 
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-final {
        min-width: 2px;
    }
    /* .reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-text {
        width: 100%;
    } */
}

@media (max-width: 492px) {
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating {
        width: fit-content;
    }
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-incial,
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-middle,
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-final {
        width: 20px;
    }
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-text {
        font-size: 0.75rem !important;
    }
}

@media (max-width: 425px) {
    .reviewCardSharedContainer-contrainer {
        padding: 10px;
    }
}

 @media (max-width: 403px) {
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-text {
        font-size: 0.87rem;
    }
 }

 @media (max-width: 367px) {
    .reviewCardSharedContainer-score-content,
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container {
        gap: 5px
    }
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating {
        width: 70%;
    }
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-incial, 
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-middle, 
    .reviewCardSharedContainer-score .reviewCardSharedContainer-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-final {
        max-width: 20px;
    }
 }