.checkcircle-container {
	display: flex;
	align-items: center;
	width: fit-content;
	position: relative;
}

.checkcircle-container input[type="checkbox"] {
	cursor: pointer;
	opacity: 0;
	width: 9px;
	height: 9px;
	border-radius: 24px;
	position: absolute;
}

.checkcircle-container label {
	display: flex;
}

.checkcircle-container label::before {
	content: '';
	width: 15px;
	height: 15px;
	border-radius: 20px;
	background: var(--transparent);
	border: 1px solid var(--gray-3);
	box-sizing: border-box;
	left: 0;
	pointer-events: none;
}

.checkcircle-check {
	display: none;
    pointer-events: none;
    position: absolute;
    left: calc(50% - 5px);
    background: var(--green-2);
    height: 10px;
    width: 10px;
    border-radius: 24px;
}

.checkcircle-check-none {
	display: none;
    pointer-events: none;
    position: absolute;
    left: calc(50% - 4.5px);
    background: var(--white);
    height: 9px;
    width: 9px;
    border-radius: 24px;
}

.checkcircle-container input[type="checkbox"]:checked ~ .checkcircle-check {
	display: flex;
}

.checkcircle-disabled input[type="checkbox"] {
	cursor: auto;
}

.checkcircle-disabled label::before {
	background: transparent;
	border: 1px solid var(--gray-3);
}

.checkcircle-disabled {
	border-radius: 24px;
	border: 1px solid var(--white-50);

	background: transparent;
}