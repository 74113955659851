.orderDelivery {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.orderDelivery-disabled {
    background: var(--transparent);
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.orderDelivery-documents {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}

.orderDelivery-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    width: 100%;
}

.orderDelivery-footer-one {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: center;
    align-items: center;
    width: 100%;
}

.orderDelivery-revision {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    text-align: center;
}

.orderDelivery-revision .custom-button {
    background: var(--gray-1);
}

.orderDelivery-text {
    color: var(--black-50);
}

.orderDelivery-detail {
    display: flex;
    flex-direction: column;
    gap: 7px
}

.orderDelivery-detail div span {
    background: var(--white);
    max-height: fit-content;
    min-height: 60px;
    height: 100%;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15) !important;
    padding: 20px !important;
}

.orderDelivery-detail div .textarea[contenteditable]:empty::before {
    padding: 0px;
}

.orderDelivery--number {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.orderDelivery-attachment {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px
}

.orderDelivery-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--gray-1);
}

@media (max-width: 768px) { 
    .orderDelivery {
        margin-bottom: 4rem;
    }
}