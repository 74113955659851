.rowTaxes-container {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.rowTaxes-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rowTaxes__ {
    height: 50px;
    /* height: 60px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* padding: 20px; */
    padding: 5px 20px;
}

.rowTaxes__text {
    /* width: 30%; */
    width: 50%;
}

.rowTaxes_transparent {
    background-color: transparent;
}

.rowTaxes_white {
    background-color: var(--white-25);
}

.rowTaxesFooter__ {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 39px 20px 20px;
    background: var(--white-90);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}

.rowTaxes-body .textfield {
    max-width: 200px;
    min-width: 100px;
    width: 70%;
}

.rowTaxes-body .textfield input:not(:placeholder-shown).textfield-input:not(:focus){
    padding: 7.5px 20px 7.5px 20px;
    text-align: end !important;
    right: 0px !important;
}

.rowTaxes-body .textfield input:focus {
    padding: 0px 15px;
    text-align: end !important;
    right: 0px !important;
}

.rowTaxes__ .inputElement-container .inputElement .inputElement-input,
.rowTaxes__ .inputElementMin-container .inputElementMin .inputElementMin-input {
    padding: 7.5px 20px 7.5px 20px;
    text-align: end;
}

/* responsive */
@media (max-width: 1045px) { 
    .rowTaxes__text {
        font-size: 0.9rem;
    }
}
@media (max-width: 880px) { 
    .rowTaxesFooter__text {
        font-size: 0.9rem;
    }
}


@media (max-width: 425px) { 
    .rowTaxes__, .rowTaxesFooter__ {
        padding: 0px 10px;
    }
    .rowTaxes__text {
        font-size: 0.85rem;
    }
}

@media (max-width: 415px) { 
    .rowTaxes-body .textfield {
        max-width: 170px;
    }
}

@media (max-width: 390px) { 
    .rowTaxes-body .textfield {
        max-width: 150px;
    }
}

@media (max-width: 347px) { 
    .rowTaxes__text {
        width: 40%;
    }
    .rowTaxes-body .textfield {
        max-width: 100px;
        width: 60%;
    }
}