.processOrder-container {
    width: 100%;
    
}

.processOrder-table-compare {
    display: flex;
    flex-direction: column;
    gap: 30px
}

.processOrder-table-compare .tableComparePlanMin_contain {
    border-radius: 24px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    margin: 0rem 0.5rem 4.5rem 0.5rem;
    max-width: 98%;

}

.processOrder-billing {
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
    padding-bottom: 5rem;
}