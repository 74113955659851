.header-month-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    justify-content: center;
    align-items: center;
    /*border-bottom: 2px solid var(--black-15);*/
    background: var(--black-15);
    border-radius: 12px 12px 0px 0px;
}
.header-month-cell:nth-child(2n+2) {
    background: var(--white-25);
}
.calendar-month-title {
    padding-bottom: 10px;
}
.header-month-cell {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    padding-right: 5px;
}

.header-month-colorCell {
    color: var(--gray-2);
}

.header-month-colorDesactive {
    color: var(--gray-4);
}

@media (max-width: 450px) { 
    .header-month-cell{
        width: 100%;
        height: 40px;
    }

    .header-month-DaySelected  {
        width: 40px;
        height: 40px; 
    }
    
    .header-month-today  {
        width: 40px;
        height: 40px;
    }
}