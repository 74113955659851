.processTitle-container {
    display: flex;
    flex-direction: column;
    gap: 20px; 
    width: 100%;
    max-width: 1000px;
    /* max-width: 600px; */
}

.processTitle-title {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 100%;
}

.processTitle-title .textfield input {
    height: 50px;
    font-family: Faktum;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--gray-1);
}

.processTitle-count {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
}

.processTitle-container .selectServiceType-container {
    max-width: fit-content;
    min-width: 260px;
}

@media (max-width: 600px) {
    .processTitle-container {
        padding: 3rem 10px 0rem 10px;
    }
}