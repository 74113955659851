.menu {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.menu-title {
    font-family: "Faktum-SemiBold";
}

.menu-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.menu-item {
    height: 340px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.menu-item-active {
    min-width: 160px;
    height: 45px;
    padding: 10px 15px;
    color:  var(--white);
    border: none;
    border-radius: 24px;
    background: #333333;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    text-align: left;
    font-family: 'Faktum-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    width: 100%;
    justify-content: flex-start;
}

.menu-item-desactive {
    min-width: 160px;
    height: 45px;
    padding: 10px 15px;
    color:  var(--black);
    border: none;
    border-radius: 24px;
    background: none;
    background-color: transparent !important;
    box-shadow: none;
    backdrop-filter: none;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    text-align: left;
    font-family: 'Faktum-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    width: 100%;
    justify-content: flex-start;
}

.menu-item .custom-button-icon,
.menu-footer .custom-button-icon {
    font-family: 'Faktum';
    font-size: 0.875rem;
}

.menu-footer {

}