.buttonBadget {
    height: fit-content;
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.05);
    padding: 9px 13px;
    cursor: pointer;
    align-items: center;
}

.buttonBadget-badget {
    border-radius: 24px;
    background: var(--red);
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonBadget-badget-content {
    color: var(--white);
    border: 2px solid var(--white-50);
    height: 22px;
    width: 22px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}