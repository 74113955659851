.footerSignUp-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    /*max-width: 20.938rem;*/
}


.footerSignUp-divider {
    position: static;
    width: 300px;
    height: 2px;
    background-color: #C4C4C4;
    border: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.footerSignUp-colorText {
    color: var(--gray-1);
    text-align: center;
    font-size: 1rem;
}

@media (max-width: 500px) { 
    .footerSignUp-container .paragraph {
        font-size: 0.8rem;
        text-align: center;
    }
    .footerSignUp-container .footerSignUp-colorText {
        font-size: 0.8rem;
    }
}
@media (max-width: 345px) { 
    .footerSignUp-divider {
        width: 250px;
    }
}