.previewDoc {
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    background: var(--white);
    border-radius: 24px;
    height: 70px;
    align-items: center;
    width: fit-content;
    text-decoration: none;
}

.previewDoc-disabled {
    background: var(--transparent);
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.previewDoc__title {
    color: var(--gray-1);
    min-width: 100px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.previewDoc img {
    display: flex;
    height: 1.7rem;
}