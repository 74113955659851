.nine-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 60px 120px;
}

.nine-section-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.nine-section-text {
    font-size: 3.125rem;
    text-align: center;
    font-family: 'Faktum-SemiBold';
    color: var(--white);
    text-shadow: -6px 9px 20px rgba(255, 197, 37, 0.36), 6px -9px 20px rgba(255, 197, 37, 0.36);
}

.nine-section-white {
    color: var(--white);
}

.nine-section-text-grandient {
    font-size: 3.125rem;
    font-family: 'Faktum-SemiBold';
    background: -webkit-linear-gradient(#F7E246, #FFC525);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.nine-section-switch {
    display: flex;
    justify-content: center;
    height: 26px;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.nine-section-button {
    width: 200px;
    height: 45px;
    font-size: 1.125rem;
    padding: 10px 9px;
    flex-direction: row;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--white);
    border-radius: 12px;
    border: 1px solid var(--bn-white, #FFF);
}

.nine-section-switch .toggle-switch .toggle-switch-label {
    height: 22px;
    background: linear-gradient(90deg, #F7E246 0%, #FFC525 100%);
}

.nine-section-switch .toggle-switch .toggle-switch-label .toggle-switch-switch {
    background: var(--white);
    margin: 0 4px;
    top: 1px;
    height: 18px;
    width: 18px;
}

.nine-section-change {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px
}

.nine-section-text-grandient-min {
    font-size: 0.875rem;
    font-family: 'Faktum-SemiBold';
    background: -webkit-linear-gradient(#F7E246, #FFC525);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.nine-section-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding-top: 1rem;
}

@media (max-width: 1380px) {
    .nine-section {
        padding: 60px;
    }
}

@media (max-width: 1248px) {
    .nine-section {
        padding: 60px 30px;
    }
}

@media (max-width: 1194px) {
    .nine-section-text, 
    .nine-section-text-grandient {
        font-size: 2.7rem;
    }
    .nine-section-row .plan-content .plan-gradient .plan
    .plan-body-first .plan-detail-row .plan-detail-column 
    .plan-text_paid {
        padding-left: 0px !important;
    }
}

@media (max-width: 1180px) { 
    .nine-section {
        padding: 60px 0px;
    }
}

@media (max-width: 1045px) { 
    .nine-section{
        gap: 30px;
        /* padding: 30px 20px; */
    }
    .nine-section-text,
    .nine-section-text-grandient {
        font-size: 2rem;
    }
    .nine-section-row {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px
    }
    .nine-section-switch-text {
        font-size: 1rem;
    }
}

@media (max-width: 665px) { 
    .nine-section-text, 
    .nine-section-text-grandient {
        font-size: 1.42rem;
    }
}