.ratingMarker-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.ratingMarker-rating {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
    /* box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15); */
}

.ratingMarker-item-incial {
    width: 40px;
    height: 20px;
    border-radius: 24px 0px 0px 24px;
    /* background: #FFFFFF; */
    background: var(--black-05);

    /* Negro Transparencia/Black 25% */
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
}

.ratingMarker-item-middle {
    width: 40px;
    height: 20px;
    /* background: #FFFFFF; */
    background: var(--black-05);

    /* Negro Transparencia/Black 25% */
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
}

.ratingMarker-item-final {
    width: 40px;
    height: 20px;
    
    border-radius: 0px 24px 24px 0px;
    /* background: #FFFFFF; */
    background: var(--black-05);

    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
}

.ratingMarker-item-clicked {
    background: #F7E246;
}

@media (max-width: 520px) {
    /*.ratingMarker-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }*/

    .ratingMarker-rating {
        width: 100%;
    }

    .ratingMarker-item-incial, .ratingMarker-item-middle, .ratingMarker-item-final  {
        max-width: 50px;
        width: 100%;
   
    }
}