.invoceTranslationLegal-main {
    /* width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px; */
    /* padding: 20px;
    backdrop-filter: blur(20px);
	border-radius: 24px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    border: 0px solid; */
    z-index: 2;
    /* border: 1px solid;
    border-image-source: var(--border-image-source); */
}

.invoceTranslationLegal-main .card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.invoceTranslationLegal-main .invoceTranslationLegal-document .dropdown .dropdown-select .move-text {
    left: 0px;
}

/* .invoceTranslationLegal-main .invoceTranslationLegal-textField .textfield input,
.invoceTranslationLegal-main .invoceTranslationLegal-document .textfield input {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
} */

.invoceTranslationLegal-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
}

.invoceTranslationLegal-header div img {
    display: flex
}

.invoceTranslationLegal-color {
    color: var(--gray-1);
}

.invoceTranslationLegal-textField {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.invoceTranslationLegal-textField-m {
    display: none;
}

.invoceTranslationLegal-width {
    width: 15.833rem !important;
}

.invoceTranslationLegal-document {
    display: flex;
    flex-direction: row;
    gap: 20px;
}


.invoceTranslationLegal-document .dropdown, .invoceTranslationLegal-document .textfield {
    max-width: 390px;
    width: 100%;
    height: 100%;
}

/* Dropdown */
.invoceTranslationLegal-document .dropdown .dropdown-select {
    padding: 20px;
}

.invoceTranslationLegal-textField .textfield .textfield-input, 
.invoceTranslationLegal-document .textfield .textfield-input {
    background: var(--white);
}

/* .invoceTranslationLegal-textField .textfield .textfield-title,
.invoceTranslationLegal-document  .textfield .textfield-title {
    top: 40%;
} */

@media (max-width: 976px) { 
    .invoceTranslationLegal-textField, .invoceTranslationLegal-document {
        flex-direction: column;
    }
    .invoceTranslationLegal-document .dropdown, .invoceTranslationLegal-document .textfield  {
        max-width: 100%;
    }
}

@media (max-width: 880px) {
    .invoceTranslationLegal-main .card-content {
        gap: 10px
    }
    .invoceTranslationLegal-textField {
        display: none;
    }
    .invoceTranslationLegal-textField-m {
        display: flex;
        flex-direction: column;
        gap: 10px
    }
    .invoceTranslationLegal-textField-m-row,
    .invoceTranslationLegal-document {
        display: flex;
        flex-direction: column;
        gap: 10px
    }
    .invoceTranslationLegal-textField-m-row .textfield .paragraph,
    .invoceTranslationLegal-document .textfield-number .paragraph {
        font-size: 0.9rem;
    }
    .invoceTranslationLegal-color,
    .invoceTranslationLegal-textField-m-row .textfield  .textfield-input,
    .invoceTranslationLegal-main .card-content .dropdown .dropdown-select .dropdown_text {
        font-size: 0.9rem !important;
    }
}

@media (max-width: 820px) { 
    .invoceTranslationLegal-textField {
        flex-direction: column;
    }

    .invoceTranslationLegal-document  {
        flex-direction: column;
    }

    .invoceTranslationLegal-width {
        width: 100% !important;
    }

    .invoceTranslationLegal-document .dropdown {
        width: 100% !important;
    }

    .invoceTranslationLegal-document .textfield input {
        width: 100% !important;
    }
    
    .invoceTranslationLegal-document .dropdown, .invoceTranslationLegal-document .textfield { 
        max-width: 100%;
    }
}
@media (max-width: 700px) { 
    .invoceTranslationLegal-main .card-content {
        border-radius: 12px;
    }
    .invoceTranslationLegal-textField-m .textfield .paragraph {
        font-size: 0.9rem;
    }
}

@media (max-width: 425px) {
	.invoceTranslationLegal-main .card-content {
		padding: 10px;
	}
}