.eventDetail-container {
    background: var(--white);
    position: absolute;
    max-width: 117px;
    width: 100%;
    flex-direction: row;
    display: flex;
    z-index: 1;
}
.eventDetail-body {
    padding: 0px 5px;
    align-self: center;
}
.eventDetail-marker {
    width: 6px;
}
.eventDetail-marker__high {
    background: var(--red);
}
.eventDetail-marker__middle {
    background-color: var(--yellow);
}
.eventDetail-marker__low {
    background-color: var(--blue-2);
}
.eventDetail-textColor {
    color: var(--black-75);
}

@media (max-width: 830px) {
    .eventDetail-container { 
        width: fit-content;
    }

    .eventDetail-body .eventDetail-textColor {
        font-size: 0.65rem;
    }
}

@media (max-width: 560px) { 
    .eventDetail-container {
        max-width: 13.5vw;
    }
}

@media (max-width: 480px) { 
    .eventDetail-body {
        width: fit-content;
        padding: 5px 0px;
        overflow: hidden;
    }

    .eventDetail-body .eventDetail-textColor {
        font-size: 0.5rem;
     
    }

}