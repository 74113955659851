.admin-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.content-outlet {
    min-height: 100%;
}

.admin-container .tabBottom {
    display: none;
}

.footer-content {
    background: var(--white) !important;
}

.admin-container .content-outlet:has(.operation-header) {
	position: sticky;
}

.admin-container .content-outlet:has(.modalIA) {
    z-index: 4 !important;
}

.admin-container .content-outlet:has(.modalProcessService) {
    z-index: 11 !important;
}

.admin-container .content-outlet:has(.modal-modalAnimation) {
	position: sticky !important;
    z-index: 5;
}

.admin-container .content-outlet:has(.modalComplete) {
	position: sticky !important;
    z-index: 5;
}

.admin-container .ProviderSearch .userVerify-active {
    top: 11rem;
    right: 19rem;
    left: 7rem;
}

@media (max-width: 768px) {
    .content-outlet {
        min-height: 100%;
    }
    .admin-container .tabBottom {
        display: flex;
    }
    .admin-container .footer {
        display: none;
    }
    .admin-container .footer-content {
        display: flex;
    }
    /* .admin-container .navbar-root div .modal-notification--main .modal-notification-container 
    .modal-notification__header img {
        display: none;
    }
    .admin-container div .modal-notification--main .modal-notification-container 
    .modal-notification__header img {
        display: none;
    } */
    .admin-container .modalQuoteSupplier-container .modal-main .modal-container {
        width: 100%;
    }
    .admin-container .tabBottom .subheader-clicked .tabBottom-subheader-content div .modal-notification--main {
        height: calc(100vh - 50px) !important;
    }
    .admin-container .ProviderSearch .userVerify-active {
        top: 11rem;
        right: 19rem;
        left: 6rem;
    }
}

@media (max-width: 674px) {
    .admin-container .ProviderSearch .userVerify-active {
        top: 11rem;
        right: 9rem;
        left: 8rem;
    }
}

@media (max-width: 600px) {
    .admin-container .ProviderSearch .userVerify-active {
        position: absolute;
        z-index: 10;
        top: 11rem;
        right: 9rem;
        left: 0rem;
        background: none;
        justify-content: flex-start;
        align-items: center;
    }

    .admin-container .ProviderSearch .userVerify-content {
        border-radius: 24px;
        max-width: 280px;
    }

    .admin-container .ProviderSearch .userVerify-content .userVerify-line {
        display: none;
    } 
    .admin-container .ProviderSearch .userVerify-content .userVerify-content-header 
    .userVerify-title {
        font-size: 1rem;
    }
}
/* .footer-bottom {
	padding-bottom: 60px;
} */