.modalIA {
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: rgba(0,0,0,.15);
  
    left: 0;
    position: fixed;
    top: 0;
    z-index: 11;
    justify-content: center;
    display: flex;
    align-items: center;
}

.modalIA-content {
    position: fixed;
    width: 600px;
    height: 224px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding: 30px;

    /* right: calc(50% - 450px); */
    /* top: 4vh; */

    backdrop-filter: blur(45px);
    background: #fff;
    border-radius: 24px;
    /* box-shadow: 0 3px 9px 0 rgba(0,0,0,.15);  */
}

.modalIA-hearder {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modalIa-title {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.modalIA-loader {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.modalIA-loader .loader-container {
    height: fit-content;
}

.modalIa-text {
    font-size:1.125rem
}

.modalIa-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: end;
    padding-top: 1rem;
}

.modalIa-footer .btn-only-text {
    color: #113D7F;
}

.modalIa-footer .custom-button {
    background-image: linear-gradient(to right, #113D7F 0%, #00A4D7 100%, #f6d365 100%);
}

@media (max-width: 600px){
    .modalIA-content{
        width: 100%;
        border-radius: 0px;
        padding: 15px;
        /* height: 100%;
        padding: 20px; */
    }
    .modalIa-footer .custom-button {
        font-size: 0.875rem;
    }
}
@media (max-width: 470px){ 
    .modalIA-content {
        padding: 15px;
    }
}

@media (max-width: 425px){ 
    .modalIa-footer {
        gap: 10px;
    }
    .modalIa-footer .custom-button {
        padding: 10px 20px;
    }
}

@media (max-width: 394px){ 
    .modalIa-footer .btn-only-text {
        width: 40%;
    }
    .modalIa-footer .custom-button {
        padding: 10px;
    }
}
