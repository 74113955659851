.modal-culqi {
    background-color: rgb(0 0 0 / 48%);
    width: 100%;
}

.modal-culqi  .modal-container {
    width: 100%;
}

.modal-culqi-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
}

.modal-culqi-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.modal-culqi-colorText {
    color: var(--gray-1);
}