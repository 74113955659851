.modalSearchUser-container {
    position: relative;
    width: 793px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.modalSearchUser-colorText {
    color: var(--gray-1);
}

.modalSearchUser-button {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.modal-container:has(.modalSearchUser-container) {
    overflow: visible;
    width: 100%;
    bottom: 4rem;
}

.modalSearchUser-selected {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.modalSearchUser-selected .searchUserItem-container:hover {
    background: rgba(255, 255, 255, 0.9);
}

.modalSearchUser--email {
    width: 100%;
    position: absolute;
    top: 85px;
    display: flex;
    background: var(--white);
    border-radius: 12px;
}


@media (max-width: 878px) {
    .modalSearchUser-container {
        width: 100%;
    }
}