.ProviderSearch-container {
  width: 100%;
  max-width: 100%;
}

.ProviderSearch-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.4rem 0.86rem; 
  gap: 10px;
}

.ProviderSearch-container-row {
  /* max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  align-self: center;
}
.ProviderSearch-left,
.ProviderSearch-left-chat {
  /* height: 94vh; */
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px; 
  /* padding: 0.4rem 0.86rem; */
}
.ProviderSearch-left::-webkit-scrollbar,
.ProviderSearch-left-chat::-webkit-scrollbar {
  display: none;
}
.ProviderSearch-left .quote-provider,
.ProviderSearch-left-chat .quote-provider {
  width: 100%;
}
.ProviderSearch-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  flex: 1 1;
  order: 1;
  padding-bottom: 10rem;
}
.ProviderSearch-right::-webkit-scrollbar {
  display: none;
}
.ProviderSearch-quotation-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem;
}
.ProviderSearch-quotation-title-left {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.ProviderSearch-content-phaseNum {
  height: 32px;
  width: 33px;
  background: var(--black-15);
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  position: relative;
}
.ProviderSearch-phaseNum {
  width: 24px;
  height: 24px;
  background-color: var(--black-75);
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: var(--white);
  border: 3px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  left: 0.26rem;
  top: 0.25rem;
}
.ProviderSearch-right-body-content,
.ProviderSearch-right-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0rem;
}
.ProviderSearch-right-body-content {
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding: 1rem 0.9rem;
}
.ProviderSearch-right-body-content::-webkit-scrollbar {
  display: none;
}
.ProviderSearch-chat,
.ProviderSearch-chat--content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  flex: 1 1;
  order: 1;
}

.ProviderSearch-chat::-webkit-scrollbar,
.ProviderSearch-chat--content::-webkit-scrollbar {
  display: none;
}
.ProviderSearch-right-body .tableQuotation .tableQuotation-footer {
  padding-top: 1.5rem;
  padding-bottom: 10rem;
}
.ProviderSearch-right-body .tableQuotation {
  gap: 0px
}
.ordersQuotationDetailPage-abstrac-summary {
  max-width: 52.5rem;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 24px;
  background: var(--background);
  border: 1px solid;
  border-image-source: var(--border-image-source);
}
.ProviderSearch-right-footer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: flex-end;
  padding: 0.5rem 0.9rem 2rem 0.9rem;
}

.ProviderSearch-right-footer .btn-normal {
  width: 100%;
}

@media (min-width: 500px) { 
  .ProviderSearch-right {
    padding: 1.4rem 0rem 1.4rem 0rem;
  }
}

@media (min-width: 675px) { 
  .ProviderSearch-left {
    flex-direction: row;
    align-items: flex-start;
  }
}

@media (min-width: 700px) { 
  .ProviderSearch-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .ProviderSearch-right {
    height: 100%;
    width: 100%;
    max-width: 880px;
    padding: 0.4rem 0rem 10rem 0rem;
    gap: 2px;
  }
  .ProviderSearch-right-body-content {
    padding: 1rem 0.86rem 0.4rem 0.86rem;
  }
  .ProviderSearch-quotation-title {
    padding: 0.5rem 0.9rem;
  }
}
@media (min-width: 769px) { 
  .ProviderSearch-container {
      margin-bottom: 0rem;
  }
  .ProviderSearch-left,
  .ProviderSearch-left-chat {
    height: 94vh;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    gap: 10px; 
    padding: 0.4rem 0.4rem 0.4rem 0.86rem;
    padding-bottom: 10rem;
    min-width: 300px;
    max-width: 340px;
  }
  .ProviderSearch-right-body-content {
    padding: 1rem 0rem 2.5rem 0.5rem;
    height: 100vh;
  }
  .ProviderSearch-container {
    width: 100%;
    max-width: 100%;
    /* padding: 0px 10px; */
  
    height: calc(100vh - 110px);
    overflow: hidden;
  }
  .ProviderSearch-right-body {
    padding-right: 0.5rem;
  }
  .ProviderSearch-container-row {
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    align-self: center;
    padding: 0px 0px 10px 0px;
    height: calc(100vh - 120px);
  }
  .ProviderSearch-right {
    padding: 0.4rem 0.5rem 0rem 0rem;
  }
  .ProviderSearch-right-body .tableQuotation .tableQuotation-footer {
    padding-bottom: 0rem;
  }
}

@media (min-width: 880px) {
  .ProviderSearch-left,
  .ProviderSearch-left-chat  {
      min-width: 340px;
      max-width: 400px;
      /* min-width: 400px;
      max-width: 450px; */
      flex: 1 1;
  }
}
@media (min-width: 1240px) {
  .ProviderSearch-container {
      padding: 0px 20px;
      /* gap: 20px */
  }
  .ProviderSearch-right {
    max-width: 840px;
  }
}

@media (min-width: 1440px) {
  .ProviderSearch .userVerify-active {
    top: 11rem !important;
    right: 19rem !important;
    left: 14rem !important;
  }
}

@media (min-width: 1600px) {
  .ProviderSearch .userVerify-active {
    left: 20rem !important;
  }
}

@media (min-width: 1865px) {
  .ProviderSearch .userVerify-active {
    left: 25rem !important;
  }
}

@media (min-width: 2026px) {
  .ProviderSearch .userVerify-active {
    left: 30rem !important;
  }
}