.headPage {
    display: flex;
    flex-direction: row;
    gap: 5px
}

.headPage-title {
    color: #181818;
    font-size: 1.125rem;
}

.headPage-subtitle {
    color: #333333;
    display: flex;
    font-family: "Faktum";
    /* font-size: 1.125rem; */
    font-size: 0.875rem;
    align-items: end;
    line-height: 28px;
}

@media (max-width: 600px) {
    .headPage {
        justify-content: flex-start;
        text-align: left;
        align-items: flex-start;
    }
    .headPage-title {
        font-size: 1rem !important;
    }
    .headPage-subtitle {
        /* width: 360px; */
        font-size: 0.875rem !important;
        /* padding: 0px; */
    }
}

@media (max-width: 600px) {
    .headPage {
        padding-left: 20px;
    }
}