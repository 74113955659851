.push-notification {
    position: absolute;
    z-index: 5;
    top: 75vh;
    right: 10px;
    
    max-height: 176px;
    border-radius: 12px;
    padding: 20px 20px 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    /* background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%); */
    /* filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.09)); */
    
    border-radius: 12px;
    /* box-shadow: 0px 3px 6px rgba(0, 0, 0push-notification-content, 0.09); */
    max-width: 308px;
    margin-left: 0.3rem;


    background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
    border-image-source: var(--border-image-source);
}
.push-notification-event {
    /* min-height: 148px; */
    height: 100%;
    max-height: 200px;
    border-radius: 12px;
    background: var(--white-75);
    padding: 20px 20px 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 10px
}
.push-notification-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.push-notification-business {
    display: flex;
    flex-direction: row;
}
.header-title-content {
    display: inline;
    max-width: 15.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    color: var(--black);
}
.push-notification-body {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.push-notification-body:hover {
    background: var(--yellow);
}
.push-notification-body .useractive {
    max-width: 40px;
    min-width: 40px;
    width: 100%;
    height: 40px;
}
.push-notification-body .useractive-white {
    max-width: 40px;
    min-width: 40px;
    width: 100%;
    height: 40px;
}
.push-notification-body .useractive img {
    max-width: 40px;
    min-width: 40px;
    width: 100%;
    height: 40px;
    object-fit: contain;
}
.push-notification-body .useractive-white img {
    max-width: 40px;
    min-width: 40px;
    width: 100%;
    height: 40px;
    object-fit: contain;
}
.push-notification-body .useractive .useractive-text {
    font-size: 18px;
}
.push-notification-body .useractive-white .useractive-text {
    font-size: 18px;
    color: #333333;
}
.push-notification-body .header-title-business {
    font-weight: 500;
    color: #333333;
}
.push-event-content {
    display: flex;
    flex-direction: column;
    gap: 10px
}
.push-step_ {
    color: var(--black-75);
}
.push-notification-header-time {
    font-weight: 500;
    color: #181818;
}
.push-notification-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    width: 100%;
}
.push-notification-estado{
    min-height: 12px;
    min-width: 12px;
    border-radius: 12px;
    background: var(--red);
    display: flex;
}
.push-step-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.push-step-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.push-notification .push-step-button 
.custom-button {
    border-radius: 24px;
    height: 28px;
    min-width: 192px;
    max-width: 250px;
    padding: 0px 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.push-notification-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}