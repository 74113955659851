.service {
	display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.service-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 20px;
	/* padding: 10px 77.5px 45px 77.5px; */
	padding: 20px 40px;
	/* margin-bottom: 15rem; */
	/* margin-bottom: 1rem; */
	width: 100%;
    max-width: 1500px;
}

.service-left {
	max-width: 400px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0px 0.5rem 10rem 0.5rem;
	height: fit-content;
	top: 0px;
	position: sticky;
	position: -webkit-sticky;

    height: 100vh;
    overflow: scroll;
}

.service-left::-webkit-scrollbar {
	display: none;
}

.service-left-go-back {
	/* padding-top: 0.6rem; */
	width: 100%;
	display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
}

.service-left .company-card-responsive__main {
	display: flex;
}

.service-display-header {
	display: none;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
}

.service-display-ratings {
	display: flex;
	align-items: center;
	gap: 10px;
}

.service .service-container .service-right .company-card-responsive {
	display: none;
}

/* .service .modal-main {
	padding: 20px 0px;
} */

.sectionTop {
	padding-top: 4rem;
}

.sectionNeverTop {
	padding-top: 2rem;
}

.service-right {
	display: flex;
	flex-direction: column;
	/* gap: 30px; */
	max-width: 840px;
	width: 100%;
	/* margin-bottom: 13rem; */
	height: 100vh;
	overflow: scroll;
	padding: 0px 0.5rem 0.5rem 0.5rem;
}
.service-right::-webkit-scrollbar {
	display: none;
}
.service-right-top {
	display: flex;
}
.service-left-go-back-header {
	display: none;
}
.service-custom-button {
	border: none;
	background: transparent;
}

.service-right #sectionPlan {	
	padding-top: 20px;
}

.service-right #sectionPlan .tableComparePlan_contain {
	padding: 10px 20px;
}

.service-right-top {
	display: flex;
    justify-content: space-between;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
    top: 0.01rem;
    position: sticky;
    position: -webkit-sticky;
    z-index: 2;
    backdrop-filter: blur(10px);
    /* height: 58px; */
	height: 46px;
	padding: 13px 0px;
}

.service-right-go-back {
	display: none;
}

.service-right-subheaders {
	display: flex;
	align-items: center;
	gap: 20px;
}

.service-mobile-btn {
	display: none;
}

.service .modal-main .modal-container {
	max-width: 860px;
    width: 100%;
    margin-top: 10px; 
    margin-bottom: 10px;
    height: auto;
}

.service .modalThanks-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
}

.service .modalThanks-container-row {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	/* gap: 10px; */
}

.service .modalThanks-container-row__ {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.service .modalThanks-container img {
	display: flex;
	width: 26px;
	height: 26px;
	margin-right: 10px;
}

.service-left .company-card .company-card-info .company-card-main 
.company-card-title div {
	display: flex;
}

.service-left .company-card .company-card-info .company-card-main 
.company-card-title div img {
	height: 1rem;
}
/* .service .modal-main .modal-container .modalQuote-container {
	margin-top: -20px;
} */

/* .service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin .quotationTransportOrigin-container .quoteTransport-container,
.service .modal-main .modal-container .modalQuote-transportOrigin-container .quoteCustoms-container,
.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin .quotationTransportOrigin-container .quoteResume-container {
	overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    max-height: calc(74vh - 50px);
    height: 100%;
} */

/* inicio del scroll */
.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin 
.quotationTransportOrigin-container .quoteTransport-container::-webkit-scrollbar,
.service .modal-main .modal-container .modalQuote-transportOrigin-container .quoteCustoms-container::-webkit-scrollbar{
	width: 0px;
	display: none;
}
/*.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin 
.quotationTransportOrigin-container .quoteTransport-container::-webkit-scrollbar-thumb {
	background: var(--black);
	border-radius: 20px;
	height: 10px;
}*/
/* fin del scroll*/

.service .modal-main .Toastify {
	position: absolute;
}

/*Dropdow del modal*/
.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin 
.quotationTransportOrigin-container .quoteTransport-container .lclcd .lclcd-bottom .lclcd-first .dropdown
.dropdown-select .move-text,
.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin 
.quotationTransportOrigin-container .quoteTransport-container .lclcd .lclcd-bottom .lclcd-second .dropdown 
.dropdown-select .move-text {
	left: 10px;
}

.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin 
.quotationTransportOrigin-container .quoteTransport-container .fclcd .fclcd-addContainer .fclcd-container-data 
.dropdown .dropdown-select .move-text{
	left: 10px;
	overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    width: 100%;
    white-space: nowrap;
}

.service .modal-main .modal-container {
	/* gap: 15px; */
	gap: 5px
}

.service-right .service-right-top .service-right-btn .custom-button {
	box-shadow: 0px 8px 20px 0px var(--black-15);
}

.service .modal-sendMessage .modal-container{
	width: fit-content;
	min-width: 500px;
}
.service-left .company-card .company-card-info .company-card-body {
	flex-direction: column-reverse;
}

.service-left .company-card .company-card-info .company-card-body .company-card-work h4{
	justify-content: center;
	display: flex;
}

.quotationTransportOrigin-loader::-webkit-scrollbar {
	width: 0px;
}

.service-left-category-responsive {
	display: none;
}

/* @media (max-width: 1440px) { 
	.service-container {
		padding: 30px 40px;
	}
} */

@media (max-width: 1360px) { 
	.service-right  {
		/* max-width: 800px; */
		max-width: 60vw;
	}
}

@media (max-width: 1319px) { 
	.service-container .service-left .company-card .company-card-imgs .lazyload-wrapper  {
		max-width: 400px;

	}
}

@media (max-width: 1285px) {
	/* .service-right .order-display .order-display-mid .order-display-main-img .order-display-img {
		min-width: auto;
		max-width: auto;
		min-height: auto;
		max-height: auto;
		width: 100%;
		height: 70%;
	} */

	.service-right .order-display .order-display-mid .order-display-main-img .order-display-video{
		max-width: 720px;
		min-width: auto;
		width: 100%;
		max-height: 400px;
		/* min-height: auto; */
		/* height: 70%; */
	}
}

@media (max-width: 1200px) { 
	.service-container {
		padding: 30px;
	}
}

@media (max-width: 1185px) {
	.service-left .company-card .company-card-info .company-card-main .company-card-title h1{
		text-align: center;
	}

	.service-right  {
		/* max-width: 800px; */
		max-width: 55vw;
	}
}

/* @media (max-width: 1144px) { 
	.service-right .order-display .order-display-top .big-header{
		font-size: 2rem;
	}
} */

/* @media (max-width: 1110px) { 
	.service-right .order-display .order-display-top .big-header{
		font-size: 1.8rem;
	}
} */

@media (max-width: 1080px) { 
	.service-right-top .service-right-btn .custom-button {
		padding: 10px 30px;
	}
}

@media (max-width: 1069px) { 
	.service-container {
		gap: 10px;
	}
	/* .service-left {
		max-width: 330px;
	} */
	/* .service-container .service-left .company-card .company-card-imgs .lazyload-wrapper {
		max-width: 330px;
		min-width: 330px;
	} */
}

@media (max-width: 1037px) { 
	.service-left {
		max-width: 350px;
	}
	.service-left .company-card {
		max-width: 380px; 
	}
	.service-container .service-left .company-card .company-card-imgs .lazyload-wrapper {
		max-width: 100%;
		min-width: 100%;
	}
}

@media (max-width: 1025px) {
	.service-container {
		padding: 20px 30px;
	}
	.company-card {
		align-self: center;
	}
	.service-left .company-card .company-card-imgs {
		height: 230px;
	}
	.service-left .company-card .company-card-imgs .lazyload-wrapper .company-card-bg {
		height: 175px;
	}
	.service-left .company-card .company-card-info {
		padding: 0px 15px;
	}
	.service-left .company-card .company-card-info .company-card-btns .btn-small{
		padding: 10px 0px;
	}
	.order-display-subheader div {
		font-size: 1rem;
	}
	.order-display-subheader .order-display-ratings .rating span,
	.order-display-subheader .order-display-ratings .commentCount span, 
	.order-display-subheader .order-display-ratings .city-country-display span {
		font-size: 0.8rem;
	}
	.order-display-subheader .order-display-ratings .rating {
		max-height: 24px;
	}
	.order-display-subheader .order-display-ratings .rating div img {
		height: 0.84rem;
	}
	.order-display-subheader .order-display-ratings .city-country-display .city-country--image img {
		width: 1.3rem;
    	height: 0.7rem;
	}
}

@media (max-width: 1013px) {
	.service-right .service-right-top .service-right-btn .btn-normal {
		padding: 10px 20px;
		min-width: 120px;
		font-size: 0.85rem;
	} 
}

@media (max-width: 952px) {
	.service-right .service-right-top .service-right-btn .btn-normal {
		padding: 10px;
		min-width: 80px;
		font-size: 0.8rem;
	}
	.service-right-top .service-right-subheaders {
		gap: 10px;
	}
}

/*Modal*/
@media (max-width: 943px) {
	.service-left {
		max-width: 350px;
	} 
	/* .service-left-category .cardContentPlan {
		padding: 20px !important;
	} */
	.order-display-subheader div {
		font-size: 0.9rem;
	}
	.order-display-subheader .order-display-ratings .rating span, 
	.order-display-subheader .order-display-ratings .commentCount span, 
	.order-display-subheader .order-display-ratings .city-country-display span {
		font-size: 0.75rem;
	}
}

@media (max-width: 914px) {
	.service-right-top .service-right-subheaders .scrollHighlightNabbar-container {
		gap: 10px
	}
	.service-right-top .service-right-subheaders .scrollHighlightNabbar-container .scrollHighlightNabbar {
		font-size: 0.875rem;
	}
	.service-right .service-right-top .service-right-btn .btn-normal {
		display: flex;
		height: 35px;
		align-items: center;
	}
}

@media (max-width: 900px) {
	.service-right-subheaders {
		gap: 10px;
	}
}

/* @media (max-width: 878px) { 
	.service-right .service-right-top .service-right-subheaders {
		gap: 10px;
	}
} */
@media (max-width: 890px) { 
	.service .modal-main .modal-container {
		/* margin: 0px; */
		gap: 15px
	}
	.service .modal-sendMessage .modal-container {
		margin: 20px;
		gap: 15px;
		width: 100%;
		min-width: auto;
	}
	.service-left-go-back .go-back-button .go-back-button-text {
		font-size: 0.875rem;
	}
}

@media (max-width: 881px) { 
	.order-display-subheader .small-subheader {
		font-size: 0.75rem;
	}
}

@media (max-width: 875px) {
	.service-container {
		padding: 20px;
	}
}

@media (max-width: 859px) {
	.service-right-subheaders {
		gap: 5px;
	}
}

@media (max-width: 852px) {
	.service-container {
		padding: 20px;
		gap: 10px
	}
}

@media (max-width: 850px) {
	.service-left {
		max-width: 320px;
	}
	.service-container .service-left .company-card .company-card-imgs .lazyload-wrapper {
		max-width: 300px;
		min-width: 300px;
	}
	.service-left .company-card .company-card-info .company-card-social {
		gap: 3px;
	}
	.service-right-top {
		height: 40px;
	}
	.company-card-responsive-social {
		gap: 6px
	}
	.company-card-responsive-imgs .lazyload-wrapper .useractive img, 
	.company-card-responsive-imgs .lazyload-wrapper .useractive, 
	.company-card-responsive-imgs {
		height: 70px;
		width: 70px;
	}
	.company-card-responsive-social .rating span, 
	.company-card-responsive-social .commentCount span, 
	.company-card-responsive-social .city-country-display span {
		font-size: 0.75rem;
	}
	.company-card-responsive-social .rating  {
		padding: 5px 7px;
	}
	.company-card-responsive-social .rating div img {
		height: 0.9rem;
	}
	.company-card-responsive-social .city-country-display .city-country--image img {
		width: 22px;
		height: 12.5px;
	}
	.company-card-responsive-social .city-country-display {
		padding: 5px 7px;
		border-radius: 12px;
		min-width: fit-content;
	}
}

/* @media (max-width: 841px) {
	.service-right-btn {
		display: none;
	}
} */

@media (max-width: 809px) {
	.service-right .review-display .review-display-top {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
	}
}
@media (max-width: 800px) {
	.service-right-top .service-right-subheaders .scrollHighlightNabbar-container .scrollHighlightNabbar {
		font-size: 0.8rem;
	}
	.order-display-subheader .order-display-ratings .rating div img {
		height: 0.7rem;
	}
	.order-display-subheader .order-display-ratings .commentCount div img {
		height: 0.6rem;
	}
	.order-display-subheader .order-display-ratings .rating span, 
	.order-display-subheader .order-display-ratings .commentCount span, 
	.order-display-subheader .order-display-ratings .city-country-display span {
		font-size: 0.65rem;
	}
}

@media (max-width: 790px) {
	.service-container {
		padding: 20px 17px;
	}
}

@media (max-width: 785px) {
	.service-right-top {
		justify-content: space-between;
		width: 100%;
	}
	.service-right-top .service-right-subheaders .scrollHighlightNabbar-container .scrollHighlightNabbar {
		font-size: 0.8rem;
	}
}

@media (max-width: 781px) {
	.service-container {
		padding: 20px 10px;
	}
}

@media (max-width: 778px) {
	.service-right .review-display .review-display-ratings {
		gap: 10px
	}
}

@media (max-width: 768px) {
	.service-left-category, .service--resume {
		display: none;
	}
	.order-display-topp {
		padding-top: 0px;
		width: 100%;
	}
	.company-card-responsive__main,
	.service-left-category-responsive {
		display: none;
	}
	/* .service-left-category-responsive {
		display: flex;
	} */
	.service-container {
		flex-direction: column;
		/* gap: 48px; */
		gap: 15px;
		min-height: 100%;
    	justify-content: flex-start;
		margin-bottom: 0rem;
		padding: 15px 20px 30px 20px;
	}
	.service-left .company-card,
	.service-left .company-card-responsive__main  {
		display: none;
	}
	.service-right-top .service-right-subheaders .scrollHighlightNabbar-container {
		gap: 30px
	}
	.service-right-top .service-right-subheaders .scrollHighlightNabbar-container .scrollHighlightNabbar {
		font-size: 1rem;
	}
	.company-card-responsive-imgs .lazyload-wrapper .useractive img, 
	.company-card-responsive-imgs .lazyload-wrapper .useractive, 
	.company-card-responsive-imgs {
		width: 90px;
    	height: 90px;
	}
	.company-card-responsive-title h1 {
		font-size: 1rem;
	}
	.service-left .service-display-header {
		display: none;
	}

	.service-right-subheaders {
		gap: 20px
	}
	.service-left {
		max-width: 100%;
		gap: 10px;
		padding-bottom: 0rem;
		top: 0px;
		position: relative;
		position: -webkit-relative;
	}
	.service .service-container .service-right .company-card-responsive {
		display: flex;
	}
	.service-right .review-display .review-display-top {
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: flex-start;
	}
	/* .service-right-top {
		position: relative;
		position: -webkit-relative;
		z-index: 0;
		top: 0;
	} */
	/* .service-right .order-display {
		background: none;
		border: 0px solid;
    	border-image-source: none;
		backdrop-filter: blur(0px);
		padding: 10px 0px 30px 0px;
		gap: 25px
	} */
	.service-right .order-display .order-display-top .order-display-header {
		gap: 10px;
	}
	.service-right .order-display .order-display-top .order-display-header .order-display-social {
		gap: 10px;
		justify-content: flex-start;
	}
	/* .service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin 
	.quotationTransportOrigin-container .quoteTransport-container .fclcd div .container-row-list .container-row
	.container-row-info {
		flex-direction: column;
		gap: 10px;
	} */
	.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin 
	.quotationTransportOrigin-container .quoteTransport-container .lclcd .lclcd-bottom .lclcd-last {
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
		align-self: flex-start;
		align-items: self-start;
	}
	/* .service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin 
	.quotationTransportOrigin-container .quoteTransport-container .fclcd div .container-row-list .container-row
	.container-row-info .container-row-textfields {
		gap: 10px;
	} */
	.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin 
	.quotationTransportOrigin-container .quoteTransport-container .fclcd div .container-row-list .container-row
	.container-row-info .container-row-textfields .textfield .textfield-title {
		left: 10px;
	}
	.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin 
	.quotationTransportOrigin-container .quoteTransport-container .fclcd div .container-row-list .container-row
	.container-row-info .container-row-textfields .container-row-trash {
		align-self: center
	}
	.service-right  {
		/* max-width: 800px; */
		max-width: 100%;
		margin-bottom: 3rem;
		align-items: center;
		gap: 20px;
		padding-top: 0.8rem;
	}
	.service-right-top {
		display: none;
	}
	.service-left-go-back-header {
		display: flex;
		height: fit-content;
		align-items: center;
		align-self: center;
	}
	.service-left-go-back-header .scrollHighlightNabbar-container .scrollHighlightNabbar {
		font-size: 0.875rem;
	}
	.order-display-subheader .small-subheader {
		font-size: 0.9rem;
	}
	.order-display-subheader .order-display-ratings .rating span, 
	.order-display-subheader .order-display-ratings .commentCount span, 
	.order-display-subheader .order-display-ratings .city-country-display span {
		font-size: 0.875rem;
	}
	.order-display-subheader .order-display-ratings .rating div img {
		height: 0.84rem;
	}
	.order-display-subheader .order-display-ratings .commentCount div img {
		height: 0.8rem;
	}
	.order-display-subheader .order-display-ratings .city-country-display .city-country--image img {
		width: 20px;
		height: 11.5px;
	}
	/* .service-right #sectionPlan {
		padding-top: 20px;
	} */
	.service-left {
		position: sticky;
		top: 0.01rem;
		max-width: 100%;
		padding: 0px;
    	z-index: 1;
		backdrop-filter: blur(10px);
		height: 46px;
	}
}

@media (max-width: 730px) {
	.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin 
	.quotationTransportOrigin-container .quoteTransport-container .fclcd .fclcd-addContainer .fclcd-container-data 
	.dropdown .dropdown-select .move-text{
		left: 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 300px;
		width: 100%;
		white-space: nowrap;
	}
}

@media (max-width: 600px) {
	.service-container {
		padding: 0px 5px 30px 5px;
	}
	.order-display-subheader {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px
	}
	.service-left-go-back {
		justify-content: flex-start;
	}
	/* .service-right .order-display .order-display-top {
		text-align: center;
	} */
	.service-left-go-back-header .scrollHighlightNabbar-container .scrollHighlightNabbar {
		font-size: 0.8rem;
	}
	.order-display-subheader .order-display-ratings .rating div img {
		height: 0.7rem;
	}
	.order-display-subheader .order-display-ratings .rating span, 
	.order-display-subheader .order-display-ratings .commentCount span, 
	.order-display-subheader .order-display-ratings .city-country-display span {
		font-size: 0.75rem;
	}
	.order-display-subheader .order-display-ratings .commentCount div img {
		height: 0.6rem;
	}
	.order-display-subheader .order-display-ratings .city-country-display .city-country--image img {
		width: 1.3rem;
		height: 0.7rem;
	}
}

@media (max-width: 569px) {
	.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin .quotationTransportOrigin-container 
	.quoteTransport-container .fclcd div .container-row-list .container-row .container-row-info {
		width: 100%;
	}
}


@media (max-width: 519px) {
	.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin .quotationTransportOrigin-container 
	.quoteTransport-container div .inputOriginDestiny_main .inputOriginDestiny-container .defineRoute-from .shipment
	.textfield input:not(:placeholder-shown).textfield-input:not(:focus)   {
		padding: 22.5px 20px 7.5px 18px;
	}
}

@media (max-width: 500px) {
	.service-right-top .service-right-subheaders .scrollHighlightNabbar-container .scrollHighlightNabbar {
		font-size: 0.875rem;
	}
	.order-display-subheader .small-subheader {
		font-size: 0.75rem;
	}
}
@media (max-width: 480px) {
	.service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin 
	.quotationTransportOrigin-container .quoteTransport-container div .inputOriginDestiny_main .inputOriginDestiny-container
	.inputOriginDestiny-to .inputOriginDestiny-text {
		display: none;
	}
	.service-right-subheaders {
		gap: 10px;
	}
}

@media (max-width: 445px) {
	.service-left-go-back-header {
		width: 100%;
		height: 38px;
		padding-left: 0.2rem;
	}
}

@media (max-width: 428px) {
	.service-right-go-back {
		margin-left: 20px;
	}

	.service-right-top {
		/*margin-left: 20px;*/
	}

	.service {
		position: relative;
	}

	.service-right .review-display {
		padding: 0px;
	}

	.service-mobile-btn {
		position: fixed;
		display: none;
		z-index: 999;
		/* position: fixed; */
		left: 0;
		right: 0;
		bottom: 0;
		background-color: var(--white-50);
		padding: 10px 20px;
		backdrop-filter: blur(40px);
	}
	.service-mobile-btn button {
		width: 100%;
	}
	.service-right .review-display .review-display-top .rating-display {
		width: 100%;
	}
	.service-right .review-display .review-display-top .rating-display .rating-display-container .rating-display-text .rating-slider .rating-slider-active,
	.service-right .review-display .review-display-top .rating-display .rating-display-container .rating-display-text .rating-slider .rating-slider-default {
		width: 0.15rem;
	}
}

@media (max-width: 398px) {
	.company-card-responsive-title h1 {
		font-size: 0.9rem;
		max-width: 11rem;
	}
}

@media (max-width: 380px) {
	.service-left-go-back {
		gap: 10px
	}
}

@media (max-width: 375px) {
	.service-right-subheaders .service-custom-button span {
		margin: 0px;
		width: 100%;
		display: initial;
	}
	.service-right-subheaders .service-custom-button {
		padding: 0px;
		height: 50px;
	}
}

@media (max-width: 371px) {
	.service-container .service-right .service-right-top .service-right-subheaders {
		gap: 10px
	}
}

@media (max-width: 352px) {
	.service-container .service-right .service-right-top .service-right-subheaders {
		gap: 5px
	}
}


@media (max-width: 343px) {
	.service-container .service-right .service-right-top .service-right-subheaders .service-custom-button .subheader {
		font-size: 0.8rem;
	}
	.service-container .service-right .service-right-top .service-right-subheaders {
		gap: 2px
	}
	.service .modal-main .modal-container {
		/* margin: 10px; */
	}
}

@media (max-width: 314px) {
	.service-container .service-right .service-right-top .service-right-subheaders .service-custom-button .subheader {
		font-size: 0.75rem;
	}
}

@media (max-width: 2639px) and (max-height: 1777px) { 
    .service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin .quotationTransportOrigin-container 
    .quotationTransportOrigin-loader,
    .service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin .quotationTransportOrigin-container 
    .quotationTransportOrigin-loader .quoteResume-container {
        height: 100%;
        max-height: calc(82vh - 72px);
        overflow: auto
    }
}

@media (max-width: 600px) and (max-height: 1000px) { 
    .service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin .quotationTransportOrigin-container 
    .quotationTransportOrigin-loader,
    .service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin .quotationTransportOrigin-container 
    .quotationTransportOrigin-loader .quoteResume-container {
        height: 100%;
        overflow: auto;
        max-height: calc(86vh - 72px);
    }
}


@media (max-width: 600px) and (max-height: 830px) { 
    .service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin .quotationTransportOrigin-container 
    .quotationTransportOrigin-loader,
    .service .modal-main .modal-container .modalQuote-transportOrigin-container .quotationTransportOrigin .quotationTransportOrigin-container 
    .quotationTransportOrigin-loader .quoteResume-container {
        height: 100%;
        overflow: auto;
        max-height: calc(75vh - 90px);
    }
}