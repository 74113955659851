.modalreview .modal-container {
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    background: var(--white);
    border: none;
    border-radius: 0px;
    justify-content: space-between;
    align-items: center;
    gap: 76px
}

.modalreview .modal-container .modal__header {
    width: 100%;
}

.modalreview-content {
    display: flex;
    flex-direction: column;
    gap: 60px;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 400px;
}

.modalreview-title {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.modalreview-profile {
    width: 100%;
    max-width: 263px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px
}

.modalreview-profile .useractive {
    background: var(--black-15);
    width: 150px;
    height: 150px;
    border-radius: 80px;
    border: 1px solid var(--gray-5);
    cursor: pointer;
}

.modalreview-profile .useractive img {
    width: 100%;
    height: 100%;
    border-radius: 80px;
    object-fit: cover;
}

.modalreview-rating {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.modalreview-rating-title {
    text-align: center;
}

.modalreview-rating-blue {
    color: var(--blue-2);
}

.modal-review-rating-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.modalreview-img {
    background: var(--yellow);
    border-radius: 50px;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.15));
}

.modalreview-img img {
    display: flex;
    width: 20px;
    height: 20px;
}

.modalreview--number {
    color: var(--gray-3);
    width: 100%;
    justify-content: flex-end;
    display: flex;
}

.modal-review-ranting-slider {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.modalreview-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.modalreview-detail__ {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.modalreview-detail-row {
    display: flex;
    flex-direction: row;
    gap: 20px
}

.modalreview-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.modalreview-footer-two {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 60px;
    width: 100%;
}

.modal-review-ranting-slider .ratingService-container .ratingService-rating .ratingService-item-incial,
.modal-review-ranting-slider .ratingService-container .ratingService-rating .ratingService-item-middle, 
.modal-review-ranting-slider .ratingService-container .ratingService-rating .ratingService-item-final {
    width: 40px;
}


@media (max-width: 390px) { 
    .modalreview-content {
        width: 100%;
    }
    .modal-review-ranting-slider .rating-slider {
        max-width: 100%;
    }
}