.filesFLCShared-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.filesFLCShared-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.filesFLCShared-title {
    text-align: center;
}

.filesFLCShared-color {
    text-align: center;
    color: var(--gray-1);
}

.filesFLCShared-files {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:30px;
}

.filesFLCShared-more {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-2);
}

.filesFLCShared-files .UploadFileArray .UploadFileArray-container 
.UploadFileArray-content-bottom {
    justify-content: space-between;
    width: 100%;
}

.filesFLCShared-files .UploadFileArray .UploadFileArray-container 
.UploadFileArray-content-bottom .UploadFileArray-bottom {
    width: 100%;
}

.filesFLCShared-files .UploadFileArray .UploadFileArray-container 
.UploadFileArray-content-bottom .multimedia-row {
    width: 100%;
    justify-content: flex-end;
}

@media (max-width: 480px) {

    .filesFLCShared-header{
        padding: 0px 8px;
    }

    .big-header {
        font-size: 1.8rem;
    }

    .big-subheader {
        font-size: 1rem;
    }

}