.paymentcd {
	max-width: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.paymentcd-cardContent {
	max-width: 100%;
    width: 100%;
	box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
	margin: 5px;
}

.paymentcd-detail {
	width: 100%;
	height: 57vh;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.payment-container {
	display: flex;
	align-items: center;
	gap: 20px;
}

.payment-container h3 {
	margin: 0;
	flex: 1;
	color: var(--gray-1);
}

.payment form {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.payment-cards-img {
	display: flex;
	align-items: center;
	gap: 15px;
}

.payment-textfields_1,
.payment-textfields_2, .payment-textfields_3 {
	display: flex;
	gap: 20px;
	justify-content: space-between;
}

.payment-textfields_1 .textfield,
.payment-textfields_2 .textfield {
	width: 100%;
}

.payment button {
	width: 100%;
}

.payment-expiration--container {
	width: fit-content;
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 50%;
}

.payment-colorText__  {
	color: transparent
}

.payment-expiration-date {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
}

.payment-cvv-dropdown-error {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin-bottom: 1.1rem;
}

.payment-vvv-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	gap: 20px;
}

.payment-error {
	color: transparent;
	font-size: 14px;
}

.payment-colorText {
	color: var(--gray-3)
}

.payment-slash{
	font-size: 20px;
	color: var(--gray-3);
	font-family: Faktum;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.payment-cvv-dropdown label {
	position: absolute;
    pointer-events: none;
    position: absolute;
    left: 20px;
    top: 50%;
    color: var(--gray-3);
    transform: translateY(-50%);
    transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
    top: 15px;
    font-family: "Faktum";
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: var(--gray-1);
}

.payment-cvv-dropdown select {
	background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    /* padding: 20px; */
    padding: 22.5px 20px 7.5px 20px;
    font-size: 16px;
    line-height: 20px;
    color: var(--gray-1);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15) !important;
    border-radius: 24px;
    border: 0px solid var(--black-15);
}

.payment-vvv-container .textfield {
	width: 60% !important;
	max-width: 100% !important;
}

.payment-cvv-dropdown {
	position: relative;
	width: 100%;
}

.payment-cvv-dropdown select {
	background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    width: 100%;
    max-width: 100%;
}



@media (max-width: 815px) {
	.payment-textfields_1 .textfield {
		width: 60%;
	}

	.payment-textfields_3  {
		flex-wrap: wrap;
	}

	.payment-expiration--container, .payment-expiration-date {
		width: 100%;
	}

	.payment-cvv-dropdown {
		width: 60%;
	}

}

@media (max-width: 647px) {
	.payment form .payment-textfields_1,
	.payment form .payment-textfields_2 {
		flex-wrap: wrap;
	}
	.payment-textfields_1 .textfield, .payment-textfields_2 .textfield {
		max-width: 100%;
	}
}

@media (max-width: 600px) { 
	.payment-textfields_1 {
		flex-direction: column;
	} 
	.payment-textfields_1 .textfield {
		width: 100%;
	}
	.payment-textfields_1 .dropdown {
		width: 100% !important;
		max-width: 100% !important;
	}


}

@media (max-width: 500px) {
	.payment-textfields_2 {
		flex-direction: column;
	}
	.payment-vvv-container .dropdown {
		width: 90% !important;
		max-width: 100% !important;
	}
}

@media (max-width: 375px) {
	.payment form .payment-textfields_1,
	.payment form .payment-textfields_2 {
		flex-wrap: wrap;
	}

	.payment-textfields_2 div:last-child {
		min-width: 100%;
	}

	.payment-vvv-container .dropdown {
		width: 90% !important;
		max-width: 100% !important;
	}
}