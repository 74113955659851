.importPhasesCard-main {
    display: flex;
    flex-direction: column;
    max-width: 25rem;
    padding: 20px 20px 0px 20px;
    background: var(--background);
    /* border: 1px solid; */
    border-image-source: var(--border-image-source);
    backdrop-filter: blur(40px);
    border-radius: 24px;
}

.importPhasesCard-colorText {
    color: var(--gray-1);
}

.importPhasesCard-header {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.importPhasesCard-header p,  
.importPhasesCard-header .importPhasesCard-title h1{
    margin: 0px;
}

.importPhasesCard-content-name {
    display: flex;
    flex-direction: row;
    gap: 9px
}

.importPhasesCard-content-verify {
    height: 1.5rem;
}

.importPhasesCard-title{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.importPhasesCard-header-rigth {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
}

.importPhasesCard-rating {
    display: flex;
    flex-direction: row;
    gap:10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.importPhasesCard-online {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 67px;
    width: 100%;
    max-height: 1.75rem;
    border-radius: 12px;
    background-color: var(--green-2);
    padding: 5px;
    color: white;
}

.importPhasesCard-container {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    margin: 10px 0px;
}

.importPhasesCard-row {
    display: flex;
    flex-direction: row;
    gap: 15px;
    cursor: pointer;
    align-items: center;
    height: 20px;
}

.importPhasesCard-number-content {
    color: var(--white);
    background: var(--black-15);
    border-radius: 24px;
    position: relative;
    height: 34px;
    width: 34px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    z-index: 3;
}

.importPhasesCard-number-content-white {
    background: var(--white-25);
    border-radius: 24px;
    position: relative;
    height: 34px;
    width: 34px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

.importPhasesCard-number {
    color: var(--gray-1);
    background: var(--white-90);
    height: 26px;
    width: 26px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0.26rem;
    top: 0.25rem;
}


.importPhasesCard-active-black {
    background-color: var(--black-75);
    border: 3px solid rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    color: white;
    box-shadow: 0px 0px 10px var(--black-50);
}

.importPhasesCard-phase {
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
}

.importPhasesCard-line {
    margin-left: 17px;
    margin-top: 9px;
    height: 15px;
    position: initial;
}

.importPhasesCard-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: var(--white-90);
    border-radius: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.importPhasesCard-price p {
    margin: 0px;
}

.importPhasesCard-main .custom-button {
    border-radius: 24px;
}

.importPhasesCard-price-title {
    color: var(--gray-2);
}

.importPhasesCard-price-number {
    color: var(--gray-1);
}
.importPhasesCard-rating .city-country-display 
.city-country--image img {
    width: 20px;
    height: 12.5px;
}

.importPhasesCard-colorText_company { 
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    min-width: 0;
    white-space: nowrap;
}

@media (max-width: 1250px) { 
    .importPhasesCard-main {
        width: 100%;
        max-width: 22rem;
    }
    .importPhasesCard-container {
        padding: 0px;
    }
    .importPhasesCard-header {
        gap: 0px;
    }
    .importPhasesCard-colorText_company {
        font-size: 0.9rem !important;
    }
    .importPhasesCard-number-content-white,
    .importPhasesCard-number-content {
        width: 26px;
        height: 26px;
        min-width: 26px;
        min-height: 26px;
    }
    .importPhasesCard-number {
        height: 20px;
        width: 20px;
        min-width: 20px;
        min-height: 20px;
        top: 0.18rem;
        left: 0.18rem;
        font-size: 0.875rem;
    }
    .importPhasesCard-line {
        margin-left: 12px;
        margin-top: 9px;
    }
    .importPhasesCard-row {
        height: 16.6px;
    }
}
@media (max-width: 1024px) {
    .importPhasesCard-rating .commentCount span,
    .importPhasesCard-rating .city-country-display span,
    .importPhasesCard-rating .importPhasesCard-online,
    .importPhasesCard-colorText  {
        font-size: 0.875rem;
    }
    .importPhasesCard-header-rigth p {
        font-size: 0.75rem;
    }
    .importPhasesCard-number, 
    .importPhasesCard-phase {
        font-size: 1rem;
    }
    .importPhasesCard-number {
        height: 23px;
        width: 23px;
        min-width: 23px;
        min-height: 23px;
        top: 0.13rem;
        left: 0.14rem;
    }
    .importPhasesCard-number-content-white, 
    .importPhasesCard-number-content {
        width: 27px;
        height: 27px;
        min-width: 27px;
        min-height: 27px;
    }
}
@media (max-width: 959px) {
    .importPhasesCard-rating {
        gap:5px;
    }
}

@media (max-width: 894px) {
    .importPhasesCard-rating .rating,
    .importPhasesCard-rating .commentCount,
    .importPhasesCard-rating .city-country-display {
        padding: 5px 8px;
    }
    .importPhasesCard-rating .rating span,
    .importPhasesCard-rating .commentCount span,
    .importPhasesCard-rating .city-country-display span,
    .importPhasesCard-rating .importPhasesCard-online {
        font-size: 0.8rem;
    }
}

@media (max-width: 880px) {
    .importPhasesCard-main {
        padding: 15px 15px 0px 15px;
        border-radius: 12px;
    }
    .importPhasesCard-rating {
        gap:10px;
    }
    .importPhasesCard-rating .rating,
    .importPhasesCard-rating .commentCount,
    .importPhasesCard-rating .city-country-display {
        padding: 5px 10px;
    }
    .importPhasesCard-rating .rating span,
    .importPhasesCard-rating .commentCount span,
    .importPhasesCard-rating .city-country-display span,
    .importPhasesCard-rating .importPhasesCard-online {
        font-size: 0.8rem;
    }
    .importPhasesCard-row {
        gap: 9px
    }
    /* .importPhasesCard-price-number {
        font-size: 0.9rem;
    } */
    .importPhasesCard-number, 
    .importPhasesCard-phase {
        font-size: 0.875rem;
    }
}
@media (max-width: 699px) {
    .importPhasesCard-number {
        /* height: 20px;
        width: 20px; */
        min-width: 20px;
        min-height: 20px;
        /* top: 0.22rem;
        left: 0.22rem; */
    }
    .importPhasesCard-rating .rating span,
    .importPhasesCard-rating .commentCount span,
    .importPhasesCard-rating .city-country-display span,
    .importPhasesCard-rating .importPhasesCard-online {
        font-size: 0.875rem;
    }
    /* .importPhasesCard-price-number {
        font-size: 1rem;
    } */
}

@media (max-width: 600px) { 
    .importPhasesCard-colorText_company {
        font-size: 1rem;
    }
    .importPhasesCard-main .custom-button {
        font-size: 1rem;
        font-family: 'Faktum';
    }
    /* .importPhasesCard-price-number {
        font-size: 0.875rem;
    } */
    .importPhasesCard-row{
        height: 16.7px;
    }
    .importPhasesCard-line {
        margin-top: 8.6px;
    }
}

@media (max-width: 425px) {
    .importPhasesCard-main {
        padding: 10px 10px 0px 10px;
        border-radius: 12px;
    }
    .importPhasesCard-rating .commentCount span,
    .importPhasesCard-rating .city-country-display span,
    .importPhasesCard-rating .importPhasesCard-online {
        font-size: 0.75rem;
    }
  
}

@media (max-width: 390px) {
    .importPhasesCard-rating {
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
    } 
}