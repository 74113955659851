.tutorial {
    display: flex;
    flex-direction: row;
    gap: 30px;
    cursor: pointer;
    justify-content: space-between;
}

.tutorial-left {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.tutorial-left-header {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.tutorial-left-header img {
    width: 1.2rem;
    height: 1.2rem;
    object-fit: cover;
}

.tutorial-right img {
    width: 90px;
    height: 60px;
    object-fit: cover;
}