.modalProcessService-footer-container {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: var(--white);
    padding-right: 1.9rem;
    align-items: center;
    z-index: 1;
}

.modalProcessService-footer--draft {
    max-width: fit-content;
    padding: 5px 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    cursor: pointer;
}

.modalProcessService-footer--buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
}

.modalProcessService-footer-draft {
    font-size: 1rem;
    color: var(--black-50);
}

@media (max-width: 820px) {
    .modalProcessService-footer-container {
        padding-right: 1.2rem;  
    }
}

@media (max-width: 600px) {
    .modalProcessService-footer-container {
        padding: 10px 15px;
        align-items: center;
        box-shadow: 0 3px 9px 0 rgba(0,0,0,.15);
    }
    .modalProcessService-footer-draft {
        font-size: 0.9rem;
    }
}

@media (max-width: 450px) {
    .modalProcessService-footer--draft {
        padding: 5px;
        gap: 5px;
        width: 65%;
    }
    .modalProcessService-footer-draft {
        font-size: 0.8rem;
    }
}

@media (max-width: 414px) {
    .modalProcessService-footer-container .modalProcessService-footer--buttons .btn-small {
        min-width: 50px;
        padding: 10px 25px;
    }
}

@media (max-width: 389px) {
    .modalProcessService-footer--draft { 
        width: 75%;
    }
    .modalProcessService-footer-container .modalProcessService-footer--buttons .btn-small {
        padding: 10px 30px;
    }
}