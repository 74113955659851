.rating-display {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 10px 20px;
	border-radius: 12px;
	background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
    filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.06));
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.06);
    border-image-source: var(--border-image-source);
}

.rating-display-container {
	display: flex;
	align-items: center;
	gap: 10px;
}

.rating-display-content-text {
	display: flex;
	flex-direction: column;
	gap: 3px
}

.rating-display-text {
	display: flex;
	align-items: center;
	gap: 10px;
}

.rating-display-img {
    display: flex;
    align-items: center;
    justify-content: center;
	width: 30px;
    height: 30px;
    border-radius: 100%;
    background: var(--yellow);
}

.rating-display-title {
	color: #181818;
}

.rating-display h1 {
	margin: 0;
	color: var(--gray-1);
}

.rating-display span {
	color: var(--gray-1);
}

@media (max-width: 600px) {  
	.rating-display-title {
		font-size: 1rem;
	}
}