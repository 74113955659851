.dropdownMultiLine-container {
    /* max-width: 270px; */
    position: relative;
    width: 100%;
    max-height: 54px;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    cursor: pointer;
    border-radius: 24px;
    background: var(--negro-transparencia-black-5, rgba(0, 0, 0, 0.05));
    backdrop-filter: blur(20px);
}

.dropdownMultiLine-nonblur {
    backdrop-filter: none;
}

.dropdownMultiLine-title--container {
    width: 100%;
    display: flex;
    padding: 20px;
}

.dropdownMultiLine-title {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.dropdownMultiLine-colorText {
    color: var(--gray-1);
}

.dropdownMultiLine-placeholder--up {
    margin-top: -10px;
    color: #333;
    font-family: "Faktum";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.dropdownMultiLine-options {
    position: absolute;
    top: 4em;
    z-index: 4;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    border-radius: 24px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FFF;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
}

.dropdownMultiLine-options--item {
    padding: 15px;
}

.dropdownMultiLine-options--item:hover {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 12px;
}

.dropdownMultiLine-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdownMultiLine-error {
    position: absolute;
    top: 3rem;
}
