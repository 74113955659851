.CalendarDetail-content {
    border-radius: 24px;
    overflow: hidden;
}
.CalendarDetail {
    width: 880px;
    max-width: 100%;
    /* max-height: 710px; */
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
}

.calendarDetail-container {
    position: relative;
    max-width: 880px;
    width: 100%;
    /*max-height: 642px;*/
    max-height:680px;
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    padding-top: 20px;
    background: var(--white-25);
}
.calendarDetail-container::-webkit-scrollbar {
    width: 0px;
}
.calendarDetail-responsive {
    display: none;
}
.calendarDetail-responsive-content-title {
    display: flex;
    position: relative;
    flex-direction: row;
    height: 50px;
    background: var(--white-75);
    align-items: center;
    padding: 10px 15px;
    gap: 6px;
}

/*Responsive */ 
.calendarDetail-responsive-content {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 30px 20px;
    gap: 21px;
    background: var(--background);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-image-source: var(--border-image-source);
}
.calendarDetail-responsive-content .bodyCalendar .bodyCalendar-content {
    background: var(--white-75);
}
@media (max-width: 554px) { 
    .calendarDetail {
        display: none;
    }
    .calendarDetail-responsive {
        display: block;
    }
    .calendarDetail-responsive-content {
        /*background: var(--white-50);*/
        height: 100%;
    }
    .CalendarDetail-content {
        border-radius: 0px;
        height: 100%;
    }
    .calendarDetail-responsive .timeLine-container .timeLine-detail-line {
        height: 2.5px;
    }
}
@media (max-width: 400px) { 
    .calendarDetail-responsive .timeLine-container .timeLine-detail-line {
        height: 2px;
    }
}