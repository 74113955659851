.chat-message-page {
    width: 100%;
    height: calc(100vh - 50px);
    /* margin-top: 30px; */
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 10px;
}

.chat-message {
    max-width: 1090px;
    /* background: blue; */
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 15px 3px 0px 0px;
    gap: 3px;
    height: 100%;
}

.chat-message .chatDetail-search-responsive {
    height: 58px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    backdrop-filter: blur(20px); 
    padding: 10px 15px;
    position: relative;
    z-index: 2;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
}

.chat-message .chatDetail-search-responsive .chat-right-search_active-icon-search img {
    display: flex;
    width: 1rem;
}

.chat-message .chat-header,
.chat-message .chatDetail-search-responsive {
    margin: 0rem 0.5rem;
}

.chat-message .chat-message-page-content,
.chat-message .chat-message-search {
    transform-origin: left top;
    flex-grow: 1;
    position: relative;
    height: 100%;
    overflow: hidden;
    transform: translateZ(0);
}

.chat-message .chat-header .chat-header-content-personal {
    border-radius: 12px;
}

.chat-message .chat-message-page-content .chat .chat-container,
.chat-message .chat-message-page-content .chat .chat-personal,
.chat-message .chat-message-search .chat .chat-container,
.chat-message .chat-message-search .chat .chat-personal {
    max-width: 100%;
}

.chat-message .chat-message-page-content .chat .chat-container .chat-message,
.chat-message .chat-message-page-content .chat .chat-personal .chat-message,
.chat-message .chat-message-search .chat .chat-container .chat-message,
.chat-message .chat-message-search .chat .chat-personal .chat-message {
    max-width: 1090px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 15px 3px 0px 0px;
    gap: 3px;
}

.chat-message .chat-message-page-content .chat .chat-container form,
.chat-message .chat-message-page-content .chat .chat-personal form ,
.chat-message .chat-message-search .chat .chat-container .form,
.chat-message .chat-message-search .chat .chat-personal .form {
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    flex: none;
    order: 2;
    width: 100%;
    min-height: var(--height-pane-footer);
    padding: 0;
    width: 100%;
    left: 0px;
}

.chatDetail-archive-list-imgs {
    max-width: 1200px;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
}

.chatDetail-archive-imagen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 125px;
    background: rgba(0, 0, 0, 0.05);
    /* border-radius: 8px; */
    color: var(--gray-3);
    overflow: hidden;
}

.modal-imagenes-content {
    width: 7rem;
    height: 8rem;
    object-fit: cover;
}

@media (max-width: 880px) { 
    .chat-message {
        padding: 15px 0px 0px 0px;
        gap: 0px
    }
    .chat-message .chat-header,
    .chat-message .chatDetail-search-responsive {
        margin: 0rem;
    }
    .chat-message .chat-header .chat-header-content-personal {
        border-radius: 0px;
    }
    .chat-message .chat-header .chat-header-content-personal .chat-header-left-personal {
        padding-left: 1rem;
    }
    .chat-message .chat-message-page-content .chat,
    .chat-message .chat-message-search .chat {
        padding-bottom: 0rem;
    }
    .chat-message .chat-message-page-content .chat .chat-container .chat-message,
    .chat-message .chat-message-page-content .chat .chat-personal .chat-message,
    .chat-message .chat-message-search .chat .chat-container .chat-message,
    .chat-message .chat-message-search .chat .chat-personal .chat-message {
        padding: 0px 3px 0px 3px;
    }
    .chat-message-page .chat-header 
    .chat-header-content {
        border-radius: 0px;
        width: 100%;
        max-width: 900px;
    }
    .chat-message-page .chat-header .chat-header-content .chat-header-left,
    .chat-message-page .chat-header .chat-header-content .chat-header-right  {
        border-radius: 0px;
        width: 100%;
        max-width: 900px;
    }
    .chat-message-page .chat-header .chat-header-content .chat-header-right .chat-header-right-content 
    .chat-header-right-dropdown .dropdown__disabled {
        background: var(--white);
        border: 0px;
    }
    .chat-message-page .chat-header .chat-header-content .chat-header-right .chat-header-right-content 
    .chat-header-right-dropdown .dropdown__disabled .dropdown-select .select-title {
        font-size: 0px;
        transform: translate(20px, 0%);
    }
    .chat-message-page .chat-header .chat-header-content .chat-header-right .chat-header-right-content 
    .chat-header-right-dropdown .dropdown__disabled .dropdown-select .move-text {
        transform: translate(0, 7%);
    }
    .chat-message-page .chat-header .chat-header-content .chat-header-left {
        background: var(--white);
    }
    .chat-message-page .chat-header .chat-header-content .chat-header-left .chat-header-title-content .chat-header-title-order, 
    .chat-message-page .chat-header .chat-header-content .chat-header-left .chat-header-title-content .chat-header-title-type-operation {
        color: var(--black);
    }
    .chat-message-page .chat-header .chat-header-content .chat-header-right .chat-header-right-content,  
    .chat-message-page .chat-header .chat-header-content .chat-header-right .chat-header-right-content .chat-header-right-dropdown {
        width: 100%;
        max-width: 800px;
        align-self: center;
    } 
    .chat-message-page .chat-header .chat-header-content .chat-header-right .chat-header-right-content .chat-header-text-right {
        max-width: 350px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .chat-message-page .chat-header .chat-header-content .chat-header-right .chat-header-right-content .chat-header-right-dropdown .dropdown-select {
        padding: 10px 20px;
    }
    .chat-message-page .chat-header .chat-header-content .chat-header-right .chat-header-right-content .chat-header-right-dropdown .dropdown-select .select-title {
        font-size: 10px;
        transform: translate(20px, 35%);
    }
    .chat-message-page .chat-header .chat-header-content .chat-header-right .chat-header-right-content .chat-header-right-dropdown .dropdown-select .move-text {
        transform: translate(0, 40%);
        font-size: 12px;
    }
    .chat-message-page .chat-header .chat-header-content .chat-header-left .chat-header-title-content{
        max-width: 300px;
        width: 100%;
    }
    .chat-message-page .chat-header .chat-header-content .chat-header-left .chat-header-title-content .chat-header-title-order,
    .chat-message-page .chat-header .chat-header-content .chat-header-left .chat-header-title-content .chat-header-title-type-operation{
        max-width: 600px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .chat-message-page .chat-header .chat-header-mobile-options {
        padding: 0px 20px 10px 20px;
        width: 100%;
        max-width: 800px;
    }
    .chat-message-page .chat-header .chat-header-mobile-options .messageTopBar-main{ 
        width: 100%;
        max-width: 800px;
    }
    .chat-message-page .chat-message-page-content .chat-container,
    .chat-message .chat-message-search .chat-container  {
        border-radius: 0px;
        width: 100%;
        max-width: 100%;
        margin: 0rem;
    }
    .chat-message-page .chat-message-page-content .chat-personal,
    .chat-message .chat-message-search .chat-personal {
        border-radius: 0px;
        width: 100%;
        max-width: 100%;
        margin: 0rem;
    }
    .chat-message-page { 
        padding-bottom: 0px;
    }
    .chat-message-page-content,
    .chat-message-search {
        padding: 0px;
    }
    .chatDetail-title {
        max-width: 500px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
} 

@media (max-width: 768px) { 
    .chat-message {
        padding: 0px;
    }
}