.rowGroup-supplier-container {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.rowGroup-supplier-header-container {
    height: 40px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white-50);

}

.rowGroup-supplier-header--title {
    color: var(--gray-1);
}

.rowGroup-supplier-header--pointer {
    cursor: pointer;
}

.rowGroup-supplier-color-white {
    color: var(--white);
}

.rowGroup-supplier-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rowGroup__ {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.rowGroup__text {
    /* width: 30%; */
}

.rowGroupFooter__ {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: var(--white-90);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.rowGroup-supplier-body .rowGroup__:nth-child(even) {
    background-color: var(--white-25);
}

/* .rowGroup_transparent {
    background-color: transparent;
}

.rowGroup_white {
    background-color: var(--white-25);
} */

.rowGroup_black {
    background-color: var(--black-50);
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.rowGroup-supplier-body .textfield {
    max-width: 200px;
    min-width: 100px;
    width: 70%;
}


.rowGroup-supplier-body .textfield input:not(:placeholder-shown).textfield-input:not(:focus){
    padding: 7.5px 20px 7.5px 20px;
    text-align: end !important;
    right: 0px !important;
}

.rowGroup-supplier-body .textfield input:focus {
    padding: 0px 15px;
    text-align: end !important;
    right: 0px !important;
    margin-right: 0px;
}

/*InputElement*/
.rowGroup-supplier-container .rowGroup-supplier-body .rowGroup__ .inputElement {
    max-width: 200px;
}
.rowGroup-supplier-container .rowGroup-supplier-body .rowGroup__ .inputElement .inputElement-focus {
    display: none;
}

.rowGroup-supplier-container .rowGroup-supplier-body .rowGroup__ .inputElement .inputElement-input,
.rowGroup-supplier-container .rowGroup-supplier-body .rowGroup__ .inputElementMin .inputElementMin-input {
    padding: 10px 20px;
    text-align: right;
}

/* responsive */
@media (max-width: 1045px) { 
    .rowGroup__text {
        font-size: 0.9rem;
    }
}

@media (max-width: 966px) { 
    .rowGroup__text {
        width: 50%;
    }
    .rowGroup__ .inputElementMin-container {
        width: 40%;
    }
}

@media (max-width: 630px) {
  
    /* .rowGroup-supplier-header-container {
       padding:10px;
    } */
    .rowGroupFooter__ div {
        font-size: 14px;
    }

    .rowGroup-supplier-header--title {
        font-size: 14px;    
    }
}

@media (max-width: 630px) {
    .rowGroup__text {
        width: 50%;
    }
}

@media (max-width: 514px) {
    .rowGroup__ .inputElement-container .inputElement .inputElement-input {
        width: 150px;
    }
}

@media (max-width: 425px) { 
    .rowGroup__, .rowGroupFooter__ {
        padding: 0px 10px;
    }
    .rowGroup__text {
        font-size: 0.85rem;
    }
}

@media (max-width: 415px) { 
    .rowGroup-supplier-body .textfield {
        max-width: 170px;
    }
}

@media (max-width: 390px) { 
    .rowGroup-supplier-body .textfield {
        max-width: 150px;
    }
}

@media (max-width: 347px) { 
    .rowGroup__text {
        width: 40%;
    }
    .rowGroup-supplier-body .textfield {
        max-width: 100px;
        width: 60%;
    }
}