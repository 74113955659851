.rowQuoteDetail-container {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.rowQuoteDetail-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rowQuoteDetail__ {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    width: 100%;
}

.rowQuoteDetail_text {
    width: 50%;
}

.rowQuoteDetail_text__ {
    text-align: right;
}

.rowQuoteDetail-body .rowQuoteDetail__:nth-child(even) {
    background-color: var(--white-25);
} 


/* .rowQuoteDetail_transparent {
    background-color: transparent;
}

.rowQuoteDetail_white {
    background-color: var(--white-15);
} */

@media (max-width: 968px) {
    .rowQuoteDetail_text, .rowQuoteDetail_text__ {
        font-size: 0.9rem;
    }
    .rowQuoteDetail__ {
        height: fit-content;
    }
}

@media (max-width: 630px) {
    .rowQuoteDetail__ {
        padding: 5px 10px;
        height: fit-content;
    }
}
@media (max-width: 425px) {
    .rowQuoteDetail_text, .rowQuoteDetail_text__ {
        font-size: 0.85rem;
    }
}