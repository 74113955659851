.emoji-container {
    display: flex;
    flex-direction: row;
    width: 15rem;
    height: 20rem;
    background: var(--white);
    border-radius: 24px;
    padding: 12px 15px;
    align-items: center;
    gap: 15px;
    bottom: 4.5rem;
    margin-top: 10px;
    position: absolute;
    align-items: flex-start;
}

.emoji-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    overflow-y: scroll;
    height: 18.3rem;
}

.emoji-grid::-webkit-scrollbar {
    width: 0px;
}

.emoji-item {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    cursor: pointer;
}

.emoji-item:hover {
    background: var(--black-05);
}