.quoteContainerShared {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    padding-bottom: 1rem;
}

.quoteContainerShared-container{
    width: 100%;
    background: linear-gradient(111deg, rgba(255, 255, 255, 0.50) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);    ;
    backdrop-filter: blur(40px);
    border-radius: 24px;
    overflow: hidden;
    padding: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.quoteContainerShared-line {
    width: 100%;
    height: 1px;
    align-self: stretch;
    background: var(--black-25);
    margin: 0;
    border: 0;
}

.quoteContainerShared-first {
    width: 100%;
    /* max-width: 370px; */
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.quoteContainerShared-first-detail {
    display: flex;
    flex-direction: column;
    gap: 15px
}

.quoteContainerShared-first-detail_ {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.quoteContainerShared-first-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.quoteContainerShared-first-row-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    max-width: 320px;
}

.quoteContainerShared-fact-service-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.quoteContainerShared-first-row-header-detail {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.quoteContainerShared-first-row-header-detail .rating, 
.quoteContainerShared-first-row-header-detail .city-country-display {
    border-radius: 6px;
}

.quoteContainerShared-firt-column {
    display: flex;
    gap: 5px;
    flex-direction: column;
}
.quoteContainerShared-first--img {
    width: 30.227px;
    height: 30px;
    background: white;
    border-radius: 50px;
    align-items: center;
    display: flex
}

.quoteContainerShared-fact-service-check {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.quoteContainerShared-first-img {
    width: 30.227px;
    height: 30px;
}

.quoteContainerShared-fact-service-check div{
    display: flex;
}

.quoteContainerShared-fact-service-check div img{
    height: 15x;
    width: 15px;
}

.quoteContainerShared-firt-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.quoteContainerShared-firt-title {
    padding-bottom: 5px;
}

.quoteContainerShared-firt-row_ {
    display: flex;
    align-items: center;
    gap: 1px
}

@media (max-width: 900px) { 
    .quoteContainerShared-first-row-header {
        max-width: 300px;
    }
}

@media (max-width: 840px) { 
    .quoteContainerShared-first-row-header {
        max-width: 250px;
    }
}

@media (max-width: 790px) { 
    .quoteContainerShared-first-row-header {
        max-width: 220px;
    }
    .quoteContainerShared-fact-service-title {
        font-size: 0.75rem;
    }
}

@media (max-width: 760px) {
    .quoteContainerShared-first-row-header {
        max-width: 200px;
    }
}

@media (max-width: 740px) {
    .quoteContainerShared-first-row-header {
        max-width: 180px;
    }
}

@media (max-width: 720px) {
    .quoteContainerShared-first-row-header {
        max-width: 160px;
    }
}

@media (max-width: 699px) {
    .quoteContainerShared-first-row-header {
        max-width: 350px;
    }
    .quoteContainerShared-fact-service-title {
        font-size: 1rem;
    }
}

@media (max-width: 560px) {
    .quoteContainerShared-first-row-header {
        max-width: 310px;
    }
    .quoteContainerShared-fact-service-title {
        font-size: 0.8rem;
    }
}

@media (max-width: 517px) {
    .quoteContainerShared-first-row-header {
        max-width: 270px;
    }
}

@media (max-width: 477px) {
    .quoteContainerShared-first-row-header {
        max-width: 230px;
    }
}

@media (max-width: 438px) {
    .quoteContainerShared-first-row-header {
        max-width: 200px;
    }
}

@media (max-width: 408px) {
    .quoteContainerShared-first-row-header {
        max-width: 180px;
    }
}

@media (max-width: 388px) {
    .quoteContainerShared-first-row-header {
        max-width: 170px;
    }
}