.status-container-green {
    background: var(--green-2);
    border-radius: 24px;
    max-width: fit-content;
    height: 30px;
    display: flex;
}
.status-container-red {
    height: 30px;
    width: fit-content;
    display: flex;
    background: var(--red);
    border-radius: 12px;   
}
.status {
    max-width: 120px;
    width: 100%;
    height: 30px;
    flex-direction: row;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0px 10px;
    justify-content: space-between;
}
@media (max-width: 1171px) {
    .status-container-green, .status {
        height: 28px;
    }
}
@media (max-width: 375px) {
    .status-container-green { 
        margin-top: 4px;;
    }
}
@media (max-width: 337px) {
    .badge-text-color {
        font-size: 0.8rem;
    }
}
