.paySuccessful-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.paySuccessful-colorText {
    color: var(--gray-1);
}

.paySuccessful-icon {
    width: 30px;
    height: 30px;
}


.paySuccessful-button {
    display: flex;
    justify-content: flex-end;
}