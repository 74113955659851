.insurance-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.insurance-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: 24px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    display: flex;
    height: 54px;
    padding: 10px 20px;
}

.insurance-container__background {
    padding: 20px;
    background-color: var(--white-90);
    border-radius: 24px;
}

.insurance-question {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.insurance-question div {
    display: flex;
}

.insurance--icon img {
    display: flex;
    width: 20px;
    height: 20px;
}

.insurance-check {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.insurance-col {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.insurance-colorText {
    color: var(--gray-1);
}

.insurance-colorText__white {
    color: var(--white);
}

.insurance-check .checkbox-container {
    border: 0px solid var(--gray-3);
    background-color: var(--black-05);
}

.insurance-info .hover .hover-text {
    right: 0px;
}

.insurance-col .text-error {
    position: absolute;
    padding-top: 3rem;
}

@media (max-width: 600px) {
    /* .insurance-question .hover .hover-text {
        left: -140px;
    } */
    .insurance-colorText {
        font-size: 0.875rem;
    }
    .insurance-col .insurance-check .paragraph-header {
        font-size: 0.875rem;
    }
    .insurance-col .insurance-check .checkbox-container input {
        width: 28px;
        height: 28px;
    }
    .insurance-col .insurance-check .checkbox-container label::before {
        width: 28px;
        height: 28px;
    }
}

/* @media (max-width: 530px) {
    .insurance-container {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }
} */

@media (max-width: 387px) {
    .insurance-content {
        padding: 10px 12px;
    }
}
@media (max-width: 374px) {
    .insurance-container {
        /* flex-direction: column; */
        gap: 20px;
        align-items: flex-start;
        align-items: center;
    }
}

@media (max-width: 370px) { 
    .insurance-container .insurance-check {
        gap: 7px;
    }
    .insurance-container .insurance-check .insurance-colorText {
        font-size: 0.9rem;
    }
}

@media (max-width: 363px) {
    .insurance-container {
        gap: 10px;
    }
}