.selectCategoryEvent-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--white);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}

.selectCategoryEvent-title {
    max-height: 45px;
    height: 100%;
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    background-color: var(--black-05);
    border-radius: 12px;
    height: 50px;
    align-items: center;
}

.selectCategoryEvent-title--colorText {
    color: var(--black-50);
}
.selectCategoryEvent-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 20px;
}
.selectCategoryEvent-content-color {
    display: flex;
    flex-direction: row;
    gap: 25px;
}
.selectCategoryEvent-content-color-responsive {
    display: none;
}
.selectCategoryEvent-content .textfield{
    max-width: 100% !important;
}
.selectCategoryEvent-content .textfield
.textfield-input {
    background-color: var(--white);
}
.selectCategoryEvent-add-title {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
}
.selectCategoryEvent-add-color-title,
.selectCategoryEvent-name-category { 
    color: var(--black-50);
}
.selectCategoryEvent-name-category {
    border: 2px solid var(--black-05);
    border-radius: 12px;
    padding: 10px 20px;
}
.selectCategoryEvent-container 
.selectCategoryEvent-content-color .github-picker {
    border-radius: 8px !important;
    height: 100%;
}
.selectCategoryEvent-content-color-select {
    height: 263px;
    width: 100px;
    border-radius: 8px;
    border: 1px solid var(--gray-4);
    display: flex;
}
.selectCategoryEvent-button {
    flex-direction: row;
    padding-bottom: 20px;
    justify-content: flex-end;
    display: flex;
    gap: 10px;
}

.selectCategoryEvent-category {
    height: 100%;
    padding: 0px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.selectCategoryEvent-borderBottom, 
.selectCategoryEvent-add-title {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--black-05);
}
.selectCategoryEvent-service___{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.selectCategoryEvent-service--box {
    width: 35px;
    height: 30px;
    border: 2px solid var(--black-15);
    border-radius: 8px;
}
.selectCategoryEvent-service--name {
    color: var(--black-75);
    max-width: 521px;
    width: 100%;
    align-self: center;
}
.selectCategoryEvent-service div {
    display: flex;
}
.selectCategoryEvent-tag {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.selectCategoryEvent-title--textBlue {
    cursor: pointer;
    color: var(--blue-2);
    padding-bottom: 10px;
    border-bottom: 1px solid var(--black-05);
}
@media (max-width: 716px) { 
    .selectCategoryEvent-content-color {
        display: flex;
        flex-direction: column-reverse;
        gap: 25px;
        align-items: center;
    }
    .selectCategoryEvent-content-color-select {
        height: 54px;
        width: 88%;
    }
    .selectCategoryEvent-container 
    .selectCategoryEvent-content-color .github-picker {
        width: 87% !important;
    }
}
@media (max-width: 694px) { 
    .selectCategoryEvent-container 
    .selectCategoryEvent-content-color .github-picker {
        width: 88% !important;
    }
}
@media (max-width: 687px) { 
    .selectCategoryEvent-container 
    .selectCategoryEvent-content-color .github-picker {
        width: 89% !important;
    }
}
@media (max-width: 671px) { 
    .selectCategoryEvent-content-color-select {
        width: 91%;
    }
    .selectCategoryEvent-container 
    .selectCategoryEvent-content-color .github-picker {
        width: 91% !important;
    }
}
@media (max-width: 668px) { 
    .selectCategoryEvent-container 
    .selectCategoryEvent-content-color .github-picker {
        width: 32rem !important;
    }
}
@media (max-width: 600px) { 
    .selectCategoryEvent-content .textfield{
        max-width: 100% !important;
    }
    .selectCategoryEvent-container 
    .selectCategoryEvent-content-color {
        display: none !important;
    }
    .selectCategoryEvent-content
    .selectCategoryEvent-content-color-responsive {
        display: inline-flex;
        flex-direction: column-reverse;
        gap: 20px;
    }
    .selectCategoryEvent-content
    .selectCategoryEvent-content-color-responsive 
    .selectCategoryEvent-content-color-select {
        width: 100%;
        height: 50px;
    }
    .selectCategoryEvent-content
    .selectCategoryEvent-content-color-responsive 
    .circle-picker  {
        width: 101% !important;
        background: rgb(255, 255, 255);
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px;
        padding: 5px;
        border-radius: 8px;
    }
    .selectCategoryEvent-content
    .selectCategoryEvent-content-color-responsive 
    .circle-picker span div span div{
        border-radius: 0% !important;
    }
}
@media (max-width: 375px) { 
    .selectCategoryEvent-button {
        display: flex;
        gap: 10px;
        flex-direction: column;
        
    }
    .selectCategoryEvent-button .btn-large{
        min-width: 100%;
    }
}