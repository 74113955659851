.tableComparePlan__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}

.tableComparePlan_header {
    display: flex;
    flex-direction: column;
}

.tableComparePlan__content_header {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.tableComparePlan {
    font-family: 'Faktum';
    font-weight: 500;
    font-size: 1rem;
    font-style: normal;
}

.tableComparePlan-important {
    color: #000;
    font-family: Faktum;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    justify-content: center;
}

.tableComparePlan___row {
    display: flex;
    flex-direction: row;
    gap: 45px;
    justify-content: space-between;
    width: 100%;
}

.tableComparePlan___row_min {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    width: 35%;
    align-items: center;
}

.tableComparePlan___row__right {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    width: 65%;
    align-items: center;
}

.tableComparePlan__index {
    width: 100%;
    max-width: 140px;
    min-width: 30px;
    text-align: center;
    display: flex;
    gap: 1px;
    justify-content: center;
}

.tableComparePlan-important-simbol {
    display: flex;
    font-size: 0.85rem;
    font-weight: 500;
    color: #000;
    align-items: center;
    padding-top: 0.4rem;
    width: auto;
    justify-content: center;
}

.tableComparePlan-simbol-small {
    display: flex;
    color: #000;
    font-size: 0.75rem;
    align-items: center;
    padding-top: 0.4rem;
    width: auto;
    justify-content: center;
}

.tableComparePlan__bottom {
    border-bottom: 1px solid var(--black-15);
    max-height: fit-content;
    min-height: fit-content;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    align-items: center;
}

.tableComparePlan__column {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.tableComparePlan__column__min {
    display: flex;
    flex-direction: column;
}

.tableComparePlan__index .btn-normal {
    padding: 10px 24px;
}

.tableComparePlan-important-paragraph {
    color: #000;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Faktum;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.tableComparePlan-paragraph {
    color: #333;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Faktum;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-content: center;
}

.tableComparePlan-small {
    color: #333;
    text-align: center;
    font-family: Faktum;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-content: center;
}

.tableComparePlan___row__right .btn-normal {
    padding: 10px 24px;
    min-width: fit-content;
}

.tableComparePlan___row__right .tagButton-main {
    max-width: fit-content;
    padding: 4px 15px;
}

@media (max-width: 1196px) { 
    .tableComparePlan___row {
        gap: 20px
    }
}

@media (max-width: 1188px) { 
    .tableComparePlan___row__right .btn-normal{
        padding: 10px 15px;
    }
}

@media (max-width: 1062px) { 
    .tableComparePlan___row__right .btn-normal{
        padding: 10px;
        font-size: 0.875rem;

    }
}

@media (max-width: 981px) { 
    .tableComparePlan__content {
        padding: 0px;
    }
    /* .tableComparePlan__content {
        gap: 35px
    } */
    .tableComparePlan-important {
        font-size: 1.1rem;
    }
    .tableComparePlan__index {
        gap: 0px;
    }
    .tableComparePlan-small {
        font-size: 0.875rem;
    }
    .tableComparePlan-simbol-small {
        font-size: 0.6rem;
        padding-top: 0.2rem;
    }
    .tableComparePlan-important-simbol {
        font-size: 0.7rem;
    }
    .tableComparePlan-paragraph,
    .tableComparePlan,
    .tableComparePlan___row_min,
    .tableComparePlan-important-paragraph {
        font-size: 0.875rem;
    }
    .tableComparePlan-important_title {
        font-size: 1.1rem !important;
    }
    .tableComparePlan___row__right .tagButton-main .smalltext-header {
        font-size: 10px;
    }
    .tableComparePlan___row__right .btn-normal {
        height: 35px;
        padding: 0px 10px;
        font-size: 0.75rem;
    }
}

@media (max-width: 796px) { 
    .tableComparePlan___row__right .btn-normal {
        height: 29px;
        padding: 0px 7px;
        font-size: 0.7rem;
    }
    .tableComparePlan___row__right {
        gap: 10px
    }
    .tableComparePlan-paragraph, .tableComparePlan, .tableComparePlan___row_min, .tableComparePlan-important-paragraph {
        font-size: 0.85rem;
    }
    /* .tableComparePlan__content {
        gap: 30px
    } */
    .tableComparePlan__index {
        font-size: 0.85rem;
    }
    .tableComparePlan-simbol-small {
        font-size: 0.6rem!important;
    }
    .tableComparePlan-important-simbol {
        font-size: 0.7rem !important;
    }
}

@media (max-width: 768px) {  
    /* .tableComparePlan__content {
        gap: 45px
    } */
    /* .tableComparePlan__content_header {
        gap: 30px
    } */
    .tableComparePlan___row_min {
        width: 35%;
    }
    .tableComparePlan_contain {
        max-width: 840px;
        width: 100%;
    }
    .tableComparePlan___row__right .btn-normal {
        padding: 10px 24px;
        min-width: -moz-fit-content;
        min-width: fit-content;
        height: 40px;
        font-size: 1rem;
    }
    .tableComparePlan-paragraph, .tableComparePlan, 
    .tableComparePlan___row_min, .tableComparePlan-important-paragraph {
        font-size: 1rem;
    }
    .tableComparePlan-important, 
    .tableComparePlan-small {
        font-size: 18px;
    }
    .tableComparePlan___row__right .tagButton-main {
        padding: 5.671px 10px;
    }
    .tableComparePlan___row__right .tagButton-main .smalltext-header {
        font-size: 12px;
    }
}

@media (max-width: 652px) {  
    .tableComparePlan___row__right .btn-normal {
        padding: 0px 15px;
        font-size: 0.875rem;
        height: 35px;
    }
}

@media (max-width: 530px) {  
    .tableComparePlan-paragraph, .tableComparePlan, .tableComparePlan___row_min, .tableComparePlan-important-paragraph {
        font-size: 0.875rem;
    }
    .tableComparePlan-important,
    .tableComparePlan-small {
        font-size: 0.875rem;
    }
    .tableComparePlan___row__right .btn-normal {
        padding: 0px 10px;
        font-size: 0.8rem;
        height: 29px;
        min-width: fit-content;
    }
    .tableComparePlan___row__right .tagButton-main .smalltext-header {
        font-size: 10px;
    }
    /* .tableComparePlan__content {
        gap: 30px
    } */
    .tableComparePlan__content_header {
        gap: 15px
    }
    .tableComparePlan___row__right .btn-normal {
        font-size: 10px;
    }
    .tableComparePlan_contain {
        padding: 15px;
    }
    .tableComparePlan-important-simbol {
        padding-top: 0.1rem;
    }
}

@media (max-width: 420px) {   
    .tableComparePlan-important_title {
        font-size: 1rem!important;
    }
}

@media (max-width: 405px) {  
    .tableComparePlan___row {
        gap: 5px
    }
    .tableComparePlan___row_min {
        width: 30%;
    }
    .tableComparePlan___row__right {
        gap: 5px;
        width: 70%;
    }
}