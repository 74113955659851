.orders-container {
    max-width: 440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.orders-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.orders-container .importPhasesCard-main {
    max-width: 100%;
    width: 100%;
    padding-bottom: 10px;
    background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.09));
    backdrop-filter: blur(20px);
    border-radius: 24px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
}

/* tabler */
/* @media (max-width: 850px) {
    .orders-container .importPhasesCard-main {
        padding-bottom: 0px;
    }
} */

/* mobile */
@media (max-width: 340px) {
    .orders-header .paragraph-header{
        font-size: 14px;
    }

    .orders-header .smalltext-header {
        font-size: 12px;
    }
}