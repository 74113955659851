.mainLogin-container {
   /* min-height: 400px; */
   min-height: fit-content;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   gap: 30px;
}

.mainLogin-text {
   color: var(--gray-1); 
}

.mainLogin-items {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 15px;
}

.mainLogin-items .buttonSignEmail-container, .mainLogin-items .textfieldPassword-container  {
   max-width: 100%;
   width: 100%;
}

/* .mainLogin-items .textfield .textfield-title,
.mainLogin-items .textfieldPassword-container .textfieldPassword .textfieldPassword-title {
   color: var(--gray-1)
} */

.mainLogin-confirm {
   width: 100%;
   padding: 10px 50px;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   color: var(--white);

   background: rgba(0, 0, 0, 0.75);
   box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
   backdrop-filter: blur(20px);
   /* Note: backdrop-filter has minimal browser support */

   border-radius: 24px;
   cursor: pointer;
}

.mainLogin-link {
   color: #2D9CBD;
   cursor: pointer;
}

.mainLogin-prevButton {
   cursor: pointer;
   position: absolute;
   z-index: 11;
   top: 2.1rem;
   left: 1.5rem;
}

/* .mainLogin-items .textfield input, .mainLogin-items .textfieldPassword-container .textfieldPassword  input {
   background: rgba(255, 255, 255, 0.9);
   border: 1px solid #333333;
   border-radius: 24px;
} */


.mainLogin-container .mainLogin-confirm {
   height: 45px;
   width: 300px;
}