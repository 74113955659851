 .buttonTypeTransport-container {
    max-width: 260px;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    border-radius: 24px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    cursor: pointer;
}

.buttonTypeTransport-container:hover {
    background: var(--gray-6);
}

.buttonTypeTransport-icon img {
    width: 30px;
    height: 15.7px;
}

.buttonTypeTransport-text {
    color: var(--gray-1);
    text-align: center;
}

.buttonTypeTransport-active {
    background: var(--yellow);
}

.buttonTypeTransport-gray {
    background: #F2F2F2;
    max-width: 260px;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    border-radius: 24px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    cursor: pointer;
}