.import-timeline-supplier {
	display: flex;
	align-items: center;
	justify-content: center;
	filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.15));
	flex-direction: row;
}

.import-timeline-supplier-stage {
	background-color: var(--white-50);
	padding: 5px 24px;
	border-radius: 24px;
	color: var(--gray-1);
	border: 1px solid var(--white);
	box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.import-timeline-supplier-stage-yellow {
	background-color: #FFE630;;
	padding: 5px 24px;
	border-radius: 24px;
	color: var(--gray-1);
	box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.import-timeline-supplier-stage h2,
.import-timeline-supplier-stage-yellow h2 {
	margin: 0;
}

.import-timeline-supplier-last-stage {
	background-color: var(--white-50);
	padding: 10px;
	border-radius: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.import-timeline-supplier-last-stage-yellow {
	background-color: #FFE630;
	padding: 10px;
	border-radius: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.import-timeline-supplier-connection {
	background: var(--white-50);
	transform: matrix(1, 0, 0, -1, 0, 0);
	width: 24px;
	height: 8px;
	border-top: 1px solid var(--white);
	border-bottom: 1px solid var(--white);
	box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.import-timeline-supplier-connection-yellow {
	background: #FFE630;;
	transform: matrix(1, 0, 0, -1, 0, 0);
	box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
	width: 24px;
	height: 8px;
}