.tableHeader {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0px 10px;
    justify-content: space-between;
    width: 100%;
}

.tableHeader-text {
    /* color: #FFF; */
    width: 102.86px;
}

@media (min-width: 600px) { 
    .tableHeader-text {
        /* color: #FFF; */
        width: 54.86px;
        /* font-size: 0.7rem; */
    }
}

@media (min-width: 934px) { 
    .tableHeader-text {
        /* color: #FFF; */
        width: 102.86px;
    }
}