.service-minuature-main {
    position: relative;
    max-width: 264px;
    min-width: 264px;
    /* max-height: 340px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid;
    border-image-source: var(--border-image-source);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    /* max-height: 380px; */
    max-height: 100%;
    background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
    filter: drop-shadow(0px 3px 21px rgba(0, 0, 0, 0.06));
    backdrop-filter: blur(20px);
}

.service-miniature-none-filter {
    filter: none;
    backdrop-filter: none;  
}

.service-minuature-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.service-minuature-img {
    /* min-height: 150px; */
    object-fit: cover;
    /* max-height: 150px; */
    max-width: 266px;
    width: 100%;
    border-radius: 24px 24px 0px 0px;
    aspect-ratio: 16 / 9;
}

.service-minuature-text{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.service-minuature-title {
    color: var(--gray-1);
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}

.service-minuature-description{
    color: var(--gray-1);
    /* height: 55px; */
    /* display: flex; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 43px;
    max-height: 43px;
}

.online-company{
    min-width: 10px;
    max-width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: var(--green-2);
}

.service-minuature-rating {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    /* padding-bottom: 15px; */
    padding-left: 10px;
    padding-right: 10px;
}

.service-minuature-rating .rating {
    min-width: 56px;
    justify-content: center;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}
.service-minuature-rating .commentCount {
    min-width: 52px;
    justify-content: center;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}
.service-minuature-rating .city-country-display {
    /* min-width: 109px; */
    min-width: 118px;
    justify-content: center;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

.service-miniature-line{
    border-bottom: var(--black15);
    /* width: 250px; */
   margin: 0px 9px;
}

.service-miniature-share {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1px 0px 6px 0px;
}

.service-miniature-social{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.service-miniature-price {
    color: var(--gray-1);
}

.service-miniature-padding-text {
    padding: 0px 10px;
    min-width: 0;
    /* justify-content: space-between; */
}

.service-miniature-buttons{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.service-miniature-buttons--bottom {
    padding-bottom: .6rem;
}

.service-minuature-button-single {
    width: 100%;
    padding: 10px;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(40px);
    border-radius: 0px 0px 24px 24px;
    color: white;
    text-align: center;
}

.service-minuature-button-single-green {
    background-color: var(--green-2);
}

.service-minuature-button-single-blue {
    background-color: var(--blue-2);
}

.service-minuature-button {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
}

.service-minuature-button .btnIcon-extra-small {
    max-width: 100%;
    width: 100%;
    height: 41px;
    border-radius: 12px;
    /* font-size: 1.125rem; */
    font-size: 0.875rem;
    background-color: var(--green-2);
}

.service-minuature-button-left {
    width: 50%;
    padding: 10px 30px;
    color: white;
    text-align: center;

    background: rgba(0, 0, 0, 0.25);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(40px);
    border-radius: 0px 0px 0px 12px;
}

.service-minuature-button-rigth {
    width: 50%;
    padding: 10px 30px;
    color: white;
    text-align: center;

    background: rgba(0, 0, 0, 0.75);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(40px);

    border-radius: 0px 0px 12px 0px;    
}

.service-minuature-main-iframe {
    border-radius: 12px 12px 0px 0px;
    overflow: hidden;
}

.service-miniature-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    min-width: 0;
}

.service-miniature-header .service-miniature-header-check div {
    display: flex;
}

.service-miniature-header div img {
    width: 1.3rem;
    min-width: 1.3rem;
}

@media (max-width: 600px) {
    .service-minuature-main {
        min-width: 175px;
    }
    .service-minuature-body {
        gap: 10px
    }
    .service-minuature-description {
        display: none;
    }
    .service-miniature-buttons--bottom {
        padding-bottom: 15px;
        gap: 5px;
    }
    .service-miniature-buttons--bottom .city-country-display {
        min-width: fit-content;
    }
    .service-miniature-buttons--bottom .city-country-display .city-country--image img {
        width: 22.01px;
        height: 11px;
    }
    .service-miniature-buttons--bottom .city-country-display span {
        display: none;
    }
    .service-minuature-title {
        font-family: 'Faktum-SemiBold';
    }
    .service-minuature-title,
    .service-miniature-buttons--bottom .rating span,
    .service-miniature-buttons--bottom .commentCount span {
        font-size: 0.75rem;
    }
    .service-miniature-header div img {
        width: 1.125rem;
        min-width: 1.125rem;
    }
    .service-miniature-buttons--bottom .rating,
    .service-miniature-buttons--bottom .commentCount {
        padding: 3px 9px;
        height: fit-content;
        min-width: fit-content;
    }
    .service-miniature-buttons--bottom .rating div img {
        width: 9.17px;
        height: 13.1px;
    }
}

@media (max-width: 400px) {
    .service-minuature-main {
        min-width: 150px !important;
    }
    .service-miniature-buttons--bottom .rating, 
    .service-miniature-buttons--bottom .commentCount {
        padding: 3px 6px;
    }
    .service-miniature-buttons--bottom .city-country-display {
        padding: 3px 6px;
        height: 24px;
    }
}