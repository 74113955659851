.rowGroup-taxes-supplier-container {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.rowGroup-taxes-header--title {
    color: var(--gray-1);
}

.rowGroup-taxes-color-white {
    color: var(--white);
}

.rowGroup-taxes-supplier-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rowGroupTaxesSupplier__ {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    width: 100%;
}

.rowGroupTaxesSupplier_text {
    /* width: 30%; */
    width: 50%;
}

.rowGroupTaxesSupplier_transparent {
    background-color: transparent;
}

.rowGroupTaxes_white {
    background-color: var(--white-15);
}

.rowGroupTaxes_black {
    background-color: var(--black-50);
}

.rowGroupTaxesSupplier__b {
    max-width: 448px;
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
}

/* .rowGroupTaxesSupplier__b .inputElement-container {
    width: 100%;
} */
.rowGroupTaxesSupplier__b .inputElement-container .inputElement .inputElement-input,
.rowGroupTaxesSupplier__b .inputElementMin-container .inputElementMin .inputElementMin-input {
    padding: 7.5px 20px 7.5px 20px;
    text-align: end;
}

.rowGroupTaxesFooter__ {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 39px 20px 20px;
    background: var(--white-90);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}

/* Responive*/
@media (max-width: 1350px) { 
    .rowGroupTaxesSupplier__b {
        width: 370px;
    }
    .rowGroupTaxesSupplier__b .sliderContent_ {
        max-width: 190px;
    }
    .rowGroupTaxesSupplier-body .textfield {
        max-width: 160px;
    }
}

@media (max-width: 1284px) { 
    .rowGroupTaxesSuppliers__b {
        width: 280px;
        gap: 10px;
    }
    .rowGroupTaxesSupplier__b .sliderContent_ {
        max-width: 170px;
        gap: 10px;
    }
    .rowGroupTaxesSupplier-body .textfield {
        max-width: 140px;
    }
}

@media (max-width: 1045px) { 
    .rowGroupTaxesSupplier__b {
        width: 260px;
        gap: 10px;
    }
    .rowGroupTaxesSupplier__b .sliderContent_ {
        max-width: 150px;
    }
    .rowGroupTaxesSupplier-body .textfield {
        max-width: 100px;
    }
    .rowGroupTaxesSupplier_text,
    .taxSharedContainer_white {
        font-size: 0.9rem;
    }
}

@media (max-width: 990px) { 
    .rowGroupTaxesSupplier__b {
        width: 230px;
        gap: 10px;
    }
    .rowGroupTaxesSupplier__b .sliderContent_ {
        max-width: 120px;
        gap: 5px;
    }
    .rowGroupTaxesSupplier__b .sliderContent_ .sliderContent-number .sliderContent__text{
        font-size: 0.9rem;
    }
    .rowGroup-taxes-body .textfield {
        max-width: 90px;
    }
}
@media (max-width: 880px) { 
    .rowGroupTaxesFooter__text {
        font-size: 0.9rem;
    }
}

@media (max-width: 868px) { 
    .rowGroupTaxesSupplier__b {
        width: 210px;
        gap: 15px;
    }
    .rowGroup-taxes-body .textfield {
        max-width: 80px;
    }
}

@media (max-width: 850px) { 
    .rowGroupTaxesSupplier__b {
        width: 100%;
    }
    .rowGroupTaxesSupplier__b .sliderContent_ {
        width: 100%;
        max-width: 218px;
    }
    .rowGroup-taxes-body .textfield {
        max-width: 200px;
    }
}

@media (max-width: 740px) { 
    .rowGroupTaxesSupplier__b {
        max-width: 365px;
        width: 100%;
    }
    .rowGroupTaxesSupplier__b .sliderContent_ {
        max-width: 200px;
    }
    .rowGroup-taxes-body .textfield {
        max-width: 150px;
    }
}

@media (max-width: 660px) { 
    .rowGroupTaxesSupplier__b {
        max-width: 265px;
    }
    .rowGroupTaxesSupplier__b .sliderContent_ {
        max-width: 150px;
    }
    .rowGroup-taxes-body .textfield {
        max-width: 100px;
    }
}

@media (max-width: 630px) {
    .rowGroup-taxes-header-container {
       padding:10px;
    }

    .rowGroup-taxes-header--title {
        font-size: 14px;    
    }
}

@media (max-width: 500px) { 
    .rowGroup-taxes-supplier-body {
        height: auto;
    }
    .rowGroupTaxesSupplier__ {
        flex-direction: column;
        display: flex;
        height: 120px;
        gap: 10px
    }
    .rowGroupTaxesSupplier_transparent, .rowGroupTaxes_white {
        /* height: 120px; */
    }
    .rowGroupTaxesSupplier_text {
        width: 100%;
    }
    .rowGroupTaxesSupplier__b {
        width: 100%;
    }
}

@media (max-width: 483px) {
    .rowGroupTaxesSupplier__b {
        max-width: 235px;
        gap: 5px;
    }
    .rowGroup-taxes-body .textfield {
        max-width: 80px;
    }
}

@media (max-width: 450px) {
    .rowGroupTaxesSupplier__b {
        max-width: 215px;
    }
    .rowGroupTaxesSupplier__b .sliderContent_ {
        max-width: 130px;
    }
    .rowGroupTaxesSupplier__b .sliderContent_ .sliderContent-number .sliderContent__text {
        font-size: 0.8rem;
    }
}

@media (max-width: 425px) {
    .rowGroupTaxes_text {
        font-size: 0.85rem;
    }
    .rowGroupTaxesSupplier__b {
        /* max-width: 195px; */
        max-width: 150px;
    }
    .rowGroup-taxes-body .textfield {
        max-width: 60px;
    }
    .rowGroup-taxes-body .textfield input:not(:placeholder-shown).textfield-input:not(:focus) {
        padding: 7.5px 15px 7.5px 15px;
    }
    .rowPhaseSeven-main .rowPhaseSeven-tableQuotation-container .rowGroup-taxes-supplier-container .rowGroupTaxesSupplier__ {
        padding: 0px 10px;
    }

}

@media (max-width: 365px) { 
    .rowGroupTaxesSupplier__ {
        flex-direction: column;
        display: flex;
        height: 120px;
        gap: 10px
    }
    .rowGroupTaxesSupplier_transparent, .rowGroupTaxesSupplie_white {
        /* height: 170px; */
    }
    .rowGroupTaxesSupplier_text {
        width: 100%;
    }
    .rowGroupTaxesSupplier__b {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px
    }
    .rowGroupTaxesSupplier__b .sliderContent_, 
    .rowGroupTaxesSupplier__b .textfield {
        width: 100%;
        max-width: 100%;
    }
    .rowGroupTaxesSupplie__ {
        flex-direction: column;
        align-items: start;
        height: auto;
        padding: 5px 10px;
        gap: 10px;
    }
    .rowGroupTaxesSupplier__b {
        max-width: 100%;
        gap: 10px;
    }
    .rowGroupTaxesSupplie_text {
        width: 100%;
    }
    .rowGroup-taxes-body .textfield {
        max-width: 100px;
    }
    .rowGroupTaxesSupplier__b .sliderContent_ {
        max-width: 100%;
    }
}