#modalDecline-body-textarea {
    border: 0px solid var(--black-15);
    font-family: inherit;
    font-size: inherit;
    padding: 15px 11px;
    display: block;
    width: 100%;
    overflow: hidden;
    /* resize: both; */
    max-width: 100%;
    min-width: 30px;
    min-height: 60px;
    line-height: 20px;
    border-radius: 24px;
    color: var(--black);
    font-family: 'Faktum';
    white-space: pre-line;
    background: var(--white);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}
.modalOperationHeader .modal-container {
    min-width: 50rem;
}
@media (max-width: 900px) {
    .modalOperationHeader .modal-container {
        min-width: 40rem;
    }
}
@media (max-width: 650px) {
    .modalOperationHeader .modal-container {
        min-width: 35rem;
    }
}

@media (max-width: 600px) {
    .modalOperationHeader .modal-container {
        min-width: 100%;
    }
}