.modal-modalAnimation {
    background: var(--black-15);
    backdrop-filter: blur(2.89794921875px);
}

.modal-modalAnimation .modal-container {
    background: var(--white);
    max-width: 60rem;
    padding: 30px;
    /* max-width: 70rem; */
    border: 0px;
}

.modal-modalAnimation .modal-container .modal__header {
    display: none;
}

.modalAnimation-content {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.modalAnimation {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

.modalAnimationNone {
    display: none;
}

.modalAnimationNothing {
    flex-direction: row;
}

.modalAnimation-video {
    border-radius: 12px;
    overflow: hidden;
    /* height: 78%; */
    max-width: 100%;
    padding-top: 0rem;
    display: flex;
    width: 100%;
}

.modalAnimation-body {
    display: flex;
}

.modalAnimation-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.modalAnimation-text {
    font-size: 1.625rem;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.modalAnimation-img {
    height: 2rem;
}

.modalAnimation-circleTabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 10px
}

.modalAnimation-buttons {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: end;
    width: 100%;
    padding-top: 10px;
}

.modalAnimation-buttons .custom-button {
    min-width: auto;
    height: 40px;
    padding: 9px 21px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.modalAnimation-button-one {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.modalAnimation-btn-outline {
    color: var(--white);
    border: 0px solid var(--black-25);
    border-radius: 24px;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    background-color: var(--black-25) !important;
    min-width: auto;
    height: 40px;
    padding: 9px 21px;

    font-family: 'Faktum-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
}

.modalAnimation-circleTabs .circleTab-container {
    background: rgba(0, 0, 0, 0.15);
}

.modalAnimation-circleTabs .circleTab__active {
    background-color: rgba(0, 0, 0, 0.75);
    border: 2px solid rgba(0, 0, 0, 0.75);
}

.modalAnimation-description__ {
    display: flex;
    flex-direction: column;
    gap: 3px
}

@media (max-width: 3500px) and (max-height: 910px) {
    .modal-modalAnimation .modal-container {
        max-width: 60rem;
    }
}

@media (max-width: 3500px) and (max-height: 815px) {
    .modal-modalAnimation .modal-container {
        max-width: 50rem;
    }
}

/* @media (max-width: 3500px) and (max-height: 715px) {
    .modal-modalAnimation .modal-container {
        max-width: 40rem;
        padding: 20px;
    }
} */

@media (max-width: 3500px) and (max-height: 616px) {
    .modal-modalAnimation .modal-container {
        max-width: 35rem;
    }
}

@media (max-width: 3500px) and (max-height: 583px) {
    .modal-modalAnimation .modal-container {
        max-width: 30rem;
    }
}

@media (max-width: 3500px) and (max-height: 532px) {
    .modal-modalAnimation .modal-container {
        max-width: 25rem;
    }
}

@media (max-width: 3500px) and (max-height: 483px) {
    .modal-modalAnimation .modal-container {
        max-width: 22rem;
    }
}

@media (max-width: 578px) and (max-height: 615px) {
    .modal-modalAnimation .modal-container {
        max-width: 100%;
    }
}

/* @media (max-width: 1118px) and (max-height: 3500px) { 
    .modal-modalAnimation .modal-container {
        border-radius: 0px;
    }
} */

@media (max-width: 779px) { 
    .modal-modalAnimation .modal-container {
        border-radius: 0px;
    }
}

@media (max-width: 779px) { 
    .modalAnimation-video {
        height: 77%;
    }
}

@media (max-width: 734px) { 
    .modalAnimation-video {
        height: 75%;
    }
}

@media (max-width: 661px) { 
    .modalAnimation-video {
        height: 73%;
    }
}

@media (max-width: 622px) { 
    .modalAnimation-video {
        height: 72%;
    }
}

@media (max-width: 600px) { 
    .modal-modalAnimation  {
        display: none;
    }
    .modal-modalAnimation .modal-container {
        height: 100%;
        justify-content: center;
    }
    .modalAnimation {
        align-self: center;
        /* height: 116%; */
        justify-content: center;
        gap: 15px;
    }
}

@media (max-width: 375px) { 
    .modalAnimation-text {
        font-size: 1.2rem;
    }
    .modalAnimation-description {
        font-size: 0.8rem;
    }
    .modal-container {
        gap: 10px
    }
    /* .modalAnimation-video {
        height: 370px;
    } */
    .modalAnimation-buttons .custom-button,
    .modalAnimation-buttons .modalAnimation-btn-outline {
        font-size: 0.9rem;
    }
}

@media (max-width: 375px) and (max-height: 667px) { 
    .modal-container {
        gap: 5px
    }
    .modalAnimation {
        height: calc(100vh - 150px);
    }
}

/* @media (max-width: 587px) { 
    .modalAnimation-video {
        height: 71%;
    }
}

@media (max-width: 550px) { 
    .modalAnimation-video {
        height: 69%;
    }
}

@media (max-width: 510px) { 
    .modalAnimation-video {
        height: 67%;
    }
}

@media (max-width: 475px) { 
    .modalAnimation-video {
        height: 65%;
    }
} */
/* 
@media (max-width: 430px) { 
    .modalAnimation-video {
        height: 61%;
    }
}

@media (max-width: 386px) { 
    .modalAnimation-video {
        height: 59%;
    }
}

@media (max-width: 367px) { 
    .modalAnimation-video {
        height: 57%;
    }
} */