.reviewUser {
	backdrop-filter: blur(40px);
	border-radius: 12px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	background: var(--background);
    border-image-source: var(--border-image-source);
}

.reviewUser-top {
	display: flex;
	align-items: center;
	gap: 10px;
}

.reviewUser-user-img {
	width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: contain;
    aspect-ratio: 16 / 9;
}

.reviewUser-top h1 {
	margin: 0;
	flex: 1;
	color: var(--gray-1);
}

.reviewUser-mid p {
	margin: 0;
	color: var(--gray-1);
}

.reviewUser-color-Date {
	color: var(--black-50);
}

.reviewUser-content-user {
	display: flex;
	flex-direction: column;
	gap: 2px;
	width: 100%;
}

@media (max-width: 450px) {
	.reviewUser-content-user .reviewUser-name-text {
		font-size: 1rem;
	}
}

@media (max-width: 390px) {
	.reviewUser-content-user .reviewUser-name-text {
		font-size: 0.95rem;
		line-height: 20px;
	}
}