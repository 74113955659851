.scrollHighlightNabbar-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 20px;
	justify-content: flex-start;
}

/* contenedor principal */
.scrollHighlightNabbar {
	color: var(--black-50);
	cursor: pointer;
	position: relative;
}

.scrollHighlightNabbar:hover {
	color: var(--black);
}

.scrollHighlightNabbar_white {
	color: var(--white);
	cursor: pointer;
	position: relative;
}

.scrollHighlightNabbar_white:hover {
	color: var(--white-75);
}

.scrollHighlightNabbar::before {
	content: "";
	width: 20px;
	height: 4px;
	border-radius: 2px;
	background-color: var(--black);
	position: absolute;
	left: calc(50% - 20px/2);
	bottom: 0%;
	margin-bottom: -10px;

	transform: scaleX(0);
	transform-origin: left;
	transition: transform 250ms ease-in;
}

.scrollHighlightNabbar:hover::before {
	transform: scaleX(1);
}

.scrollHighlightNabbar-clicked {
	color: var(--black);
	position: relative;
}

.scrollHighlightNabbar-clicked::before {
	content: "";
	width: 20px;
	height: 4px;
	border-radius: 2px;
	background-color: var(--black);
	position: absolute;
	left: calc(50% - 20px/2);
	bottom: 0%;
	margin-bottom: -10px;
	transition: none;
	transform: none;
}
