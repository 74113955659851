.textarea-content {
position: absolute;
}
.textarea {
	border: 0px solid var(--black-15);
	font-family: inherit;
	font-size: inherit;
	padding: 15px 11px;
	display: block;
	width: 100%;
	overflow: hidden;
	/*resize: both;*/ 
	min-height: 60px;
	line-height: 20px;
	border-radius: 24px;
	color: var(--black);
    font-family: 'Faktum';
	white-space: pre-line;

	background: var(--white);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    /* backdrop-filter: blur(20px); */
}

.textarea[contenteditable]:empty::before {
	content: "Inserta aquí tus notas";
	color: gray;
	font-family: 'Faktum';
	height: 10px;
    /* align-items: center; */
    display: flex;
	padding: 5px;
}

.textarea-disabled {
	border: 1px solid var(--black-25);
	font-family: inherit;
	font-size: inherit;
	padding: 15px 11px;
	display: block;
	width: 100%;
	overflow: hidden;
	/*resize: both;*/ 
	min-height: 60px;
	line-height: 20px;
	border-radius: 24px;
	color: var(--black);
    font-family: 'Faktum';
	white-space: pre-line;

	background: var(--transparent);
}

.textarea-disabled[contenteditable]:empty::before {
	content: "Inserta aquí tus notas";
	color: gray;
	font-family: 'Faktum';
	height: 10px;
    /* align-items: center; */
    display: flex;
	padding: 5px;
}

.textarea-title-content {
	background: red;
}

.textarea-title {
	top: 15px;
    font-family: "Faktum";
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: var(--gray-1);
	transform: translateY(-50%);
    transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
	position: absolute;
}

@media (max-width: 600px) { 
	.textarea {
		font-size: 0.875rem;
	}
	.textarea-disabled {
		font-size: 0.875rem;
	}
	.textarea[contenteditable]:empty::before {
		font-size: 0.875rem;
	}
}