.toggle-switch {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #ffffff;
  }
  
  .toggle-switch span {
    margin: 0 10px;
    cursor: pointer;
  }
  
  .toggle-switch span.active {
    font-weight: bold;
  }
  
  .toggle-slider {
    width: 60px;
    height: 30px;
    padding: 4px;
    background: linear-gradient(90deg, #FFD018 0%, #FFC525 100%);
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 1.957px 5.87px 0px rgba(0, 0, 0, 0.15);
  }

  .toggle-slider-black {
    width: 60px;
    height: 30px;
    padding: 4px;
    background: linear-gradient(90deg, #1a1a1a 0%, #000000 100%);
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 1.957px 5.87px 0px rgba(0, 0, 0, 0.15);
  }
  
  .toggle-slider .slider,
  .toggle-slider-black .slider {
    width: 23px;
    height: 23px;
    background: var(--bn-white, #FFF);    
    border-radius: 50%;
    position: absolute;
    top: 2.9px;
    transition: all 0.3s ease;
  }

  .toggle-switch-text-white  {
    color: var(--white);
  }

  .toggle-switch-text-black  {
    color: var(--black);
  }
  
  .toggle-slider .slider.monthly,
  .toggle-slider-black .slider.monthly {
    left: 1px;
  }
  
  .toggle-slider .slider.annual,
  .toggle-slider-black .slider.annual {
    right: 1px;
  }
  