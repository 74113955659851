.reviewCard-contrainer {
    max-width: 52.5rem;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    background: var(--background);
    border-image-source: var(--border-image-source);
    
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 24px;
}

.reviewCard-column {
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.reviewCard-right__ {
    display: flex;
    justify-content: flex-end;
}

.reviewCard-display {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.reviewCard-img img {
    background: var(--yellow);
    padding: 2px;
    border-radius: 50px;
}

.reviewCard-column .rating-display {
    backdrop-filter: none;
    padding: 0px;
}

.reviewCard-blockText {
    /* width: 16.875rem; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.reviewCard-colorTitle {
    color: var(--gray-1);
    font-size: 1rem;
}

.reviewCard-colorDetail {
    color: var(--gray-2);
    /* flex-direction: row;
    display: flex;
    gap: 5px */
}
.reviewCard-score-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    width: 100%;
}

.reviewCard-colorAmount {
    color: var(--gray-3);
}

.reviewCard-score {
    /* width: 20.625rem; */
    width: 100%;
    display: flex;  
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.reviewCard-score .textfield input {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
}

.reviewCard-score .reviewCard-score-content .ratingMarker-container {
    display: flex;
    gap: 10px
}

.reviewCard-score-content .reviewCard-img {
    display: flex;
}

.reviewCard-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.reviewCard-add {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-text {
    width: 150%;
}

.textfield {
    width: 100%;
}

.reviewCard-score .textfield  .textfield-input  {
    background: var(--white);
}

@media (max-width: 880px) {
    .reviewCard-colorTitle {
        font-size: 0.9rem !important;
    }
    .reviewCard-contrainer,
    .reviewCard-blockText,
    .reviewCard-score-content {
        gap: 10px
    }
    .reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-text {
        font-size: 0.9rem !important;
    }
    .reviewCard-button .custom-button {
        font-size: 0.9rem;
    }
    
}
@media (max-width: 750px) {
    .reviewCard-blockText {
        width: auto;
    }  

    .reviewCard-score {
        width: auto;
    }
 }

@media (max-width: 700px) {
    .reviewCard-contrainer {
        border-radius: 12px;
    }
}

 @media (max-width: 526px) { 
    .reviewCard-colorDetail  {
        /* flex-direction: column; */
    }
 }

 @media (max-width: 520px) {
    .reviewCard-img {
        /* align-self: flex-start; */
    }
    .reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-rating {
        width: 100%;
    }
    .reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-incial, 
    .reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-middle, 
    .reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-final {
        min-width: 2px;
    }
    /* .reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-text {
        width: 100%;
    } */
 }

 @media (max-width: 425px) {
    .reviewCard-contrainer {
        padding: 10px;
    }
}

 @media (max-width: 403px) {
    .reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-text {
        font-size: 0.87rem;
    }
 }

 @media (max-width: 367px) {
    .reviewCard-score-content,
    .reviewCard-score .reviewCard-score-content .ratingMarker-container {
        gap: 5px
    }
    .reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-rating {
        width: 70%;
    }
    .reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-incial, 
    .reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-middle, 
    .reviewCard-score .reviewCard-score-content .ratingMarker-container .ratingMarker-rating .ratingMarker-item-final {
        max-width: 20px;
    }
 }