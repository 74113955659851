.progressAnimation {
    width: 100%;
    height: 24px;
    background-color: rgba(51, 51, 51, 0.10);
    border-radius: 24px;
    overflow: hidden;
}

.progressAnimation-bar {
    height: 24px;
    background: linear-gradient(90deg, #000 0%, rgba(51, 51, 51, 0.70) 100%);
    border-radius: 24px 0px 0px 24px;
    align-items: center;
    display: flex;
    padding-left: 0.5rem;
    width: 0%; 
    transition: width 2s ease; 
}

.progressAnimation-text {
    color: var(--white);
}