.card {
    width: 100%;
    max-width: 1280px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    border-radius: 12px;
    backdrop-filter: blur(40px);
    border-image-source: var(--border-image-source);
    /* background: var(--white); */
    /* padding: 20px; */
}
@keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
  
    100% {
      background-position: 468px 0;
    }
}

.shimmer-animation {
    background-color: #f6f7f8;
    background-image: linear-gradient(
      to right,
      var(--white-50) 0%,
      var(--gray-6) 20%,
      var(--white-50) 40%,
      var(--white-50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
}

.loader-shimmer-banner {
    height: 4rem;
    background-size: 800px 18rem;
    border-radius: 5px;
    margin-bottom: 12px;
  }
  
  .loader-shimmer-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    gap: 20px;
  }
  
  .loader-shimmer-title {
    height: 35px;
    flex: 0 0 auto;
    width: 120px;
  }
  
  .loader-shimmer-rating {
    height: 25px;
    flex: 0 0 auto;
    width: 70px;
  }
  
  .loader-shimmer-list {
    height: 20px;
    width: 190px;
    margin-bottom: 5px;
  }
  
  .loader-shimmer-list:last-child {
    margin-bottom: 0;
  }
  
  .loader-shimmer-info {
    height: 35px;
    width: 100%;
    max-width: 300px;
    margin-bottom: 5px;
    border-radius: 3px;
  }
  
  .loader-shimmer-info:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 700px) {
    .loader-shimmer-banner {
        height: 3rem;
    }
    .loader-shimmer-info {
      height: 30px;
    }
  }

  @media (max-width: 500px) {
    .loader-shimmer-banner {
        height: 3rem;
    }
    .loader-shimmer-info {
      height: 25px;
    }
    .loader-shimmer-header {
      gap: 10px
    }
 }