.importGroup-main {
    width: 100%;
    max-width: 270px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 24px;

    background: 
        lightgray -53.575px 0px / 188.037% 100.066% no-repeat, 
        linear-gradient(111deg, rgba(255, 255, 255, 0.50) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);

    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(4.5px);
    overflow: hidden;
}

.importGroup-img {
    width: 100%;
    height: 180px;
    border-radius: 24px 24px 0 0;
}

.importGroup-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.importGroup-body {
    display: flex;
    flex-direction: column;
    padding: 0px 10px 10px 10px;
    gap: 10px
}

.importGroup-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px
}

.importGroup-icon-calendar {
    width: 18px;
    height: 14.212px;
}

.importGroup-icon-box {
    width: 20px;
    height: 20px;
}

.importGroup-bar-container {
    width: 100%;
    height: 24px;
    background-color: rgba(51, 51, 51, 0.10);
    border-radius: 24px;
    overflow: hidden;
}

.importGroup-bar {
    height: 24px;
    background: linear-gradient(90deg, #000 0%, rgba(51, 51, 51, 0.70) 100%);
    border-radius: 24px 0px 0px 24px;
    align-items: center;
    display: flex;
    padding-left: 0.5rem;
    width: 0%; 
    transition: width 2s ease; 
}

.importGroup-bar-text {
    color: var(--white);
}

.importGroup-badget {
    height: 24px;
    width: fit-content;
    background: var(--red);
    display: flex;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    color: var(--white);
    border-radius: 24px;
}

.importGroup-agency {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.importGroup-agency div {
    display: flex;
    align-items: center;
}

.importGroup-agency div img {
    width: 18.201px;
    height: 18px;
}

.importGroup-user {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    object-fit: cover;
}