.ordersPage {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px
}

.loading-screen {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    /* height: 100vh; */
    /* background-color: #fff; Fondo blanco durante la carga */
}

.ordersPage-header {
    display: none;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    padding-top: 20px;
}

.ordersPage-container {
    /* height: calc(100vh - 90px); */
    height: calc(100vh - 50px);
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 20px;
    margin: 0 auto;
    display: flex;
    padding: 20px 60px 0px 60px;
}

/* lado derecho */
.ordersPage-left {
    width: 100%;
    max-width: 830px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 10px;
}
    .ordersPage-left-action {
        height: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        padding: 0rem 0.5rem;
    }

    .ordersPage-left-action-left {
        /* width: 100%; */
        height: 38px;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .ordersPage-left-action-left_one {
        /* width: 100%; */
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding-left: 0.1rem;
    }

    .ordersPage-left-action-left_one .searchTextField-container {
        width: 100%;
        max-width: 350px;
    }

    .ordersPage-left-action-left-icon-back {
        margin-top: 0.5rem;
        cursor: pointer;
    }

    .ordersPage-left-action-left-icon {
        border-radius: 24px;
        border: 1px solid #FFF;
        background: linear-gradient(111deg, rgba(255, 255, 255, 0.50) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);
        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        width: 36px;
        min-width: 36px;
        height: 36px;
        align-items: center;
        align-self: center;
        display: flex;
        justify-content: center;
    }

    .ordersPage-left-action-right .btn-normal {
        max-width: 153px;
        height: 36px;
        padding: 0px 20px;
        font-size: 0.875rem;
    }

    .ordersPage-left-operation {
        display: flex;
        width: 100%;
        height: 100%;
        display: block;
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;
        overflow-y: scroll;
        overflow-x: none;
    }

    .ordersPage-left-operation::-webkit-scrollbar {
        display: none;
        height: 0px;
    }
    .ordersPage-left-operation-scroll {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 10px
    }
    .ordersPage-left-action-second {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .ordersPage-left-action-first {
        display: none;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
    }
    .ordersPage-left-action-first-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 5px;
    }
    .ordersPage-left-action-first-row {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
    }
    .ordersPage-left-action-first-text-blue {
        color: var(--blue-2);
    }

    /* lado izquierdo */
    .ordersPage-right {
        max-width: 312px;
        display: flex;
        flex: 1 1;
        gap: 3px;
    }

    .ordersPage-header .subheader-clicked{
        color: var(--black);
    }

@media (max-width: 1440px){ 
    .ordersPage-container {
        padding: 20px 20px 0px 20px;
    }
}

@media (max-width: 1002px){ 
    .ordersPage-right {
        max-width: 292px;
    }
}

@media (max-width: 960px){ 
    .ordersPage-container {
        padding: 20px 20px 0px 15px;
        gap: 10px
    }
}

@media (max-width: 900px){ 
    .ordersPage-header {
        display: flex;
    }
    .ordersPage-left-operation {
        padding: 0rem !important;
    }
    .ordersPage-left-action-first {
        display: flex !important;
    }
    .ordersPage-left-action {
        padding: 0rem 0.5rem !important;
        height: fit-content !important;
    }
    .ordersPage-left-action-right {
        display: none;
    }
    .ordersPage-container {
        height: 100vh;
        padding: 20px 20px 20px 20px;
    }
    .ordersPage-right {
        max-width: 850px;
        justify-content: center;
    }
    .ordersPage-left-action-first-right .btn-normal {
        height: 36px;
        font-size: 0.875rem;
        width: 145px;
        padding: 4px 5px;
    }
    .ordersPage-left-action-left_one,
    .ordersPage-left-action-left_one .filterService {
        gap: 10px !important
    }
}


@media (max-width: 630px) {
    .ordersPage-left-action-right {
        display: none;
    }
    .ordersPage-left-action-left {
        height: 39px;
        width: 100% !important;
        justify-content: space-between !important;
    }
    .ordersPage-left-action-first-left {
        gap: 2px !important
    }
    .ordersPage-left-action-first-row,
    .ordersPage-left-action-first-row span,
    .ordersPage-left-action-first-text-blue {
        font-size: 0.75rem;
    }
    .ordersPage-header .subheader {
        font-size: 0.875rem;
    }
    .ordersPage-left-action-left_one,
    .ordersPage-left-action-left_one .searchTextField-container {
        width: 100%;
    }
    .ordersPage-container {
        max-width: calc(100vw);
        padding: 10px 0px 30px 0px;
    }
} 