.four-section {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.four-section-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 60px 180px;
}

.four-section-text {
    font-size: 2.813rem;
    text-align: center;
    font-family: 'Faktum-SemiBold';
    color: var(--white);
}

.four-section-white {
    color: var(--white);
}

.four-section-text-grandient {
    font-size: 2.813rem;
    font-family: 'Faktum-SemiBold';
    background: -webkit-linear-gradient(#F7E246, #FFC525);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.four-section-tools {
    display: flex;
    flex-direction: column;
    padding: 0px 120px;
    align-items: center;
}

@media (max-width: 1323px) {
    .four-section-tools {
        padding: 0px 60px;
    }
}

@media (max-width: 1274px) {
    .four-section-header {
        padding: 60px;
    }
}

@media (max-width: 1194px) {
    .four-section-text,
    .four-section-text-grandient {
        font-size: 2.6rem;
    }
    .four-section-text_subtitle {
        font-size: 1.1rem;
        text-align: center;
    }
}

@media (max-width: 1045px) {
    .four-section-header {
        padding: 60px 20px;
    }
    .four-section-text,
    .four-section-text-grandient {
        font-size: 2rem;
    }
}

@media (max-width: 740px) {
    .four-section-text {
        display: flex;
        flex-direction: column;
    }
    .four-section-text_subtitle {
        width: 530px;
        text-align: center;
    }
}

@media (max-width: 665px) {
    .four-section-header {
        padding: 10px 20px;
    }
    .four-section-text,
    .four-section-text-grandient {
        font-size: 1.525rem;
    }
    .four-section-text_subtitle {
        width: 370px;
        font-size: 1rem;
        text-align: center;
    }
    .four-section-tools {
        padding: 0px 20px;
    }
}

@media (max-width: 405px) {
    .four-section-text_subtitle {
        width: 277px;
    }
}