.signUp-container {
    /* max-width: 26.938rem; */
    max-width: 29.438rem;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.signUp-divider {
    position: static;
    width: 300px;
    height: 2px;
    background-color: #C4C4C4;
    border: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.signUp-colorText {
    color: var(--gray-1);
    text-align: center;
}

.signUp-container .footerSignUp-container {
    /* max-width: 20.938rem; */
}

@media (max-width: 470px) {

    .btnIcon-large {
        padding: 10px;
    }

    .signUp-container {
        padding: 0px;
    }

}

@media (max-width: 400px) {
    .signUp-container .custom-button-icon {
        font-size: 1rem;
    }
    .signUp-divider {
        width: 100%;
    }
}