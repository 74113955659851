.faqCard-content {
    height: fit-content;
    width: 300px;
    /* background: #FFFFFF; */
    /* background-image: linear-gradient(to bottom, #ffffff, #fcfcfc, #f9f9f9, #f7f7f7, #f4f4f4); */
    backdrop-filter: blur(20px);
    border-radius: 12px;
    padding: 20px;
    flex-direction: column;
    display: flex;
    gap: 10px;

    background: #333;

    -webkit-box-shadow: 0px 3.301px 9.903px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3.301px 9.903px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3.301px 9.903px 0px rgba(0, 0, 0, 0.15);
}

.faqCard-show {
    flex-direction: column;
    gap: 15px;
    display: flex;

    width: 300px;

    cursor: pointer;
    font-size: 17px;
    font-weight : normal;
    color: #fff;
    outline : none;
    border: none;
    position: relative;
    z-index: 2;
    font-size: 14px;
    font-weight: bold;
    padding: 20px;
    border-radius: 12px;
    border: 3px solid #FFE630;
    /* background-color: transparent;
    background-image: linear-gradient(to right, rgba(247, 226, 71), rgba(251, 214, 57), rgba(254, 202, 42)), 
                      linear-gradient(to right, rgba(247, 226, 71), rgba(251, 214, 57), rgba(254, 202, 42)); */
    /* background-size: 100% 3px;
    background-position: 0 100%, 0 0;
    background-repeat: no-repeat;
    background-clip: border-box; */
    color: #fff;
    box-shadow: 
        6px -9px 20px 0px rgba(255, 220, 125, 0.30), 
        -6px 9px 20px 0px rgba(255, 220, 125, 0.30);

    /* animation: pulse 1.5s infinite ease-in-out, radioactive-glow 2.5s infinite alternate ease-in-out;
    &:before,
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            bottom: 0;
            width: 21px;
            border: 3px solid #F7E247;
            border-right: none;
            border-radius: 30px 0 0 30px;
        }
        
        &:after {
            border: 3px solid #FECA2A;
            border-left: none;
            left: auto;
            right: 0;
            border-radius: 0 30px 30px 0;
        } */
}

/* .faqCard-show::before,
.faqCard-show::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    width: 17px;
    border: 3px solid #F7E247;
    border-right: none;
    border-radius: 30px 0 0 30px;
} */

/* .faqCard-show::after {
    border: 3px solid #FECA2A;
    border-left: none;
    left: auto;
    right: 0;
    border-radius: 0 30px 30px 0; 
} */

.faqCard-content-header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem; 
}

.home-questions-subTitle {
    text-align: left;
    color: var(--white);
    
}
.home-questions-content-answer_ {
    text-align: left !important;
    color: var(--white);
    
}
.home-questions-img-down {
    width: 10px;
    height: 7px;
    
}

@media (max-width: 1045px) {
    .faqCard-content,
    .faqCard-show {
        width: 300px;
    }
}

@media (max-width: 790px) {
    .faqCard-content,
    .faqCard-show {
        width: 300px;
    }
}

@media (max-width: 690px) {
    .faqCard-content,
    .faqCard-show {
        width: 300px;
    }
}

@media (max-width: 590px) {
    .faqCard-content,
    .faqCard-show {
        width: 300px;
    }
}

@media (max-width: 490px) {
    .faqCard-content,
    .faqCard-show {
        width: 300px;
    }
}

@media (max-width: 440px) {
    .faqCard-content,
    .faqCard-show {
        width: 340px;
    }
}

@media (max-width: 390px) {
    .faqCard-content,
    .faqCard-show {
        width: 320px;
    }
}

@media (max-width: 960px) {
    .faqCard-show {
        grid-template-columns: 1fr; 
        gap: 15px;
    }

    .faqCard-show,
    .faqCard-content {
        width: 100%;
        height: fit-content; 
    }
}