.loginPage-container .modal-main .modal-container {
    max-width: 34.438rem;
    width: 100%;
    padding: 40px;
}

.loginPage-container .modal-TyC .modal-container {
    max-width: 90vw;
    width: 100%;
}

.loginPage-container .modal-main .modal-container {
    /* margin-top: 20px;
    margin-bottom: 20px;
    max-height: 870px;
    /* height: 100%; */
    /* min-height: 400px; */
}

@media (max-width: 550px) { 
    .loginPage-container .modal-main .modal-container .signUpFullData-container {
        padding: 0px;
    }
    .loginPage-container .modal-main .modal-container .signUpFullData-container .footerSignUp-container .paragraph {
        text-align: center;
    }
}
@media (max-width: 477px) { 
    .loginPage-container .modal-main .modal-container {
        padding: 40px 30px;
    }
    .loginPage-container .modal-main .modal-container .signUpFullData-container .signUpFullData-password .paragraph {
        font-size: 0.85rem;
    }
}
@media (max-width: 370px) { 
    .loginPage-container .modal-main .modal-container {
        padding: 40px 20px;
    }
    .loginPage-container .modal-main .modal-container .signUpFullData-container .footerSignUp-container {
        gap:20px
    }
    .loginPage-container .modal-main .modal-container .signUpFullData-container .footerSignUp-container .footerSignUp-divider {
        width: 250px;
    }
    .loginPage-container .modal-main .modal-container .signUpFullData-container .footerSignUp-container .footerSignUp-colorText {
        font-size: 0.8rem;
    }
}

@media (max-width: 320px) { 
    .loginPage-container .modal-TyC .modal-container {
        max-width: 100vw;
    }
}