.arrivalDelivery-main {
    max-width: 52.5rem;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    backdrop-filter: blur(20px);
	border-radius: 24px;
    background: var(--background);
    border: 0px solid;
    border-image-source: var(--border-image-source);
}

.arrivalDelivery-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.arrivalDelivery-row-m {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

.arrivalDelivery-row .dropdown  .dropdown-select .move-text {
    left: 0px;
}

.arrivalDelivery-row .textfield {
    width: 100%;
}

.arrivalDelivery-button {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.tagButton-main {
    padding: 5px 15px;
}

.tagButton-main div {
    font-family: 'Faktum-Medium';
    font-size: 12px;
    line-height: 14px;
}

.arrivalDelivery-button button div {
    display: flex;
}

.arrivalDelivery-text {
    color: var(--gray-1);
}

.arrivalDelivery-button .custom-button-icon {
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
    backdrop-filter: blur(0px);
}

.arrivalDelivery-row .dropdown .dropdown-select {
    padding: 20px;
}

/* .arrivalDelivery-main .arrivalDelivery-row .dropdown .dropdown-select .dropdown_disable_color {
    padding-top: 15px;
} */

.arrivalDelivery-main .arrivalDelivery-row .dropdown .dropdown-list {
    max-height: 357px;
    height: 455%;
    overflow: scroll;
}

.arrivalDelivery-main .arrivalDelivery-row .dropdown .dropdown-list::-webkit-scrollbar {
    width: 0px;
}

@media (max-width: 1256px) {
    .arrivalDelivery-row .dropdown {
        max-width: 270px;
    }
}

@media (max-width: 1200px) {
    .arrivalDelivery-row .dropdown {
        max-width: 225px;
    }
}

@media (max-width: 1060px) {
    .arrivalDelivery-row .dropdown {
        max-width: 200px;
    }
}

@media (max-width: 1060px) {
    .arrivalDelivery-row .dropdown {
        max-width: 180px;
    }
}

@media (max-width: 965px) {
    .arrivalDelivery-row .dropdown {
        max-width: 160px;
    }
}

@media (max-width: 939px) {
    .arrivalDelivery-row,
    .arrivalDelivery-row-m {
        gap: 10px
    }
    .arrivalDelivery-row .dropdown {
        max-width: 157px;
    }

    .arrivalDelivery-row-m .dropdown .dropdown-select,
    .arrivalDelivery-row .dropdown .dropdown-select {
        padding: 20px 10px;
    }
}

@media (max-width: 880px) { 
    .arrivalDelivery-main {
        gap: 10px
    }
    .arrivalDelivery-row,
    .arrivalDelivery-row-m {
        flex-direction: column;
    }
    .arrivalDelivery-text {
        font-size: 0.9rem;
    }
    .arrivalDelivery-row .dropdown {
        max-width: 100%;
    }
    .arrivalDelivery-row-m .dropdown {
        max-width: 100%;
    }
}

@media (max-width: 720px) {
    .arrivalDelivery-row {
        flex-direction: column;
    }
    .arrivalDelivery-row .dropdown {
        max-width: 100%;
    }
}

@media (max-width: 700px) {
    .arrivalDelivery-row-m .dropdown .dropdown-select,
    .arrivalDelivery-row .dropdown .dropdown-select {
        padding: 20px;
    }
    .arrivalDelivery-row .textfield .paragraph {
        font-size: 0.9rem;
    }
    .arrivalDelivery-main {
        border-radius: 12px;
    }
}

@media (max-width: 600px) {
    .arrivalDelivery-row-m { 
        gap: 10px
    }
}

@media (max-width: 425px) {
    .arrivalDelivery-main {
        padding: 10px;
    }
}