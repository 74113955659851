.quoteTransport-container {  
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0rem 0.5rem 7rem 0.5rem; 
}

/* .quoteTransport-container__scroll {
    max-height: calc(100vh - 340px);
    height: 100%;
    overflow-y: scroll;
}  */

.quoteTransport-scroll_bottom {
    padding-bottom: 8rem;
}

.quoteTransport-row {
    width: 100%;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 1rem;
}

.quoteTransport-row .fclcd .fclcd-addContainer .fclcd-container-data .fclcd-container-data_row 
.dropdown .dropdown-select .move-text {
    width: 100%;
    max-width: 160px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.quoteTransport-row .fclcd .fclcd-addContainer .fclcd-container-data .fclcd-container-data_row 
.dropdown .dropdown-list .dropdown-list__item {
    white-space: pre-wrap;
}

.quoteTransport-container__scroll::-webkit-scrollbar {
    width: 0px;
}

.quoteTransport-longDown {
    display: flex;
    justify-content: center;
}

.quoteTransport-container div .finalDeliveryPlace, .quoteTransport-container .lclcd, .quoteTransport-container .fclcd {
    max-width: 100%;
    width: 100%;
}

.quoteTransport-container-send {
    background: var(--white-90);
    border-radius: 12px;
    padding: 15px 20px;
}

.quoteTransport-row__{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.quoteTransport-row__div {
    display: flex;
}


.quoteTransport-row .fclcd .fclcd-addContainer .fclcd-container-data 
.fclcd-container-data_row .dropdown .dropdown-hr {
    height: 16rem;
    width: 100%;
    position: absolute;
    top: 64px;
}

/* .quoteTransport-row .fclcd .fclcd-addContainer .fclcd-container-data 
.fclcd-container-data_row .dropdown .dropdown-show {
    display: block !important;
} */

.quoteTransport-row .fclcd .fclcd-addContainer .fclcd-container-data 
.fclcd-container-data_row_ .dropdown .dropdown-hr {
    height: 14rem;
    width: 100%;
    position: absolute;
    top: 64px;
}

.quoteTransport-row .fclcd .fclcd-addContainer .fclcd-container-data 
.fclcd-container-data_row_ .dropdown  .dropdown-show {
    display: block !important;
}

@media (max-width: 600px) {
    .modalFull-main:has(.quoteTransport-container) {
        height: 100%;
    }
    .quoteTransport-title {
        font-size: 0.875rem;
    }
    .quoteTransport-container__scroll {
        max-height: 100%;
    } 
}