.quoter-container {
    width: 100%;
    height: 100%;
    max-width: 870px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
}

.quoter-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    z-index: 1;
}

.quoter-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.quoter-formTitle {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.quoter-title .textfield input {
    height: 50px;
}

.quoter-title--number {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.quoter-container-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: space-between;
}

.quoter-container-buttons-draft {
    color: var(--black-50);
}

.quoter-buttons__draft{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.quoter-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.quoter-timeline {
    display: flex;
    justify-content: center;
}

.modalQuote-full:has(.quote-container_full) {
   top: 4vh;
}

.quoter-phases-line {
    display: none;
}

.quoter-buttons-one {
    width: 100%;
    justify-content: flex-end;
    display: flex;
}

@media (max-width: 600px) {
    .quoter-buttons__draft-none,
    .quoter-buttons__draft  {
        display: none;
    }
    .modalQuote-main:has(.quoter-title) {
        height: fit-content;
        top: 10rem;
    }
    .modalQuote-full:has(.quote-container_full) {
        top: 0vh;
     }
    .quoter-container-buttons-one {
        position: relative;
    }
 
    .quoter-timeline {
        position: fixed;
        padding: 0px 30px;
        background: var(--white);
        width: 100%;
        z-index: 2;
        left: 0;
    }
    .modalQuote-main {
        padding: 20px 15px;
    }
    .quoter-body {
        margin-top: 4rem;
        /* margin-bottom: 5rem;*/
        padding-bottom: 5rem; 
        position: relative;
        overflow: scroll;
        display: flex;
        width: 100%;
        /* height: calc(100vh - 260px); */
        height: calc(100vh - 200px);
        flex-direction: column;
    }
    .quoter-body form {
        padding: 0rem 0.5rem; 
    }
    .quoter-body form,
    .quoter-body .quoteTransport-container,
    .quoter-body .quoteCustoms-container {
        width: 100%;
    }
    .quoter-container-buttons {
        left: 0;
        bottom: 0rem;
        width: 100%;
        padding: 20px 30px;
        z-index: 2;
        background: #FFF;
        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(45px);
        position: fixed;
    }

    .quoter-buttons {
        width: 100%;
    }
    .quoter-buttons-one .custom-button {
        padding: 10px 0px;
        max-width: 150px;
        height: 45px;
        width: 100%;
    }
    .quoter-buttons .custom-button {
        padding: 10px 0px;
        min-width: 90px;
        max-width: 100%;
        width: 100%;
        height: 45px;
    }
}

@media (max-width: 440px) { 
    .quoteCustoms-container .merchandiseValue .merchandiseValue-container 
    .merchandiseValue-container_ .dropdown .dropdown-select .move-text {
        left: 0px;
    }
    .quoteCustoms-container .merchandiseValue .merchandiseValue-container 
    .dropdown .dropdown-select .move-text {
        left: 0px;
    }
}

@media (max-width: 420px) {
    .quoter-body {
        padding-bottom: 8rem;
    }
}

@media (max-width: 400px) {
    .quoter-body {
        padding-bottom: 3rem;
    }
}

@media (max-width: 380px) {
    .quoter-body {
        padding-bottom: 2rem;
    }
} 