.card-operation {
    width: 100%;
    max-width: 100%;
    min-width: 100px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    padding: 0.8rem;
    border-radius: 12px;
    backdrop-filter: blur(40px);
    border-image-source: var(--border-image-source);

    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0.5rem 0rem 0.5rem 0rem;
    /* background: var(--white); */
    /* padding: 20px; */
}
@keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
  
    100% {
      background-position: 468px 0;
    }
} 

.shimmer-operation-animation {
    background-color: #f6f7f8;
    background-image: linear-gradient(
      to right,
      var(--white-50) 0%,
      var(--gray-6) 20%,
      var(--white-50) 40%,
      var(--white-50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
}

.loader-shimmer-operation-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.loader-shimmer-operation-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px
}

.loader-shimmer-operation-logo {
    height: 30px;
    width: 100%;
    max-width: 50px;
    background-size: 800px 18rem;
}

.loader-shimmer-operation-text {
    height: 30px;
    width: 100%;
    max-width: 400px;
}

.loader-shimmer-operation-item {
    height: 30px;
    width: 100%;
    max-width: 150px;
}

.loader-shimmer-operation-body {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.loader-shimmer-operation-body_content {
    display: flex;
    flex-direction: row;
    gap: 10px
}

.loader-shimmer-operation-table_item {
    height: 30px;
    width: 100%;
    max-width: 250px;
}


  @media (max-width: 900px) {
    .card-operation {
        width: 98.4%;
        margin: 0.5rem 0rem 0.5rem 0.5rem;
        gap: 10px
    }
    .loader-shimmer-operation-text { 
        max-width: 300px;
    }
    .loader-shimmer-operation-item {
        max-width: 100px;
    }
    .loader-shimmer-operation-table_item,
    .loader-shimmer-operation-item,
    .loader-shimmer-operation-logo,
    .loader-shimmer-operation-text {
        height: 20px;
    }
    .loader-shimmer-operation-body {
        gap: 5px
    }
    .loader-shimmer-operation-logo {
        max-width: 30px;
    }
  }

  @media (max-width: 700px) {
    .card-operation {
        width: 97.4%;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
        gap: 10px;
    }
  }

  @media (max-width: 600px) { 
    .loader-shimmer-operation-text { 
        max-width: 200px;
    }
  }

  @media (max-width: 470px) {
    .card-operation {
        width: 96.4%;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
        gap: 10px;
    }
  }

  @media (max-width: 400px) {
    .card-operation {
        width: 95.8%;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
        gap: 10px;
    }
    .loader-shimmer-operation-text { 
        max-width: 150px;
    }
  }