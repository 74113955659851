.detail-profile {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    /* background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(20px); */
}
.detail-profile-title-section {
    color: #333333;
}
.detail-profile .detail-profile-header {
    display: none
}
.detail-profile__row__ {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.detail-profile__row {
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.detail-profile__row-it {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;

}

.detail-profile__row-it .textfield {
    width: 60%;
}

.detail-profile__row-it .dropdown {
    width: 60%;
}

/* .detail-profile__row-typeIt {
    max-width: 200px;
    width: 100%;
}

.detail-profile__row-itNumber {
    max-width: 150px;
    width: 100%;   
} */


.detail-profile__row .phoneCountry-container .phoneCountry-item .textfield .textfield-input,
.detail-profile__row .textfield .textfield-input {
    backdrop-filter: blur(40px);
}

.detail-profile__row .phoneCountry-container {
    width: 100%;
}

.detail-profile-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.detail-profile .detail-profile__row 
.dropdown .dropdown-select {
    padding: 20px;
}

.detail-profile .detail-profile-header .profile-detail 
.profile-detail-content .useractive .useractive-logged {
    display: none;
}

/* .detail-profile .detail-profile__row .textfield 
input:not(:placeholder-shown).textfield-input:not(:focus) {
    padding: 22.5px 20px 7.5px 19px;
} */

.detail-profile__password-content {
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 50%;
    height: 50px;
    align-items: self-start;
    justify-content: center;
    padding: 0px 10px 0px 20px;
    box-sizing: border-box;
    outline: none;
    backdrop-filter: blur(40px);
    border-radius: 24px;
    border: 0px solid rgba(0, 0, 0, 0.25);
    background: var(--white);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.detail-profile__password {
    color: var(--blue-2);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
}

.detail___1, .detail___2, .detail-profile__footer {
    display: none;
}

.detail-profile__row .dropdown .dropdown-select .move-text {
    left: 0px;
}

.detail-profile__row .countryDropdown-container .countryDropdown-item 
.countryDropdown-item-column-dropdown {
    width: 100%;
    /* max-width: 406px; */
    max-width: 100%;
    min-width: 100px;
}

/* .detail-profile__row .countryDropdown-container .countryDropdown-item 
.countryDropdown-item-column-dropdown .countryDropdown-item-content {
    padding-left: 20px;
    background-color: transparent;
    backdrop-filter: blur(40px);
} */

.detail-profile__row_ {
    display: flex;
    justify-content: flex-end;
    text-align: end;
}

.detail-profile__row .textfieldPassword-container {
    width: 100%;
}

.detail-profile__row .dropdown .dropdown-list {
    max-height: 345px;
    
    overflow-x: hidden;
    overscroll-behavior: revert;
}

.detail-profile__row .dropdown .dropdown-list::-webkit-scrollbar {
    width: 0px;
}

.detail-profile__row-cursor {
    cursor: pointer;
}

.detail-profile__row .countryDropdown-container {
    width: 105%;
}

.detail-profile__password-content-disabled {
    background: rgba(255, 255, 255, 0.15) !important;
    backdrop-filter: blur(4.5px);
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
}

.detail-profile .detail-profile__row .textfield 
input:not(:placeholder-shown).textfield-input:not(:focus),
.detail-profile .detail-profile__row .textfield input:focus {
    background: rgba(255, 255, 255, 0.15) !important;
    backdrop-filter: blur(4.5px);
}

.detail-profile__row .phoneCountry-container .phoneCountry-item 
.phoneCountry-item-column-dropdown .phoneCountry-item-content {
    background: rgba(255, 255, 255, 0.15) !important;
    backdrop-filter: blur(4.5px) !important;
}

.detail-profile__row .countryDropdown-container .countryDropdown-item 
.countryDropdown-item-column-dropdown .countryDropdown-item-content {
    background: rgba(255, 255, 255, 0.15) !important;
    backdrop-filter: blur(4.5px);
}

.detail-profile__row .textfield .textfield-input   {
    background: rgba(255, 255, 255, 0.15) !important;
    backdrop-filter: blur(4.5px);
}

.detail-profile__row___ {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.detail-profile .headPage .headPage-title {
    font-size: 1rem;
}

.detail-profile__firstImport {
    width: 100%;
}

@media (max-width: 1200px) {
    .detail-profile__password {
        font-size: 0.9rem;
    }
    .detail-profile__row___ .textfield .textfield-title {
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (max-width: 1160px) {
    .detail-profile__row,
    .detail-profile__row___, .detail-profile__row-it {
        /* flex-direction: column; */
        gap: 15px
    }

}


@media (max-width: 1016px) { 
    .detail-profile__row {
        gap: 15px;
    }
    .detail-profile .detail-profile__row .textfield 
    input:not(:placeholder-shown).textfield-input:not(:focus),
    .detail-profile .detail-profile__row .textfield input:focus {
        padding: 22.5px 20px 7.5px 12px;
    }
    .detail-profile .detail-profile__row .textfield .textfield-title {
        left: 14px;
    }
    .detail-profile__row .countryDropdown-container .countryDropdown-item 
    .countryDropdown-item-column-dropdown .countryDropdown-item-content {
        padding-left: 14px;
    }
    .detail-profile__row .phoneCountry-container .phoneCountry-item 
    .phoneCountry-item-column-dropdown .phoneCountry-item-content {
        padding-left: 13px;
    }
}

@media (max-width: 985px) { 
    .detail___1, .detail___2 {
        display: inline-flex;
    }
    .detail___3 {
        display: none;
    }
    .detail-profile__row {
        flex-direction: column;
    }
}

@media (max-width: 960px) {
    .detailProfile {
        height: 100%;
        padding: 20px;
        padding-bottom: 10rem;
    }
}
@media (max-width: 768px) { 
    .detail-profile {
        /* padding: 20px; */
        height: 100%;
        overflow: scroll;
    }
    .detail-profile::-webkit-scrollbar {
        display: none;
    }
    .detail-profile .detail-profile-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    /* .detail-profile .headPage {
        display: flex;
        text-align: center;
    } */
    /* .detail-profile .detail-profile-header .profile-detail {
        max-width: 455px;
    } */
    /* .detail-profile .detail-profile-header .profile-detail .profile-detail-content 
    .profile-detail__row .profile-detail-list {
        width: 330px;
    } */
    
    .detail-profile__row-it {
        gap: 10px;
    }
    
     .detail-profile__row-it .textfield {
        width: 100%;
    }
}
@media (max-width: 630px) { 
    /* .detail-profile,
    .business,
    .profile {
        margin-bottom: 10rem;
    } */
    .detail-profile__row {
        flex-direction: column;
    }
    /* .detail-profile__password-content {
        width: 100%;
        padding: 5px 10px;
    } */
    .detail-profile__row .countryDropdown-container .countryDropdown-item .countryDropdown-item-column-dropdown {
        max-width: 100%;
    }
    .detail-profile__row-cursor {
        flex-direction: row;
    }
    .detail-profile__row .countryDropdown-container {
        width: 100%;
    }
}
@media (max-width: 617px) { 
    .detail-profile .detail-profile-header {
        flex-direction: column;
        gap: 30px;
    }
    .detail-profile .detail-profile-header .content-number {
        width: 100%;
        max-width: 100%;
        justify-content: flex-start;
    }
}

@media (max-width: 600px) { 
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content 
    .profile-detail__row .profile-detail-list .status  {
        height: 30px;
    }
    .detail-profile .headPage {
        padding-left: 0px;
    }
    .detailProfile {
        padding: 20px 10px;
        padding-bottom: 15rem;
    }
}

@media (max-width: 524px) {  
    .detail-profile .headPage .headPage-title {
        font-size: 1.375rem;
    }
}
/* @media (max-width: 521px) { 
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content 
    .profile-detail__row .profile-detail-list {
        width: 100%;
        max-width: 290px !important;
        gap: 10px; 
    }
} */
@media (max-width: 480px) { 
    .detail-profile__row {
        flex-direction: column;
    }
    /* .detail-profile__password-content { 
        padding: 7px 10px;
    } */
    /* .detail-profile__row__ .buttonEditChange {
        display: none;
    } */
    .detail-profile__footer {
        display: flex;
        justify-content: flex-end;
    }
    .detail-profile__row-cursor {
        flex-direction: row;
    }
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content 
    .profile-detail__row .profile-detail-list {
        width: 100%;
        max-width: 260px !important;
        gap: 10px; 
    }
}

@media (max-width: 454px) {  
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content 
    .profile-detail__row .profile-detail-list {
        width: 100%;
        max-width: 240px !important;
        gap: 10px; 
    }
}

@media (max-width: 434px) {  
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content 
    .profile-detail__row .profile-detail-list {
        width: 100%;
        max-width: 220px !important;
        gap: 5px; 
    }
}

@media (max-width: 408px) { 
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content 
    .profile-detail__row .profile-detail-list {
        max-width: 200px !important;
    }
}

@media (max-width: 394px) { 
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content {
        gap: 10px;
    }
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content .useractive .useractive-logged {
        width: 12px;
        height: 12px;
    }
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content .profile-detail__row 
    .profile-detail-list {
        gap: 0px
    }
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content .profile-detail__row 
    .profile-detail-list .useractive .img {
        width: 200px;
        gap: 0px
    }
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content .profile-detail__row 
    .profile-detail-list .profile-detail-name {
        font-size: 1rem;
    }
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content .profile-detail__row .profile-detail-list .profile-detail-business,
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content .profile-detail__row .profile-detail-list .profile-detail-nickname{
        font-size: 0.9rem;
    }
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content .profile-detail__row .profile-detail-list .status-container-green{
        border-radius: 9px;
    }
}

@media (max-width: 388px) { 
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content 
    .profile-detail__row .profile-detail-list {
        max-width: 180px !important;
    }
}
@media (max-width: 356px) {  
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content .profile-detail__row 
    .profile-detail-list {
        width: 190px;
    }
    /*Cantidad de las relaciones que tienes*/
    .detail-profile .detail-profile-header .content-number {
        justify-content: space-between;
    }
    .detail-profile .detail-profile-header .content-number .content-number-section {
        width: 90px;
    }
    .detail-profile .detail-profile-header .content-number .content-number-section .content-number-contact,
    .detail-profile .detail-profile-header .content-number .content-number-section .content-number-orden, 
    .detail-profile .detail-profile-header .content-number .content-number-section .content-number-review {
        font-size: 0.7rem;
    }
    .business .order__nav .subheader {
        justify-content: flex-start;
    }
    .business .order__nav .subheader {
        font-size: 0.9rem;
    }
}

@media (max-width: 320px) { 
    .detail-profile .detail-profile-header .profile-detail .profile-detail-content .profile-detail__row 
    .profile-detail-list {
        width: 155px;
    }
}

@media (max-width: 316px) { 
    .detail-profile .detail-profile-header .profile-detail 
    .profile-detail-content {
        gap: 10px
    }
    .detail-profile .detail-profile-header .profile-detail 
    .profile-detail-content .profile-detail__row .profile-detail-list {
        width: 150px;
    }
}
