.invoceTranslationImporter-main {
    z-index: 3;
}

.invoceTranslationImporter-main .card-contet {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    /* 
    padding: 20px;
    backdrop-filter: blur(20px);
	border-radius: 24px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    /* border: 1px solid; 
    border-image-source: var(--border-image-source);*/
    /*border: 0px solid;
    ; */
}

.invoceTranslationImporter-main p {
    margin: 0px;
}

.card-content .invoceTranslationImporter-input .dropdown {
    width: 43%;
}

.card-content .invoceTranslationImporter-input-natural .dropdown {
    width: 100%;
    max-width: 50%;
}

.invoceTranslationImporter-main .dropdown .dropdown-select .move-text {
    left: 0px;
}

.invoceTranslationImporter-main .dropdown .dropdown-list {
    max-width: 280px;
}

.invoceTranslationImporter-main .dropdown .dropdown-list .dropdown-list__item {
    max-width: 100%;
}

/* .invoceTranslationImporter-main .invoceTranslationImporter-input .textfield input {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
} */

.invoceTranslationImporter-input,
.invoceTranslationImporter-input-natural {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.invoceTranslationImporter-input-m {
    display: none;
}

.invoceTranslationImporter-color {
    color: var(--gray-1);
}

.invoceTranslationImporter-input--businessName { 
    max-width: 290px;
    width: 100%;
}

.invoceTranslationImporter-input--ruc {
    max-width: 240px;
    width: 100%;
}

.invoceTranslationImporter-input--personNatural {
    max-width: 100%px;
    width: 100%;
}

/* Dropdown */
.invoceTranslationImporter-main .dropdown .dropdown-select {
    padding: 20px;
}

.invoceTranslationImporter-input .textfield .textfield-input {
    background: var(--white);
}

.invoceTranslationImporter-input-natural-column {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--black-25);
    border-radius: 48px;
    padding: 5.5px 20px 7.5px 20px;
}

.invoceTranslationImporter-input-natural-answer {
    font-size: 0.9rem !important;
    color: #000;
    text-overflow: ellipsis;
    font-family: 'Faktum-Medium';
    font-style: normal;
    font-weight: 400;
}

.invoceTranslationImporter-input-natural-title {
    color: var(--gray-1);
    font-size: 0.65rem !important;
    font-family: "Faktum";
    font-weight: normal;
}
/* .invoceTranslationImporter-input .textfield-title {
    top: 40%;
} */

@media (max-width: 976px) { 
    .invoceTranslationImporter-input,
    .invoceTranslationImporter-input-natural {
        flex-direction: column;
    }
    .card-content .invoceTranslationImporter-input .dropdown {
        width: 100%;
        max-width: 100%;
    }
    .invoceTranslationImporter-input .invoceTranslationImporter-input--businessName, 
    .invoceTranslationImporter-input .invoceTranslationImporter-input--ruc {
        max-width: 100%;
    }
    .invoceTranslationImporter-main .dropdown {
        width: 100%;
        min-width: 35%;
        max-width: 220px;
    }
    .card-content .invoceTranslationImporter-input-natural .dropdown {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 880px) {
    .invoceTranslationImporter-input {
        display: none;
    }

    .invoceTranslationImporter-input-natural {
        display: flex;
        flex-direction: column;
        gap: 10px
    }
    .card-content .invoceTranslationImporter-input-natural .dropdown {
        width: 100%;
        max-width: 100%;
    }

    .invoceTranslationImporter-main .card-content {
        gap: 10px
    }

    .invoceTranslationImporter-input-m { 
        flex-direction: column;
        display: flex;
        gap: 15px;
    }
    .invoceTranslationImporter-input-m-row {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    .invoceTranslationImporter-input .textfield input {
        width: 100% !important;
    }

    .invoceTranslationImporter-input--businessName, .invoceTranslationImporter-input--ruc  {
        max-width: 100%;
    }
    .invoceTranslationImporter-main .paragraph-header,
    .invoceTranslationImporter-main .paragraph   {
        font-size: 0.9rem;
    }
    .invoceTranslationImporter-main .card-content  .dropdown .dropdown-select .select-title,
    .invoceTranslationImporter-input-natural .textfield .textfield-title {
        font-size: 0.65rem !important;
    }
    .invoceTranslationImporter-input-natural .textfield .textfield-input,
    .invoceTranslationImporter-main .card-content  .dropdown .dropdown-select .dropdown_text {
        font-size: 0.9rem !important;
    }
}

@media (max-width: 834px) {
    .invoceTranslationImporter-main .dropdown {
        width: 100%;
        max-width: 300px;
    }
}

@media (max-width: 700px) {
    .invoceTranslationImporter-main .card-content {
        border-radius: 12px;
    }
}

@media (max-width: 480px) {
    .card-content .invoceTranslationImporter-input-natural .dropdown {
        width: 100%;
        max-width: 100%;
    }
    .invoceTranslationImporter-input-natural {
        display: flex;
        flex-direction: column;
        gap: 15px
    }
}

@media (max-width: 425px) {
    .card-content .dropdown {
        width: 100% !important;
    }
}

@media (max-width: 425px) {
	.invoceTranslationImporter-main .card-content {
		padding: 10px;
	}
}