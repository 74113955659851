.verify-container {
    position: absolute;
    z-index: 10;
    top: 12.8rem;
    right: 1rem;
}

.verify-content {
    background: var(--white);
    border-radius: 24px;
    box-shadow: 0px 1.5px 3px 0px rgba(0, 0, 0, 0.60);
   
    max-width: 280px;
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px; 
} 
.verify-line { 
    display: none;
}
.verify-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.verify-status {
    color: var(--white);
    background: var(--black);
    padding: 5px 10px;
    border-radius: 24px;
    font-size: 0.875rem;
}
.verify-status-top {
    color: var(--white);
    background: var(--blue-2);
    padding: 5px 10px;
    border-radius: 24px;
    font-size: 0.875rem;
}
.verify-description {
    display: flex;
    flex-direction: row;
    gap: 10px; 
    align-items: center;
}
.verify-description div img {
    width: 1.3rem;
}
.verify-description-title {
    font-size: 0.875rem;
}
.verify-more {
    color: var(--blue-2);
    font-size: 0.875rem;
    cursor: pointer;
}
.verify-content .custom-button {
    font-size: 0.875rem;
}


@media (max-width: 600px) {
    .verify-container {
        background: var(--black-15);
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0rem;
        right: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    .verify-content {
        bottom: 0px;
        display: flex;
        position: relative;
        max-width: 100%;
        border-radius: 24px 24px 0px 0px;
        height: auto;
    }
    .verify-line {
        display: flex;
        height: 0.3rem;
        width: 30px;
        background: var(--black-15);
        align-items: center;
        align-self: center;
        border-radius: 10px;
    }
    .verify-title {
        font-size: 1.25rem;
    }
}