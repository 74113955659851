.badge-container {
    width: 22px;
    height: 22px;
    border-radius: 48px;
    background-color: var(--red);
    justify-content: center;
    align-items: center;
    display: flex;
}
.badge-text-color {
    color: var(--white-90);
}
/*responsive*/
@media (max-width: 1025px) {
    badge-text-color {
        font-size: 0.8rem;
    }
}