.card-comment {
    display: flex;
    width: 210px;
    height: 300px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    border-radius: 24px;
    background: rgba(51, 51, 51, 0.60);

    backdrop-filter: blur(4.5px);
    justify-content: space-between;
}

.card-comment-text {
    color: var(--white)
}

.card-comment-footer {
    display: flex;
    flex-direction: row;
    gap: 5px
}

.card-comment-column {
    display: flex;
    flex-direction: column;
}

.card-comment-verify {
    height: 11px;
    width: 11px;
}

.card-comment-verify img {
    height: 11px;
    width: 11px;
}

.card-comment-row {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
}

.card-comment-profile {
    height: 30px;
    width: 30px;
    border-radius: 50%;
}