.eight-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 60px 0px;
    /**background: linear-gradient(180deg, rgba(19, 19, 19, 0.05) 27.92%, rgba(255, 193, 87, 0.30) 100%);**/
    overflow: hidden;
}

.eight-section-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.eight-section-text {
    font-size: 2.125rem;
    text-align: center;
    font-family: 'Faktum-SemiBold';
    color: var(--white);
}

.eight-section-white {
    color: var(--white);
}

.eight-section-start {
    width: 24px;
    height: 23px;
    background: #FFC525;
    clip-path: 
        polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}
.eight-section-review-start {
    display: flex;
    flex-direction: row;
    gap: 6px
}

/**button */
.eight-btn {
    width: 198px;
    height: 58px;
    border-radius: 12px;
    padding: 15px 20px;
    cursor: pointer;
    align-items: center;
    display: flex;
    background: linear-gradient(94deg, #F7E246 0%, #FFC525 73.97%);

    backdrop-filter: blur(20px);
    color: var(--black);

    font-size: 1.375rem;
    font-family: 'Faktum';
    justify-content: center;
    gap: 5px;
    box-shadow: 0 0 20px rgba(255, 194, 87, 0.4), 0 0 30px rgba(255, 194, 87, 0.5), 0 0 50px rgba(247, 226, 70, 0.4);
    animation: pulse 1.5s infinite ease-in-out, radioactive-glow 2.5s infinite alternate ease-in-out;
}

.eight-btn-resalt {
    font-size: 1.375rem;
    font-family: 'Faktum-Bold';
}

.eight-section-img {
    height: 300px;
}

.landing-first-img {
    width: 142.222px;
    height: 80px;
}

/* .eight-section-list-comment {
    display: flex;
    flex-direction: row;
    gap: 20px
} */

@media (max-width: 1045px) {
    .eight-section {
        padding: 30px 0px;
    }
    .eight-section-header {
        gap: 10px
    }
    .eight-section-review-start {
        display: none;
    }
    .eight-section-text,
    .eight-section-white {
        font-size: 1.25rem;
    }
    .eight-btn {
        width: 168px;
        padding: 11px 20px;
        height: 45px;
        font-size: 1.125rem;
        align-items: center;
    }
    .eight-btn-resalt {
        font-size: 1.125rem;
    }
}

@media (max-width: 665px) {
    .eight-section {
        padding: 0px 0px 30px 0px;
    }
    .eight-section-header {
        gap: 6px
    }
    .eight-section-text,
    .eight-section-white {
        font-size: 0.95rem;
    }
    .eight-btn-resalt {
        font-size: 0.95rem;
    }
    .landing-first-img {
        width: 88.889px;
        height: 50px;
    }
}