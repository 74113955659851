.adminCard-row {
    display: flex;
    flex-direction: row;
    gap: 20px
}

@media (max-width: 1000px) { 
    .adminCard-row {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 768px) { 
    .adminCard-row {
        display: flex;
        flex-direction: row;
    }
}

@media (max-width: 600px) { 
    .adminCard-row {
        display: flex;
        flex-direction: column;
    }
}