.progress-circle {
  transform: rotate(-90deg);
}
  .circle-background,
  .circle-progress {
    fill: none;
  }
  
  .circle-background {
    stroke: #ffffff00;
  }
  
  .circle-progress {
    stroke: #fbd032;
    stroke-linecap: square;
    stroke-linejoin: round;
  }
  
  .circle-text {
    fill: var(--white);
  }