.processPage-content {
    width: 100%;
    max-width: 100%;
    padding: 0px 10px;
    height: calc(100vh - 110px);
    overflow: hidden;
    margin-bottom: 5rem;
}
.processPage-content-row {
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-self: center;
    /* padding: 0px 0px 10px 0px; */
    height: calc(100vh - 120px);
}
.processPage-left {
    /* height: 94vh; */
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    gap: 10px; 
    padding: 0.4rem 0.86rem;
    padding-bottom: 10rem;
}
.processPage-left::-webkit-scrollbar {
    display: none;
}
.processPage-left-header {
    width: 100%;
    max-width: 353px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.processPage-title {
    font-size: 0.9rem;
}
.processPage-left-header .buttonBadget div .message-icon,
.processPage-chat-content .buttonBadget div .message-icon {
    display: flex
}
.processPage-left-header .buttonBadget div .message-icon img,
.processPage-chat-content .buttonBadget div .message-icon {
    width: 26px;
    height: 18.2px;
}
.processPage-left .processPage-left-multimedia,
.processPage-left .processPage-left-multimedia .multimedia-container {
    width: 100%;
    max-width: 22rem;
}
.processPage-chat-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
}
.processPage-right {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    flex: 1 1;
    order: 1;
    padding: 1.4rem 0.9rem 0rem 0.9rem;
    padding-bottom: 5rem;
}
.processPage-right::-webkit-scrollbar {
    display: none;
}
.processPage-right,
.processPage-right-phases,
.processPage-right-phases-one {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px
}
.processPage-right-phases {
    overflow: scroll;
    padding-bottom: 5rem;
    padding: 0.9rem 0.5rem 15rem 0.5rem;
}
.processPage-right-phases::-webkit-scrollbar {
    display: none;
}
.processPage-colorText {
    font-size: 0.9rem;
}
.processPage-right-phases-one-content {
    backdrop-filter: blur(40px);
    background: var(--white-50);
    border-radius: 24px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.processPage-right-phases-one {
    margin-bottom: 5rem;
}
.processPage-right-phases .filesFLC-container .filesFLC-files {
    width: 100%;
    gap: 10px
}
.processPage-right-phases .trackingShipping-container {
    max-width: 100%;
    width: 100%;
}
.processPage-right-phases .filesFLC-container {
    width: 100%;
    max-width: 100%;
}

.processPage-right-phases .trackingShipping-container 
.trackingShipping-static .trackingShipping-card-day{
    display: none;
}

.processPage-right-phases .invoice-text-description {
    font-size: 0.9rem;
}

.processPage-phase-six {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.processPage-chat--content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    flex: 1 1;
    order: 1;
    padding: 0rem 0.5rem 0rem 0.5rem;
}
.processPage-chat--content::-webkit-scrollbar {
    display: none;
}

.processPage-chat--content .chat {
    padding-bottom: 1rem;
}

.processPage-chat--content .chat .chat-personal {
    max-width: 100%;
}

/* hidden scroll bar */
.processPage-left::-webkit-scrollbar {
    display: none;
}

@media (min-width: 700px) {
    .processPage-chat-content {
        padding: 0.5rem 0.9rem;
    }
    .processPage-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* gap: 10px; */
    }
    .processPage-left {
        padding: 0.4rem 0.5rem;
        /* margin-bottom: 5rem; */
        min-width: 300px;
        max-width: 330px;
    }
    .processPage-left-header {
        height: 37px;
        max-width: 100%;
    }
    .processPage-right-phases {
        overflow: none;
        padding: 0rem;
    }
    .processPage-left-header .buttonBadget {
        display: none;
    }
    .processPage-left .processPage-left-multimedia, 
    .processPage-left .processPage-left-multimedia .multimedia-container {
        width: 100%;
    }
    .processPage-right {
        /* height: 94vh; */
        width: 100%;
        max-width: 880px;
        padding: 0.4rem 0rem 0.4rem 0.86rem;
        gap: 2px;
    }
    .processPage-right-phases {
        overflow: scroll;
        padding: 1.3rem 0.4rem 1rem 0.4rem;
        /* margin-bottom: 5rem; */
    }
    .processPage-right-phases::-webkit-scrollbar {
        display: none;
    }
    .processPage-title {
        font-size: 1rem;
    }
    .processPage-chat-content .headerCustomsPhases-container 
    .headerCustomsPhases-title .headerCustomsPhases-title--text {
        font-size: 0.9rem;
    }
    .headerCustomsPhases-chat-content .headerCustomsPhases-container 
    .headerCustomsPhases-title .headerCustomsPhases-title--number {
        height: 30px;
        width: 30px;
    }
    .headerCustomsPhases-chat-content .headerCustomsPhases-container 
    .headerCustomsPhases-title .headerCustomsPhases-title--number
    .headerCustomsPhases-title--number-content {
        left: 0.15rem;
        top: 0.15rem;
        font-size: 0.9rem;
        height: 24px;
        width: 24px;
    }
    .headerCustomsPhases-container {
        width: 100%;
    }
    .processPage-left .processPage-left-multimedia,
    .processPage-left .processPage-left-multimedia .multimedia-container {
        max-width: 100%;
    }
}

@media (min-width: 769px) { 
    .processPage-chat--content .chat {
        /* padding-bottom: 5rem; */
    }
    .processPage-content {
        margin-bottom: 0rem;
    }
}

@media (min-width: 880px) {
    .processPage-left {
        min-width: 340px;
        max-width: 415px;
        /* min-width: 400px;
        max-width: 450px; */
        flex: 1 1;
    }
    .processPage-right-phases-one-content {
        gap: 10px
    }
    .processPage-chat-content .headerCustomsPhases-container .headerCustomsPhases-title .headerCustomsPhases-title--text {
        font-size: 1rem;
    }
    .headerCustomsPhases-container {
        width: 65%;
    }
    .processPage-chat-right {
        max-width: 880px;
        width: 100%;
        display: flex;
        flex-direction: column;
        transform-origin: left top;
        flex-grow: 1;
        position: relative;
        overflow: hidden;
        transform: translateZ(0);
        height: 100%;
    }
}

@media (min-width: 1024px) {
    .processPage-title,
    .processPage-right-phases .invoice-text-description {
        font-size: 1rem;
    }
    .processPage-right-phases,
    .processPage-right-phases .filesFLC-container .filesFLC-files,
    .processPage-phase-six  {
        gap: 20px
    }
    .processPage-chat-content .headerCustomsPhases-container 
    .headerCustomsPhases-title .headerCustomsPhases-title--number
    .headerCustomsPhases-title--number-content {
        left: 0.25rem;
        top: 0.25rem;
    }
}

@media (min-width: 1240px) {
    .processPage-content {
        padding: 0px 20px;
        /* gap: 20px */
    }
    .processPage-right {
        max-width: 840px;
    }
    .processPage-chat-content .headerCustomsPhases-container 
    .headerCustomsPhases-title .headerCustomsPhases-title--number
    .headerCustomsPhases-title--number-content {
        left: 0.25rem;
    }
}