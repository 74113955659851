.suscription {
    display: flex;
    flex-direction: column;
    gap: 20px
}


.suscription .headPage .headPage-title {
    font-size: 1rem;
}

.suscription-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.suscription-two {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: end
}

.suscription-two .dropdown {
    width: 100%;
}

.subscription-creditNumber{
    height: 54px;
    display: flex;
    border: 1px solid var(--black-25);
    padding: 20px;
    border-radius: 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.subscription-plan {
    height: 54px;
    display: flex;
    border: 1px solid var(--black-25);
    padding: 20px;
    border-radius: 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.detailSubscription-text-blue {
    color: var(--blue-2);
    font-family: 'Faktum-SemiBold';
    cursor: pointer;
}

.subscription-column {
    display: flex;
    flex-direction: column;
}

.subscription-text-title {
    font-family: "Faktum";
    font-weight: normal;
    font-size: 0.75rem;
    color: var(--gray-1);
}

.subscription-text-plan {
    font-family: 'Faktum';
    font-size: 0.875rem;
    color: black
}

.suscription-two .creditNumber {
    width: auto; 
    max-width: auto;
    min-width: auto;
}
.subscription-cancel {
    margin-top: 1.875rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media (max-width:1293px) {
    .suscription-row {
        flex-direction: column;
    }
    .suscription-two .dropdown {
        max-width: 100%;
    }
}

@media (max-width:995px) {
    .subscription-creditNumber .suscription-two {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 10px
    }
    .subscription-creditNumber {
        border-radius: 24px;
        height: 80px;
    }
    .suscription-two {
        gap: 10px
    }
}

@media (max-width:780px) { 
    .subscription-creditNumber {
        height: 95px;
    }
}

@media (max-width:768px) { 
    .suscription-two {
        gap: 20px
    }
    .subscription-creditNumber {
        border-radius: 40px;
        height: 54px;
    }
    .subscription-creditNumber .suscription-two {
        display: flex;
        flex-direction: row;
        align-items: end;
        gap: 10px;
    }
}

@media (max-width:638px) { 
    .subscription-creditNumber {
        border-radius: 24px;
        height: 80px;
    }
    .subscription-creditNumber .suscription-two {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 10px;
    }
}

@media (max-width:540px) {  
    .suscription-two {
        flex-direction: column;
    }
}

@media (max-width:440px) {  
    .subscription-creditNumber {
        height: 100%;
        flex-direction: column;
        min-height: 95px;
        max-height: fit-content;
        gap: 10px;
        justify-content: flex-start;
        text-align: start;
        align-items: flex-start;
    }
}