
.word {
    display: block;
  }
  
  .word span {
    /* color: var(--black); */
    background: #2039C9;
    background: linear-gradient(to right, #2039C9, #0197AA 61%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
  }
  
  .word span::after {
    content: "";
    width: 8px;
    height: 100%;
    background: var(--black);
    display: block;
    position: absolute;
    right: -10px;
    top: 0;
    animation: blink 0.5s ease infinite alternate-reverse;
  }
  
  @keyframes blink {
    from {
      opacity: 100%;
    }
  
    to {
      opacity: 0%;
    }
  }
  
  .word.empty {
    visibility: hidden;
  }
  
  .word.empty span::after {
    visibility: visible;
    right: 0;
  }
  
  @media (max-width: 600px) { 
    .word span::after {
      width: 4px;
    }
    .mega-header, .useTypingtext {
      font-style: normal;
      font-weight: 400;
      font-size: 2.125rem;
      line-height: 40px;
    }
  }

  @media (max-width: 524px) { 
    .useTypingtext {
      font-style: normal;
      font-weight: 400 !important;
      font-size: 2.125rem !important;
      line-height: 40px !important;
    }
  }