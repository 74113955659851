.notFound {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: var(--gray-0);
    align-items: center;
    justify-content: center;
}

.notFound-content {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.notFound-img > img {
    width: 100%;
    height: 60vh;
}

.notFound-content-text {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.notFound-content-text .submega-encabezado {
    font-size: 3.6rem;
    color: #F2F2F2;
    font-weight: 900;
    width: 400px;
    text-align: center;
    line-height: 72px;
}

.notFound-content-text .small-subheader {
    font-size: 1.8rem;
    color: #F2F2F2;
    width: 450px;
    text-align: center;
    line-height: 40px;
}

@media (max-width: 1249px) {
	.notFound-content {
		padding: 0px 4rem;
	}
    .notFound-img > img {
        height: 100%;
    }
}

@media (max-width: 1145px) {
	.notFound-content-text .submega-encabezado {
        font-size: 3rem;
        width: 360px;
        line-height: 65px;
    }
    
    .notFound-content-text .small-subheader {
        font-size: 1.23rem;
        width: 315px;
        line-height: 31px;
    }
}

@media (max-width: 980px) {
	.notFound-content-text .submega-encabezado {
        font-size: 2.8rem;
        width: 335px;
        line-height: 60px;
    }
    
    .notFound-content-text .small-subheader {
        font-size: 1.1rem;
        width: 270px;
        line-height: 23px;
    }
}

@media (max-width: 930px) {
    .notFound-content {
        display: flex;
        flex-direction: column-reverse;
        padding: 4rem;
        height: 100%;
        justify-content: center;
    }
    .notFound-img > img {
        height: auto;
        width: 100%
    }
    .notFound-content-text .submega-encabezado {
        font-size: 2.8rem;
        width: 335px;
        line-height: 60px;
    }
    
    .notFound-content-text .small-subheader {
        font-size: 1.1rem;
        width: 270px;
        line-height: 23px;
    }
}

@media (max-width: 630px) {
    .notFound-content {
        padding: 2rem;
    }
}

@media (max-width: 446px) {
    .notFound-content-text .submega-encabezado {
        font-size: 2.5rem;
        width: auto;
        line-height: 55px;
    }
    
    .notFound-content-text .small-subheader {
        font-size: 1.1rem;
        width: 270px;
        line-height: 23px;
    }
}

@media (max-width: 400px) {
    .notFound-content-text .submega-encabezado {
        font-size: 2.3rem;
        line-height: 50px;
    }
    .notFound-content-text .small-subheader {
        width: auto;
    }
}