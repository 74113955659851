.modalLateral-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.modalLateralcontainer-body{
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100vh - 235px);
}
.modalLateralcontainer-first {
    display: flex;
    gap: 15px;
    flex-direction: column;
    width: 100%;
    padding: 30px 0px;
    border-top: 1px solid var(--gray-4);
}

.modalLateralcontainer-firt-column {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.modalLateralcontainer-firt-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modalLateralcontainer-firt-row-header {
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}

.modalLateralcontainer-firt--img {
    width: 100%;
    min-width: 30.227px;
    max-width: 30.227px;
    height: 30px;
    border-radius: 999px;
    overflow: hidden;
}

.modalLateralcontainer-firt-img {
    width: 30.227px;
    height: 30px;
}

.modalLateralcontainer-firt-row-header-detail {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-right: 0.5rem;
}

.modalLateralcontainer-firt-row-header-detail .rating,
.modalLateralcontainer-firt-row-header-detail .city-country-display {
    border-radius: 6px;
}

.modalLateralcontainer-firt-row-header-detail .rating span,
.modalLateralcontainer-firt-row-header-detail .city-country-display span {
    font-size: 0.75rem;
}

.modalLateralcontainer-firt-row-header-detail .city-country-display 
.city-country--image img {
    width: 20px;
    height: 11.5px;
}

.modalLateralcontainer-firt-title {
    padding-bottom: 15px;
}

.modalLateralcontainer-bottom {
    /* height: 100%; */
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-top: 10px;
    gap: 30px;
}

.modalLateralcontainer-bottom_column {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.modalLateralcontainer-firt-row_ {
    align-items: center;
    gap: 5px;
}

.modalLateralcontainer-bottom .btn-normal {
    display: flex;
    height: 50px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 8.181px;
    flex-shrink: 0;
    border-radius: 12px;
}