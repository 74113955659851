.profile-detail {
    flex-direction: column;
    display: flex;
    width: 100%;
    /* max-width: 400px; */
    max-width: 100%
}

.profile-detail-content .useractive {
    width: 120px;
    height: 100px;
    max-width: 120px;
    min-width: 100px;
}

.profile-detail-content {
    flex-direction: row;
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
}
.profile-detail__row {
    flex-direction: row;
    align-items: center;
    display: flex;
    gap: 15px;
    width: 100%;
    justify-content: space-between;
}
.profile-detail-content
.useractive img {
	display: flex;
}
.profile-detail-content
.useractive img {
    width: 100%;
    height: 100px;
	border-radius: 70px;
    object-fit: cover;
}
.profile-detail-content
.useractive-logged {
	left: 80%;
    top: 80%;
    width: 14px;
	height: 14px;
}
.profile-detail-list {
    position: relative;

    flex-direction: column;
    align-self: center;
    display: flex;
    gap: 5px;
    width: 600px;
}
.profile-detail-name {
    width: 100%; 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    color: #333333;
}

.profile-detail-name > div > img {
    width: 18px;
    display: flex;
    cursor: pointer;
}

.profile-detail-list .userVerify-container {
    top: 1.5rem;
    left: 7rem;
}

.profile-detail-business, 
.profile-detail-nickname {
    color: var(--gray-2);
}
.profile-detail-nickname-request {
    color: var(--red);
}
.profile-detail-name,
.profile-detail-business, 
.profile-detail-nickname,
.profile-detail-nickname-request {
    max-width: 600px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
}

.profile-detail-icon {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.profile-detail-buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

@media (max-width: 1184px) { 
    .profile-detail-list {
        width: 500px;
    }
}

@media (max-width: 955px) { 
    .profile-detail-list {
        width: 415px;
    }
}

/* @media (max-width: 858px) { 
    .profile-detail-list {
        width: 415px !important;
    }
} */

@media (max-width: 794px) { 
    .profile-detail-list {
        width: 380px;
    }
}

@media (max-width: 768px) { 
    .profile-detail-content .useractive {
        width: 137px;
        height: 100px;
    }
    .profile-detail {
        width: 100%;
    }
    .profile-detail-content {
        width: 100%;
    }
    .profile-detail-list {
        width: 450px;
        gap: 10px;
    }
}

@media (max-width: 649px) { 
    .profile-detail-list {
        width: 400px;
    }
}

@media (max-width: 599px) { 
    .profile-detail-list {
        width: 350px;
    }
    .profile-detail-list .userVerify-container {
        top: 1.5rem;
        left: -4rem;
    }
}

@media (max-width: 548px) { 
    .profile-detail-list {
        width: 300px;
    }
}

@media (max-width: 500px) { 
    .profile-detail-list {
        width: 280px;
    }
}

/*@media (max-width: 1184px) {
    .profile-detail {
        max-width: 324px;
    }
    .profile-detail-content
    .useractive img {
        width: 90px;
        height: 90px;
        border-radius: 70px;
    }
    .profile-detail-content
    .useractive-logged {
        left: 77%;
        top: 86%;
        width: 14px;
        height: 14px;
    }
    .profile-detail-name {
        font-size: 1rem;
    }
    .profile-detail-business, 
    .profile-detail-nickname,
    .profile-detail-nickname-request {
        font-size: 0.9rem;
    }
    .profile-detail-content {
        gap: 15px;
        max-width: 324px;
    }
    .profile-detail-list {
        gap: 1px
    }
}

@media (max-width: 986px) { 
    .profile-detail-content {
        gap: 8px
    }
    .profile-detail-list {
        width: 180px;
    }
}

@media (max-width: 766px) { 
    .profile-detail-name,
    .profile-detail-business, 
    .profile-detail-nickname,
    .profile-detail-nickname-request {
        max-width: 400px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
    }
}
@media (max-width: 480px) {
    .profile-detail-content {
        max-width: 409px;
    }
    .profile-detail-content
    .useractive-logged {
        left: 83%;
        top: 77%;
        width: 14px;
        height: 14px;
    }
    .profile-detail-list {
        width: 100%;
    }
    .profile-detail-name,
    .profile-detail-business, 
    .profile-detail-nickname,
    .profile-detail-nickname-request {
        max-width: 250px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
    }
}
@media (max-width: 468px) { 
    .profile-detail-icon {
        gap: 20px
    }
}
@media (max-width: 425px) { 
    .profile-detail-name,
    .profile-detail-business, 
    .profile-detail-nickname,
    .profile-detail-nickname-request {
        max-width: 220px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
    }
}
@media (max-width: 375px) {
    .profile-detail-content
    .useractive-logged {
        left: 83%;
        top: 75%;    
    }
}
@media (max-width: 369px) { 
    .profile-detail-content {
        gap: 10px;
    }
    .profile-detail-content
    .useractive-logged {
        left: 83%;
        top: 79%;    
    }
    .profile-detail-name,
    .profile-detail-business, 
    .profile-detail-nickname,
    .profile-detail-nickname-request {
        max-width: 200px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
    }
}
@media (max-width: 338px) { 
    .profile-detail-name,
    .profile-detail-business, 
    .profile-detail-nickname,
    .profile-detail-nickname-request {
        max-width: 150px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
    }
}*/

