.merchandiseValue {
    display: flex;
    flex-direction: column;
    gap: 20px;
   
}

.merchandiseValue-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    width: 100%;
}

.merchandiseValue-container_ {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
}

.merchandiseValue-value {
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.merchandiseValue-incoterm {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.merchandiseValue-link {
    width: 100%;
}

.merchandiseValue-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.merchandiseValue-row__ {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.merchandiseValue-row__  div {
    display: flex;
}

.merchandiseValue-row .hover .hover-text {
    width: 260px;
    bottom: 0px;
    top: 30px;
    height: fit-content;
    right: 0rem;
}

.merchandiseValue--icon img{
    display: flex;
    width: 20px;
    height: 20px;
}

.merchandiseValue-container .dropdown {
    max-width: 110px;
}


.merchandiseValue-container .dropdown .dropdown-select .select-title {
    transform: translate(20px, 50%);
}

.merchandiseValue-container .dropdown .dropdown-select .move-text {
    max-width: 160px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.merchandiseValue-container .textfield input:not(:placeholder-shown).textfield-input:not(:focus) {
    padding: 22.5px 10px 7.5px 20px;
}

.merchandiseValue-container .textFieldMoney input,
.merchandiseValue-container .textFieldMoney p {
	text-align: start;
}
.merchandiseValue-container .textFieldMoney p {
    color: var(--gray-1);
}

.merchandiseValue-container .inputElement-container {
    width: 100%;
}

.merchandiseValue-container_ .hover .hover-text {
    right: 2vh;
    top: 3vh;
    bottom: unset;
}

.merchandiseValue-courier .hover .hover-text{
    right: 0vh;
    left: -9vh;
}

.merchandiseValue-incoterm .merchandiseValue-insurance,
.merchandiseValue-incoterm .merchandiseValue-insurance .insurance-container {
    width: 100%;
}

.merchandiseValue-insurance_ {
    display: none;
}

@media (max-width: 1019px) {
    .merchandiseValue-value {
        width: 80%;
    }
    .merchandiseValue-incoterm {
        width: 20%;
    }
    .merchandiseValue-incoterm .merchandiseValue-insurance {
        display: none;
    }
    .merchandiseValue-container .dropdown {
        max-width: 170px;
    }
    .merchandiseValue-insurance_ {
        display: flex;
    }
    .merchandiseValue-insurance_ .insurance-container {
        width: 100%;
    }
}

@media (max-width: 880px) {
    .merchandiseValue-courier .hover .hover-text{
        right: 0vh;
        left: -10vh;
    }
}

@media (max-width: 813px) {
    .merchandiseValue-courier .hover .hover-text{
        right: 0vh;
        left: -13vh;
    }
}

@media (max-width: 805px) {
    .merchandiseValue-row .hover .hover-text {
        width: 232px;
    }
}

@media (max-width: 772px) {
    .merchandiseValue-courier .hover .hover-text{
        right: 0vh;
        left: -15vh;
    }
}

@media (max-width: 729px) {
    .merchandiseValue-courier .hover .hover-text{
        right: 0vh;
        left: -19vh;
    }
}

@media (max-width: 768px) {

    .merchandiseValue-container {
        flex-direction: row;
        gap: 20px;
    }
    
    .merchandiseValue-row {
        width: 55%;
    }

    .merchandiseValue-container_ {
        width: 100%;
    }

    .merchandiseValue-container .inputElement-container {
        width: 100%;
    }
}

@media (max-width: 719px) {
    .merchandiseValue-courier .hover .hover-text{
        right: 0vh;
        left: 1vh;
    }
    .merchandiseValue-incoterm {
        width: 30%;
    }
    .merchandiseValue-row .hover .hover-text {
        width: 200px;
        /* height: 8rem; */
    }
}

@media (max-width: 637px) {
    /* .merchandiseValue-container_ .inputElement-container .inputElement {
        width: 11rem;
    } */
    .merchandiseValue-container .dropdown {
        max-width: 140px;
    }
    .merchandiseValue-value {
        width: 89%;
    }
}

@media (max-width: 636px) {
    .merchandiseValue-row .hover .hover-text {
        width: 182px;
        /* height: 9rem; */
    }
}

@media (max-width: 600px) {
    .merchandiseValue-container {
        flex-direction: column;
    }
    .merchandiseValue-container_ .inputElement-container .inputElement{
        width: 20rem;
    }
    .merchandiseValue-container .merchandiseValue-row {
        width: 100%;
    }
    .merchandiseValue-row .hover .hover-text {
        /* bottom: 30px; */
        width: 350px;
        height: fit-content;
        /* top: -96px; */
    }
    .merchandiseValue-row__ {
        font-size: 0.875rem;
    }
    .merchandiseValue-container .dropdown .dropdown-select .move-text {
        left: 0px;
    }
    .merchandiseValue-container {
        gap: 15px
    }
    .merchandiseValue-container .inputElement-container {
        width: 63%;
    }
    .merchandiseValue-container .dropdown {
        max-width: 140px;
    }
}

@media (max-width: 580px) {
    .merchandiseValue-value {
        width: 72%;
    }
}

@media (max-width: 517px) {
    .merchandiseValue-container_ .inputElement-container .inputElement{
        width: 17rem;
    }
}

@media (max-width: 464px) {
    .modalProcessService-content {
        padding: 0px 5px;
    }
    .merchandiseValue-container_ .inputElement-container .inputElement{
        width: 14rem;
    }
    .merchandiseValue-container_ {
        gap: 5px
    }
    .merchandiseValue-container .dropdown .dropdown-select .select-title {
        transform: translate(10px, 50%);
    }
    .merchandiseValue-container .dropdown .dropdown-select {
        padding: 10px;
        border-radius: 12px;
    }
}

@media (max-width: 440px) {
    .merchandiseValue-container_ .inputElement-container .inputElement .inputElement-focus {
        max-width: 150px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .merchandiseValue-container .dropdown .dropdown-select .move-text {
        left: 10px;
        max-width: 120px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media (max-width: 418px) {
    .merchandiseValue-container_ .inputElement-container .inputElement{
        width: 12rem;
    }
}

@media (max-width: 403px) {
    .merchandiseValue-row .hover .hover-text {
        /* bottom: 30px; */
        width: 280px;
        height: fit-content;
        /* top: -108px; */
    }
}

@media (max-width: 387px) {
    .merchandiseValue-container_ .inputElement-container .inputElement{
        width: 11rem;
    }
}

@media (max-width: 365px) {
    .merchandiseValue-container_ .inputElement-container .inputElement{
        width: 10rem;
    }
    .merchandiseValue-container_ .inputElement-container .inputElement .inputElement-focus {
        max-width: 130px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .merchandiseValue-container .dropdown .dropdown-select .move-text {
        left: 10px;
        max-width: 100px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media (max-width: 345px) {
    .merchandiseValue-container_ .inputElement-container .inputElement{
        width: 8rem;
    }
    .merchandiseValue-container_ .inputElement-container .inputElement .inputElement-focus {
        max-width: 100px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .merchandiseValue-container .dropdown .dropdown-select .move-text {
        left: 10px;
        max-width: 100px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media (max-width: 332px) {
    .merchandiseValue-row .hover .hover-text {
        bottom: 0px;
        width: 228px;
        height: 6.5rem;
        top: 29px;
    }
}