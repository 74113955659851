.selectCard {
    width: 100%;
    /* max-width: 800px; */
    max-width: 93rem;
    padding: 0px 0.5rem 10px 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    /* overflow-x: scroll; */
    flex-wrap: wrap;
}

.selectCard::-webkit-scrollbar {
   background: #F3F3F3;;
   border-radius: 24px;
   height: 5px;
}

.selectCard::-webkit-scrollbar-thumb {
    background: var(--black-75);
    border-radius: 24px;
 }

.selectCard-container {
    width: 100%;
    min-width: 180px;
    /* max-width: 180px; */
    max-width: 280px;
    /* height: 100%; */
    height: auto;
    background: #F3F3F3;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 24px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.selectCard-container-active {
    width: 100%;
    min-width: 180px;
    height: 180px;
    background: var(--white);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 24px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

.selectCard-header-img {
    display: flex;
    width: 180px;
    height: 180px;
}

.selectCard-header-img img {
    width: 100%;
    height: 100%;
}

.selectCard-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding: 15px;
}

.selectCard-header-title .checkcircle-container .checkcircle-container label::before {
    border: 0px solid var(--gray-3);
    background: #D9D9D9;
}

.selectCard-header-title .checkcircle-container .checkcircle-container label .checkcircle-check {
    pointer-events: none;
    position: absolute;
    left: calc(54% - 5.5px);
    background: var(--green-2);
    height: 9px;
    width: 9px;
    border-radius: 24px;
}

.ModalRegisterNew-body:has(.selectCard) {
    width: 100%;
}

.selectCard-check {
    min-width: 20px;
    min-height: 20px;
    height: 20px;
    width: 20px;
    background: #D9D9D9;
    border-radius: 50px;
    cursor: pointer;
}

.selectCard-check-active {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    cursor: pointer;
}

.selectCard-check-active img {
    height: 20px;
    width: 20px;
    cursor: pointer;
}

@media (max-width: 1075px) { 
    .selectCard {
        /* justify-content: flex-start; */
        justify-content: center;
    }
}

@media (max-width: 695px) { 
    .selectCard-container {
        max-width: 250px;
    }
}

@media (max-width: 600px) { 
    .selectCard {
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
        align-items: center;
        max-height: 400px;
        width: 100%;
    }
    .selectCard-container {
        display: flex;
        flex-direction: row;
        height: 60px;
        min-height: 60px;
        border-radius: 12px;
        width: 100%;
        max-width: 330px;
    }
    .selectCard-header-img {
        height: 60px;
        width: 60px;
    }
    .selectCard-header-img img {
        height: 60px;
        width: 60px;
        object-fit: cover;
    }
    .selectCard-check,
    .selectCard-check-active img,
    .selectCard-check-active {
        height: 15px;
        width: 15px;
        min-width: 15px;
        min-height: 15px;
    }
  }