/*Modal notificaciones*/
.panel-content-notification {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.panel-content-notification-header {
    display: flex;
    flex-direction: row;
    gap: 5px
}

.panel-content-notification-header img {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
}

.panel-content-notification-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 0rem 0.5rem;    
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .navbar-root div .modal-notification--main .modal-notification-container 
    .modal-notificationes-content .card-notification,
    .navbar-root div .modal-notification--main .modal-notification-container 
    .modal-notificationes-content .card-notification-new {
        max-width: 98.5%;
    }
}

@media (max-width: 669px) {
    .navbar-root div .modal-notification--main .modal-notification-container 
    .modal-notificationes-content .card-notification,
    .navbar-root div .modal-notification--main .modal-notification-container 
    .modal-notificationes-content .card-notification-new {
        max-width: 97.5%;
    }
}

@media (max-width: 437px) {
    .navbar-root div .modal-notification--main .modal-notification-container 
    .modal-notificationes-content .card-notification,
    .navbar-root div .modal-notification--main .modal-notification-container 
    .modal-notificationes-content .card-notification-new {
        max-width: 96.5%;
    }
}