.card-content .importation-substructure-consignee_dropdown {
	width: 100%;
}

.card-content  .importation-substructure-consignee_dropdown .dropdown {
	width: 100%;
	max-width: 100%;
}

.importation-substructure-consignee_dropdown .dropdown .dropdown-select {
	max-width: 100% !important;
}

.card-content .dropdown {
	max-width: 220px;
    width: 100%;
}

.card-content .dropdown .dropdown-select {
    /* max-width: 350px;
    width: 100%; */
	padding: 20px;
}

.card-content .dropdown .dropdown-select .move-text {
    left: 0px;
}

.card-content .dropdown .dropdown-list {
	width: 100%;
    min-width: 100%;
}

.importation-substructure-consignee .dropdown .dropdown-list .dropdown-list__item  {
	width: 100%;
	max-width: 100%;
	padding: 20px;
}

.importation-substructure-consignee .dropdown .dropdown-list .dropdown-list__item:hover {
	border-radius: 24px;
}

.importation-substructure-consignee-inputs {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 30px;
}

.importation-substructure-consignee-inputs .dropdown {
	width: 100%;
	max-width: 100%;
}

.importation-substructure-consignee .textfield {
	width: 100%;
}

.importation-substructure-consignee p {
	color: var(--gray-1);
	margin: 0;
}

.importation-substructure-consignee-btn {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

}

.importation-substructur--error {
	color: var(--red);
}

.importation-substructure-consignee .importation-substructure-consignee-inputs 
.textfield input {
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
}

/* .importation-substructure-consignee-btn .custom-button {
	font-size: 1.125rem;
} */

.importation-substructure-consignee-btn .custom-button-icon {
	box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.15);
	background-color: var(--transparente);
}

.importation-substructure-consignee-inputs .textfield-input  {
	background: var(--white);
}

@media (max-width: 968px) {
	.importation-substructure-consignee-first {
		gap: 15px
	}
	.importation-substructure-consignee-inputs {
		gap: 10px;
	}
	.importation-substructure-consignee-inputs .textfield .paragraph {
		font-size: 12px;
	}
	.importation-substructure-consignee-btn .custom-button {
		font-size: 0.9rem;
		padding: 10px 30px;
	}
}

@media (max-width: 880px) {
	.importation-substructure-consignee-inputs {
		flex-direction: column;
	}
	.importation-substructure-consignee-first .paragraph-header,
	.importation-substructure-consignee-first .paragraph {
		font-size: 0.9rem;
	}
	.importation-substructure-consignee-first {
		gap: 10px
	}
	.importation-substructure-consignee_dropdown .dropdown .dropdown-select 
	.paragraph-header {
		font-size: 0.9rem;
	}
	.importation-substructure-consignee-inputs .textfield 
	.paragraph {
		font-size: 0.9rem !important;
	}
	.importation-substructure-consignee-inputs .textfield 
	.textfield-input {
		font-size: 0.9rem !important;
	}
	.importation-substructure-consignee-inputs .dropdown{
		width: 100% !important
	}
}

@media (max-width: 700px) {
	.importation-substructure-consignee-inputs {
		flex-direction: row;
	}
	.importation-substructure-consignee-first {
		border-radius: 12px;
	}
}

@media (max-width: 600px) {
	.importation-substructure-consignee-inputs {
		flex-direction: column;
		gap: 10px;
	}
}

@media (max-width: 492px) {
	.importation-substructure-consignee-btn {
		display: flex;
		gap: 20px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
	.importation-substructure-consignee-btn .custom-button {
		font-size: 0.9rem;
		padding: 10px 60px;
	}
}

@media (max-width: 440px) {
	.importation-substructure-consignee .dropdown .dropdown-select .move-text {
		font-size: 0.85rem;
	}
}

@media (max-width: 425px) {
	.importation-substructure-consignee-first {
		padding: 10px;
	}
}

@media (max-width: 379px) {
	.importation-substructure-consignee-btn .custom-button {
		padding: 10px;
		width: 100%;
	}
}