.sectionAnimation {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 60px;
    justify-content: center;
    max-width: 1500px;
    padding: 60px 0px;
}

.sectionAnimation-reverse {
    flex-direction: row-reverse;
}

.sectionAnimation-title {
    font-size: 1.938rem;
}

.sectionAnimation-title,
.sectionAnimation-description,
.sectionAnimation-item,
.sectionAnimation-description2 {
    color: var(--white);
}

.sectionAnimation-description {
    font-style: italic;
}

.sectionAnimation-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sectionAnimation-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.sectionAnimation-list-item .cardHoverExpand-img {
    width: 20px;
    height: 20px;
}

.sectionAnimation-left {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    max-width: 330px;
}

.sectionAnimation-left-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sectionAnimation-btn {
    width: 198px;
    height: 58px;
    border-radius: 12px;
    padding: 15px 20px;
    cursor: pointer;
    display: flex;
    background: linear-gradient(94deg, #F7E246 0%, #FFC525 73.97%);

    backdrop-filter: blur(20px);
    color: #131313;

    font-size: 1.375rem;
    font-family: 'Faktum';
    gap: 5px;
    justify-content: center;
}

.sectionAnimation-btn-resalt {
    font-size: 1.375rem;
    font-family: 'Faktum-Bold';
}

.sectionAnimation-right {
    width: 810px;
    display: flex;
}

.sectionAnimation-img {
    width: 100%;
    height: 505px;
    max-width: 810px;
}
.sectionAnimation-left .btnIcon-extra-small {
    display: flex;
    width: 229px;
    height: 54px;
    border-radius: 90px;
    background-color: var(--gray-1);
    font-size: 1.125rem !important;
    font-family: 'Faktum-SemiBold';
}

@media (max-width: 1274px) {
    .sectionAnimation-reverse {
        gap: 40px;
    }
    .sectionAnimation-right {
        width: 620px;
    }
    .sectionAnimation-img {
        height: 445px;
    }
    .sectionAnimation-left {
        gap: 20px
    }
}

@media (max-width: 1194px) {
    .sectionAnimation {
        padding: 40px 0px;
    }
    .sectionAnimation-left {
        max-width: 310px;
    }

    .sectionAnimation-title {
        font-size: 1.9rem;
    }
}

@media (max-width: 1045px) {
    .sectionAnimation {
        display: flex;
        flex-direction: column-reverse;
    }
    .sectionAnimation-right,
    .sectionAnimation-left {
        width: 100%;
        max-width: 100%;
    }
    .sectionAnimation-img {
        height: auto;
        max-width: 100%;
    }
    .sectionAnimation-btn {
        width: 167px;
        height: 45px;
        align-self: center;
        font-size: 1.125rem;
        color: var(--black);
        padding: 11px 20px;
    }
    .sectionAnimation-btn-resalt {
        font-size: 1.125rem;
    }
}

@media (max-width: 665px) { 
    .sectionAnimation {
        gap: 30px;
        padding: 30px 0px
    }
    .sectionAnimation-title {
        font-size: 1.125rem;
        line-height: 20px;
    }
    .sectionAnimation-description,
    .sectionAnimation-item,
    .sectionAnimation-description2 {
        font-size: 0.875rem;
    }
    .sectionAnimation-left,
    .sectionAnimation-list {
        gap: 10px
    }
    .sectionAnimation-btn {
        margin-top: 2rem;
    }
}