.sectionNavbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sectionNavbar-left {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.sectionNavbar-right-arrow {
    height: 15px;
}

.sectionNavbar-icon-content {
    width: 20px;
}

.sectionNavbar-icon {
    width: 20px;
    height: 20px;
}

.sectionNavbar div {
    display: flex;
}