.modalAddEvent .modal-main{
    /*z-index: 6;*/
}
.modalAddEvent-container {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.modalAddEvent-row_ {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.modalAddEvent-row {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.modalAddEvent-add-person {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: var(--blue-2);
    cursor: pointer;
}
.modalAddEvent-add-person-title {
    color: var(--gray-2);
}
.modalAddEvent-content-list-add-person__ {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    max-height: 220px;
    overflow-y: scroll;
}
.modalAddEvent-content-list-add-person {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.modalAddEvent-service--box {
    width: 30px;
    height: 30px;
    border: 2px solid var(--black-15);
    border-radius: 8px;
}
.modalAddEvent-list-add-person .profile-detail
.profile-detail-content {
    height: 45px;
    gap: 10px
}
.modalAddEvent-list-add-person .profile-detail
.profile-detail-content .useractive img{
    height: 45px;
    width: 45px;
}
.modalAddEvent-list-add-person .profile-detail
.profile-detail-content .useractive .useractive-logged {
    height: 10px;
    width: 10px;
}
.modalAddEvent-list-add-person .profile-detail
.profile-detail-content .profile-detail-list {
    gap: 0px
}
.modalAddEvent-list-add-person {
    display: flex;
    flex-direction: column;
    gap: 10px
}
.modalAddEvent-textField {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.modalAddEvent-icon {
    display: flex;
    width: 27px;
    height: 30px;
}

.modalAddEvent-calendar-container {
    max-width: 298px;
    width: 100%;
    max-height: 45px;
    height: 100%;
    position: relative;
    display: flex;

}

/* personalizacion del calendario*/
.modaAddEvent-calendar {
    position: absolute;
    max-width: 320px;
    width: 100%;
    background: var(--white);
    backdrop-filter: 0px;
    z-index: 10;
}

/* sobre escrimiendo componente calendar */ 
.modaAddEvent-calendar .calendar-container {
    position: relative;
    padding: 0px;
    padding-bottom: 10px;
    max-width: 330px;
    width: 100%;
    max-height: 260px;
    height: 100%;
    background: var(--white);
   
    border-radius: 24px;
}

.modaAddEvent-calendar .calendar-container .calendar-title{
    padding: 10px 30px;
    background: var(--black-05);
    border-radius: 24px;
}

.modaAddEvent-calendar .calendar-container .calendarRow-container {
    width: 100%;
    padding: 0px 5px;
}

.modaAddEvent-calendar .calendar-container .calendarRow-cell {
    height: 25px;
    width: 75%;
}

.modaAddEvent-calendar .calendar-container .calendarRow-cell .paragraph{
    font-size: 14px;
}

.modaAddEvent-calendar .calendar-container .calendarRow-cell .calendarRow-DaySelected, 
.modaAddEvent-calendar .calendar-container .calendarRow-cell .calendarRow-today {
    height: 30px;
    width: 30px;
    background: var(--black-50);
}
.modalAddEvent-button {
    justify-content: flex-end;
}

.modalAddEvent .modal-container {
    max-width: 700px;
    background: var(--white);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    width: 100%;
}

.modalAddEvent-row .custom-button-icon {
    max-width: 298px;
    padding: 15px 20px;
    width: 100%;
    max-height: 45px;
    height: 100%;
    font-family: 'Faktum';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--black-50);
    justify-content: space-between;
    border-radius: 12px;
}

.modalAddEvent .modalAddEvent-container
.modalAddEvent-row_ .modalAddEvent-textField
.modal-container .textfield {
    max-width: 127px;
    width: 100%;
}

.modalAddEvent .modalAddEvent-container
.modalAddEvent-row_ .modalAddEvent-textField
.modal-container .textfield .textfield-input{
    background-color: var(--black-05);
}

.modalAddEvent-row .textarea {
    max-width: 640px;
    width: 100%;
    max-height: 100px;
    height: 100%;
}

.modalAddEvent-row .textarea textarea {
    background-color: var(--black-05);
}

.modalAddEvent-row .textarea .textarea-title {
    color: var(--black-50)
}

.modalAddEvent-content-list-add-person__ 
.modalAddEvent-content-list-add-person .profile-detail 
.profile-detail-content .profile-detail__row .profile-detail-list 
.profile-detail-name {
    font-family: 'Faktum';
	font-style: normal;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 18px;
	color: var(--black);
}
.modalAddEvent-content-list-add-person__ 
.modalAddEvent-content-list-add-person .profile-detail 
.profile-detail-content .profile-detail__row .profile-detail-list 
.profile-detail-nickname {
    font-family: 'Faktum';
	font-style: normal;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 18px;
	color: var(--gray-3);
}
.modalAddEvent-content-list-add-person__ 
.modalAddEvent-content-list-add-person .profile-detail 
.profile-detail-content .profile-detail__row .profile-detail-list 
.profile-detail-nickname-request {
    font-family: 'Faktum';
	font-style: normal;
	font-weight: 400;
	font-size: 0.75rem;
}

@media (max-width: 574px) { 
    .modalAddEvent-row_ {
        flex-direction: column;
        width: 100%;
    }
    .modalAddEvent-textField { 
        flex-direction: column;
        width: 100%;
    }
    .modalAddEvent-textField .textfield,
    .modalAddEvent-row .modalAddEvent-calendar-container,
    .modalAddEvent-row .modalAddEvent-calendar-container
    .custom-button-icon { 
        max-width: 100%;
    }
}
@media (max-width: 368px) { 
    .modalAddEvent-button {
        display: flex;
        gap: 10px;
        flex-direction: column;
    }
    .modalAddEvent-button .btn-large{
        min-width: 100%;
    }
}