.cardTypeService-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    /* height: 500px; */
    /* padding-bottom: 40px; */
    overflow: hidden;
    /* gap: 60px; */
    gap: 40px;
}

.cardTypeService-header-title {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.cardTypeService-card {
    cursor: pointer;
    border-radius: 26px;
    padding: 30px;;
    width: 390px;
    height: 317px;
    display: flex;
    gap: 30px; 
    flex-direction: column;
    opacity: 1;
    backdrop-filter: blur(20px);
    background-image: linear-gradient(to bottom, #ffffff, #fcfcfc, #f9f9f9, #f7f7f7, #f4f4f4);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

.cardTypeService-card-active {
    cursor: pointer;
    border-radius: 26px;
    padding: 30px;;
    width: 390px;
    display: flex;
    gap: 30px; 
    flex-direction: column;
    height: 317px;
    opacity: 1;
    -webkit-box-shadow: 0 8px 32px 0 rgb(31 38 135 / 10%);
    -moz-box-shadow: 0 8px 32px 0 rgb(31 38 135 / 10%);
    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 10%);

    /* background: rgb(255 255 255 / 39%);
    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 10%); */
    backdrop-filter: blur(20px);
    background-color: transparent;
    background-image: linear-gradient(to right, rgba(52, 168, 83), rgba(0, 164, 215), rgba(17, 61, 127)), 
                      linear-gradient(to right, rgba(52, 168, 83), rgba(0, 164, 215), rgba(17, 61, 127));
    background-size: 100% 3px;
    background-position: 0 100%, 0 0;
    background-repeat: no-repeat;
    background-clip: border-box;
    &:before,
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            bottom: 0;
            width: 21px;
            border: 3px solid #34a858;
            border-right: none;
            border-radius: 30px 0 0 30px;
        }
        
        &:after {
            border: 3px solid #114081;
            border-left: none;
            left: auto;
            right: 0;
            border-radius: 0 30px 30px 0;
        }
}

.caruselComment-card-active::before,
.caruselComment-card-active::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    width: 21px;
    border: 3px solid #34a858;
    border-right: none;
    border-radius: 30px 0 0 30px;
}

.caruselComment-card-active::after {
    border: 3px solid #114081;
    border-left: none;
    left: auto;
    right: 0;
    border-radius: 0 30px 30px 0;
}

.cardTypeService-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cardTypeService-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.cardTypeService-right {
    align-items: center;
    display: flex;
}

.cardTypeService-row {
    display: flex;
    flex-direction: row;
    padding: 1rem 0rem;
    gap: 20px;
    width: 100%;
    justify-content: center;
}

.cardTypeService-img {
    border-radius: 30px;
    height: 45px;
    width: 45px;
    align-items: center;
    display: flex;
    justify-content: center;
    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 10%);
} 

.cardTypeService-logoMin {
    height: 24px;
    width: 24px;
}

.selectServiceType-text-filter {
    font-family: 'Faktum-Medium';
}

.cardTypeService-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.cardTypeService-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.cardTypeService-text {
    color: var(--gray-1);
    font-size: 1rem;
}

.cardTypeService-scroll {
    overflow: hidden;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardTypeService-scroll .caruselComment-phaseTwo {
    display: none;
}

.cardTypeService-container .btn-small {
    min-width: 300px;
    background-color: var(--black) !important;
    margin-bottom: 1.5rem;
}
.order-modalSiatuation .modal-container {
    max-width: 60rem;
}

@media (max-width: 1285px) {
    .cardTypeService-row {
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 100%;
        justify-content: flex-start;
        display: flex;
        width: 100%;
        /* height: 100vh; */
        scroll-snap-type: x mandatory;
        overflow-x: scroll;
        overflow-y: hidden;
        padding-left: 20px;
        padding-right: 10px;
        padding-top: 0.5rem;
        padding-bottom: 0.8rem;
        gap: 3rem;
    }
    .cardTypeService-card-active,
    .cardTypeService-card {
        height: 305px;
        width: 360px;
        min-width: 360px;
        gap: 20px;
    }
    .cardTypeService-body {
        gap: 20px;
    }
    .cardTypeService-items {
        gap: 10px
    }
}

@media (max-width: 1075px) { 
    .cardTypeService-row {
        padding-left: 10px
    }
}

@media (max-width: 600px) {
    .order-modalSiatuation .modal-container {
        max-width: 100%;
        width: 100%;
    }
    .cardTypeService-container {
        gap: 30px;
    }
    .cardTypeService-header-title {
        gap: 0px;
    }
    .cardTypeService-row::-webkit-scrollbar {
        display: none;
    }
    .cardTypeService-scroll .caruselComment-phaseTwo {
        display: flex;
    }
    .cardTypeService-container .btn-small {
        min-width: fit-content;
    }
    .cardTypeService-Title {
        font-size: 1.375rem !important;
        line-height: 53px;
    }
    .cardTypeService-text {
        font-size: 0.875rem;
    }
}

@media (max-width: 511px) {
    .cardTypeService-header-title {
        gap: 10px;
    }
    .cardTypeService-Title {
        font-size: 1.375rem !important;
        line-height: 35px;
    }
    .cardTypeService-text {
        font-size: 0.875rem !important;
    }
}

@media (max-width: 403px) {
    .caruselComment-content-responsive .caruselComment-card {
        flex: 0 0 100%;
        /* width: 100%;
        object-fit: cover; */
        scroll-snap-align: center;
        max-width: 22rem;
        border-radius: 30px;
        padding: 30px;
        /* min-width: 28rem; */
        display: flex;
        gap: 30px;
        flex-direction: column;
        opacity: 1;
        height: 378px;
        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
        background-color: transparent;
        background-image: linear-gradient(to right, rgba(52, 168, 83), rgba(0, 164, 215), rgba(17, 61, 127)), 
                        linear-gradient(to right, rgba(52, 168, 83), rgba(0, 164, 215), rgba(17, 61, 127));
        background-size: 100% 3px;
        background-position: 0 100%, 0 0;
        background-repeat: no-repeat;
        background-clip: border-box;
        &:before,
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                left: 0;
                top: 0;
                bottom: 0;
                width: 42px;
                border: 3px solid #34a858;
                border-right: none;
                border-radius: 30px 0 0 30px;
            }
            
            &:after {
                border: 3px solid #114081;
                border-left: none;
                left: auto;
                right: 0;
                border-radius: 0 30px 30px 0;
            }
    }
    .caruselComment-card-active::before,
    .caruselComment-card-active::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        bottom: 0;
        width: 42px;
        border: 3px solid #34a858;
        border-right: none;
        border-radius: 30px 0 0 30px;
    }

    .caruselComment-card-active::after {
        border: 3px solid #114081;
        border-left: none;
        left: auto;
        right: 0;
        border-radius: 0 30px 30px 0;
    }
}