  @font-face {
	font-family: "Faktum";
	src: url('Fonts/Faktum-Regular.ttf') format('truetype'), 
	  url('Fonts/Faktum-Regular.otf') format("opentype");
  }

  @font-face {
	font-family: "Faktum-Medium";
	src: url('Fonts/Faktum-Medium.ttf') format('truetype'), 
	  url('Fonts/Faktum-Medium.otf') format("opentype");
  }

   @font-face {
	font-family: "Faktum-SemiBold";
	src: url('Fonts/Faktum-SemiBold.ttf') format('truetype'), 
	  url('Fonts/Faktum-SemiBold.otf') format("opentype");
  }

  @font-face {
	font-family: "Faktum-Bold";
	src: url('Fonts/Faktum-Bold.ttf') format('truetype'), 
	  url('Fonts/Faktum-Bold.otf') format("opentype");
  }

  /* @font-face {
	font-family: "Faktum-Light";
	src: url('Fonts/Faktum-Light.otf') format('truetype'), 
	  url('Fonts/Faktum-Light.otf') format("opentype");
  } */

* {
	box-sizing: border-box;

	--white: #FFFFFF;

	--transparent: #00000000;

	/* Black */
	--black: #000000;
	--black-1: #1F1F1F;

	/* Grays */
	--gray-0: #181818;
	--gray-1: #333333;
	--gray-2: #4F4F4F;
	--gray-3: #828282;
	--gray-4: #BDBDBD;
	--gray-5: #E0E0E0;
	--gray-6: #F2F2F2;
	--gray-7: #FAFAFA;
	/* Whites */
	--white-90: rgba(255, 255, 255, 0.9);
	--white-75: rgba(255, 255, 255, 0.75);
	--white-50: rgba(255, 255, 255, 0.50);
	--white-25: rgba(255, 255, 255, 0.25);
	--white-15: rgba(255, 255, 255, 0.15);
	--white-05: rgba(255, 255, 255, 0.05);
	/* Blacks */
	--black-90: rgba(0, 0, 0, 0.9);
	--black-75: rgba(0, 0, 0, 0.75);
	--black-50: rgba(0, 0, 0, 0.50);
	--black-25: rgba(0, 0, 0, 0.25);
	--black-15: rgba(0, 0, 0, 0.15);
	--black-05: rgba(0, 0, 0, 0.05);
	/* Main Colors */
	--blue-2: #2D9CDB;
	--red: #EB5757;
	--yellow: #F7E246;
	--green-2: #27AE60;
	--online: #8BEA41;

	/*Calendario*/ 
	--square-size: 68px;
    --square-gap: 0px;
    --week-width: auto;
	--square-width: auto;
    --lightest: #ebedf0;
    --light: #c6e48b;
    --medium: #40c463;
    --dark: #30a14e;
    --darkest: #216e39;

	/* Glass Morph */
	--background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
	--border-image-source: linear-gradient(121.86deg, #FFFFFF 5.23%, rgba(255, 255, 255, 0) 41.9%);
}


.app {
	/* background-size: cover;
	*/
	min-height: 100vh;
    width: 100%; 
	height: -webkit-fill-available;

}

.admin-bgUser {
	background-image: url("bg-img-1.png");
	background-size: cover;
	min-height: 100%;
	height: 100vh;
    width: 100%;
}

.admin-bg {
	/* background-image: url("bg-img.png"); #181818*/
	background-color: var(--black);
	/* background-image: url("background.png"); */
	background-size: cover;
	min-height: 100vh;
    width: 100%;
}

.global-bg-loading {
	background-image: url("bg-img.png");	
}

body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.mega-header {
	font-family: 'Faktum-SemiBold';
	font-style: normal;
	font-size: 3.125rem;
	font-weight: 500;
	line-height: 63px;
	color: var(--black);
}

.submega-encabezado {
	font-family: 'Faktum';
	font-style: normal;
	font-size: 3.125rem;
	font-weight: 500;
	line-height: 63px;
	color: var(--black);
}

.big-header {
	font-family: 'Faktum-SemiBold';
	font-style: normal;
	font-weight: 500;
	font-size: 2.125rem;
	line-height: 43px;
	color: var(--black);
}

.header-grand-medium {
	font-family: 'Faktum';
	font-style: normal;
	font-weight: 400;
	font-size: 2.125rem;
	line-height: 43px;
	color: var(--black);
}

.big-subheader {
	font-family: 'Faktum-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 25px;
	color: var(--black);
}

.medium-header {
	font-family: 'Faktum-SemiBold';
	font-style: normal;
	font-weight: 500;
	font-size: 1.625rem;
	line-height: 33px;
	color: var(--black);
}

.header-medium {
	font-family: 'Faktum';
	font-style: normal;
	font-weight: 500;
	font-size: 1.625rem;
	line-height: 33px;
	color: var(--black);
}

/* .header-medium_ {
	font-family: 'Faktum-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 1.625rem;
	line-height: 33px;
	color: var(--black);
} */

.small-header {
	font-family: 'Faktum-SemiBold';
	font-style: normal;
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 28px;
	color: var(--black);
}

.small-header-medium {
	font-family: 'Faktum';
	font-style: normal;
	font-weight: 400;
	font-size: 1.375rem;
	line-height: 28px;
	color: var(--black);
}

.small-subheader {
	font-family: 'Faktum';
	font-style: normal;
	font-weight: 400;
	font-size: 1.25rem;
	line-height: 25px;
	color: var(--black);
}

.small-bold {
	font-family: 'Faktum-SemiBold';
	font-style: normal;
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 23px;
	color: var(--black);
}

.small-medium {
	font-family: 'Faktum-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 23px;
	color: var(--black);
}

.small-boldheader {
	font-family: 'Faktum-SemiBold';
	font-style: normal;
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 23px;
	color: var(--black);
}

.paragraph-header {
	font-family: 'Faktum-Medium';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 20px;
	color: var(--black);
}

.paragraph {
	font-family: 'Faktum';
	font-style: normal;
	font-weight: 300;
	font-size: 1rem;
	line-height: 20px;
	color: var(--black);
}

/* .light {
	font-family: 'Faktum-Light';
	font-style: normal;
	font-size: 1rem;
	font-weight: 400;
	line-height: normal;
	color: var(--black);
} */

.smalltext-header {
	font-family: 'Faktum-Medium';
	font-style: normal;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 18px;
	color: var(--black);
}

.smalltext {
	font-family: 'Faktum';
	font-style: normal;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 18px;
	color: var(--black);
}

.tinytext-header {
	font-family: 'Faktum-Medium';
	font-style: normal;
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 15px;
	color: var(--black);
}

.tinytext {
	font-family: 'Faktum';
	font-style: normal;
	font-weight: 450;
	font-size: 0.75rem;
	line-height: 15px;
	color: var(--black);
}

.text-error {
	width: fit-content;
    margin-top: 5px;
    color: var(--red);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.box-shadow-card {
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

.box-shadow-card::before {
	z-index: -1;
}

/* @media (max-width: 768px) {  
	.admin-bgUser {
		background-size: inherit; 
	}
} */