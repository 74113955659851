.lclcdAir-container {
    /* max-width: 800px; */
    max-width: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    /* border-radius: 24px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px); */
}

.lclcdAir-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.lclcdAir-header .checkbox-container input[type="checkbox"] {
	width: 39px;
    height: 39px;
}

.lclcdAir-header .checkbox-container label::before {
	width: 39px;
    height: 39px;
}

.lclcdAir-header .checkbox-container {
    border: 0px solid var(--gray-3);
    background: var(--black-05);
}

.lclcdAir-header--title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 5px; */
}

.lclcdAir-header--check {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
}

.lclcdAir-colorText {
    color: var(--gray-1);
}

.lclcdAir-header-mobile {
    display: none;
}

.lclcdAir-paquete-container {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 10px;
}

.lclcdAir-paquete--total {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
}

.lclcdAir-volumen {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.lclcdAir-volumen-col {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
}


.lclcdAir-volumen-col  .inputElement-container .inputElement input {
    width: 154px;
}

@media (max-width: 690px) {
    .lclcdAir-volumen {
        flex-direction: column;
        gap: 15px;
    }
    .lclcdAir-volumen .inputElement-container, 
    .lclcdAir-volumen .inputElement-container .inputElement input {
        max-width: 100%;
        width: 100%;
    }
    
    .lclcdAir-volumen-col--inputs .dropdown {
        max-width: 30%;
    }
}

@media (max-width: 600px) {
    .lclcdAir-colorText {
        font-size: 0.875rem;
    }
    .lclcdAir-colorText-- {
        font-size: 0.75rem;
    }
    .lclcdAir-paquete--total_send {
		font-size: 1rem;
		font-family: 'Faktum-Medium';
	}
}

@media (max-width: 400px) {
    /* .lclcdAir-header  {
        display: none;
    } */
    .lclcdAir-header-mobile {
        display: flex;
        flex-direction: column;
        gap: 10px
    }
    .lclcdAir-header--title-mobile {
        display: flex;
        flex-direction: row;
        gap: 15px;
		align-items: center;
    }
    .lclcdAir-header--title-mobile .checkbox-container {
		width: 25px;
		height: 25px;
	}
    .lclcdAir-paquete-colorBlack {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 200px;
	}
}