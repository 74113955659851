.UploadFileArray {
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(24px);
    padding: 20px;
    border-radius: 24px;
    background: var(--background);
    border: 1px solid;
    border-image-source: var(--border-image-source);
    max-width: 840px;
}

.UploadFileArray-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.UploadFileArray-container h1,
.UploadFileArray-container p {
    color: var(--gray-1);
}

.UploadFileArray-content-bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:6px
}

.UploadFileArray-multimedia {
    display: flex;
    flex-direction: row;
    gap: 6px;
    width: 100%;
    justify-content: flex-end;
}

.UploadFileArray-bottom {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    gap: 12px;
}

.UploadFileArray-bottom span {
    color: var(--gray-1);
}

.UploadFileArray-bottom .hover .hover-text {
    width: 250px;
}

.UploadFileArray h1,
.UploadFileArray p {
    margin: 0;
}

.UploadFileArray-card--icon {
    background: var(--white);
    width: 3rem;
    height: 3rem;
    object-fit: scale-down;
    cursor: pointer;
    border-radius: 8px;
}
@media (max-width: 700px) {
    .UploadFileArray {
        border-radius: 12px;
    }
}

@media (max-width: 880px) {
    .UploadFileArray-container {
        gap: 10px
    }
    .UploadFileArray-container .paragraph-header,
    .UploadFileArray-container .paragraph {
        font-size: 0.9rem;
    }   
}

@media (max-width: 550px) {
    .UploadFileArray-bottom {
        gap: 10px;
    }   
}

@media (max-width: 514px) {
    .UploadFileArray-multimedia {
        justify-content: flex-start;
    } 
}

@media (max-width: 447px) {
    .UploadFileArray-bottom .custom-button-icon {
        line-height: 10.3px;
    }
}

@media (max-width: 425px) {
	.UploadFileArray {
		padding: 10px;
	}
}

@media (max-width: 400px) {
    /* .UploadFileArray-bottom {
        flex-direction: column;
        align-items: unset;
    } */

    .UploadFileArray-bottom span {
        align-self: center;
    }
}