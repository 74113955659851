.hover-body {
	display: flex;
	flex-direction: column;
	gap: 5px
}

.hover-body-content {
	display: flex;
	flex-direction: row;
	gap:9px;
	align-items: center;
}

.hover-body-btn {
	border-radius: 24px;
	background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
	box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(20px);
	display: flex;
	padding: 6px 9px;
	align-items: center;
	gap: 10px;
    cursor: pointer;
}
