/* EStilo del body */
.itemGroupChat-card-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: auto;
    max-height: 120px;
    box-sizing: border-box;
    cursor: pointer;
    max-width: 350px;
    min-width: 83px;
}
/*Hover (efecto al pasar el mouse)*/
.itemGroupChat-card-container:hover {
    background: var(--black-05);
}

.itemGroupChat-card-active  {
    background: var(--black-05);
    box-sizing: border-box;
    cursor: pointer;
    max-width: 350px;
    min-width: 83px;
}

.itemGroupChat-card-textColor-title {
    color: var(--black-75);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 240px;
    min-width: 50px;
    width: 100%;
}
.itemGroupChat-card-textColor-business {
    color: var(--black-75);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 240px;
    min-width: 50px;
    /*width: 100%;*/
}
.itemGroupChat-card-option {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}
.itemGroupChat-card-textColor-msg {
    color: var(--black-75);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 240px;
    min-width: 50px;
    width: 100%;
}
.itemGroupChat-card-marker {
    width: 100%;
    max-width: 6px;
    min-width: 6px;
}
.itemGroupChat-card-body {
    padding: 5px 0px;
    align-self: center;
    flex-direction: column;
    display: flex;
    gap: 10px;
    width: 100%;
    padding: 20px 20px 20px 0px;
}
.itemGroupChat-clock {
    color: var(--black-50);
}
.itemGroupChat-card-body-title {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px
}
.itemGroupChat-card-marker__high {
    background: var(--red);
}

.itemGroupChat-card-marker__middle {
    background-color: var(--black-75);
    /* background-color: var(--yellow); */
}

.itemGroupChat-card-marker__low {
    background-color: var(--blue-2);
}
.itemGroupChat-group-chat {
    display: flex;
    justify-content: space-between;
}

/* @media (max-width: 1231px) { 
    .itemGroupChat-card-textColor-title,
    .itemGroupChat-card-textColor-business,
    .itemGroupChat-card-textColor-msg {
        max-width: 214px;
        min-width: 50px;
    }
} */

/* @media (max-width: 1204px) { 
    .itemGroupChat-card-textColor-title,
    .itemGroupChat-card-textColor-business,
    .itemGroupChat-card-textColor-msg {
        max-width: 220px;
        min-width: 50px;
    }
} */

@media (max-width: 1180px) { 
    .itemGroupChat-card-textColor-title,
    .itemGroupChat-card-textColor-business,
    .itemGroupChat-card-textColor-msg {
        max-width: 220px;
    }
}

@media (max-width: 1171px) { 
    .itemGroupChat-card-textColor-title,
    .itemGroupChat-card-textColor-business,
    .itemGroupChat-card-textColor-msg {
        max-width: 210px;
    }
}

@media (max-width: 1155px) { 
    .itemGroupChat-card-textColor-title,
    .itemGroupChat-card-textColor-business,
    .itemGroupChat-card-textColor-msg {
        max-width: 260px;
    }
}

@media (max-width: 1097px) { 
    .itemGroupChat-card-body {
        padding: 20px 13px 20px 0px;
    }
}
@media (max-width: 1008px) { 
    .itemGroupChat-card-textColor-title {
        font-size: 0.9rem;
    }
    .itemGroupChat-card-textColor-business,
    .itemGroupChat-card-textColor-msg {
        font-size: 0.8rem;
    }
}

@media (max-width: 988px) { 
    .itemGroupChat-card-textColor-title,
    .itemGroupChat-card-textColor-business,
    .itemGroupChat-card-textColor-msg {
        max-width: 250px;
    }
}

@media (max-width: 926px) { 
    .itemGroupChat-card-container {
        gap: 7px;
    }
}

@media (max-width: 923px) { 
    .itemGroupChat-card-textColor-title,
    .itemGroupChat-card-textColor-business,
    .itemGroupChat-card-textColor-msg {
        max-width: 245px;
    }
}

@media (max-width: 768px) { 
    .itemGroupChat-card-textColor-title,
    .itemGroupChat-card-textColor-business,
    .itemGroupChat-card-textColor-msg {
        max-width: 600px;
    } 
    .itemGroupChat-card-container {
        max-width: 100%;
    }
}