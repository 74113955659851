.import-timeline {
	display: flex;
	align-items: center;
}

.import-timeline-stage {
	background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
	padding: 5px 24px;
	border-radius: 24px;
	color: var(--gray-1);
	box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(20px);
}

.import-timeline-stage-yellow {
	background-color: var(--yellow);
	padding: 5px 24px;
	border-radius: 24px;
	color: var(--gray-1);
	box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(20px);
}

.import-timeline-stage h2,
.import-timeline-stage-yellow h2 {
	margin: 0;
}

.import-timeline-last-stage {
	background: var(--white, rgba(255, 255, 255, 0.50));
	padding: 10px;
	border-radius: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
    height: 40px;
	box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);

}

.import-timeline-last-stage-yellow {
	background-color: var(--yellow);
	padding: 10px;
	border-radius: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
    height: 40px;
}

.import-timeline-connection {
	background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
	box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(20px);
	width: 24px;
	height: 8px;
	/* transform: matrix(1, 0, 0, -1, 0, 0);

	border-top: 1px solid var(--gray-5);
	border-bottom: 1px solid var(--gray-5); */
}

.import-timeline-connection-yellow {
	background: var(--yellow);
	transform: matrix(1, 0, 0, -1, 0, 0);
	width: 24px;
	height: 8px;
}

@media (max-width: 600px) { 
	.import-timeline-stage h2,
	.import-timeline-stage-yellow h2 {
		font-size: 0.75rem;
	}
}