.bodyCalendar {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 12px
}
.bodyCalendar-content {
    background: var(--white);
    height: 59px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    width: 100%;
    gap: 12px
}