.rowGroup-container {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.rowGroup-header-container {
    height: 40px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white-50);

}

.rowGroup-header--title {
    color: var(--gray-1);
}

.rowGroup-header--pointer {
    cursor: pointer;
}

.rowGroup-color-white {
    color: var(--white);
}


/* responsive tablet */
@media (max-width: 630px) {
  
    .rowGroup-header-container {
       padding:10px;
    }

    .rowGroup-header--title {
        font-size: 14px;    
    }
}

@media (max-width: 580px) {
    .rowGroup-header--title {
        font-size: 12px;    
    }
}
