.messageChat-main {
    display: flex;
}

.messageChat__left {
    justify-content: flex-start;
    display: flex;
    width: 100%;
}

.messageChat__rigth {
    justify-content: flex-end;
}

.messageChat-container {
    /* padding: 10px 0px 5px 0px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;  
    min-width: 30px;
    /* max-width: 80%; */
    max-width: 58%;
}

.messageChat-box {
    padding: 10px 20px;
}

.messageChat__right {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.messageChat-box__left {
    background: var(--white);
    border-radius: 0px 24px 24px 24px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.messageChatImage {
    width: 179px;
    height: 180px; 
    border-radius: 24px 24px 0px 0px;
    padding: 0px;
}
.messageChatImage img {
    width: 180px;
    height: 180px;  
    border-radius: 24px 24px 0px 0px;
    object-fit: cover;
}

.messageChat-box__rigth {
    background: var(--black-75);
    border-radius: 24px 24px 0px 24px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    gap: 3px;
    flex-direction: column;
    display: flex;
}

.messageChatboxDoc {
    padding: 0px;
}

.messageChatboxDoc a .messageChatDoc {
    padding: 10px 20px 10px 20px;
    background: rgba(255, 255, 255, 0.05);
}

.messageChatboxImage {
    padding: 0px;
    border-radius: 24px;
    width: 180px;
}

.messageChatboxContentDoc {
    padding: 10px 20px 0px 20px;
}

.messageChat-colorWhite {
    color: var(--white-90);
    justify-content: flex-end;
    display: flex;
    /* padding: 10px 20px 0px 20px; */
}

.messageChat-colorBlack {
    color: var(--black-75);
}

.messageChat-box__timeWhite {
    display: flex;
    justify-content: flex-end;
    font-family: 'Faktum';
    color: var(--white-75);
    gap: 0.5rem;
    padding: 0px 20px 10px 20px;
}

.messageChatboxTimeDoc {
    padding: 0px 20px 10px 20px;
}

.messageChatboxTimeImage {
    padding: 10px 20px 10px 20px;
}

.messageChat-box__timeBlack {
    display: flex;
    justify-content: flex-end;
    color: var(--black-50);
}

.messageChat-button {
   margin: 5px 0px;
}

.messageChat-alert {
    color: var(--red);
    text-align: center;
}

.messageChat-attachment {
    /* min-width: 189px;
    min-height: 111px; */
    /* padding: 10px 10px 5px 10px; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    /* Blanco Transparencia/White 25% */
    /* background: rgba(255, 255, 255, 0.25); */

    /* Negro Transparencia/Black 25% */
    /* border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 8px; */
}

.messageChatDoc {
    flex-direction: row !important;
}

.messageChat-attachment-black {
    /* min-width: 189px;
    min-height: 111px;
    padding: 10px 10px 5px 10px; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    /* Blanco Transparencia/White 25% */
    /* background: rgb(202 202 202 / 25%); */
    /* Negro Transparencia/Black 25% */
    /* border: 1px solid rgba(0, 0, 0, 0.25); */
    /* border-radius: 8px; */
}

.messageChat-attachment--icon {
    width: 26.4px;
    height: 33px;
    object-fit: cover;
}

.messageChat-name-document {
    max-width: 490px;
    width: 100%;
    min-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--black-75);
}

.messageChat-name-documentWhite {
    max-width: 490px;
    width: 100%;
    min-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--white-90);
}

@media (max-width: 988px) {
    .messageChat-name-documentWhite {
        max-width: 460px;
    }
}

@media (max-width: 974px) {
    .messageChat-name-documentWhite {
        max-width: 400px;
    }
}

@media (max-width: 962px) {
    .messageChat-name-documentWhite {
        max-width: 300px;
    }
    .messageChat-container .small-bold {
        font-size: 1rem;
    }
    .messageChat-colorBlack,
    .messageChat-name-document,
    .messageChat-name-documentWhite,
    .messageChat-colorWhite {
        font-size: 0.9rem;
    }
}

/* @media (max-width: 917px) {
    .messageChat-name-documentWhite {
        max-width: 200px;
    }
} */

@media (max-width: 796px) {
    .messageChatboxDoc {
        max-width: 21rem;
    }
}

@media (max-width: 768px) {
    
    .messageChat-container .small-bold {
        font-size: 16px;
    }

    .messageChat-container .messageChat-box .paragraph {
        font-size: 14px;
    }

    .messageChat-container .messageChat-box .messageChat-button button  {
        font-size: 14px;
    }

    .messageChat-container .messageChat-box .messageChat-box__time {
        font-size: 10px;
    }

}

@media (max-width: 425px) {
    
    .messageChat-container .small-bold {
        font-size: 14px;
    }

    .messageChat-container .messageChat-box .paragraph {
        font-size: 12px;
    }

    .messageChat-container .messageChat-box .messageChat-button button  {
        font-size: 12px;
    }

    .messageChat-container .messageChat-box .messageChat-box__time {
        font-size: 8px;
    }

}