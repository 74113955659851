.tabBottom {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    padding: 6px 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: space-around;
    gap: 6px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.15);
    z-index: 10;
    overflow: hidden;

    background: var(--white);
    -webkit-overflow-scrolling: touch;
    
}

.tabBottom span.subheader.tabBottom-subheader.subheader-clicked::before,
.tabBottom span.subheader.tabBottom-subheader.subheader::before {
    height: 0px !important;
    background-color: none !important;
    bottom: 0% !important;
    transition: transform 0ms ease-in;
}

.tabBottom-subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100px;
    cursor: pointer;
}

.tabBottom-subheader-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.75rem;
    font-family: 'Faktum';
    font-style: normal;
    font-weight: 400;
    align-items: center;
    color: var(--gray-1);
}

@media (max-width: 600px) {
    .tabBottom {
        gap: 3rem;
        height: 51px;
    }
    .tabBottom-subheader-content  {
        font-size: 0.625rem;
        justify-content: space-around;
        /* height: 10px; */
        height: 35px;
    }
    .tabBottom-subheader {
        width: 70px;
        height: fit-content;
    }
}

@media (max-width: 569px) {
    .tabBottom {
        gap: 2rem;
    }
}

@media (max-width: 508px) {
    .tabBottom {
        gap: 1rem;
    }
}

@media (max-width: 438px) {
    .tabBottom {
        gap: 0.5rem;
    }
}

@media (max-width: 408px) {
    .tabBottom {
        gap: 0.3rem;
    }
}

@media (max-width: 395px) {
    .tabBottom {
        padding: 6px 12px;
    }
}

@media (max-width: 389px) {
    .tabBottom {
        padding: 6px 8px;
    }
}

@media (max-width: 380px) {
    .tabBottom {
        gap: 0.2rem;
    }
    .tabBottom-subheader-content {
        font-size: 0.6rem;
    }
}