.importation-substructure-cargoagentandhscode-input {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;
	backdrop-filter: blur(20px);
	border-radius: 24px;
    background: var(--background);
    border: 1px solid;
    border-image-source: var(--border-image-source);
	max-width: 840px;
} 

.importation-substructure-cargoagentandhscode-card {
	/* background-image: url("../../../../public/images/png/backgroundblur.png"); */
	background-size: cover;
	backdrop-filter: blur(0px);
	/* z-index: 1; */
}


.importation-substructure-cargoagentandhscode-input-bottom {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.importation-substructure-cargoagentandhscode-input-btns {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 10px;
}

.importation-substructure-cargoagentandhscode-input p,
.importation-substructure-cargoagentandhscode-input h1 {
	margin: 0;
	color: var(--gray-1);
}

.importation-substructure-cargoagentandhscode-input-bottom div .textarea {
	background: var(--white);
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
}

.importation-substructure-cargoagentandhscode-content-btns {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.card-content h1,
.card-content p {
	margin: 0px;
}

.importation-substructure-cargoagentandhscode-input-bottom div
.textarea[contenteditable]:empty::before {
    content: "Inserta aquí tu agente de carga";
}

@media (max-width: 968px) {
	.importation-substructure-cargoagentandhscode-card {
		gap: 15px
	}
}

@media (max-width: 880px) {
	.importation-substructure-cargoagentandhscode-card {
		gap: 10px
	}
	.importation-substructure-cargoagentandhscode-card .paragraph-header,
	.importation-substructure-cargoagentandhscode-card .paragraph {
		font-size: 0.9rem;
	}
}

@media (max-width: 768px) {
	.importation-substructure-cargoagentandhscode-content-btns {
		flex-direction: column;
		gap: 10px
	}
	.importation-substructure-cargoagentandhscode-input-btns {
		justify-content: flex-start;
	}
}

@media (max-width: 700px) {
	.importation-substructure-cargoagentandhscode-content-btns {
		flex-direction: row;
	}
	.importation-substructure-cargoagentandhscode-card {
		border-radius: 12px !important;
	}
}

@media (max-width: 564px) {
	.importation-substructure-cargoagentandhscode-input-btns 
	.buttonEditChange .custom-button-icon {
		height: fit-content;
	}
}

@media (max-width: 500px) {
	.importation-substructure-cargoagentandhscode-input-btns 
	.custom-button {
		width: 60%;
	}
}
@media (max-width: 449px) {
	.importation-substructure-cargoagentandhscode-input-btns {
		flex-direction: column;
		align-self: flex-end;
		align-items: flex-end;
		width: 100%;
	}
	.importation-substructure-cargoagentandhscode-content-btns {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-end;
	}
}

@media (max-width: 425px) {
	.importation-substructure-cargoagentandhscode-card {
		padding: 10px;
	}
}
