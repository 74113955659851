.forgotPassword-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.forgotPassword-colorText {
    color: var(--gray-1)
}

.forgotPassword-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


@media (max-width: 404px) {
    .forgotPassword-buttons .btn-small {
        padding: 10px 20px;
    }
}
@media (max-width: 336px) {
    .forgotPassword-buttons .btn-small {
        padding: 10px 6px;
    }
}