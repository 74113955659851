.six-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.six-section-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 60px 180px;
}

.six-section-text {
    font-size: 2.813rem;
    text-align: center;
    font-family: 'Faktum-SemiBold';
    color: var(--white);
    text-shadow: -6px 9px 20px rgba(255, 197, 37, 0.36), 6px -9px 20px rgba(255, 197, 37, 0.36);
}

.six-section-white {
    color: var(--white);
}

.six-section-text-grandient {
    font-size: 2.813rem;
    font-family: 'Faktum-SemiBold';
    background: -webkit-linear-gradient(#F7E246, #FFC525);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.six-seccion-history {
    display: flex;
    flex-direction: row;
    padding: 40px 120px 60px 120px;
    gap: 60px;
    width: 100%;
    justify-content: center;
}

.six-section-history-left {
    width: 100%;
    max-width: 691px;
    display: flex;
    gap: 2rem;
    justify-content: flex-start;
    width: 100%;
}

.six-section-history-right {
    width: 100%;
    max-width: 330px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 15px;
}

.six-section-title {
    color: var(--white);
}

.six-section-history-right_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.six-section-progress {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 70px;
    max-height: 70px;
}

.six-section-start {
    width: 14.61px;
    height: 14px;
    background: #FFC525;
    clip-path: 
        polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.six-section-review {
    display: flex;
    flex-direction: row;
    gap: 10px
}

.six-section-review-start {
    display: flex;
    flex-direction: row;
    gap: 6px
}

@media (max-width: 1323px) {
    .six-seccion-history {
        padding: 40px 60px 60px 60px;
    }
}

@media (max-width: 1194px) {
    .six-section-header {
        padding: 60px;
    }
    .six-section-text,
    .six-section-text-grandient {
        font-size: 2.6rem;
    }
    .six-seccion-history {
        gap: 40px
    }
    .six-section-title {
        font-size: 1.9rem;
    }
    .six-section-history-right_row {
        gap: 15px
    }
    .six-section-progress {
        max-width: 60px;
        max-height: 60px;
    }
}

@media (max-width: 1045px) {
    .six-section-header {
        padding: 30px;
    }
    .six-section-title {
        font-size: 1.625rem;
    }
    .six-section-text, 
    .six-section-text-grandient {
        font-size: 2rem;
    }
    .six-seccion-history {
        gap: 30px;
        padding: 0px 30px 30px 30px;
        flex-direction: column;
        align-items: center;
    }
    .six-section-history-left {
        max-width: 720px;
    }
    .six-section-history-right {
        max-width: 100%;
        padding-top: 0px;
        align-items: center;
        text-align: center;
    }
    .six-section-history-right_row {
        width: 470px;
    }
}

@media (max-width: 665px) {
    .six-section-header {
        padding: 30px 20px;
        display: none;
    }
    .six-section-text, 
    .six-section-text-grandient {
        font-size: 1.42rem;
    }
    .six-seccion-history {
        padding: 0px 20px 30px 20px;
    }
    .six-section-history-right {
        text-align: start;
        align-items: flex-start;
    }
}

@media (max-width: 512px) {
    .six-section-history-right,
    .six-section-history-right_row {
        max-width: 330px;
    }
}