.inputChat-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: var(--white);
    border-radius: 24px;
    padding: 10px 15px;
    align-items: center;
    gap: 15px;
    bottom: 1rem;
    margin-top: 10px;
    /* position: fixed; */
}
.inputChat-circle {
    /* padding: 6px; */
    background: #F2F2F2;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 43px;
    min-width: 37px;
    cursor: pointer;
}
.inputChat-circle div {
    display: flex;
}
.inputChat-circle_cursor {
    cursor: pointer;
}
.inputChat-line {
    border: 1px solid #F2F2F2;
    height: 26px;
}
.inputChat__input {
    width: 100%;
    border-color: transparent;
    background: transparent;
    padding: 5px;
    outline: none;
    color: var(--gray-1);
}
.inputChat__input  {
    border-color: transparent;
    
}

@media (max-width: 1361px) {
    .inputChat-container {
        
    }
}

@media (max-width: 550px) {
    .inputChat-container {
        gap: 5px;
    }
}
