.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: absolute;
	bottom: 0;
	height: 1px;
	border-top: 2px solid rgba(51, 51, 51, 0.15);
}

.footer-topline {
	min-width: 94.89%; 
	height: 1px;
	background-color: var(--gray-2);
	display: none;
}

.footer-container {
	padding: 40px 60px;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	/* flex-wrap: wrap; 
	gap: 30px;*/
	width: 63%;
}

.footer-row__ {
	flex-direction: row;
	display: flex;
	justify-content: space-around;
	gap: 30px;
	/* width: 100%; */
}

.footer-row___    {
	width: 100%;
	flex-direction: row;
	display: flex;
	justify-content:flex-start;
	gap: 15px;
	align-self: center;
	align-items: center;
}

.footer-row___ .footer-img {
    /* height: 27px;
    width: 180.37px; */
	height: 21.6px;
	width: 144.26px;
}

.footer-logo {
	/* max-width: 270px; */
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.footer-logo img {
	max-width: 172px;
	object-fit: contain;
}

.footer-social {
	display: flex;
	align-items: center;
	gap: 22px;
}

.footer-legal {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.footer-your-space {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.footer-meetus {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.footer .modal-main .modal-container .modal__header .modal__heade-left .modal-logo {
	display: none;
}

.footer .modal-main .modal-container {
	height: 90%;
	margin-top: 10px;
	margin-bottom: 10px;
}
.footer-column___ {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-self: center;
	width: 70%;
}
.footer-row__column {
	width: 30%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 20px;
}
.footer-copy {
	color: #333333;
	font-size: 1rem;
}

/* .footer-protected {
	font-family: 'Faktum-Medium';
} */

.footer-copy-color {
	color: var(--gray-3);
	/* color: #333333; */
	font-size: 0.75rem;
}

.footer a {
	display: inline-block;
	text-decoration: none;
	font-size: 1rem;
	color: var(--gray-1);
}

.footer h3 {
	margin: 0;
	font-size: 1rem;
}

.footer p {
	margin: 0;
}

.footer-column___copyright {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.copyright-mobile {
	display: flex;
}

@media (max-width: 2144px) {
	.footer-container {
		/* width: 66%; */
		width: 100%;
		max-width: 1500px;
	}
}
@media (max-width: 1900px) {
	.footer-container {
		/* width: 74%; */
		width: 100%;
		max-width: 1500px;
	}
}
/* @media (max-width: 1658px) {
	.footer-container {
		width: 83%;
	}
}
@media (max-width: 1539px) {
	.footer-container {
		width: 89%;
	}
}
@media (max-width: 1358px) {
	.footer-container {
		width: 96%;
	}
} */
@media (max-width: 1234px) {
	.footer-container {
		width: 100%;
		padding: 40px 60px;
	}
}
@media (max-width: 1126px) {
	.footer-container {
		gap: 20px;
		padding: 40px;
	}
}

@media (max-width: 1115px) {
	.footer-row___ {
		width: 77%;
		align-self: start;
	}
	.footer-container {
		justify-content: space-between
	}
	.footer h3,
	.footer-copy,
	.footer a {
		font-size: 1rem;
	}
}
@media (max-width: 986px) {
	.footer-column___ {
		width: 59%;
	}
	.footer-row___ {
		width: 100%;
	}
}
@media (max-width: 924px) { 
	.footer-container {
		justify-content: space-between;
	}
	.footer-row__column {
		width: 33%;
	}
}
@media (max-width: 895px) {
	.footer .modal-main .modal-container {
		margin: 0px 20px;
	}
}
@media (max-width: 857px) {
	.footer-row___ {
		gap: 15px;
	}
	.footer a,
	.footer h3,
	.footer-copy {
		font-size: 1rem;
	}
}
@media (max-width: 828px) {
	.footer-row__column {
		width: 40%;
	}
	.footer-container {
		padding: 40px 20px;
	}
	.footer-row__column {
		gap: 20px;
	}
}
@media (max-width: 797px) {
	.footer-container {
		display: flex;
	}
	.footer-column___ {
		gap: 10px;
		width: 40%;
	}
	.footer a, .footer h3, .footer-copy {
		font-size: 0.875rem;
	
	}
	.footer-column___copyright {
		gap: 0.3rem
	}
	.footer-row__column {
		width: 33%;
	}
}
@media (max-width: 757px) {
	.footer-column___ {
		width: 50%;
	}
	.footer-row__column {
		width: 40%;
	}
}
@media (max-width: 750px) {
	.footer-row__ {
		align-items: flex-start;
		justify-content: center;
	}
	.footer-logo, .footer-your-space, .footer-legal, .footer-meetus {
		max-width: 250px;
		width: 100%;
	}
}

@media (max-width: 750px) {
	.footer-container { 
		padding: 40px 20px;
	}
}
@media (max-width: 709px) {
	.footer-container {
		flex-direction: column-reverse;
		justify-content: center;
		gap: 60px;
	}
	.footer-row___ {
		justify-content: center;
	}
	/* .footer-row___ {
		display: none;
	} */
	.footer-row__column {
		width: 100%;
		justify-content: space-evenly;
	}
	.footer-column___ {
		width: 100%;
		justify-content: center;
		text-align: center;
	}
	.footer-column___copyright {
		align-items: center;
	}
}
@media (max-width: 652px) {
	.footer-container,
	.footer-row___  {
		flex-direction: column;
		width: 100%;
	}
	.footer-row___ img {
		width: 200px;
	}
	.footer-your-space,
	.footer-legal {
		max-width: 120px;
	}

	.footer-row__column {
		flex-direction: column;
		align-items: self-start;
		gap: 30px;
		/* justify-content: space-around; */
		width: 100%;
	}
	
	.footer-row__ {
		width: 100%;
	}

	.footer-row__column {
		flex-direction: row;
	}

	.footer-container {
		gap: 50px;
		flex-direction: column-reverse;
	}
}
@media (max-width: 600px) {
	.footer-row__ {
		padding: 0px 20px;
	}
}

@media (max-width: 514px) {
	.footer-container { 
	    /* flex-direction: column; */
		width: 100%;
		margin: auto;
		padding: 40px 0px;
	}
	.footer-logo {
		max-width: 100%;
	}
	.footer-logo, .footer-your-space, .footer-legal, .footer-meetus {
		max-width: 100%;
	}
}

@media (max-width: 480px) {
	.footer-row__ {
		flex-direction: column;
		align-items: center;
	}
}

@media (max-width: 433px) {
	.footer-copy {
		padding: 0px 10px;
	}
}

@media (max-width: 390px) {
	.footer-column___ {
		width: 90%;
	}
}