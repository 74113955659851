.eleven-section {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 60px 90px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.eleven-section-row {
    width: 100%;
    max-width: 1444px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eleven-section-text {
    color: var(--white);
}

.eleven-section-title {
    /* width: 100%; */
    width: fit-content;
    align-items: center;
    display: flex;
}

.eleven-section-row_ { 
    width: 100%;
    max-width: 1444px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 60px
}

.eleven-section-row_1,
.eleven-section-row_2,
.eleven-section-row_3 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.eleven-section-row_4 img {
    width: 384px;
    height: 239.719px;
}

.eleven-section_column {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.eleven-section_row {
    display: flex;
    flex-direction: row;
    gap: 10px
}

.eleven-section_row_ {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
}

.eleven-section-row_1_item {
    cursor: pointer;
}

.eleven-section_row_social {
    display: flex;
    flex-direction: row;
    gap: 22px
}

.eleven-section_row_social img {
    width: 20px;
    height: 20px;
}

.eleven-section_right {
    align-items: end;
}

.eleven-section-logo {
    width: 158.88px;
    height: 24px;
}

.eleven-section-row .emailSection-container {
    width: auto;
}

.eleven-section-row_new_copy {
    display: none;
}

.eleven-section_row_social-img {
    width: 20px !important;
    height: 20px;
}

.eleven-section-email {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.eleven-section-email2 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: fit-content;
    align-items: center;
    justify-content: end;
}

.eleven-section-button {
    width: 136px;
    height: 39px;
    display: flex;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 12px;
    cursor: pointer;
    background: linear-gradient(94deg, #F7E246 0%, #FFC525 73.97%);

    backdrop-filter: blur(20px);
    font-family: 'Faktum';
    box-shadow: 0 0 20px rgba(255, 194, 87, 0.4), 0 0 30px rgba(255, 194, 87, 0.5), 0 0 50px rgba(247, 226, 70, 0.4);
    animation: pulse 1.5s infinite ease-in-out, radioactive-glow 2.5s infinite alternate ease-in-out;
}

.eleven-section-bold {
    font-family: 'Faktum-SemiBold';
}

.eleven-section_row_social_hrf {
    cursor: pointer;
}

@media (max-width: 1323px) {
    .eleven-section {
        padding: 60px;
    }
}

@media (max-width: 1225px) {
    .eleven-section-email2 {
        flex-direction: column;
        align-items: end;
    }
}

@media (max-width: 1194px) {
    .eleven-section-title {
        font-size: 1.9rem;
    }
}

@media (max-width: 1045px) {
    .eleven-section {
        padding: 30px 30px;
        gap: 30px
    }
    .eleven-section-title {
        font-size: 2rem;
    }
    .eleven-section-row,
    .eleven-section-row_ {
        flex-direction: column;
        gap: 30px
    }
    .eleven-section-second {
        flex-direction: column-reverse;
    }
    .eleven-section-email .emailSection-container{
        align-items: flex-start;
    }
    .eleven-section-row_4 img {
        width: 100%;
        height: auto;
    }
    .eleven-section-row_new {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .eleven-section-row_new_copy {
        display: flex;
    }
    .eleven-section_right {
       flex-direction: row;
       justify-content: space-between;
       align-items: center;
    }
    .eleven-section_right img {
        width: 105px;
    }
    .eleven-section-email2 {
        flex-direction: row;
        align-items: center;
    }
}

@media (max-width: 665px) {
    .eleven-section {
        padding: 30px 20px;
    }
    .eleven-section-email2 {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 20px
    }
    .eleven-section-title {
        font-size: 1.42rem;
        line-height: 35px;
    }
    .eleven-section_column {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .eleven-section-button {
        width: 155px; 
    }
}

@media (max-width: 425px) {
    .eleven-section-email .emailSection-container 
    .emailSection {
        width: 335px;
        padding: 6px 6px 6px 0px;
    }
}