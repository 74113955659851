.ItemVessels-container {
    width: 100%;
    max-width: 350px;
    /* padding: 15px 20px; */
    padding: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    height: auto;   
}

.ItemVessels-container:hover {
    background-color: var(--gray-6) !important;
    border-radius: 0px !important;
}

.ItemVessels-container .smalltext-header {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 541px;
    width: 100%;
    white-space: nowrap;
}