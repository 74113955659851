.searchServiceItem {
    /* max-width: 425px; */
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

}

.searchService-container {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 20px;
   align-items: flex-start; 
}

.searchService-colorText {
    color: var(--gray-1);
}

.searchService-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}

.searchService-header .dropdown{
    max-width: 150px;
}

.searchService-container .searchTextField-container {
    max-width: 425px;
    width: 100%;
}

.searchService-container .searchTextField-container .searchTextField-input,
.searchService-container .searchTextField-container .searchTextField-input::placeholder{
    color: var(--gray-3);
}

.searchService-listMain {
    position: relative;
    width: 100%;
    height: 100%;
}

.searchService-list {

    width: 100%;
    padding: 5px;
    max-height: 53vh;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    
    gap: 20px;

    overflow: scroll;
    overflow-x: hidden;
}

.searchService-list::-webkit-scrollbar {
    width: 0px;
}

.searchService-loader {
    width: 100%;
    
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.searchServiceItem-verified img{
    width: 18.201px;
    height: 18px;
}

.searchService-container .searchService-list {
    padding: 0.5rem 0.5rem 1rem 0.5rem;
}

.searchService-container .searchService-list .service-minuature-main {
    min-width: 175px;
    max-width: 175px;
    filter: drop-shadow(0px 3px 9px 0px rgba(0, 0, 0, 0.15));
}

.searchService-container .searchService-list .service-minuature-main .service-minuature-body 
.service-minuature-text .service-miniature-header .service-minuature-title {
    font-size: 0.75rem;
}

.searchService-container .searchService-list .service-minuature-main .service-minuature-body 
.service-minuature-text .service-miniature-header .service-miniature-header-check div img {
    width: 1.125rem;
    min-width: 1.125rem;
}

.searchService-container .searchService-list .service-minuature-main .service-minuature-body 
.service-minuature-description {
    display: none;
}

.searchService-container .searchService-list .service-minuature-main .service-minuature-body 
.service-minuature-images {
    height: 92px;
    width: 173px;
}
.searchService-container .searchService-list .service-minuature-main .service-minuature-body 
.service-minuature-rating .city-country-display {
    width: 100%;
    min-width: fit-content;
    max-width: fit-content;
}
.searchService-container .searchService-list .service-minuature-main .service-minuature-body 
.service-minuature-rating .rating {
    padding: 3px 9px;
    height: -moz-fit-content;
    height: fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.searchService-container .searchService-list .service-minuature-main .service-minuature-body 
.service-minuature-rating .rating .rating_pointer-icon img {
    height: 13px;
}
.searchService-container .searchService-list .service-minuature-main .service-minuature-body 
.service-minuature-rating .rating span {
    font-size: 0.75rem;
}
.searchService-container .searchService-list .service-minuature-main .service-minuature-body 
.service-minuature-rating .commentCount  {
    padding: 3px 9px;
    height: -moz-fit-content;
    height: fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.searchService-container .searchService-list .service-minuature-main .service-minuature-body 
.service-minuature-rating .commentCount span {
    font-size: 0.75rem;
}
.searchService-container .searchService-list .service-minuature-main .service-minuature-body 
.service-minuature-rating .city-country-display .smalltext-header {
    display: none;
}
.searchService-container .searchService-list .service-minuature-main .service-minuature-body 
.service-minuature-rating .city-country-display .city-country--image img {
    width: 22.01px;
    height: 11px;
}

/* @media (max-height: 762px){
    .searchService-list {
        max-height: 200px;
    }
}

@media (max-height: 716px){
    .searchService-list {
        max-height: 150px;
    }
}

@media (max-height: 659px){
    .searchService-list {
        max-height: 135px;
    }
} */

@media (max-width: 600px){
    .searchService-list {
        gap: 5px;
    }
    .searchService-container .searchService-list .service-minuature-main {
        min-width: 162px;
        max-width: 162px;
    }
    .searchService-container .searchService-list .service-minuature-main .service-minuature-body .service-minuature-images {
        width: 160px;
    }
}

@media (max-width: 450px){ 
    .searchServiceItem  {
        gap: 10px;
    }
}

@media (max-width: 417px){ 
    .searchService-listMain .searchServiceItem .searchServiceItem-container-selected .searchServiceItem-name {
        gap: 5px;
        max-width: 180px;
    }
    .searchService-listMain .searchServiceItem .searchServiceItem-container-selected .searchServiceItem-rating .rating {
        padding: 5px 7px;
    }
    .searchService-listMain .searchServiceItem .searchServiceItem-container-selected .searchServiceItem-rating .commentCount {
        padding: 5px 7px;
    }
    .searchService-listMain .searchServiceItem .searchServiceItem-container-selected .searchServiceItem-rating {
        width: 21px;
        height: 11.5px;
    }
    .searchServiceItem {
        gap: 5px!important;
    }
}

@media (max-width: 397px){ 
    .searchService-container .searchService-listMain .searchService-list .searchServiceItem .searchServiceItem-container 
    .searchServiceItem-name {
        gap: 5px;
        max-width: 180px;
    }
    .searchService-container .searchService-listMain .searchService-list .searchServiceItem .searchServiceItem-container 
    .searchServiceItem-name .searchServiceItem-colorText {
        width: 112px;
    }
}

@media (max-width: 380px){  
    .searchService-container .searchService-listMain .searchService-list .searchServiceItem .searchServiceItem-container  
    .searchServiceItem-rating .rating {
        padding: 5px 7px;
    }
    .searchService-container .searchService-listMain .searchService-list .searchServiceItem .searchServiceItem-container  
    .searchServiceItem-rating .commentCount {
        padding: 5px 7px;
    }
    .searchService-container .searchService-listMain .searchService-list .searchServiceItem .searchServiceItem-container  
    .searchServiceItem-rating .searchServiceItem-rating--img {
        width: 21px;
        height: 11.5px;
    }
}