.faq-display {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-top: 20px;
}

.faq-display h1 {
	margin: 0;
	color: var(--gray-1);
}

.faq-display-title{
    text-shadow: 0 0 20px rgba(255, 194, 87, 0.4), 
	0 0 30px rgba(255, 194, 87, 0.5), 
	0 0 50px rgba(247, 226, 70, 0.4);
}

@media (max-width: 768px) {
	.faq-display {
		padding-top: 0px;
	}
}


@media (max-width: 768px) {
    .faq-display{
        grid-template-columns: 1fr; 
        gap: 15px;
    }

    .faq-display {
        width: 100%;
        height: auto; 
    }
}