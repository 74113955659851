.creditCard-form, .creditCard {
    width: 100%;
}


.creditCard .card-background {
    width: 100%;

    display: flex;
    gap: 30px;
    border: 1px solid rgba(84, 63, 212, 0.30);
}

.creditCard-row {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    gap: 10px;
}

#creditCard-culqi {
    width: 100%;
    overflow: hidden; /* Evita que el contenido se desborde */
    position: relative;
    aspect-ratio: 15 / 10; /* Proporción común para iframes */ 
    background: var(--white);
}

#creditCard-culqi iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; /* Opcional: elimina el borde del iframe */
}

.creditCard .card-background .button-container {
    background: var(--orange);
    color: var(--white);

    padding: 10px 20px;
    border-radius: 50px;
}

.creditCard-text-orange {
    color: var(--orange);
}

.creditCard-text {
    color: var(--white);
}

.creditCard-footer {
    display: flex;
    flex-direction: column;

    gap: 10px;
}

.creditCard-row .textField-container {
    width: 31%;
}

.creditCard .card-background {
    padding: 0px;
}

@media (max-width: 768px) {
    #creditCard-culqi {
        aspect-ratio: 12/10;
    }
    
}

@media (max-width: 600px) {
    #creditCard-culqi {
        aspect-ratio: 5/6;
    }
    
}