.option {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.option-content {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}
.option-tabs {
    display: flex;
    flex-direction: row;
    gap: 10px
}
.option .option-tabs .btnIcon-normal {
    border-radius: 24px;
    height: 30px;
    color: var(--gray-2);
    min-width: 0px;
    padding: 7px 15px;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
}
.option .option-new-event
.btn-normal {
    border-radius: 24px;
    height: 30px;
    color: var(--white);
    padding: 0px 40px;
}
.option-range {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px
}
.option-range-week {
    display: none;
}
.option-icon {
    justify-content: center;
    display: flex;
    cursor: pointer;
}
.option-tabs-active{
    color: var(--white) !important;
}
.option-mobile {
    display: none;
}
.option .option-new-event .btn-normal {
    box-shadow: 0px 0px 0px var(--black-15);
}
@media (max-width: 1247px){ 
    .option-range {
        gap: 20px
    }
}
@media (max-width: 1240px){ 
    .option .option-new-event
    .btn-normal {
        font-size: 0.85rem;
        padding: 0px 10px;
    }
}
@media (max-width: 1236px){ 
    .option-range {
        gap: 10px
    }
}
@media (max-width: 1211px){ 
    .option .option-new-event 
    .btn-normal {
        padding: 0px 20px;
    }
}
@media (max-width: 1161px){ 
    .option-range-text {
        font-size: 0.85rem;
    }
}
@media (max-width: 1130px){ 
    .option-content{
        gap: 5px;
    }
    .option-range-text {
        font-size: 0.8rem;
    }
    .option .option-new-event 
    .btn-normal {
        padding: 0px 10px;
    }
}
@media (max-width: 1066px){
    .option-tabs {
        gap: 5px;
    }
    .option .option-tabs .btnIcon-normal {
        padding: 0px 9px;
        height: 30px;
        /* font-size: 0.75rem; */
    }
    .option .option-new-event .btn-normal {
        font-size: 0.75rem;
        padding: 0px;
    }
    .option-range-text {
        font-size: 0.75rem;
    }
    .option-range {
        gap: 5px;
    }
}
@media (max-width: 900px){
    .option .option-new-event .btn-normal {
        min-width: 115px;
    }
}
@media (max-width: 884px){
    .option-range {
        gap: 20px;
    }
    .option-tabs {
        gap: 10px;
    }
    .option .option-tabs .btnIcon-normal {
        padding: 0px 13px;
    }
}
@media (max-width: 768px) {
    .option-mobile {
        display: flex;
    }

    .option-desktop {
        display: none;
    }
}

@media (max-width: 644px){
    .option-range {
        gap: 5px;
    }
    /* .option-tabs {
        gap: 5px;
    } */
    .option .option-tabs .btnIcon-normal {
        padding: 0px 10px;
    }
    .option-tabs .custom-button-icon div{
        display: flex
    }
}
@media (max-width: 587px){
    .option .option-new-event .btn-normal {
        font-size: 0.7rem;
        padding: 0px;
    }
    .option-range-text {
        font-size: 0.7rem;
    }
    .option .option-tabs .btnIcon-normal {
        padding: 0px 8px;
    }
    .option-range {
        gap: 3px;
    }
    .option-tabs .custom-button-icon div img{
        height: 13px;
    }
    .option-tabs {
        gap: 6px;
    }
}

@media (max-width: 554px){ 
    .option-range {
        display: none;
    }
    .option-new-event {
        display: none; 
    }
    .option-content {
        justify-content: center;
        gap: 20px;
        display: flex;
    }
    .option {
        gap: 30px;
        display: flex;
        flex-direction: column;
    }
    .option-mobile{
        display: none;
    }
    .option .option-tabs .btnIcon-normal {
        border-radius: 24px;
        height: 30px;
        color: var(--gray-2);
        min-width: 0px;
        padding: 7px 25px;
    }
    .option-range-week {
        display: block;
        flex-direction: row;
        align-self: center;
    }
    .option-range-week-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: var(--white-50);
        height: 36px;
        width: 297px;
        gap: 5px;
        border-radius: 24px;
    }
}

@media (max-width: 380px){ 
    .option .option-tabs .btnIcon-normal {
        padding: 7px 15px;
    }
}
@media (max-width: 300px){ 
    .option-tabs {
        gap: 5px
    }
}