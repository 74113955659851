.card-notification {
    /* min-height: 176px; */
    min-height: fit-content;
    border-radius: 24px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    
    max-width: 100%;

    background: var(--white);
    /* -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px); */
    border-image-source: var(--border-image-source);
}

.card-notification-new {
    /* min-height: 176px; */
    min-height: fit-content;
    border-radius: 24px;
    padding: 20px 20px 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    
    max-width: 100%;
    background: rgba(0, 0, 0, 0.03);
}

.card-notification-disactived {
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: var(--white)!important;
}

.card-notification-event {
    /* min-height: 148px; */
    height: 100%;
    max-height: 200px;
    border-radius: 24px;
    background: var(--white-75);
    padding: 20px 20px 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 10px
}
.card-notification-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.card-notification-business {
    display: flex;
    flex-direction: row;
}
.header-title-content {
    display: inline;
    max-width: 15.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    color: var(--black);
}
.card-notification-body {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.card-notification-body .useractive {
    max-width: 40px;
    min-width: 40px;
    width: 100%;
    height: 40px;
}
.card-notification-body .useractive-white {
    max-width: 40px;
    min-width: 40px;
    width: 100%;
    height: 40px;
    filter: drop-shadow(0px 2.1621623039245605px 2.1621623039245605px rgba(0, 0, 0, 0.10));
}
.card-notification-body .useractive img {
    max-width: 40px;
    min-width: 40px;
    width: 100%;
    height: 40px;
    object-fit: scale-down;
}
.card-notification-body .useractive-white img {
    max-width: 40px;
    min-width: 40px;
    width: 100%;
    height: 40px;
    object-fit: scale-down;
}
.card-notification-body .useractive .useractive-text {
    font-size: 18px;
}
.card-notification-body .useractive-white .useractive-text {
    font-size: 18px;
    color: #333333;
}
.card-notification-body .header-title-business {
    font-weight: 500;
    color: #333333;
}
.card-event-content {
    display: flex;
    flex-direction: column;
    gap: 10px
}
.card-step_ {
    color: var(--black-75);
}
.card-notification-header-time {
    font-weight: 500;
    color: #181818;
}
.card-notification-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
}
.card-notification-estado{
    min-height: 12px;
    min-width: 12px;
    border-radius: 24px;
    background: var(--red);
    display: flex;
}
.card-step-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.card-step-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.card-notification .card-step-button 
.custom-button {
    border-radius: 24px;
    height: 28px;
    min-width: 192px;
    max-width: 250px;
    padding: 0px 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.card-notification-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}