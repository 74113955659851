.operation-header {
	background: var(--white);
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    height: 60px;
	align-items: center;  
	padding: 10px 0px 10px 20px; 
	position: sticky;
    top: 0;
    z-index: 4;                                                                                                                                                                                                                                                                                                         
}
.operation-header-left {
    max-width: 400px;
	height: 60px;
    padding-right: 29px;
    width: 100%;
	display: flex;
	background: var(--white);
}

.operation-header-left .operation-header-options {
	display: none;
}

.operation-header-left-content {
	display: flex;
    gap: 20px;
}

.operation-header-arrow-back {
	cursor: pointer;
}

.operation-header-arrow-back, 
.arkabia-op-icon, .operation-header-home
.operations-header-right-teams 
.operations-header-right-teams-small{
	align-self: center;
}
.operation-header-home {
    cursor: pointer;
	padding: 7px;
	display: none;
}
.operation-title-container, 
.operations-header-right-teams-small{
	flex-direction: row;
	display: flex;
	gap:10px;


	/* ver si funciona o no*/
	width: 100%;
    max-width: 100%;
    min-width: 0;
}
.operation-title-content {
	width: 100%;
	max-width: 100%;
	min-width: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.operation-title-order, 
.operation-title-type-operation {
	margin: 0px;
	color: var(--gray-1);
}
.operation-title-type-operation {
	display: none;
}
/* .operation-header-options {
	align-self: center;
	display: none;
} */
.operation-title-order, .operation-title-type-operation {
	overflow: hidden;
    text-overflow: ellipsis;
    max-width: 320px;
	min-width: 0;
	width: 100%;
    white-space: nowrap;
}
.operation-header-right {
	display: flex;
	
	clip-path: polygon(2% 0, 100% 0%, 100% 100%, 0% 100%);
    background: rgba(0, 0, 0, 0.05);
	backdrop-filter: blur(40px);
	padding-right: 20px;
	height: 60px;
	width: 100%;
    padding-left: 50px;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.operation-header-right-nothing {
	display: none;
}
.operation-header-right-content {
	flex-direction: row;
	align-items: center;
    display: flex;
	gap: 10px
}
.operation-header-home {
    border-radius: 14px;
    padding: 5px 7px;
	align-self: center;
}
.operation-header-home--active {
	background: var(--gray-1);
}
.operations-header-right-teams {
	gap: 10px;
    display: flex;
	max-width: 62vw;
	width: 100%;
}
.btn-agency-add {
	background-color: transparent !important;
	border-color: transparent !important;
	padding: 10px 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 260px;
	min-width: 35px;
	width: 100%;
	white-space: nowrap;
}
.btn-plus {
	color: var(--blue-2);
    border-color: transparent;
	width: 100%;
	max-width: 125px;
	background: none;
}
.operations-header-right-teams
.btn-agency-add-active, .operations-header-right-teams-small
.btn-agency-add-active {
	padding: 10px 15px;
	overflow: hidden;
	text-overflow: ellipsis;
	/* max-width: 260px; */
	max-width: fit-content;
	min-width: 35px;
	width: 100%;
	white-space: nowrap;
	color: var(--white);
	border-radius: 24px;
	border: 0;

	/* box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15); */
}
.operations-header-right-teams-small {
	max-width: 60vw;
	overflow-x: overlay;
}
.operation-header-right-dropdown {
	display: none;
}
.operation-more-options {
	display: flex;
    padding-left: 20px;
    width: 20px;
    position: relative;
	cursor: pointer;
}
.operation-header-right-team {
	color: var(--gray-2);
	font-weight: 300;
}
.operation-option-header {
	position: absolute;
    display: flex;
    flex-direction: row;
	top: 35px;
	right: 0px;
	margin-right: 5px;
	width: 10rem;
	flex-direction: column;
	text-align: flex-end;
    align-items: flex-end;
}
.operation-option-figure {
	width: 0;
    height: 0;
	margin-right: 6px;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid var(--yellow);
}
.operation-option-body {
	background: var(--yellow);
	width: 100%;
	border-radius: 8px;
	height: auto;
}
.operation-option-body-item {
	padding: 8px 15px;
	cursor: pointer;
}
.operation-option-body-item:hover {
	background: var(--white-50);
}
@media (max-width: 1653px) { 
	.operations-header-right-teams {
		max-width: 60vw;
	}
}
@media (max-width: 1550px) { 
	.operations-header-right-teams {
		max-width: 50vw;
	}
	.operations-header-right-teams-small {
		max-width: 50vw;
	}
}
@media (max-width: 1510px) { 
	.operations-header-right-teams {
		max-width: 60vw;
		gap: 5px
	}
	.operation-header-left {
		max-width: 340px;
	}
	.operation-title-order, 
	.operation-title-type-operation {
		/* max-width: 240px; */
		min-width: 0;
		max-width: 275px;
	}
	.operation-title-content {
		/* max-width: 79%; */
	}
}
@media (max-width: 1380px) { 
	.operation-header-left {
		max-width: 320px;
	}
	.operation-header-right {
		padding: 0px;
		padding-left: 30px;
		padding-right: 20px;
	}
	.operation-title-content {
		max-width: 100%;
	}
}
@media (max-width: 1321px) { 
	.operation-title-order, 
	.operation-title-type-operation {
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 255px;
		width: 100%;
		white-space: nowrap;
	}
	.operations-header-right-teams {
		width: 100%;
		overflow-x: overlay;
		max-width: 50vw;
	}
}
@media (max-width: 1145px) { 
	.operations-header-right-teams-small {
		max-width: 40vw;
	}
}
@media (max-width: 1024px) { 
	.operation-header-left {
		max-width: 260px;
		padding-right: 0px;
	}
	.operation-header-left-content {
		width: 100%;
		gap: 10px
	}
	.operation-title-order, 
	.operation-title-type-operation {
		/* max-width: 170px; */
		padding-right: 10px;
	}
	/* .operation-title-content {
		max-width: 60%;
	} */
}
@media (max-width: 940px) { 
	.operation-header-left {
		padding-right: 0px;
	}
	.operation-header-right {
		padding-left: 20px;
	}
}
@media (max-width: 854px) { 
	.operation-title-order, .operation-title-type-operation {
	    /* max-width: 140px; */
	}
}
@media (max-width: 793px) { 
	.operation-title-order, .operation-title-type-operation {
	    /* max-width: 125px; */
	}
}
@media (max-width: 768px) { 
	.operation-header {
		flex-direction: column;
		padding: 0px;
		margin-top: 9px;
		height: auto;
		z-index: 4;
	}
	.operation-header-left {
		background: var(--white);
		backdrop-filter: blur(20px);
	}
	.operation-title-order, 
	.operation-title-type-operation {
		overflow: hidden;
		text-overflow: ellipsis;
		/* max-width: 541px; */
		max-width: 668px;
		width: 100%;
		white-space: nowrap;
	}
	.operation-header-left, 
	.operation-header-right {
		box-sizing: border-box;
		max-width: 100%;
		/* height: 60px; */
		height: 40px;
		padding-left: 20px;
		padding-right: 20px;
		justify-content: space-between;
		display: flex;
		align-items: center;
	}
	.operation-header-right {
		padding: 0px;
		clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
		padding-left: 20px;
		padding-right: 20px;
		/* display: none; */
	}
	.operations-header-right-teams {
		max-width: 75vw;
	}
	.operations-header-right-teams-small {
		max-width: 60vw;
	}
	.operation-option-header {
		/* top: 96px; */
		top: 60px;
	}
	.operation-header-left .operation-header-options {
		display: flex;
		justify-content: flex-end;
	}
	.operation-header-right .operation-more-options {
		display: none;
	}
	.operation-header-home {
		display: flex;
	}
	.operation-header-right-dropdown {
		display: flex;
	}
	.operation-header-right-dropdown .dropdown .dropdown-select .select-title {
		display: none;
	}
	.operation-header-right-dropdown .dropdown .dropdown-select {
		width: 330px;
		padding: 10px 20px 10px 10px;
		background: var(--white)
	}
	.operation-header-right-dropdown .dropdown .dropdown-select .move-text {
		transform: translate(0, 0%);
	}
	.operations-header-right-teams,
	.operations-header-right-teams-small {
		display: none;
	}
	.operation-title-content {
		max-width: 100%;
		/* max-width: 70%; */
	}
}
@media (max-width: 654px) { 
	.operations-header-right-teams {
		max-width: 70vw;
	}
	.operation-title-order, .operation-title-type-operation {
		/* max-width: 450px; */
	}
}
@media (max-width: 554px) { 
	.operation-title-order, .operation-title-type-operation {
		overflow: hidden;
		text-overflow: ellipsis;
		/* max-width: 390px; */
		width: 100%;
		white-space: nowrap;
	}
	.operations-header-right-teams {
		max-width: 60vw;
	}
	.operations-header-right-teams-small {
		max-width: 50vw;
	}
}

@media (max-width: 495px) { 
	.operation-title-order, .operation-title-type-operation {
		/* max-width: 350px; */
	}
}
@media (max-width: 480px) { 
	.operation-header-left {
		background: var(--white);
	}
	.operation-header-options,
	.operation-header-right-dropdown {
		display: block;
		width: 100%;
	}
	.operation-more-options,
	.operation-header-right-team,
	.operations-header-right-teams,
	.btn-plus,
	.operations-header-right-teams-small {
		display: none;
	}
	.operation-header-right-dropdown 
	.dropdown .dropdown-select{
		width: 100%;
		padding: 10px;
		color: var(--blue-2);
		height: 45px;
		/* background: var(--white-50); */
	}
	.operation-header-right { 
		/*background: var(--white-25);*/
		width: 100%;
		max-width: 900px;
		clip-path: none;
	}
	.operation-header-right-content {
		width: 100%;
		max-width: 800px;
		align-self: center;
	}
	.operation-header-right-dropdown 
	.dropdown .dropdown-select .select-title{
		transform: translate(12px, 50%);
	}
	.operation-header-right-dropdown 
	.dropdown .dropdown-select .move-text{
		color: var(--gray-1);
	}
	.operation-header-options {
		width: 50px;
		cursor: pointer;
	}
	.operation-option-header {
		top: 35px;
		margin-right: 8.5px;
	}
}

@media (max-width: 454px) { 
	.operation-title-order, .operation-title-type-operation {
		/* max-width: 310px; */
	}
}

@media (max-width: 431px) { 
	.operation-header-right-dropdown .dropdown .dropdown-select .move-text {
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 300px;
		width: 100%;
		white-space: nowrap;
		left: 0px;
	}
}

@media (max-width: 417px) { 
	.operation-title-order, .operation-title-type-operation {
		/* max-width: 270px; */
	}
}

@media (max-width: 413px) { 
	.operation-header-right-dropdown .dropdown .dropdown-select .move-text {
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 250px;
		width: 100%;
		white-space: nowrap;
		left: 0px;
	}
}

@media (max-width: 353px) { 
	.operation-header-right-dropdown .dropdown .dropdown-select .move-text {
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 200px;
		width: 100%;
		white-space: nowrap;
		left: 0px;
	}
}

@media (max-width: 350px) { 
	.operation-header-left-content {
		gap: 15px
	}
	.operation-title-order {
		/* max-width: 162px; */
	}
	.operation-header-right-dropdown 
	.dropdown .dropdown-select .move-text{
		font-size: 0.8rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

@media (max-width: 311px) { 
	.operation-header-right-dropdown .dropdown .dropdown-select .move-text {
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 150px;
		width: 100%;
		white-space: nowrap;
		left: 0px;
	}
}