.card-groupContainer {
    width: 100%;
    max-width: 1098px;
    min-width: 100px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    backdrop-filter: blur(40px);
    border-image-source: var(--border-image-source);
    background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

@keyframes placeholderShimmer {
    0%{
        background-position: -468px 0;
    }
    100%{
        background-position: 468px 0;
    }
}

.shimmer-operation-animation {
    background-color: #f6f7f8;
    background-image: linear-gradient(
      to right,
      var(--white-50) 0%,
      var(--gray-6) 20%,
      var(--white-50) 40%,
      var(--white-50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
}

.shimmer-operation-column-width {
    width: 100%;
    max-width: 162px;
}

.shimmer-operation-column-width-min {
    width: 100%;
    max-width: 82px;
}

.loader-shimmer-groupContainer-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.loader-shimmer-groupContainer-row-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
}

.loader-shimmer-groupContainer-row-min {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.loader-shimmer-groupContainer-logo {
    /* height: 70px;
    width: 70px; */
    height: 99.2px;
    width: 106px;
    background-size: 800px 18rem;
}

.loader-shimmer-groupContainer-text {
    height: 20px;
    width: 100%;
    max-width: 400px;
}

.loader-shimmer-groupContainer-item{
    height: 40px;
    width: 100%;
    max-width: 80px;
    gap: 10px;
    margin-top: 15px;
    margin-left: 70px;
}

.loader-shimmer-groupContainer-body{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.loader-shimmer-groupContainer-body_content{
    background-color: #f6f7f8;
    background-image: linear-gradient(
      to right,
      var(--white-50) 0%,
      var(--gray-6) 20%,
      var(--white-50) 40%,
      var(--white-50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    display: flex;
    gap: 10px;
    height: 35px;
    background-size: 800px 18rem;
    margin-left: 80px;
    margin-top: -40px;
}

.loader-shimmer-groupContainer-body_content{
    height: 20px;
    width: 100%;
    max-width: 100px;
}

.loader-shimmer-groupContainer-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loader-shimmer-groupContainer-button {
    width: 100%;
    max-width: 82px;
    background-color: #f6f7f8;
    background-image: linear-gradient(
      to right,
      var(--white-50) 0%,
      var(--gray-6) 20%,
      var(--white-50) 40%,
      var(--white-50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    display: flex;
    gap: 10px;
    height: 35px;
    background-size: 800px 18rem;
}

.loader-shimmer-groupContainer-progress {
    width: 100%;
    max-width: 105px;
    background-color: #f6f7f8;
    background-image: linear-gradient(
      to right,
      var(--white-50) 0%,
      var(--gray-6) 20%,
      var(--white-50) 40%,
      var(--white-50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    display: flex;
    gap: 10px;
    height: 50px;
    background-size: 800px 18rem;
}

.loader-shimmer-groupContainer-price {
    width: 100%;
    max-width: 122.72px;
    background-color: #f6f7f8;
    background-image: linear-gradient(
      to right,
      var(--white-50) 0%,
      var(--gray-6) 20%,
      var(--white-50) 40%,
      var(--white-50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    display: flex;
    gap: 10px;
    height: 66px;
    background-size: 800px 18rem;
}

@media (max-width: 900px) {
    .card-groupContainer{
        width: 30px;
        width: 100%;
        max-width: 250px;
    }
    .loader-shimmer-groupContainer-text{
        max-width: 300px;
    }
    .loader-shimmer-groupContainer-item{
        max-width: 80px;
    }
    .loader-shimmer-groupContainer-table_item,
    .loader-shimmer-groupContainer-item,
    .loader-shimmer-groupContainer-logo,
    .loader-shimmer-groupContainer-text{
        height: 20px;
    }
    .loader-shimmer-groupContainer-body{
        gap: 10px;
    }
    .loader-shimmer-groupContainer-logo{
        max-width: 200px;
    }
}

@media (max-width: 470px) {
    .card-groupContainer{
        width: 96.4%;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
        gap: 10px;
    }
}

@media (max-width: 400px) {
    .card-groupContainer{
        width: 95.8%;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
        gap: 10px;
    }
    .loader-shimmer-groupContainer-text{
        max-width: 150px;
    }
}
