.processServiceHeader-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.processServiceHeader-left {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.processServiceHeader-left--logo {
    width: 37px;
    height: 26px;
}

.processServiceHeader-left--logo div img {
    width: 100%;
}


.processServiceHeader-left--content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
}

.processServiceHeader-colorText {
    color: var(--gray-1);
}

.processServiceHeader--close {
    width: 14px;
    height: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.processServiceHeader--close div,
.processServiceHeader--close div img {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

@media (max-width: 600px) {
    .processServiceHeader-left--logo {
        width: 30px;
        height: 19px;
    }
    .processServiceHeader-container {
        padding: 5px 15px 0px 15px;
        height: 50px;
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        display: flex;
        position: fixed;
        z-index: 1;
        top: 0px;
        left: 0px;
        right: 0px;
    }
}