.row {
	min-height: 30px;
	display: flex;
	align-items: center;
	gap: 20px;
}

.row__title {
	width: 100%;
	/* max-width: auto; */
	min-height: 20px;
	color: var(--gray-1);
	min-width: 45px;
    max-width: 20%;

}

.row__msg {
	width: 100%;
    min-width: 10px;
    max-width: 480px;
	color: var(--gray-1);
	text-align: start;
}

.row__values {
	flex: 1;
	display: flex;
	text-align: end;
	gap: 10px;
	min-height: 20px;
	justify-content: space-around;
}

.row__values-item {
	min-width: 118.75px;
	max-width: 200px;
	width: 100%;
	color: #4F4F4F;
	text-align: start;
}

.row__values-item__image {
	display: flex;
    align-items: center;
	max-height: 55px;
}

.row__values-item__image img {
	max-height: 70px;
	height: 100%;
	min-width: 80px;
	max-width: 200px;
	width: 100%;
	object-fit: cover;
}

.row__values-item object {
	max-height: 70px;
	height: 100%;
	min-width: 80px;
	max-width: 200px;
	width: 100%;
	object-fit: cover;
}

.imageClass {
	cursor: pointer;
}

.rowTextWhite {
	color: var(--white);
}

.rowTextBlack {
	color: var(--black);
}

@media (max-width: 1200px) {
	.row {
		gap: 10px
	}
}

@media (max-width: 1070px) {
	.row__title {
		/*max-width: 150px;*/
		max-width: auto;
	}	

	.row__values {
		gap: 0px;
	}

	.row__values-item__image img,
	.row__values-item object {
		width: 100%;
	}

}

@media (max-width: 1024px) { 
    .row {
        gap: 10px
    }
	.row__title,
	.row__values-item,
	.row__msg  {
		font-size: 0.8rem;
	}
}

@media (max-width: 1002px) { 
	.row__values-item {
		min-width: 100px;
	}
}

@media (max-width: 920px) { 
	.row__title, .row__values-item, .row__msg {
		font-size: 0.75rem;
	}
}

@media (max-width: 900px) { 
	.row__values-item {
		min-width: 128px;
	}
}

@media (max-width: 690px) { 
	.row__title {
		max-width: 34%;
	}
}

@media (max-width: 630px) { 

	.row {
		padding: 10px !important;
		gap: 0px;
	}

	.row__title {
		font-size: 14px;
	}	

	.row__values-item   {
		font-size: 13px;
		max-width: 150px;
		min-width: 60px;
	}
	
}

@media (max-width: 580px) { 
	.row__title {
		font-size: 12px;
	}	

	.row__values-item   {
		font-size: 12px;
	}

 }

@media (max-width: 440px) { 
	.row__values-item {
        max-width: 93px;
        min-width: 60px;
    }
	.row__title {
        max-width: 31%;
    }

 }