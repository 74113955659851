.ordersQuotationPage-main {
    min-height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ordersQuotationPage-container {
    flex-direction: column;
    display: flex;
    justify-content: center;
    gap: 30px;
    width: 100%;
    max-width: 1500px;
}

/* Header */
.ordersQuotationPage-header {
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: center;
    gap: 40px;
    padding: 45px 20px 0px 20px;
}

.ordersQuotationPage-header .headPage {
    flex-direction: column;
}

.ordersQuotationPage-header .headPage .headPage-title {
    line-height: 23px;
}

/* Body */
.ordersQuotationPage-body {
    width: 100%;
    display: flex;
    gap: 40px;
    flex-direction: row;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: center;
    gap: 40px;
    padding: 0px 20px 0px 20px;
    padding-bottom: 20px;
    justify-content: center;
} 

.ordersQuotationPage-services {
    /* max-width:  400px; */
    max-width: 310px;
    min-width: 310px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.ordersPage-quotation-icon-search {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;

    background: #FFFFFF;

    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
}

.ordersQuotationPage-company-filter {
    display: flex;
    flex-direction: row;
    gap: 20px
}

.ordersQuotationPage-services .searchTextField-container {
    background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
    border-image-source: var(--border-image-source);
}

.ordersQuotationPage-services-quotations {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ordersQuotationPage-company{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ordersQuotationPage-company .companyList-main .companyList-list {
    grid-template-columns: repeat(3, 1fr);
}

.tabs-container {
    width: 100%;
}

.ordersQuotationPage-tabs-service {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ordersQuotationPage-tabs-service-options {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.ordersQuotationPage-tabs-service-options .searchTextField-container {
    max-width: 100%;
    width: 100%;
}

.ordersQuotationPage-tabs-service .companyList-main {
    margin-top: 20px;
}

.ordersQuotationPage-tabs-service .companyList-main .companyList-list .order-minuature-main{
    flex: 30%;
}

.ordersQuotationPage-mobile  {
    width: 100%;
    display: none;
}

.ordersQuotationPage-tabs-service .ordersQuotationPage-company-mobile {
    display: none;
}


.ordersQuotationPage-tabs-service .servicesOptions-main .searchTextField-container {
    max-width: 100%;
    background-color: var(--white);
}

.ordersQuotationPage-tabs-services .servicesOptions-main .servicesOptions-text .dropdown, .ordersQuotationPage-tabs-service .servicesOptions-main .servicesOptions-text .dropdown .dropdown-select-icon, 
.ordersQuotationPage-tabs-service .servicesOptions-main .servicesOptions-text .dropdown .dropdown-select, .ordersQuotationPage-tabs-service .servicesOptions-text .dropdown .dropdown-list, 
.mordersQuotationPage-tabs-services .servicesOptions-main .servicesOptions-text .dropdown .dropdown-list__item {
    background-color: var(--white);
}

.ordersQuotationPage-mobile-btn--container {
   display: none;
}

.ordersQuotationPage-mobile-btn--row {
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* align-items: center; */
    text-align: center;
}

.ordersQuotationPage-options {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: row;
}

.ordersQuotationPage-main .modalFilter {
    position: absolute;
}

.ordersQuotationPage-mobile-btn{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.ordersQuotationPage-mobile-btn .btn-normal {
    min-width: 48.7%;
    height: 45px;
    padding: 10px 30px;
}

.ordersQuotationPage-loading {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;    
}

.ordersQuotationPage-btn--number {
    font-weight: bolder;
    font-family: 'Faktum-SemiBold';
}

.ordersQuotationPage-company .companyList-main .companyList-list 
.service-minuature-main {
    min-width: 200px;
}

.ordersQuotationPage-headPage {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.ordersQuotationPage-subTitle--mobile {
    display: none;
}

.ordersQuotationPage-filter-icon {
    height: 45px;
    border-radius: 24px;
    background: linear-gradient(111deg, rgba(255, 255, 255, 0.50) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    font-size: 1rem;
    font-family: "Faktum-Medium";
    align-items: center;
    display: none;
    padding: 14.67px;
    gap: 10px;
    backdrop-filter: blur(20px);
}

.ordersQuotationPage-filter-icon div img {
    display: flex;
}


@media (max-width: 1385px) {
    .ordersQuotationPage-services {
        max-width: 310px;
    }
    .ordersQuotationPage-company .companyList-main .companyList-list 
    .service-minuature-main .service-minuature-body .service-minuature-rating {
        gap: 5px;
        width: 100%;
    }
}

@media (max-width: 1280px) {
    .ordersQuotationPage-company .companyList-main .companyList-list 
    .service-minuature-main .service-minuature-body .service-minuature-rating .rating {
        padding: 5px;
        min-width: 51px;
    }
    .ordersQuotationPage-company .companyList-main .companyList-list 
    .service-minuature-main .service-minuature-body .service-minuature-rating .commentCount {
        padding: 5px;
        min-width: 40px;
    }
    .ordersQuotationPage-company .companyList-main .companyList-list 
    .service-minuature-main .service-minuature-body .service-minuature-rating .city-country-display {
        padding: 5px;
        min-width: 60px;
        width: 100%;
    }
}

@media (max-width: 1186px) { 
    .ordersQuotationPage-container {
        gap: 20px
    }
}

@media (max-width: 1165px) {
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .rating span,
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .commentCount span,
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display span {
        font-size: 0.9rem;
    }
}

@media (max-width: 1126px) {
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .rating span,
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .commentCount span,
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display span {
        font-size: 0.8rem;
    }
}


@media (max-width: 1110px) {
    .ordersQuotationPage-company .companyList-main .companyList-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-description {
        font-size: 0.9rem;
    }
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .rating span,
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .commentCount span,
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display span {
        font-size: 0.75rem;
    }
    .ordersQuotationPage-body {
        gap: 30px
    }
}

@media (max-width: 1097px) {
    .ordersQuotationPage-body {
        gap: 20px;
        height: calc(100vh);
    }
}

@media (max-width: 1070px) {
    .ordersQuotationPage-services-quotations .quotationCard {
        padding: 15px;
    }
    .ordersQuotationPage-services-quotations .quotationCard .quotationCard-container 
    .quotation-company .quotationCard-colorText .company-row-items_ {
        font-size: 0.87rem;
    }
    .ordersQuotationPage-services-quotations .quotationCard .quotationCard-container 
    .quotation-company .quotationCard-colorText .company-row-items {
        font-size: 0.8rem;
    }
}

@media (max-width: 1052px) {
    .ordersQuotationPage-company .companyList-main .companyList-list 
    .service-minuature-main .service-minuature-body .service-minuature-rating 
    .city-country-display .city-country--image img {
        width: 1.1rem;
        height: 0.68rem;
    }
}

@media (max-width: 1030px) {
    .ordersQuotationPage-company .companyList-main 
    .companyList-filter-desktop {
        display: none;
    }

    .ordersQuotationPage-company .companyList-main 
    .companyList-filter-mobile {
        display: flex;
    }
}

@media (max-width: 1025px) { 
    .ordersQuotationPage-company .companyList-main .companyList-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .ordersQuotationPage-company .companyList-main .companyList-list 
    .service-minuature-main .service-minuature-body .service-minuature-description {
        height: 60px;
        font-size: 1rem;
    }
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .rating span, 
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .commentCount span, 
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display span {
        font-size: 1rem;
    }
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating {
        justify-content: space-between;
    }
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .commentCount {
        padding: 5px 10px;
        min-width: 52px;
    }
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display {
        padding: 5px 10px;
        min-width: 118px;
        width: 50%;
    }
    .ordersQuotationPage-company .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .rating {
        padding: 5px 10px;
        min-width: 56px;
    }
}

/** laptop */

@media (max-width: 960px) { 
    .ordersQuotationPage-mobile-btn--container {
        position: fixed;
        display: block;
        z-index: 999;
        /* position: fixed; */
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--white-50);
        /* padding: 10px 20px; */
        padding: 15px;
        backdrop-filter: blur(40px);
       
    }
    /* .ordersQuotationPage-container {
        flex-direction: column;
        padding: 40px 20px;
    }     */
    .ordersQuotationPage-services-quotations {
        justify-content: center;
        align-items: center;
    }

    .ordersQuotationPage-services, .ordersQuotationPage-company {
        display: none;
    }

    .ordersQuotationPage-mobile {
        display: flex;
        flex-direction: column;
        gap: 20px
    }

    .ordersQuotationPage-mobile .tabs-main .tabs-header {
        align-items: flex-start;
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .ordersQuotationPage-tabs-service .servicesOptions-main{
        max-width: 100%;
    }

    .ordersQuotationPage-tabs-service .servicesOptions-main .searchTextField-container {
        max-width: 100%;
        background: var(--background);
        box-shadow: 0px 8px 20px rgb(0,0,0,0.15);
        height: 43px;
        border-image-source: var(--border-image-source);
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main {
        max-width: 100%;
        min-width: 200px;
    }
}

/* tablet */
@media (max-width: 960px) { 
    .ordersQuotationPage-tabs-service .companyList-main .companyList-filter-desktop {
        display: none;
    }

    .ordersQuotationPage-tabs-service .companyList-main .filterButton-container {
        display: flex;
    }
 }

@media (max-width: 876px) { 
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .rating span,
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .commentCount span, 
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display span{
        font-size: 0.8rem;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display .city-country--image img {
        width: 1.6rem;
        height: 0.9rem;
    }

}

@media (max-width: 870px) {  
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display {
        min-width: 80px;
    }
}

@media (max-width: 859px) {  
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .rating {
        min-width: 50px;
        padding: 5px;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .commentCount {
        min-width: 46px;
        padding: 5px;
    }
}

@media (max-width: 845px) {  
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-description {
        height: 80px;
    }
}

@media (max-width: 832px) {
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .rating span, 
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .commentCount span, 
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display span {
        font-size: 0.75rem;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display .city-country--image img {
        width: 1.2rem;
        height: 0.8rem;
    }
}

@media (max-width: 830px) {
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-description {
        height: 80px;
    }
}

@media (max-width: 800px) {
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating {
        gap: 5px;
        padding-bottom: 5px;
    }
}

@media (max-width: 790px) {
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list  {
        grid-gap: 15px !important;  
        grid-template-columns: repeat(2, 1fr) !important
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .rating span, .ordersQuotationPage-tabs-service 
    .companyList-main .companyList-list .service-minuature-main .service-minuature-body .service-minuature-rating 
    .commentCount span, .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display span {
        font-size: 1rem;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display {
        min-width: 140px;
        padding: 5px 10px;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main .service-minuature-body 
    .service-minuature-rating .commentCount {
        min-width: 52px;
        padding: 5px 10px;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main .service-minuature-body 
    .service-minuature-rating .rating {
        min-width: 56px;
        padding: 5px 10px;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main .service-minuature-body 
    .service-minuature-description {
        height: 60px;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main .service-minuature-body 
    .service-minuature-rating {
        padding-bottom: 5px;
        gap: 15px
    }
}

@media (max-width: 768px) {
    .ordersQuotationPage-main {
        margin-bottom: 10rem;
    }

    .ordersQuotationPage-main .operation-header .operation-header-right {
        display: none;
    }
    .ordersQuotationPage-mobile-btn--container {
        bottom: 3.2rem;
    }
}

@media (max-width: 669px) {
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .city-country-display {
        min-width: 110px;
        padding: 5px 10px;
    }
}

@media (max-width: 609px) {
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating {
        gap: 10px
    }
}

@media (max-width: 600px) { 
    .ordersQuotationPage-header {
        padding: 20px 20px 0px 20px;
    }
    .ordersQuotationPage-container {
        gap: 0px
    }
    .ordersQuotationPage-filter-icon {
        display: flex;
    }
    .ordersQuotationPage-body {
        padding-top: 15px;
    }
    .ordersQuotationPage-tabs-service {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tabs-container {
        width: 100%;
    }
    .ordersQuotationPage-tabs-service .companyList-main {
        margin-top: 0px;
    }
    .ordersQuotationPage-tabs-service .servicesOptions-main {
        width: 100%;
    }
    .ordersQuotationPage-header .headPage .headPage-subtitle {
        width: 100%;
        line-height: 20px;
    } 
    .ordersQuotationPage-mobile .tabs-main .tabs-header {
        justify-content: flex-start;
    }
    .ordersQuotationPage-header .headPage {
        width: 100%;
        padding-left: 0px;
    }
    .ordersQuotationPage-company-mobile .company-minuature-main .company-minuature-content div .lazyload-wrapper {
        display: none;
    }
    .ordersQuotationPage-company-mobile .company-minuature-content {
        display: flex;
    }
    .ordersQuotationPage-tabs-service-options {
        width: 100%;
        height: 43px;
        display: none;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main .service-minuature-body 
    .service-minuature-rating .rating span, .ordersQuotationPage-tabs-service .companyList-main .companyList-list 
    .service-minuature-main .service-minuature-body .service-minuature-rating .commentCount span{
       font-size: 0.75rem;;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main .service-minuature-body 
    .service-minuature-rating .city-country-display span {
        display: none;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main .service-minuature-body 
    .service-minuature-rating .city-country-display {
        min-width: fit-content;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main .service-minuature-body 
    .service-minuature-rating .city-country-display .city-country--image img {
        width: 22.01px;
        height: 11px;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main .service-minuature-body 
    .service-minuature-rating .rating .rating_pointer-icon img {
        width: 9.17px;
        height: 13.1px;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main {
        min-width: 175px;
    }
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main .service-minuature-body 
    .service-minuature-rating .rating, .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .commentCount{
        padding: 3px 9px;
        height: -moz-fit-content;
        height: fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
    }
}

 @media (max-width: 500px) { 
    .ordersPage-container .modalFilterMobile-container {
        margin: 4rem 2rem 0rem 4rem;
    }
 }

/** celular */
@media (max-width: 480px) { 
    /* .ordersQuotationPage-container{
        margin: 20px;
    } */
    .ordersPage-container .modalFilterMobile-container {
        margin: 4rem 2rem 0rem 3.5rem;
    }
    .ordersQuotationPage-tabs-service-options {
        gap: 10px
    }
    .ordersQuotationPage-tabs-service-options .filterService .paragraph-header,
    .ordersQuotationPage-tabs-service-options .filterService .paragraph {
        font-size: 0.9rem;
    }
    .ordersQuotationPage-tabs-service-options .filterService .filterService-dropdown-content 
    .filterService-dropdown {
        height: 43px;
        padding: 0px 10px;
    }
 }

 @media (max-width: 444px) { 
    .ordersPage-container .modalFilterMobile-container {
        margin: 4rem 2rem 0rem 2.5rem;
    }
    .ordersQuotationPage-tabs-service-options .filterService {
        gap: 10px
    }
    .ordersQuotationPage-tabs-service-options .filterService .paragraph-header, 
    .ordersQuotationPage-tabs-service-options .filterService .paragraph {
        font-size: 0.875rem;
    }
 }

 @media (max-width: 400px) { 
    .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main .service-minuature-body 
    .service-minuature-rating .rating, .ordersQuotationPage-tabs-service .companyList-main .companyList-list .service-minuature-main 
    .service-minuature-body .service-minuature-rating .commentCount{
        padding: 3px 6px;
        height: -moz-fit-content;
        height: fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
    }
    .ordersQuotationPage-tabs-service-options .filterService .paragraph-header, 
    .ordersQuotationPage-tabs-service-options .filterService .paragraph {
        font-size: 0.75rem;
    }
 }

 @media (max-width: 423px) { 
    .ordersPage-container .modalFilterMobile-container {
        max-width: 330px;
    }
 }

 @media (max-width: 388px) { 
    .ordersPage-container .modalFilterMobile-container {
        max-width: 250px;
        margin: 4rem 0rem 0rem 1.5rem;
    }
 }

 @media (max-width: 384px) { 
    .ordersQuotationPage-container .ordersQuotationPage-mobile .tabs-main .tabs-container .ordersQuotationPage-services-quotations .quotationCard {
        max-width: 21.5rem;
    }
    .ordersQuotationPage-container .ordersQuotationPage-mobile .tabs-main .tabs-container .ordersQuotationPage-services-quotations .quotationCard 
    .quotationCard-container .quotationCard-buttons .btn-normal {
        padding: 10px 26px;
    }
 }

 @media (max-width: 380px) { 
    .ordersPage-container .modalFilterMobile-container {
        max-width: 300px;
    }
 }

 @media (max-width: 374px) { 
    .ordersQuotationPage-services-quotations .quotationCard { 
        max-width: 19rem;
    }

    .ordersQuotationPage-services-quotations  .quotationCard-buttons .quotationCard-btns {
        gap: 5px;
    }

    .ordersQuotationPage-services-quotations .quotationCard-buttons .custom-button  {
        /* padding: 10px; */
        font-size: 14px;
        min-width: 120px;
    }

    .ordersQuotationPage-services-quotations .company-row .smalltext-header, 
    .ordersQuotationPage-services-quotations .company-row .smalltext, 
    .ordersQuotationPage-services-quotations .quotationCard-header-left .smalltext-header {
        font-size: 12px;
    }
 }

 @media (max-width: 366px) { 
    .ordersPage-container .modalFilterMobile-container {
        max-width: 350px;
        margin: 4rem 2rem 0rem 2rem;
    }
 }

@media (max-width: 348px) { 
    .ordersPage-container .modalFilterMobile-container {
        max-width: 300px;
        margin: 4rem 0rem 0rem 1.5rem;
    }
}