.userPlanPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 60px 120px 120px 120px;
}

.userPlanPage-goBack {
    width: 100%;
    /* margin-left: 25vh; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.userPlanPage-goBack .goBack-back { 
    margin-left: 7rem;
}

.userPlanPage-content {
    width: 100%;
    max-width: 1125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.userPlanPage-title-section {
    max-width: 721px;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 24px;
    background:var(--black-75);
    backdrop-filter: blur(20px);
}

.userPlanPage-title-img {
    width: 70px;
}

.userPlanPage-title-section__text {
    color: var(--white);
}

@media (max-width: 1360px) {
    .userPlanPage {
        padding: 90px 60px 120px 60px
    }
}

@media (max-width: 1245px) {
    .userPlanPage {
        padding: 50px 0px 50px 00px;
    }
}

@media (max-width: 1045px) {
    .userPlanPage-title-section {
        flex-direction: column;
    }
    .userPlanPage-title-img {
        display: none;
    }
}

@media (max-width: 968px) {

    .userPlanPage-title-section {
        margin: 0px 10px;
       
    }

    .userPlanPage-title-section__text {
        font-size: 1.2rem;
    }
    .userPlanPage-goBack .goBack-back { 
        margin-left: 5rem;
    }
}

@media (max-width: 740px) {
    .userPlanPage-title-section {
        margin: 0px 10px;
        max-width: fit-content;
    }
    .userPlanPage-title-section__text {
        font-size: 1.1rem;
    }
}

@media (max-width: 698px) {
    .userPlanPage-title {
        padding: 0px 20px; 
        text-align: center;
    }

    .userPlanPage-title-section {
      padding: 10px 20px;
    }

    .userPlanPage-title-section__text {
        font-size: .9rem;
        text-align: center;
    }
    .userPlanPage-title-img {
        width: 50px;
    }
}

@media (max-width: 600px) {
    .userPlanPage-content {
        gap: 30px
    }
    .userPlanPage-goBack .goBack-back { 
        margin-left: 2rem;
    }
}

@media (max-width: 370px) {
    .userPlanPage-title-section__text {
        font-size: .8rem;
    }
    .userPlanPage-title-img {
        width: 40px;
    }
}