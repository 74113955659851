/* .phaseCustomsAgency {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
} */
.phaseCustomsAgency-column,
.phaseCustomsAgency-column .customsAgency-phase-six {
    display: flex;
    flex-direction: column;
    gap: 20px
}
.phaseCustomsAgency-text-description {
    font-size: 0.9rem;
}
.phaseCustomsAgency-text-red {
    color: var(--red);
}
.phaseCustomsAgency-phase-six {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.phaseCustomsAgency-column {
    min-width: 100%;
}

/* #phase-one {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 92%;
} */

#phase-one,
#phase-two,
#phase-three,
#phase-four,
#phase-five,
#phase-six,
#phase-seven {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 100%;
    /* scroll-snap-align: center; */
    /* padding-left: 0.5rem; */
}

.phaseCustomsAgency-right {
    padding-right: 0rem;
}

.phaseCustomsAgency-left {
    padding-left: 0.5rem;
}

.phaseCustomsAgency-column .importation-substructure-cargoagentandhscode-card {
    backdrop-filter: blur(20px);
    border-radius: 24px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
}
.phaseCustomAgency-chat-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
}

.phaseCustomAgency-chat-content .headerCustomsPhases-container  {
    width: 100%;
}

.phaseCustomsAgency-provider {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.phaseCustomsAgency-provider .insurance-container .insurance-info{
    display: none;
}

@media (min-width: 600px) {
    #phase-one {
        min-width: 94%;
    }
    .phaseCustomsAgency-left {
        padding-left: 1rem;
    }
    /* .phaseCustomsAgency { 
        gap: 20px;
    } */
}


@media (min-width: 700px) {
    /* .phaseCustomsAgency {
        flex-direction: column;
        gap: 30px;
    } */
    .phaseCustomsAgency .tableQuote,
    .phaseCustomsAgency .trackingShipping-container,
    .phaseCustomsAgency-column {
        min-width: 100%;
    }
    .phaseCustomsAgency-right {
        padding-right: 0rem;
    }
    .phaseCustomsAgency-left {
        padding-left: 0.3rem;
    }
    .phaseCustomAgency-chat-content {
        display: none;
    }
    #phase-one,
    #phase-two,
    #phase-three,
    #phase-four,
    #phase-five,
    #phase-six,
    #phase-seven { 
        padding-left: 0.5rem;
    }
}


@media (min-width: 1024px) {
    .phaseCustomsAgency-text-description {
        font-size: 1rem;
    }
    .phaseCustomsAgency-column .filesFLC-container .filesFLC-files,
    .customsAgency-phase-six  {
        gap: 20px
    }
}