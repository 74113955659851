.planDetail {
    min-width: 880px;
    max-width: 880px;
    width: 100%;
    display: flex;
    padding: 20px;
    align-items: center;
    align-self: center;
    flex-direction: column;

    border-radius: 24px;
    border: 1px solid var(--gray-3);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

.planDetail-black {
    background: var(--gray-1);
}

.planDetail-black .planDetail-content-header,
.planDetail-black .planDetail-content {
    border-bottom: 1px solid #828282;
}

.planDetail-white {
    border: 1px solid #FFF;

    background: linear-gradient(111deg, rgba(255, 255, 255, 0.50) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);

    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(4.5px);
}

.planDetail-white .planDetail-content-header,
.planDetail-white .planDetail-content {
    border-bottom: 1px solid #FFF;
}

.planDetail-content-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 0px 20px 0px;
    border-bottom: 1px solid #3333331a;
}
.planDetail-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0px;
    border-bottom: 1px solid #3333331a;
}
.planDetail___row {
    display: flex;
    flex-direction: row;
    gap: 45px;
    justify-content: space-between;
    width: 100%;
}
.planDetail_min {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    width: 34%;
    align-items: center;
}
.planDetail_min_header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    width: 34%;
    align-items: center;
}
.planDetail___row__right {
    display: flex;
    flex-direction: row;
    gap: 0px;
    justify-content: space-between;
    width: 63%;
    align-items: center;
}
.planDetail__index {
    width: 100%;
    max-width: 140px;
    min-width: 30px;
    text-align: center;
    display: flex;
    gap: 1px;
    justify-content: center;
    flex-direction: column;
}
.planDetail__index_header {
    width: 100%;
    max-width: 150px;
    min-width: 30px;
    text-align: center;
    display: flex;
    gap: 1px;
    justify-content: center;
    flex-direction: column;
}
.planDetail__bottom {
    max-height: -moz-fit-content;
    max-height: fit-content;
    min-height: -moz-fit-content;
    min-height: 25px;
    align-items: center;
}
.planDetail-content .planDetail_title {
    font-family: 'Faktum-Medium';
    font-size: 1rem !important;
}

.planDetail__index div img {
    width: 17px;
}

.planDetail-img {
    width: 12px;
    height: 8px;
}

.planDetail-content-down {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    padding: 20px 0px 0px 0px;
}

@media (max-width: 600px) {
    .planDetail_min_header,
    .planDetail__index_header {
        font-size: 1.2rem;
    }
    .planDetail-content-header {
        padding: 0px 0px 10px 0px;
    }
   .planDetail-content {
        gap: 5px;
        padding: 10px 0px;
    }
     /* .planDetail-content .planDetail_title {
        font-family: 'Faktum-Medium';
        font-size: 0.875rem !important;
    }
    .planDetail_min,
    .planDetail__index {
        font-size: 0.75rem;
    } */
}
