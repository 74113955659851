.modalProcessService {
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: rgba(0,0,0,.15);
  
    left: 0;
    position: fixed;
    top: 0;
    z-index: 11;
}

.modalProcessService-container {
    position: fixed;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    right: calc(50% - 35vw);
    /* top: 4vh; */
    -webkit-backdrop-filter: blur(45px);
    backdrop-filter: blur(45px);
    background: #fff;
    /* border-radius: 24px; */
    box-shadow: 0 3px 9px 0 rgba(0,0,0,.15);
}

.modalProcessService-content {
    width: 100%;
    /* margin-bottom: 30px; */
    margin-bottom: 10px;
    display: flex;
    /* height: 100%; */
    height: 92%;
}

@media (max-width: 1240px) { 
    .modalProcessService-container {
        width: 84%;
        right: calc(50% - 42vw);
    }
}

@media (max-width: 960px) { 
    .modalProcessService-container {
        width: 90%;
        right: calc(50% - 45vw);
    }
}

@media (max-width: 900px) {
    /* .modalProcessService-container {
        margin: auto;
        right: auto;
    } */
}
@media (max-width: 820px) {
    .modalProcessService-container {
        width: 100%;
        margin: auto;
        right: auto;
        padding: 20px;
    }
}

@media (max-width: 600px) {
    .modalProcessService-container:has(.processTitle-title) {
        height: 100%;
        /* top: 10rem; */
    }
    .modalProcessService-container {
        border-radius: 0;
        height: 100%;
        /* padding: 20px 15px; */
        /* padding: 0px 0px 3.4rem 0px; */
        padding: 0px;
        position: relative;
        top: 0;
        width: 100%;
    }
    .modalProcessService-footer--draft {
        display: none;
    }
    .modalProcessService-content  {
        margin-bottom: 0px;
        margin-top: 16px !important;    
        padding: 0px 15px;
    }
}
/* @media (max-width: 400px) {
    .modalProcessService-container:has(.processTitle-title) {
        top: 1rem;
    }
} */