.card-content {
    width: 100%;
    display: flex;
	flex-direction: column;
	padding: 20px;
	gap: 20px;
	backdrop-filter: blur(20px);
	border-radius: 24px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    border: 1px solid;
    border-image-source: var(--border-image-source);
    max-width: 840px;
}