.filterService {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    cursor: pointer;
}
.filterService-dropdown-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px
}
.filterService-dropdown {
    width: fit-content;
    height: 36px;
    background: #FFFFFF;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px;
    gap: 15px;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
}

.filterService-dropdown div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.filterService--options {
    position: absolute;
    background: #FFFFFF;
    border-radius: 24px;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
    margin-top: 3rem;
    overflow: hidden;
    z-index: 1;
    /* height: 100%; */
    /* max-height: 200px; */
    min-height: fit-content;
    min-width: 250px;
    /* max-height: 500px !important; */

}

.filterService--options-search {
    width: 100%;
  
    height: fit-content;
    padding: 8px 10px;
}

.filterService--options-items {
    /* border-radius: 24px; */
    box-sizing: border-box;
    overflow-y: scroll;
}
.filterService--options-background_selected {
    background: var(--gray-5);
}
/* .filterService--options::-webkit-scrollbar {
    display: none;
} */

.filterService--options-item  {
    padding: 10px 20px;

}

.filterService--options-item:hover {
    background: var(--gray-5);
}