.userSearchList {
    position: relative;
    max-width: 793px;
    width: 100%;
    height: 100%;
}

.userSearchList-container {
    /* padding: 10px; */
    position: absolute;
    z-index: 3;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--white);
    border-radius: 12px;
}



@media (max-width: 878px) { 
    .userSearchList-container {
        gap: 0px;
    }
}
