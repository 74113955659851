.event-month-container {
    width: 104%;
    flex-direction: row;
    display: flex;
    z-index: 1;
    align-items: center;
    padding-left: 5px;
    margin-left: 2px;
    cursor: pointer;
}
.event-month-select {
    background: var(--white);
    width: 104%;
    flex-direction: row;
    display: flex;
    z-index: 1;
    align-items: center;
    padding-left: 5px;
    margin-left: 2px;
    border-radius: 3px;
    cursor: pointer;
}
.event-month-body {
    align-self: center;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.event-month-marker {
    width: 4px;
    height: 3px;
    border-radius: 24px;
}
.event-month-marker__high {
    background: var(--red);
}
.event-month-marker__middle {
    background-color: var(--yellow);
}
.event-month-marker__low {
    background-color: var(--blue-2);
}
.event-month-textColor {
    color: var(--black-75);
    font-size: 0.65rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50px;
    text-align: start;
}
.event-month-time {
    color: var(--gray-3);
    font-size: 0.65rem;
}
@media (max-width: 1243px) { 
    .event-month-container {
        width: 100%;
    }
    .event-month-select {
        width: 100%;
    }
}
@media (max-width: 1130px) { 
    .event-month-body {
        width: 90%;
    }
    .event-month-marker {
        width: 3px;
        height: 3px;
        border-radius: 24px;
        padding-right: 2px;
    }
}
/*@media (max-width: 830px) {
    .event-month-container { 
        width: fit-content;
    }

    .event-month-body .event-month-textColor {
        font-size: 0.65rem;
    }
}

@media (max-width: 560px) { 
    .event-month-container {
        max-width: 13.5vw;
    }
}

@media (max-width: 480px) { 
    .event-month-body {
        width: fit-content;
        padding: 5px 0px;
        overflow: hidden;
    }

    .event-month-body .event-month-textColor {
        font-size: 0.5rem;
     
    }

}*/