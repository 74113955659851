.frequentlyQuestions {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
    padding: 150px 120px 50px 120px;
    background: linear-gradient(93deg, rgba(19, 19, 19, 0.05) 32.19%, rgba(255, 193, 87, 0.30) 96.43%);
}

.frequentlyQuestions-text {
    font-size: 2.813rem;
    font-family: 'Faktum-Bold';
    font-weight: 500;
}

.frequentlyQuestions-faq {
    width: 100%;
    max-width: 800px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-direction: column;
    gap: 30px;
}

.frequentlyQuestions-faq .faqCard-content,
.frequentlyQuestions-faq .faqCard-show {
    width: 100%;
}

.frequentlyQuestions-card {
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid var(--bn-white, #FFF);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    color: var(--white);

    cursor: pointer;
}

.frequentlyQuestions-card .custom-button-icon div img,
.frequentlyQuestions-card div img {
    display: flex;
}

.frequentlyQuestions-card-text {
    display: flex;
    align-items: center;
    gap: 5px
}

.frequentlyQuestions-title {
    text-shadow: 0 0 20px rgba(255, 194, 87, 0.4), 
    0 0 30px rgba(255, 194, 87, 0.5), 
    0 0 50px rgba(247, 226, 70, 0.4);
}

.frequentlyQuestions-text-white {
    color: var(--white);
}

.AnimationImages-3 {
    margin-top: 10px;
}

.frequentlyQuestions-card-text-content {
    text-align: center;
}

@media (max-width: 1108px){
    .frequentlyQuestions {
        padding: 120px 60px 50px 60px;
    }
}

@media (max-width: 1101px){
    .frequentlyQuestions-card-button .btnIcon-normal {
        padding: 10px 15px;
        min-width: 220px;
        max-width: 220px;
        width: 100%;
    }
}

@media (max-width: 990px){
    .frequentlyQuestions {
        padding: 120px 30px 50px 30px;
    }
}

@media (max-width: 864px){
    .frequentlyQuestions-faq {
        grid-template-columns: repeat(2, 1fr);
    }
    .frequentlyQuestions-card-text div img {
        display: none;
    }
}

@media (max-width: 768px){
    .frequentlyQuestions {
        padding: 110px 20px 40px 20px;
    }
    .frequentlyQuestions-faq {
        gap: 20px;
    }
}

@media (max-width: 655px){
    .frequentlyQuestions-card {
        flex-direction: column;
    }
    .frequentlyQuestions-card-button {
        width: 100%;
        display: flex;
        justify-content: end;
    }
}

@media (max-width: 600px){
    .frequentlyQuestions {
        gap: 30px
    }
    .frequentlyQuestions-text {
        font-size: 2rem;
    }
    .frequentlyQuestions-faq .faqCard-content .faqCard-content-header .home-questions-subTitle {
        font-size: 1rem;
    }
    .frequentlyQuestions-faq .faqCard-show .faqCard-content-header .home-questions-subTitle {
        font-size: 1rem;
    }
    .frequentlyQuestions-faq .faqCard-show .home-questions-content-answer_ {
        font-size: 0.9rem;
    }
    .frequentlyQuestions {
        padding: 90px 20px 40px 20px;
    }
}

@media (max-width: 450px){
    .frequentlyQuestions-text {
        font-size: 1.9rem;
        text-align: center;
    }
}


@media (max-width: 768px) {
    .frequentlyQuestions-faq{
        grid-template-columns: 1fr; 
        gap: 15px;
    }

    .frequentlyQuestions-faq{
        width: 100%;
        height: auto; 
    }
}