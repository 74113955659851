.modal-culqi-detail-content {
    /* background-color: rgb(0 0 0 / 48%); */
    width: 100%;
}

.modal-culqi-detail-content  .modal-container {
    width: 100%;
    height: 100%;
    max-width: 100%;
    border-radius: 0px;
    margin: 0;
}

.modal-culqi-detail-content .modal-container .modal__header {
    justify-content: end;
}

.modal-culqi-detail-content .modal-container .modal__header .modal__heade-left {
    display: none;
}

.modal-culqi-detail-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
}

.modal-culqi-detail-content_ {
    width: 100%;
    height: calc(100vh - 120px);
    /* max-width: 740px;
    height: calc(100vh - 120px); */
    align-items: flex-start;
    justify-content: center;
    overflow: scroll;
    gap: 40px;
    justify-content: center;
    display: flex
}

.modal-culqi-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.modal-culqi-colorText {
    color: var(--gray-1);
}

.modal-culqi-detail {
    width: 100%;
    max-width: 340px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modal-culqi-detail-title {
    padding-bottom: 20px;
    border-bottom: 1px solid #BDBDBD;
}

.modal-culqi-column {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #BDBDBD;
}

.modal-culqi-firt-row_header {
    width: 100%;
    max-width: 204px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
}

.modal-culqi-firt-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modal-culqi-firt-row-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}

.modal-culqi-firt-row-header-detail {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-right: 0.5rem;
}

.modal-culqi-firt-row-header-detail .rating,
.modal-culqi-firt-row-header-detail .city-country-display {
    border-radius: 6px;
}

.modal-culqi-firt-row-header-detail .rating span,
.modal-culqi-firt-row-header-detail .city-country-display span {
    font-size: 0.75rem;
}

.modal-culqi-firt-row-header-detail .city-country-display 
.city-country--image img {
    width: 20px;
    height: 11.5px;
}

.modal-culqi-firt-column {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.modal-culqi-firt-row_ {
    display: flex;
    align-items: center;
    gap: 5px;
}


/* CHECKOUT */
.modal-culqi-checkout {
    width: 100%;
    max-width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modal-culqi-checkout-header {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.modal-culqi-checkout .creditCard-form,
.modal-culqi-checkout .creditCard-form .creditCard {
    height: 100%;
}

.modal-culqi-checkout .creditCard-form .creditCard #creditCard-culqi {
    height: 100%;
    max-width: 350px;
}

@media (max-width: 950px) {
    .modal-culqi-detail-container {
        gap: 20px
    }
    .modal-culqi-checkout .creditCard-form .creditCard #creditCard-culqi {
        height: 100%;
        max-width: 420px;
    }
}

@media (max-width: 880px) {
    .modal-culqi-checkout .creditCard-form .creditCard #creditCard-culqi {
        height: 100%;
        max-width: 400px;
    }
    .modal-culqi-checkout {
        max-width: 350px;
    }
}

@media (max-width: 800px) {
    .modal-culqi-checkout .creditCard-form .creditCard #creditCard-culqi {
        height: 100%;
        max-width: 350px;
    }
    .modal-culqi-checkout {
        max-width: 335px;
    }
}

@media (max-width: 775px) {

    .modal-culqi-detail {
        display: none;
    }

    .modal-culqi-detail-container {
        flex-direction: column;
        justify-content: flex-start;
    }
    .modal-culqi-detail-container {
        height: 100%;
    }
    .modal-culqi-checkout {
        max-width: 100%;
        max-width: 443px;
    }
    .modal-culqi-checkout .creditCard-form .creditCard #creditCard-culqi {
        max-width: 100%;
    }
}