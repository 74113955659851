.plan-content {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

/*PLAN SELECTED WHITE*/
.plan-selected-white .plan-gradient {
    background: var(--white-90) !important;
}

.plan-selected-white .plan-gradient .plan .plan-body-first 
.plan-header-price  .plan-header .plan-text-white{
    color: var(--black);
}
.plan-selected-white .plan-gradient .plan .plan-body-first 
.plan-detail-row .plan-text-white {
    color: var(--black) !important;
}

.plan-selected-white .plan-gradient::before,
.plan-selected-white .plan-gradient::after  {
    border: 0px solid #113D7F !important;
}
.plan-selected-white .plan-gradient::after {
    border: 0px solid #113D7F !important; 
}

/*PLAN SELECTED BLACK*/
.plan-selected-black .plan-gradient {
    background: var(--black-75) !important
}

.plan-selected-black .plan-gradient .plan .plan-body-first 
.plan-header-price  .plan-header .plan-text-black {
    color: var(--white);
}
.plan-selected-black .plan-gradient .plan .plan-body-first 
.plan-detail-row .plan-text-black {
    color: var(--white) !important;
}

.plan-gradient {
    min-width: 230px;
    max-width: 230px;
    min-height: 215px;
    /* height: fit-content; */
    display: flex;
    width: 230px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: var(--gray-1, #333);;
    
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);

    border: 1px solid var(--bn-gray-3, #828282);
}

.plan-black .plan-gradient:hover {
    background: var(--white-25);
}

.plan-selected-black .plan-gradient-popular.selected,
/* .plan-black .plan-gradient:hover, */
.plan-black .plan-gradient-popular,
.plan-black .plan-gradient.selected  {
    width: 100%;
    cursor: pointer;
    font-size: 17px;
    font-weight : normal;
    color: #fff;
    outline : none;
    border: 5px solid #F7E246;
    position: relative;
    z-index: 2;
    font-size: 14px;
    font-weight: bold;
    padding: 20px;
    border-radius: 22px;
    background: var(--bn-gray-1, #333);
    color: #fff;
}

.plan-black .plan-popular {
    margin-top: -0.7rem;
    position: absolute;
    max-width: fit-content !important;
    justify-content: center;
    display: flex;
    align-items: center;
    align-self: center;
    border-radius: 90px;
    background: linear-gradient(93deg, #F7E246 -0.3%, #FFC525 74.94%);
    padding: 5px 30px;
    z-index: 3;
}

.plan-black .plan-popular div {
    color: var(--black);
}

.plan-text-black {
    color: var(--black);
}

.plan {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    scroll-snap-align: center;
}

.plan-body-first {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.plan-price {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.plan-price .big-header {
    font-size: 2.125rem;
    font-family: 'Faktum-Medium';
}

.plan-price--currency{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.plan-price__text {
    display: flex;
    
}

.plan-header-price {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.plan-header {
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-direction: column;
    text-align: start;
    align-items: self-start;
}

.plan-text-title {
    font-size: 1.4rem;
}

.plan-text-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.plan-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px
}

.plan-detail-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
}

.plan-button {
    /* background-image: url(../../../public/images/png/selectBackground.png); */
    width: 100%;
    border-radius: 20px;
    cursor: pointer;
    padding-top: 5px;
}

.plan-button button {
    min-width: 100%;
    padding: 10px 25px;
    border-radius: 24px;
    backdrop-filter: blur(20px);
    background-color: transparent!important;
    font-size: 0.875rem;
    font-family: 'Faktum';
}

.plan-button-black button{
    border: 1px solid var(--black);
    color: var(--black);
}

.plan-selected-black .plan-gradient .plan .plan-body-first 
.plan-button-selected button {
    border: 1px solid var(--white) !important;
    color: var(--white) !important;
}


.plan-selected-black .plan-gradient .plan .plan-body-second 
.plan-detail-row .plan-text {
    color: var(--white);
}

.plan-announcement {
    width: 100%;
    text-align: center;
}  

.plan-footer {
    color: #828282
}

.plan-detail-icon .plan-detail-icon-check img {
    width: 18.39px;
    height: 15.73px;
}

.plan-detail-icon .plan-detail-icon-close img {
    width: 14px;
    height: 14px;
}


/* WHITE */
.plan-white {
    cursor: pointer;
}

.plan-white .plan-gradient {
    background: linear-gradient(111deg, rgba(255, 255, 255, 0.50) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);

    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(4.5px);
}

/* .plan-white .plan-gradient:hover, */
.plan-white .plan-gradient-popular,
.plan-white .plan-gradient.selected  {
    border: 6px solid var(--black-75);
    padding: 15px;
}

.plan-white .plan-gradient:hover {
    background: var(--black-25);
}

.plan-white .plan-popular {
    margin-top: -0.7rem;
    position: absolute;
    max-width: fit-content !important;
    justify-content: center;
    display: flex;
    align-items: center;
    align-self: center;
    border-radius: 90px;
    background: linear-gradient(93deg, #404040 -0.3%, #000000 74.94%);
    padding: 5px 30px;
    z-index: 3;
}

.plan-white .plan-popular div {
    color: var(--white);
}

.plan-selected-white .plan-gradient .plan .plan-body-second 
.plan-detail-row .plan-text {
    color: var(--black);
}

.plan-button-white button{
    border: 1px solid var(--white);
    color: var(--white);
}

.plan-selected-white .plan-gradient .plan .plan-body-first 
.plan-button-selected button {
    border: 1px solid var(--black) !important;
    color: var(--black) !important;
}

.plan-text-white {
    color: var(--white);
}

.plan-body-second {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


@media (max-width: 1045px) { 
    .plan-detail-column {
        display: none;
    }
}