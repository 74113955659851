.modalLateral .modal-main {
    justify-content: flex-end;
    align-items: flex-start;
}

.modalLateral .modal-main .modal-container {
    max-width: 427px;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0px;
    overflow: hidden;
    gap: 0px;
}