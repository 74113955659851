.checkbox-container {
	display: flex;
	align-items: center;
	width: fit-content;
	position: relative;
	border-radius: 6px;

	background: var(--black-05);
    border: 0px solid var(--gray-3);
}

.checkbox-container_white {
	background-color: var(--white) !important;
}

.checkbox-container input[type="checkbox"] {
	cursor: pointer;
	opacity: 0;
	width: 32px;
	height: 32px;
	position: absolute;
}

.checkbox-container label {
	display: flex;
}


.checkbox-container label::before {
	content: '';
	width: 32px;
	height: 32px;
	border-radius: 6px;
	background: transparent;
	box-sizing: border-box;
	left: 0;
	pointer-events: none;
}

.checkbox-check {
	display: none;
	pointer-events: none;
	position: absolute;
    left: calc(50% - 8px);
}

.checkbox-container input[type="checkbox"]:checked ~ .checkbox-check {
	display: flex;
}

.checkbox-disabled input[type="checkbox"] {
	cursor: auto;
}

.checkbox-disabled label::before {
	background: var(--black-05);
}