.signin-btn {
	height: 40px;
    padding: 10px 40px;
	background-color: transparent;
	cursor: pointer;
	border-radius: 12px;
	border: 1px solid var(--gray-3);
	/* box-shadow: 0px 8px 20px var(--black-15); */
	backdrop-filter: blur(40px);
	color: var(--white);
} 