.tableComparePlanMin__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 10px;
}

.tableComparePlanMin__content_header {
    display: flex;
    flex-direction: column;
    gap: 30px
}

.tableComparePlanMin {
    font-family: 'Faktum';
    font-weight: 500;
    font-size: 0.9rem;
    font-style: normal;
}

.tableComparePlanMin-important {
    color: #000;
    font-family: Faktum;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.tableComparePlanMin___row {
    display: flex;
    flex-direction: row;
    gap: 45px;
    justify-content: space-between;
    width: 100%;
}

.tableComparePlanMin___row_min {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    width: 35%;
    align-items: center;
}

.tableComparePlanMin___row__right {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    width: 65%;
    align-items: center;
}

.tableComparePlanMin__index {
    width: 100%;
    max-width: 140px;
    min-width: 30px;
    text-align: center;
}

.tableComparePlanMin__index div {
    display: flex;
    justify-content: center;
}

.tableComparePlanMin__bottom {
    border-bottom: 1px solid var(--black-15);
    max-height: 52px;
    min-height: fit-content;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    align-items: center;
}

.tableComparePlanMin__column {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.tableComparePlanMin__column__min {
    display: flex;
    flex-direction: column;
}

.tableComparePlanMin__index .btn-normal {
    padding: 10px 24px;
}

.tableComparePlanMin-important-paragraph {
    color: #000;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Faktum;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.tableComparePlanMin-paragraph {
    color: #333;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Faktum;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.tableComparePlanMin-small {
    color: #333;
    text-align: center;
    font-family: Faktum;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.tableComparePlanMin___row__right .btn-normal {
    padding: 0px 17px;
    height: 35px;
    min-width: fit-content;
    font-size: 0.875rem;
}

.tableComparePlanMin___row__right .tagButton-main {
    max-width: fit-content;
    padding: 5.671px 15px;
}

@media (max-width: 1196px) { 
    .tableComparePlanMin___row {
        gap: 20px
    }
}

@media (max-width: 981px) { 
    .tableComparePlanMin__content {
        padding: 0px;
    }
    .tableComparePlanMin__content_header {
        gap: 20px
    }
    .tableComparePlanMin__content {
        gap: 35px
    }
    .tableComparePlanMin-important,
    .tableComparePlanMin-small {
        font-size: 18px;
    }
    .tableComparePlanMin-paragraph,
    .tableComparePlanMin,
    .tableComparePlanMin___row_min,
    .tableComparePlanMin-important-paragraph {
        font-size: 0.875rem;
    }
    .tableComparePlanMin___row__right .tagButton-main .smalltext-header {
        font-size: 10px;
    }
}

@media (max-width: 796px) { 
    /* .tableComparePlanMin___row__right .btn-normal {
        height: 29px;
        padding: 0px 7px;
        font-size: 0.7rem;
    } */
    
    .tableComparePlanMin___row__right {
        gap: 10px
    }
    .tableComparePlanMin-important, .tableComparePlanMin-small {
        font-size: 16px;
    }
    .tableComparePlanMin-paragraph, .tableComparePlanMin, .tableComparePlanMin___row_min, .tableComparePlanMin-important-paragraph {
        font-size: 0.85rem;
    }
    .tableComparePlanMin__content {
        gap: 30px
    }
}

@media (max-width: 768px) { 
    .tableComparePlanMin__content_header {
        gap: 30px
    }
    .tableComparePlanMin___row_min {
        width: 35%;
    }å
    .tableComparePlanMin___row__right .btn-normal {
        /* padding: 10px 24px;
        min-width: -moz-fit-content;
        min-width: fit-content;
        height: 40px; */
        font-size: 0.8rem;
    }
    
    .tableComparePlanMin-important, 
    .tableComparePlanMin-small {
        font-size: 18px;
    }
    .tableComparePlanMin___row__right .tagButton-main {
        padding: 5.671px 10px;
    }
    .tableComparePlanMin___row__right .tagButton-main .smalltext-header {
        font-size: 12px;
    }
}

@media (max-width: 652px) {  
    .tableComparePlanMin___row__right .btn-normal {
        font-size: 0.75rem;
    }
    .tableComparePlanMin__content {
        gap: 20px;
    }
}

@media (max-width: 530px) {  
    .tableComparePlanMin-paragraph, .tableComparePlanMin, .tableComparePlanMin___row_min, .tableComparePlanMin-important-paragraph {
        font-size: 0.875rem;
    }
    .tableComparePlanMin-important,
    .tableComparePlanMin-small {
        font-size: 0.875rem;
    }
    .tableComparePlanMin___row__right .tagButton-main .smalltext-header {
        font-size: 10px;
    }
    .tableComparePlanMin__content_header {
        gap: 15px
    }
    .tableComparePlanMin___row__right .btn-normal {
        height: 35px;
        padding: 0px 10px;
    }
    .tableComparePlanMin_contain {
        padding: 15px;
    }
}

@media (max-width: 490px) {  
    .tableComparePlan___row_min{
        font-size: 0.75rem !important;
    }
}

@media (max-width: 470px) {  
    .tableComparePlanMin___row__right .btn-normal {
        font-size: 0.72rem;
    }
}

@media (max-width: 449px) {  
    .tableComparePlanMin___row__right .btn-normal {
        padding: 0px 7px;
    }
}

@media (max-width: 405px) {  
    .tableComparePlanMin___row {
        gap: 5px
    }
    .tableComparePlanMin___row_min {
        width: 30%;
    }
    .tableComparePlanMin___row__right {
        gap: 5px;
        width: 70%;
    }
}