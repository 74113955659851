.packages-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.packages-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.packages-row-content {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.packages-row .dropdown {
    max-width: 100%;
}

.packages-row .inputElement-container {
    width: 100%;
}

.packages-colorBlack {
	color: var(--gray-0);
}

.packages-add-total {
    display: flex;
    flex-direction: row;
    gap: 5px
}

.packages-trash {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	cursor: pointer;
}

.packages-colorText {
	color: var(--gray-1)
}

.packages-xColor {
    color: var(--gray-3);
}

.packages-add {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.packages-add--text {
    cursor: pointer;
    color: var(--blue-2);
}

.packages-row-second-mobile {
    display: none; 
}

.packages-row-first-mobile {
    display: none;
}

@media (max-width: 700px) {  
    .packages-row-content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .packages-row-content .dropdown,
    .packages-row-content .inputElement-container,
    .packages-row-content .inputElement-container .inputElement {
       max-width: 100%;
       min-width: 100%
    }
    .packages-row-second {
        display: none;
    }
    .packages-row-second-mobile {
        display: flex; 
        flex-direction: row;
    }
    /* .packages-row-second-mobile .dropdown {
        max-width: 100%;
    } */
    .packages-row-second-mobile-1 {
        width: 230%;
    }
    .packages-row-second-mobile-1 .packages-xColor {
        display: none;
    }
    .packages-row-second-mobile .dropdown .dropdown-select {
        display: flex;
        width: 100%;
    }
}

@media (max-width: 639px) { 
    .packages-row-second-mobile-1 {
        width: 77%;
    }
    .packages-row-second-mobile-1 .inputElement-container .inputElement{
        min-width: 90px;
    }
}

@media (max-width: 600px) {  
    .packages-row-second-mobile-1 {
        gap: 10px;
    }
    .packages-colorText {
        font-size: 0.875rem;
    }
    .packages-add-total span,
    .packages-add-total .lclcd-paquete-colorBlack {
        font-size: 1rem;
    }
    .packages-row-content {
        display: none;
    }
    .packages-row-first-mobile {
        display: flex;
    }
    .packages-row-first-mobile__1 .dropdown {
        max-width: 50%;
    }
    .packages-row-first-mobile__1 .inputElement-container {
        width: 100%;
    }
    .packages-row-first-mobile__2 .dropdown {
        width: 40%;
    }
    .packages-row-first-mobile__2 .inputElement-container {
        width: 70%;
    }
    .packages-row-first-mobile__2 .inputElement-container .inputElement input {
        width: 100%;
        min-width: 190px;
    }
    /* .packages-row-second-mobile-1 .inputElement-container .inputElement .inputElement-focus {
        left: 13px;
    } */
}

@media (max-width: 566px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 38%;
    }
}

@media (max-width: 561px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 37%;
    }
}

@media (max-width: 555px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 36%;
    }
}

@media (max-width: 555px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 36%;
    }
}

@media (max-width: 551px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 35%;
    }
}

@media (max-width: 546px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 33%;
    }
}

@media (max-width: 537px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 31%;
    }
}

@media (max-width: 525px) {  
    .packages-row-second-mobile-1 {
        gap: 7px;
    }
}

@media (max-width: 523px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 30%;
    }
}

@media (max-width: 518px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 29%;
    }
}

@media (max-width: 515px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 28%;
    }
}

@media (max-width: 508px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 27%;
    }
}

@media (max-width: 504px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 26%;
    }
}

@media (max-width: 495px) {  
    .packages-row-first-mobile__2 .dropdown {
        width: 25%;
    }
}

@media (max-width: 494px) {  
    .packages-row-second-mobile-1 .inputElement-container .inputElement {
        min-width: 80px;
    }
    .packages-row-first-mobile__2 .inputElement-container {
        width: 64%;
    }
    .packages-row-first-mobile__2 .dropdown {
        width: 31%;
    }
}

@media (max-width: 491px) {  
    .packages-row-first-mobile__2 .inputElement-container {
        width: 65%;
    }
}

@media (max-width: 486px) {  
    .packages-row-first-mobile__2 .inputElement-container {
        width: 68%;
        min-width: 65%;
    }
}

@media (max-width: 478px) {  
    .packages-row-first-mobile__2 .inputElement-container {
        width: 68%;
        min-width: 68%;
    }
}

@media (max-width: 462px) {  
    .packages-row-first-mobile__2 .inputElement-container {
        width: 69%;
        min-width: 69%;
    }
}


@media (max-width: 460px) {  
    .packages-row-first-mobile__2 .inputElement-container {
        width: 61%;
        min-width: 61%;
    }
    .packages-row-first-mobile__2 .dropdown {
        width: 34%;
    }
    .packages-row-second-mobile-1 .inputElement-container .inputElement {
        min-width: 70px;
    }
}

@media (max-width: 456px) {  
    .packages-row-second-mobile,
    .packages-row-first-mobile {
        gap: 15px
    }
    .packages-row-second-mobile-1 {
        width: 260%;
    }
    .packages-row-first-mobile__2 .inputElement-container {
        width: 69%;
        min-width: 69%;
    }
}

@media (max-width: 439px) {  
    .packages-row-second-mobile,
    .packages-row-first-mobile {
        gap: 10px
    }
    /* .packages-row-second-mobile-1 .inputElement-container .inputElement  
    .inputElement-focus {
        left: 13px;
    } */
    .packages-row-second-mobile-1 .inputElement-container .inputElement 
    .inputElement-input {
        padding: 22.5px 20px 7.5px 13px;
    }
    .packages-row-first-mobile__2 .inputElement-container {
        width: 70%;
        min-width: 70%;
    }
}

@media (max-width: 427px) {  
    .packages-row-second-mobile-1 .inputElement-container .inputElement {
        min-width: 65px;
    }
}

@media (max-width: 404px) { 
    .packages-row-second-mobile-1,
    .packages-row-first-mobile__2 {
        gap: 5px 
    }
    .packages-row-first-mobile_ {
        gap: 10px
    }
    .packages-row-second-mobile-1 .inputElement-container .inputElement {
        min-width: 60px;
    }
    .packages-row-second-mobile {
        gap: 5px
    }
}

@media (max-width: 392px) { 
    .packages-row-second-mobile-1 .inputElement-container .inputElement  
    .inputElement-focus {
        left: 13px;
    }
    .packages-row-second-mobile-1 .inputElement-container .inputElement .inputElement-input-title {
        padding: 22.5px 20px 7.5px 13px !important;
    }
}

@media (max-width: 387px) { 
    .packages-row-first-mobile__2 .inputElement-container {
        width: 71%;
        min-width: 71%;
    }
}

@media (max-width: 379px) { 
    .packages-row-first-mobile__2 .inputElement-container .inputElement input {
        width: 100%;
        min-width: 100px;
    }
}