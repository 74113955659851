.filterSelectorExtended {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.filterSelectorExtended-main{
    position: relative;
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    /* max-width: 840px; */
    gap: 15px;
    /* backdrop-filter: blur(40px);
    border-radius: 24px; */
    /* background: var(--background); */
    /* border: 1px solid;
    border-image-source: var(--border-image-source); */

}

.filterSelectorExtended-item-content-dropdown {
    /* display: flex;
    flex-direction: column; */
    /* min-width: 167.5px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.filterSelectorExtended-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: var(--gray-1);
}

.filterSelectorExtended-item div img {
    display: flex;
    width: 10px;
}

.filterSelectorExtended-item-selected {
    /* width: 100%; */
    background: var(--black-75);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: var(--white);
    padding: 10px 25px;
    border-radius: 24px;
    height: 45px;
}

.filterSelectorExtended-item-selected div {
    display: flex;
}

.filterSelectorExtended-item-selected div img {
    width: 10px;
}

.filterSelectorExtended-item-selected .filterSelectorExtended-text-filter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.filterSelectorExtended-checkbox {
    width: 24px;
    height: 24px;
    border: 1.5px solid var(--gray-1);
    box-sizing: border-box;
    border-radius: 6px;
    appearance: none;
    /* color: red; */
    /* transform: scale(0); */

    display: grid;
    place-content: center;

    appearance: none;
    background-color:transparent;
    margin: 0;
    font: inherit;
    color: currentColor;
    /* width: 1.15em;
    height: 1.15em; */
    /* border: 0.15em solid currentColor; */
    /* border-radius: 0.15em; */
    transform: translateY(-0.075em);


}

.filterSelectorExtended-checkbox::before {
    content: "";
    width: 15px;
    height: 15px;
    transform: scale(0);
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    box-shadow: inset 1em 1em var(--gray-1); 
}


.filterSelectorExtended-checkbox:checked::before {
    transform: scale(1);
 }

 .filterSelectorExtended-item {
    background: var(--white);
    /* background-image: var(--border-image-source); */
    backdrop-filter: blur(20px);
    padding: 0px 15px;
    border-radius: 24px;
    height: 36px;
    border: 1.5px solid #181818;
    justify-content: center;
    align-items: center;
 }

 .filterSelectorExtended-item .city-country-display {
     background-color: unset;
 }

.filterSelectorExtended--arrow {
   display: flex;
   align-items: center;
}

.filterSelectorExtended--options {
    max-width: 265px;
    width: 100%;
    position: absolute;
    top: 50px;
    z-index: 9;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(40px);
    border-radius: 24px;
    border: 1px solid;
    border-image-source: var(--border-image-source);
    overflow: hidden;
    background: var(--white);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}

.filterSelectorExtended--options-background {
    background: var(--white);
   
}

.filterSelectorExtended--options-background_selected {
    background: var(--gray-5);
    display: flex;
    align-items: center;
    gap: 10px;
}

.filterSelectorExtended--options-item{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    color: var(--black);
    cursor: pointer;
    gap: 20px
}

.filterSelectorExtended--options-item:hover {
    background: var(--gray-5);
}

.filterSelectorExtended--options-keyboards {
    max-width: 265px;
    width: 100%;
    position: absolute;
    top: 50px;
    left: 183px;
    z-index: 9;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(40px);
    border-radius: 24px;
    border: 1px solid var(--white);
    
    /* overflow: scroll; */
    background: var(--white);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
    overflow-x: hidden;
}

.filterSelectorExtended--options-content {
    height: calc(100vh - 500px);
    overflow-y: scroll;
}
.filterSelectorExtended--options-keyboards--none-search {
    max-height: calc(100vh - 600px);
    height: 100%;
    min-height: fit-content;
}

/* Inicio del scroll */ 
.filterSelectorExtended--options-keyboards::-webkit-scrollbar {
    width: 0.4rem;
}

.filterSelectorExtended--options-keyboards::-webkit-scrollbar-thumb {
    background-color: var(--gray-4);
    border-radius: 12px;
}

/* Fin del scroll */ 

.filterSelectorExtended-item div {
    display: flex;
}

.filterSelectorExtended-categorie {
    display: none;
}

.filterSelectorExtended--options-item img {
    width: 1.7rem
}

.filterSelectorExtended--options-keyboards .servicesOptions-main {
    display: none;
}

@media (max-width: 1100px) {
    
    .serviceHomePage-container .serviceHomePage-services {
        max-width: 100%;
        width: 100%;    
    }
    
    .serviceHomePage-container .serviceHomePage-services .big-header {
        text-align: center;
    }

    .serviceHomePage-services .servicesOptions-main {
        max-width: 100%;
    }

    .serviceHomePage-services .servicesOptions-main .searchTextField-container {
        max-width: 100%;
    }
    
    .filterSelectorExtended-main {
        justify-content: space-between;
        gap: 10px;
    }

    .filterSelectorExtended-item {
        /* width: 100%; */
        text-align: center;
        align-items: center;
    }
} 

/** tablet */
@media (max-width: 5000px) { 
    .filterSelectorExtended-item {
        background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
        backdrop-filter: blur(20px);
        box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
        border-image-source: var(--border-image-source);
    } 
}

@media (max-width: 1083px) { 
    .filterSelectorExtended-item-content-dropdown .filterSelectorExtended-item .filterSelectorExtended-text-filter {
        /* max-width: 150px; */
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: end;
    }
}

@media (max-width: 1013px) { 
    .filterSelectorExtended-item-content-dropdown .filterSelectorExtended-item .filterSelectorExtended-text-filter {
        /* max-width: 130px; */
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: end;
    }
}

@media (max-width: 960px) {
    .filterSelectorExtended-categorie {
        display: flex;
        position: relative;
        width: auto;
    }
    .filterSelectorExtended-item-content-dropdown .filterSelectorExtended-item .filterSelectorExtended-text-filter {
        font-size: 0.9rem;
        max-width: 100px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: start;
    }
    .filterSelectorExtended-categorie .filterSelectorExtended-item {
        width: auto;
        align-items: center;
    }
    .filterSelectorExtended-categorie .filterSelectorExtended-item img { 
        height: 1.4rem;
    }
    .filterSelectorExtended-categorie .filterSelectorExtended-item div {
        display: flex;
    }
    .filterSelectorExtended-categorie .filterSelectorExtended-item div img {
        height: 0.4rem;
    }

    .filterSelectorExtended-categorie .filterSelectorExtended--options {
        max-width: 260px;
        width: 260px;
    }
    .filterSelectorExtended-main {
        width: 265px;
    }
    .filterSelectorExtended--options-item {
        font-size: 0.9rem;
        gap: 0.7rem;
        align-items: center;
    }
    /* .filterSelectorExtended--options-keyboards {
        max-width: 265px;
        height: 265px;
        overflow: scroll;
        overflow-x: hidden;
    } */
    .filterSelectorExtended--options-keyboards {
        gap: 1rem
    }
    .filterSelectorExtended--options-keyboards::-webkit-scrollbar {
        width: 0px;
    }
}

@media (max-width: 959px) {
    .filterSelectorExtended--options-content {
        height: calc(100vh - 620px);
    }
}

@media (max-width: 881px) {
    .filterSelectorExtended-item {
        padding: 10px 20px;
    }
    .filterSelectorExtended-main {
        width: 245px;
    }
}

@media (max-width: 834px) {
    .filterSelectorExtended-categorie .filterSelectorExtended-item {
        width: auto;
    }
}

@media (max-width: 879px) {
    .filterSelectorExtended-categorie .filterSelectorExtended-item {
        display: flex;
        padding: 10px 14px;
    }
    .filterSelectorExtended-item {
        padding: 10px 20px;
    }
}

@media (max-width: 799px) {
    .filterSelectorExtended-item {
        padding: 10px 17px;
        /* height: 40px; */
    }
    .filterSelectorExtended-categorie .filterSelectorExtended-item img { 
        height: 1.2rem;
    }
    .filterSelectorExtended-main {
        width: 233px;
    }
}

@media (max-width: 778px) {
    .filterSelectorExtended-item {
        padding: 10px 15px;
        /* height: 40px; */
    }
}

@media (max-width: 770px) {
    .filterSelectorExtended-main {
        width: 225px;
    }
    .filterSelectorExtended-categorie .filterSelectorExtended-item {
        width: auto;
    }
}

@media (max-width: 768px) {
    .filterSelectorExtended-content {
        display: flex;
        flex-direction: column;
    }
    .filterSelectorExtended--options-keyboards .servicesOptions-main {
        display: flex;
        padding: 5px;
    }
    .filterSelectorExtended--options-content {
        height: calc(100vh - 600px);
        overflow-y: scroll;
    }
    .filterSelectorExtended-item-content-dropdown .filterSelectorExtended-item .filterSelectorExtended-text-filter {
        max-width: 150px;
    }
}


@media (max-width: 700px) {
    .filterSelectorExtended--options-keyboards {
        left: auto;
        right: 0px;
        top: 55px;
    }

    .filterSelectorExtended--options-keyboards .searchTextField-container{
        margin: 6px;
    }
}

@media (max-width: 690px) { 
    .filterSelectorExtended-item-content-dropdown .filterSelectorExtended-item .filterSelectorExtended-text-filter {
        max-width: 100px;
    }
}

@media (max-width: 600px) { 
    .filterSelectorExtended{
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        width: 100%;
    }
    .filterSelectorExtended-item-content-dropdown,
    .filterSelectorExtended-main,
    .filterSelectorExtended-categorie .filterSelectorExtended-item {
        width: 100% !important;
    }
    .filterSelectorExtended-item-content-dropdown .filterSelectorExtended-item .filterSelectorExtended-text-filter {
        font-size: 1rem;
    }
    .filterSelectorExtended-categorie .filterSelectorExtended-item {
        height: 52px;
        align-items: center;
    }
    .filterSelectorExtended-categorie .filterSelectorExtended-item img { 
        height: 1.8rem;
    }
    .filterSelectorExtended-item {
        /* height: 52px; */
        align-items: center;
    }
    .filterSelectorExtended-categorie .filterSelectorExtended-item .filterSelectorExtended-text-filter {
        width: 100%;
        max-width: 430px;
        text-align: start;
        min-width: 50px;
    }

    .filterSelectorExtended-categorie .filterSelectorExtended--options {
        top: 64px;
    }

    .filterSelectorExtended--options-keyboards {
        max-width: 230px;
        right: 0rem;
    }
    .filterSelectorExtended-item-content-dropdown .filterSelectorExtended-item .filterSelectorExtended-text-filter {
        max-width: fit-content;
    }

    /* .filterSelectorExtended-item {
        width: 100%;
        background: var(--white);
        border: 1.5px solid #181818;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.15);
    } */
    .filterSelectorExtended-categorie .filterSelectorExtended-item .filterSelectorExtended-text-filter {
        max-width: 100%;
    }
    .filterSelectorExtended-text-filter {
        font-size: 0.875rem!important;
    }
}

@media (max-width: 550px) {
    .filterSelectorExtended-main {
        /* flex-direction: column; */
        align-items: flex-start;
        gap: 20px;
    }

    .filterSelectorExtended-item {
        background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
        backdrop-filter: blur(20px);
        box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.09);
    }
}