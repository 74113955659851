.ordersQuotationDetailPage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    position: relative;
    height: calc(100vh - 110px);
}

.ordersQuotationDetailPage-container .customsAgency-container-header-mobile {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px 50px;
}

.ordersQuotationDetailPage-container-header-mobile {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px 50px;
}

.ordersQuotationDetailPage-container-header-mobile .buttonBadget {
    display: none;
}

.ordersQuotationDetailPage-container-header-mobile .buttonBadget {
    box-shadow: 0 0px 0px 0 rgba(0,0,0,.15);
}

.ordersQuotationDetailPage-container .customsAgency-container-header-mobile .buttonBadget div div {
    display: flex;
}

.ordersQuotationDetailPage-container-row {
    max-width: 1440px;
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-self: center; 
    padding: 0px 20px 10px 20px;
    height: calc(100vh - 120px);
}

/*Scroll x */
.ordersQuotationDetailPage-menu-scroll {
    display: flex;
    gap: 20px;
    min-width: 340px;
    max-width: 400px;
    flex-direction: column;
    height: 60px;
    align-items: center; 
    position: sticky;
    top: 6rem;
    z-index: 3;
    flex: 1 1;
}
.ordersQuotationDetailPage-chat .customsAgency-chat-content {
    margin: 0.5rem 0rem;
}

.ordersQuotationDetailPage-menu-scroll .ordersQuotationDetailPage-menu-scroll-multimedia {
    max-width: 440px;
    width: 100%;
}
.ordersQuotationDetailPage-menu-scroll div .multimedia-container{
    width: 440px;
    max-width: 100%;
}

.ordersQuotationDetailPage-chat {
    max-width: 880px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
    transform-origin: left top;
    flex-grow: 1;
    position: relative;
    height: 100%;
    overflow: hidden;
    transform: translateZ(0);
    height: 100%;
}

.ordersQuotationDetailPage-mobile--orders {
    display: none;
}

.ordersQuotationDetailPage-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    flex: 1 1;
    order: 1;
    padding: 1.4rem 0.9rem 0rem 0.9rem;
    padding-bottom: 10rem;
}

.ordersQuotationDetailPage-content-chat {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    flex: 1 1;
    order: 1;
    padding:  1rem 0.9rem 0rem 0.5rem;
}

.ordersQuotationDetailPage-content-chat .tableQuote .tableQuote-currecy .dropdown {
    width: 20%;
    max-width: 150px;
    min-width: 150px;
}

.ordersQuotationDetailPage-content::-webkit-scrollbar,
.ordersQuotationDetailPage-content-chat::-webkit-scrollbar {
    display: none;
}

.ordersQuotationDetailPage-content .import-timeline-supplier {
    height: 4rem;
}

.ordersQuotationDetailPage-abstrac {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 0.5rem;
}

.ordersQuotationDetailPage-abstrac-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    background: var(--white-50);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 24px;
    padding: 20px;
}

.ordersQuotationDetailPage-abstrac .new-quote-transport .new-quote-transport-row__2 
.dropdown .dropdown-select .move-text, .ordersQuotationDetailPage-abstrac .new-quote-transport
.dropdown .dropdown-select .move-text, .ordersQuotationDetailPage-abstrac .new-quote-customs-data
.new-quote-customs-data-regimen .dropdown .dropdown-select .move-text, .ordersQuotationDetailPage-abstrac 
.new-quote-customs-data .new-quote-customs-data-row__ .dropdown .dropdown-select .move-text,
.ordersQuotationDetailPage-abstrac .new-quote-customs-data .new-quote-customs-data-row__m
.dropdown .dropdown-select .move-text, .ordersQuotationDetailPage-abstrac .new-quote-customs-data
.new-quote-customs-data-row__1 .dropdown .dropdown-select .move-text {
    left: 0px;
}
.ordersQuotationDetailPage-chat--content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* height: calc(100vh - 250px); */
    height: 100%;
}

.ordersQuotationDetailPage-chat--content .chat-personal {
    margin-bottom: 1rem;
    max-width: 100%;
}

.ordersQuotationDetailPage-container .absolute-container {
    display: none;
}

#ordersQuotationDetailPage-contract-mobile {
    margin-bottom: 3rem;
}

.ordersQuotationDetailPage-abstrac--edit {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.ordersQuotationDetailPage-chat .customsAgency-chat-content .headerCustomsPhases-container {
    width: 50%;
}

.ordersQuotationDetailPage-chat .ordersQuotationDetailPage-content .ordersQuotationDetailPage-abstrac 
.ordersQuotationDetailPage-abstrac-content .new-quote-customs-data .new-quote-customs-data-row__upload 
.new-quote-customs-data-upload .new-quote-customs-data-upload--attached {
    justify-content: flex-start;
}

.ordersQuotationDetailPage-chat .ordersQuotationDetailPage-content .ordersQuotationDetailPage-abstrac 
.ordersQuotationDetailPage-abstrac-content .new-quote-customs-data .new-quote-customs-data-row__upload 
.new-quote-customs-data-upload .new-quote-customs-data-upload--attached .updateText-content {
    max-width: 200px;
}

@media (max-width: 1440px) { 
    .ordersQuotationDetailPage-chat .chat-container {
        height: calc(84vh - 94px);
    }
}

@media (max-width: 1389px) { 
    .ordersQuotationDetailPage-container .ordersQuotationDetailPage-container-header-mobile {
        padding: 0px 20px;
    }
}

@media (max-width: 1310px) { 
    .ordersQuotationDetailPage-container-row {
        padding: 0px 0px 10px 13px;
        gap: 10px;
    }
}

@media (max-width: 1231px) {
    .ordersQuotationDetailPage-menu-scroll {
        max-width: 400px;
        min-width: 350px;
    }
}

@media (max-width: 1096px) {
    .ordersQuotationDetailPage-abstrac .new-quote-transport .new-quote-transport-row__2 
    .dropdown .dropdown-select .move-text, .ordersQuotationDetailPage-abstrac 
    .new-quote-transport .dropdown .dropdown-select .move-text {
        max-width: 145px;
    }
}

@media (max-width: 1086px) {
    .ordersQuotationDetailPage-abstrac .new-quote-transport .new-quote-transport-row__2 
    .dropdown .dropdown-select .move-text, .ordersQuotationDetailPage-abstrac 
    .new-quote-transport .dropdown .dropdown-select .move-text {
        max-width: 140px;
    }
}

@media (max-width: 1010px) {
    .ordersQuotationDetailPage-menu-scroll {
        min-width: 350px;
    }
}
@media (max-width: 900px) {
    .ordersQuotationDetailPage-abstrac .new-quote-transport .new-quote-transport-row__lclair .new-quote-transport-row__1,
    .ordersQuotationDetailPage-abstrac .new-quote-transport .new-quote-transport-row__lclair .new-quote-transport-row__2_air, 
    .ordersQuotationDetailPage-abstrac .new-quote-customs-data .new-quote-customs-data-row__m {
        gap: 10px
    }
}

@media (max-width: 880px) {
    .ordersQuotationDetailPage-menu-scroll {
        min-width: 300px;
        max-width: 330px;
    }
    .orders-container .importPhasesCard-main {
        border-radius: 12px !important;
    }
    .ordersQuotationDetailPage-menu-scroll-multimedia div .multimedia-container .multimedia-text {
        font-size: 0.9rem;
    }
}

@media (max-width: 700px) {
    .ordersQuotationDetailPage-chat {
        height: 100%;
    }
    .ordersQuotationDetailPage-container-row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0px; 
        height: 100%;
    }
    .ordersQuotationDetailPage-container {
        margin-top: 40px;
    }
    .ordersQuotationDetailPage-mobile--orders {
        display: flex;
        justify-content: center;
    }
    .ordersQuotationDetailPage-menu-scroll .orders-container {
        display: none;
    }
    .ordersQuotationDetailPage-content .tableQuote,
    .ordersQuotationDetailPage-content .ordersQuotationDetailPage-abstrac,
    .ordersQuotationDetailPage-content .ordersQuotationDetailPage-mobile--orders  {
        /* margin: 0px 20px; */
    }
    .ordersQuotationDetailPage-abstrac .ordersQuotationDetailPage-colorText {
        display: none;
    }
    .ordersQuotationDetailPage-container-header-mobile .buttonBadget {
        display: flex;
        width: 20%;
        justify-content: center;
    }
    .ordersQuotationDetailPage-container-header-mobile .buttonBadget div div { 
        display: flex;
    }
    .ordersQuotationDetailPage-content .custom-button {
        margin: 0px 20px;
    }
    .ordersQuotationDetailPage-container-header-mobile {
        display: none;
    }
    .ordersQuotationDetailPage-container
    .ordersQuotationDetailPage-container-header-mobile {
        display: flex;
    }
    .ordersQuotationDetailPage-menu-scroll {
        position: relative;
        top: 0;
        height: fit-content;
    }
    .ordersQuotationDetailPage-chat .customsAgency-chat-content {
        width: 100%;
        max-width: 500px;
        justify-content: center;
        align-self: center;
    }
    .ordersQuotationDetailPage-menu-scroll {
        min-width: 100%;
    }
    .ordersQuotationDetailPage-menu-scroll div .multimedia-container {
        width: 100%;
        max-width: 600px;
    }
    .ordersQuotationDetailPage-chat .customsAgency-chat-content .headerCustomsPhases-container {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .ordersQuotationDetailPage-container-row {
        margin-bottom: 3rem;
    }
    .ordersQuotationDetailPage-container {
        margin-top: 30px;
        padding-bottom: 5rem;
    }
}

@media (max-width: 478px) {
    .ordersQuotationDetailPage-container-row .ordersQuotationDetailPage-menu-scroll {
        width: 100%;
        padding: 0px 20px;
        justify-content: flex-start;
        align-items: start;
        max-width: 100%; 
    }
}

@media (max-width: 400px) {
    .ordersQuotationDetailPage-abstrac .new-quote-transport .new-quote-transport-row__2 
    .dropdown .dropdown-select .move-text {
        left: 8px;
    }
    .ordersQuotationDetailPage-abstrac .new-quote-transport .new-quote-transport-row__2___ 
    .dropdown .dropdown-select .move-text {
        left: 8px;
        max-width: 250px !important;
    }  
    .ordersQuotationDetailPage-abstrac .new-quote-transport .new-quote-transport-row__m 
    .dropdown .dropdown-select .move-text {
        left: 7px;
    } 
}