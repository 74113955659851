.new-quote-transport {
    /* background: var(--white-50);
    backdrop-filter: blur(40px); */
    border-radius: 24px;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.new-quote-transport-title {
    color: var(--gray-1);
}

.new-quote-transport-row__, .new-quote-transport-row__1, .new-quote-transport-row__2 {
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.new-quote-transport .dropdown-select {
    /* border:  1px solid var(--black-25); */
    padding: 20px;
}

.new-quote-transport-row__lclair-dropdown {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px
}

.new-quote-transport-row__1-air .dropdown {
    width: 50%;
}

.new-quote-transport-row__ .dropdown-select .move-text {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    min-width: 35px;
    width: 100%;
    white-space: nowrap;
}

.new-quote-transport-row__1 .move-text {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    min-width: 35px;
    width: 100%;
    white-space: nowrap;
}


.new-quote-transport-row__2 .move-text {
    overflow: hidden;
    text-overflow: ellipsis;
    /* max-width: 250px; */
    max-width: 190px;
    min-width: 35px;
    width: 100%;
    white-space: nowrap;
}

.new-quote-transport-row__m {
    display: none;
}

.new-quote-transport-upload {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    background: var(--black-05);
    border-radius: 12px;
    padding: 20px;
}

.new-quote-transport-upload-text {
    color: var(--gray-1);
}

.new-quote-transport-row__lclair {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.new-quote-transport-row__1_air {
    display: flex;
    gap: 20px
}

.new-quote-transport-row__2_air {
    display: none;
}

.new-quote-transport-row__1___ {
    display: none;
}

.new-quote-transport-row__dropdown {
    width: 100%;
}

.new-quote-transport-row__dropdown .dropdown {
    width: 100% !important;
}

.new-quote-transport-row__ .dropdown {
    width:  55%;
}
.new-quote-transport-title__LCL {
    padding-top: 15px;
}

.new-quote-transport-row__2___ {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.new-quote-transport-row__1__ .dropdown .dropdown-select .dropdown_text {
    overflow: hidden;
    text-overflow: ellipsis;
    /* max-width: 250px; */
    max-width: 170px;
    min-width: 35px;
    width: 100%;
    white-space: nowrap;
}

/* Responsive  */

@media (max-width: 1318px) { 
    .new-quote-transport-row__2 .move-text {
        overflow: hidden;
        text-overflow: ellipsis;
        /* max-width: 250px; */
        max-width: 170px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
}

@media (max-width: 1200px) { 
    .new-quote-transport-row__, .new-quote-transport-row__1, .new-quote-transport-row__2 {
        gap: 10px;
    }
}

@media (max-width: 1177px) { 
    .new-quote-transport-row__ {
        display: none;
    }
    .new-quote-transport-row__m {
        gap: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .new-quote-transport-row__m .move-text {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
        min-width: 35px; 
        width: 100%;
        white-space: nowrap;
    }
    /* .new-quote-transport .new-quote-transport-row__2___ .dropdown__disabled .dropdown-select .dropdown_disable_color {
        max-width: 350px !important;
        display: flex;
        width: 100%;
    } */
}

@media (max-width: 1109px) { 
    .new-quote-transport-row__1__ .dropdown .dropdown-select .dropdown_text {
        overflow: hidden;
        text-overflow: ellipsis;
        /* max-width: 250px; */
        max-width: 140px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
}

@media (max-width: 1040px) { 
    .new-quote-transport .select-title {
        transform: translate(10px, 50%);
        left: 10px
    }
    .new-quote-transport-row__2 .dropdown .dropdown-select .move-text,
    .new-quote-transport-row__m  .dropdown .dropdown-select .move-text {
        max-width: 170px;
    }
}

@media (max-width: 1022px) { 
    .new-quote-transport-row__2_air {
        display: flex;
        gap: 20px
    }
    .new-quote-transport-row__1_air {
        display: none;
    }
}

@media (max-width: 1010px) { 
    .new-quote-transport-row__1__ .dropdown .dropdown-select .dropdown_text {
        overflow: hidden;
        text-overflow: ellipsis;
        /* max-width: 250px; */
        max-width: 160px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
}

@media (max-width: 1007px) { 
    .new-quote-transport-row__2 .dropdown .dropdown-select {
        padding: 20px 15px;
    }
    .new-quote-transport-row__2 .dropdown .dropdown-select .select-title {
        left: 6px;
    }
}

@media (max-width: 970px) { 
    .new-quote-transport-row__2 .dropdown .dropdown-select {
        padding: 20px 11px;
    }
    .new-quote-transport-row__2 .dropdown .dropdown-select .select-title {
        left: 2px;
    }
}

@media (max-width: 968px) { 
    .new-quote-transport-row__2,
    .new-quote-transport-row__1__,
    .new-quote-transport-row__ {
        display: flex;
        flex-direction: column;
    }
    .new-quote-transport {
        gap: 10px
    }
    .new-quote-transport-row__1__ .dropdown .dropdown-select .dropdown_text {
        max-width: 400px;
    }
    .new-quote-transport-row__ .dropdown {
        width: 100%;
    }
    .new-quote-transport-row__m{
        display: none;
    } 
    
}

@media (max-width: 880px) { 
    .new-quote-transport-row__2_air,
    .new-quote-transport-row__m  {
        display: none;
    }
    .new-quote-transport-row__1_air,
    .new-quote-transport-row__  {
        display: flex;
    }
    .new-quote-transport-row__1__ .dropdown .dropdown-select .dropdown_text {
        overflow: hidden;
        text-overflow: ellipsis;
        /* max-width: 250px; */
        max-width: 350px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
    .new-quote-transport .new-quote-transport-row__2 .textfield .textfield-input,
    .new-quote-transport .new-quote-transport-row__2 .textAreaInput .textAreaInput-answer,
    .new-quote-transport .new-quote-transport-row__1__ .dropdown .dropdown-select .dropdown_text {
        font-size: 0.9rem !important;
    }
    .new-quote-transport .new-quote-transport-row__2 .textfield .textfield-title,
    .new-quote-transport .new-quote-transport-row__2 .textAreaInput .textAreaInput-title {
        font-size: 0.65rem !important;
    }
}

@media (max-width: 785px) { 
    .new-quote-transport-row__1__ .dropdown .dropdown-select .dropdown_text {
        max-width: 300px;
        min-width: 35px;
    }
}

@media (max-width: 736px) { 
    .new-quote-transport-row__1__ .dropdown .dropdown-select .dropdown_text {
        overflow: hidden;
        text-overflow: ellipsis;
        /* max-width: 250px; */
        max-width: 260px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
}

@media (max-width: 634px) { 
    .new-quote-transport-row__2_air,
    .new-quote-transport-row__m  {
        display: flex;
        flex-direction: row !important;
    }
    .new-quote-transport-row__1_air,
    .new-quote-transport-row__  {
        display: none;
    }
    .new-quote-transport-row__2 {
        gap: 10px
    }
    .new-quote-transport-row__m {
        gap: 10px
    }
    .new-quote-transport-row__1 {
        gap: 10px;
    }
}

@media (max-width: 634px) { 
    .new-quote-transport-row__2 {
        display: flex;
        flex-direction: column !important;
    }
}

@media (max-width: 614px) { 
    .new-quote-transport-row__1 {
        flex-direction: column !important;
    }
    .new-quote-transport-row__1__ .dropdown .dropdown-select .dropdown_text {
        overflow: hidden;
        text-overflow: ellipsis;
        /* max-width: 250px; */
        max-width: 400px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
}

@media (max-width: 600px) { 
    .new-quote-transport-row__lclair {
        gap: 15px
    }
    .new-quote-transport-row__1-air {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .new-quote-transport-row__1-air .dropdown {
        width: 100%;
    }
}

@media (max-width: 584px) { 
    .new-quote-transport-row__1 {
        flex-direction: row;
    }

    .new-quote-transport-row__1-air {
        flex-direction: column;
    }

    .new-quote-transport-row__1 .dropdown .dropdown-select .move-text {
        max-width: 200px;
    }
    /* .new-quote-transport-row__2_air {
        flex-direction: column;
    }  */
}

@media (max-width: 550px) { 
    /* .new-quote-transport-row__m, .new-quote-transport-row__2 {
        flex-direction: column;
    } */
    .new-quote-transport .dropdown .dropdown-select .move-text {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 170px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
}

@media (max-width: 540px) { 
    .new-quote-transport-row__1__ .dropdown .dropdown-select .dropdown_text {
        overflow: hidden;
        text-overflow: ellipsis;
        /* max-width: 250px; */
        max-width: 350px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
}

@media (max-width: 514px) { 
    .new-quote-transport .dropdown .dropdown-select .move-text {
        max-width: 140px;
    }
}

@media (max-width: 498px) { 
    .new-quote-transport-row__1 {
        flex-direction: column;
    }
}

@media (max-width: 490px) { 
    .new-quote-transport-row__1__ .dropdown .dropdown-select .dropdown_text {
        overflow: hidden;
        text-overflow: ellipsis;
        /* max-width: 250px; */
        max-width: 320px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
}

@media (max-width: 475px) { 
    .new-quote-transport-row__, 
    .new-quote-transport-row__1, 
    .new-quote-transport-row__2 {
        gap: 10px;
    }
    .new-quote-transport-row__1-air {
        gap: 15px;
    }
}

@media (max-width: 457px) { 
    .new-quote-transport-row__1__ .dropdown .dropdown-select .dropdown_text {
        overflow: hidden;
        text-overflow: ellipsis;
        /* max-width: 250px; */
        max-width: 300px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
}

@media (max-width: 440px) { 
    .new-quote-transport .dropdown .dropdown-select .move-text {
        max-width: 100px;
    }
    .new-quote-transport-row__1__ {
        display: none;
    }
    .new-quote-transport-row__1___ {
        display: flex;
    }
}

@media (max-width: 416px) { 
    .new-quote-customs-data-row__m .dropdown .dropdown-select .select-title {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80px;
        min-width: 35px;
        width: 100%;
        white-space: nowrap;
    }
}

@media (max-width: 404px) { 
    .new-quote-transport-upload {
        flex-direction: column;
    }
    .new-quote-transport-upload .updateText-content .updateText-content-button-file {
        text-align: center;
    }
}

@media (max-width: 400px) { 
    .new-quote-transport .dropdown-select {
        padding: 20px !important;
    }
}