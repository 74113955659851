.billing-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.billing-cardContent {
    max-width: 100%;
    width: 100%;
    margin: 5px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    margin-top: 0.5rem;
    margin-bottom: 0.7rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.billing-cardContent-container {
    max-width: 88%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.billing-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

@media (max-width: 600px) { 
    .billing-cardContent .dropdown {
        max-width: 220px !important;
        width: 100% !important;
    }
    .billing-row {
        flex-direction: column;
    }
    .billing-row .dropdown {
        max-width: 100% !important;
        width: 100% !important;
    }
    .billing-cardContent-container {
        max-width: 100%;   
    }
}