.five-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 60px 0px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(19, 19, 19, 0.05) 32.19%, rgba(255, 197, 37, 0.30) 96.43%);
    overflow: hidden;
}

.five-section-header {
    display: flex;
    flex-direction: column;
    gap: 30px
}

.five-section-white {
    text-align: center;
    color: var(--white);
}

.five-section-text-grandient {
    background: -webkit-linear-gradient(#F7E246, #FFC525);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.five-section-button {
    width: 175px;
    height: 58px;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--white);

    border-radius: 12px;
    border: 1px solid var(--bn-white, #FFF);
}

.five-section-button-img {
    width: 27px;
    height: 27px;
    display: flex;
}

.five-section-m,
.five-section-m_1 {
    display: none;

}

.five-section-img {
    height: 120px;
}

.five-section .animation-video {
    border-radius: 0px;
    background: none;
    box-shadow: none
}


@media (max-width: 1194px) {
    .five-section-header {
        gap: 20px;
        justify-content: center;
        align-items: center;
    }
    .five-section-title {
       font-size: 1.9rem;
    }
}

@media (max-width: 1045px) {
    .five-section {
        background: black;
    }
    .five-section-title {
        font-size: 2rem;
    }
    .five-section-subtitle {
        font-size: 1.1rem;
    }
    .five-section-w {
        display: none;
    }
    .five-section-m {
        display: flex;
        flex-direction: column;
    }
    .five-section-button {
        width: 153px;
        height: 45px;
        font-size: 1.125rem;
        padding: 10px 9px;
    }
}

@media (max-width: 665px) {
    .five-section {
        padding: 30px 0px;
    }
    .five-section-title {
        font-size: 1.42rem;
    }
    .five-section-subtitle {
        width: 378px;
        font-size: 1rem;
    }
    .five-section-m {
        display: none;
    }
    .five-section-m_1 {
        line-height: 34px;
        display: flex;
        flex-direction: column;
    }
    .five-section .animation-video {
        width: 990px;
        max-width: 990px;
    }
}

@media (max-width: 410px) {
    .five-section-subtitle {
        width: 300px;
        font-size: 1rem;
    }
}