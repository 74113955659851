.eventGridCalendar-container {
    position: relative;
    max-width: 827px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.eventGridCalendar-detail--row {
    position: relative;
    max-width: 118px;
    width: 100%;
    max-height: 68px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
/*Columnas 7 veces*/
.eventGridCalendar-detail--row:nth-child(7n),
.eventGridCalendar-detail--row:nth-child(7n+1), 
.eventGridCalendar-detail--row:nth-child(7n-2), 
.eventGridCalendar-detail--row:nth-child(7n+3){
    background: rgb(224 224 224 / 10%);
}

/*Horizontal 24 veces*/
.eventGridCalendar-detail--row:nth-child(n+1):nth-child(-n+7),
.eventGridCalendar-detail--row:nth-child(n+15):nth-child(-n+21),
.eventGridCalendar-detail--row:nth-child(n+29):nth-child(-n+35),
.eventGridCalendar-detail--row:nth-child(n+43):nth-child(-n+49),
.eventGridCalendar-detail--row:nth-child(n+57):nth-child(-n+63),
.eventGridCalendar-detail--row:nth-child(n+71):nth-child(-n+77),
.eventGridCalendar-detail--row:nth-child(n+85):nth-child(-n+91),
.eventGridCalendar-detail--row:nth-child(n+99):nth-child(-n+105),
.eventGridCalendar-detail--row:nth-child(n+113):nth-child(-n+119),
.eventGridCalendar-detail--row:nth-child(n+127):nth-child(-n+133),
.eventGridCalendar-detail--row:nth-child(n+141):nth-child(-n+147),
.eventGridCalendar-detail--row:nth-child(n+155):nth-child(-n+161),
.eventGridCalendar-detail--row:nth-child(n+169):nth-child(-n+175),
.eventGridCalendar-detail--row:nth-child(n+183):nth-child(-n+189),
.eventGridCalendar-detail--row:nth-child(n+197):nth-child(-n+203),
.eventGridCalendar-detail--row:nth-child(n+211):nth-child(-n+217),
.eventGridCalendar-detail--row:nth-child(n+225):nth-child(-n+231),
.eventGridCalendar-detail--row:nth-child(n+239):nth-child(-n+245),
.eventGridCalendar-detail--row:nth-child(n+253):nth-child(-n+259),
.eventGridCalendar-detail--row:nth-child(n+267):nth-child(-n+273),
.eventGridCalendar-detail--row:nth-child(n+281):nth-child(-n+287),
.eventGridCalendar-detail--row:nth-child(n+295):nth-child(-n+301),
.eventGridCalendar-detail--row:nth-child(n+309):nth-child(-n+315),
.eventGridCalendar-detail--row:nth-child(n+323):nth-child(-n+329)  {
    background: rgb(255 255 255 / 12%);
}
.eventGridCalendar-detail--row:nth-child(7n-1), 
.eventGridCalendar-detail--row:nth-child(7n+2),
.eventGridCalendar-detail--row:nth-child(7n-3) {
    background: rgb(255 255 255 / 20%);
}

.eventGridCalendar-detail--minute {
    position: relative;
    height: 2.34px;
    width: 100%;
    display: flex;
}