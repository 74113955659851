.mainService {
    /* display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 50px);
    flex: 1 1;
    display: flex;
    overflow: hidden;
}

.mainService-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding: 10px 77.5px 45px 77.5px; */
    padding: 40px 0px 0px 0px;
    gap: 20px;
    width: 100%;
    max-width: 1500px;
    /* margin-bottom: 10rem; */
    overflow: scroll;
}

.mainService-container::-webkit-scrollbar {
    display: none;
}

.mainService-body {
    height: 100vh;
    width: 100%;
    display: flex;
    gap: 40px;
    flex-direction: row;
}

.mainService-body-min-3 {
    /* height: 900px; */
    height: 100vh;
    width: 100%;
    display: flex;
    gap: 40px;
    flex-direction: row;
}

.mainService-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.mainService-provider {
    /* max-width: 400px; */
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* gap: 5px; */
}

.mainService-provider .servicesOptions-main .servicesOptions-title {
    padding: 0px 30px;
}

.mainService-provider .servicesOptions-main .servicesOptions-text {
    overflow: scroll;
    padding: 20px 30px;
}
.mainService-provider .servicesOptions-main .servicesOptions-text::-webkit-scrollbar {
    display: none;
}
.mainService-provider .headPage {
    width: 100%;
    padding: 0px 30px;
}

.mainService-provider .headPage .headPage-subtitle {
    display: flex;
    font-family: "Faktum";
    font-size: 0.875rem;
    /* font-size: 1.125rem; */
}

.mainService-header-right {
    align-items: center;
    display: flex;
    max-width: 840px;
    width: 100%;
}

.mainService-services {
    max-width: 400px;
    width: 100%;
    flex-direction: column;
    gap: 30px
}

.mainService-company {
    /* max-width: 840px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0px 30px;
}
.Filtro-F {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-family: 'Faktum-Medium';
	font-style: normal;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 18px;
    color: var(--gray-1);
}
.mainService-containerShared {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10rem;
    gap: 30px;
}

.mainService-containerShared--col {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.mainService-containerShared--col-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
}

.mainService-containerShared--group {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.mainService-containerShared-text {
    width: 100%;
    text-align: center;
}

.mainService-containerShared-text-color {
    color: var(--gray-1);
}

/* .Filtro-F {
    width: 97%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */

.filter-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.filter-item--text {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.filter-item--clearFilter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.filter-item--clearFilter button {
    /* color: var(--gray-1);
    font-family: 'Faktum';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; */
}

.responsive-filter {
    display: none;
}

.responsive-filter button {
    color: var(--black);
}

@media (max-width: 845px) {
    .Filtro-F, .filter-container {
        gap: 10px;
    }

    .filter-item--clearFilter button {
        padding: 10px 15px;
    }
}

@media (max-width: 768px) {
    
    .Filtro-F .filter-container,  .filter-item--clearFilter {
        display: none;
    }

    .responsive-filter {
        display: flex;
    }
    
}

/* @media (min-width: 601px) {
    .Filtro-F .filter-item {
        display: flex;
        flex-direction: column;
    }
} */
@media (max-width: 600px) {
    .Filtro-F {
        flex-direction: column;
        gap: 10px;
    }

    .Filtro-F .filter-item {
        display: none; 
    }
    .Filtro-F .responsive-filter {
        display: block; 
        width: 100%;
    }
}

.mainService-loader {
    /* height: 500px;
    width: 100%;
    justify-content: flex-start;
    align-items: self-start;
    display: flex; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    max-width: 1500px;
    width: 100%;
}

.mainService-main-content-filters {
    display: flex;
    flex-direction: row;
    gap: 20px
}

.mainService-main-content-filter {
    width: 100%;
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}

.mainService-main-content-filter .companyList-requestQuoteButton .custom-button {
    height: 41px;
    border-radius: 24px;
    font-family: 'Faktum-Medium';
    background: var(--gray-1) !important;
    font-size: 0.875rem;
    padding: 10px 20px;
}

.mainService-services .servicesOptions-main .servicesOptions-text .services 
.hover .hover-text {
    left: 26rem;
} 

.mainservice-filters {
    display: none;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    /*eliminar el with despues de esta primera version*/
    width: 100%;
    max-width: fit-content;
}
.mainservice-filters-expand {
    width: 100%;
    /* max-width: 400px; */
    max-width: 350px;
    display: none;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    transition-timing-function: linear;
}

.mainservice-filters-expand  .searchTextField-container {
    width: 100%;
}
.mainservice-filters .filterSelectorMiniature-main {
    background: var(--gray-6);
}
.mainservice-filters .searchTextField-min {
    border-radius: 24px;
    background: var(--white-75);
    height: 36px;
    width: 36px;
}
.mainservice-filter-icon {
    height: 45px;
    border-radius: 24px;
    background: linear-gradient(111deg, rgba(255, 255, 255, 0.50) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    font-size: 1rem;
    font-family: "Faktum-Medium";
    align-items: center;
    display: flex;
    padding: 14.67px;
    gap: 10px;
    backdrop-filter: blur(20px);
}

.mainservice-filter-icon div img {
    display: flex;
}

.chat-search-icon {
    border-radius: 24px;
    border: 1px solid #FFF;
    background: linear-gradient(111deg, rgba(255, 255, 255, 0.50) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    width: 46px;
    height: 45px;
    align-items: center;
    align-self: center;
    display: flex;
    justify-content: center;
    transition: width 2s
}

.mainService-company .companyList-main {
    margin-bottom: 0rem;
}

@media (max-width: 1280px) {  
    .mainService-container {
        padding: 40px 0px 45px 0px;
    }
}

@media (max-width: 1155px) {  
    .mainService-loader {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 930px) {  
    .mainService-provider .servicesOptions-main .servicesOptions-title {
        padding: 0px 30px;
    }
}

@media (max-width: 899px) {  
    .mainService-loader {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .mainService-container {
        gap: 20px;
        padding: 20px 0px 45px 0px;
    }
    .mainservice-filters {
        display: flex;
    }
    .mainservice-filters-expand {
        display: flex;
        height: 45px;
        max-width: 100%;
    }
    .mainService-main-content-filters {
        display: none;
    }
    .mainservice-filters-expand .searchTextField-container {
        max-width: 100%;
    }
}

@media (max-width: 600px) { 
    .mainService-provider {
        gap: 4px;
    }
    .mainService-provider .servicesOptions-main .servicesOptions-title {
        padding: 10px 0.5rem 0px 0.5rem;
        font-size: 0.875rem;
    }
    .mainService-provider .headPage {
        padding: 0px 0.5rem;
    }
    .mainService-provider .servicesOptions-main .servicesOptions-text {
        padding: 8px 0.5rem 16px 0.5rem;
    }
    .mainService-provider .headPage .headPage-title {
        display: none; 
    }
    .mainService-company {
        padding: 0rem  0.5rem;
    }
    .mainService-main-content-filter .companyList-requestQuoteButton .custom-button {
        font-size: 0.875rem;
    }
    .mainService-provider .headPage .headPage-subtitle {
        line-height: 20px;
        justify-content: flex-start;
        text-align: start;
    }
    .mainservice-filter-icon {
        font-size: 0.875rem;
    }
}

@media (max-width: 450px) { 
    .mainService-main-content-filter .companyList-requestQuoteButton .custom-button {
        padding: 0px 15px;
    }
    .mainService-main-content-filter .companyList-requestQuoteButton .custom-button {
        font-size: 0.75rem;
    }
    .mainService-loader { 
        grid-gap: 10px;
    }
}

@media (max-width: 383px) { 
    .mainService-provider .servicesOptions-main .servicesOptions-title {
        font-size: 0.84rem;
    }
}

@media (max-width: 375px) { 
    .mainservice-filters-expand .searchTextField-container {
        padding: 10px 21px;
    }
}