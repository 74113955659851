.shipment {
	position: relative;
	/* max-width: 300px; */
}

.ports-content {
	position: absolute;
	left: 0;
	margin-top: 10px;
	width: -webkit-fill-available;
	/* padding: 2px; */
	z-index: 1;
	background: var(--white, #FFF);
	/* box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15); */
	box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.15);


	border-radius: 24px;
	overflow-x: hidden;
	/* max-height: 215px; */
	max-height: 190px;
    min-height: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.ports-content-no-scroll::-webkit-scrollbar {
	width: 0px !important;
	height: 0px !important;
}

.ports-content::-webkit-scrollbar {
	width: 7px;
    height: 4px;
	background-color: #F5F5F5;
}

.ports-content::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.ports-content::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--gray-1);
}

.ports-content div {
	cursor: pointer;
}

.ports-content .row:hover {
	background: var(--black-15) !important;
}

/* .ports-content .row {
	border-radius: 24px;
} */

.shipment-map {
	width: inherit;
	min-height: 194px;
	border-radius: 12px !important;
	margin-top: 2px;
}

.ports-content .row , .ports-content .row .row__title, 
.ports-content .row .row__title .portrow,
.ports-content .row .row__title .portrow .portrow-info  {
	max-width: 100%;
	width: 100%;
}
.ports-content .row, .ports-content .row .row__title, .ports-content .row .row__title .portrow {
	gap: 10px;
}

/* .shipment .textfield .textfield-input {
	border-radius: 24px;
	background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
	box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(20px);
}  */

.ports-content .row .row__title .portrow .portrow-info > span {
	line-height: inherit;
}


@media (max-width: 3500px) and (max-height: 812px) {
    .ports-content {
        max-height: 150px;
    	min-height: 10px;
    }
}

@media (max-width: 3500px) and (max-height: 704px) {
    .ports-content {
        max-height: 120px;
    	min-height: 10px;
    }
}

@media (max-width: 600px) { 
	.ports-content .row {
		padding: 10px 15px !important;
	}
	.ports-content::-webkit-scrollbar {
		width: 0px;
	}
}