.processService-container {
    width: 100%;  
    display: flex;
    justify-content: center;
}

.processService-content {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.processService-detail {
    width: 100%;
    height: 100%;
    display: flex;

    /* max-height: calc(100vh - 340px); */

    /* max-height: calc(100vh - 180px); */
    height: 100%;
    overflow-y: auto;
    overflow-x: none;
}

.processService-detail::-webkit-scrollbar {
    width: 0px;
}

.processTitle-description {
    font-family: "Faktum-Medium";
    font-weight: bold;
    color: var(--black);    
}

@media (max-width:820px) { 
    .processService-detail {
        /* max-height: calc(100vh - 340px); */
        /* max-height: calc(100vh - 250px); */
    }
    .processService-content {
        gap: 15px
    }
}


@media (max-width:747px) { 
    .processService-detail .processRequestQuote-container .quoteTransport-container  
    .quoteTransport-row .lclcd .lclcd-bottom .lclcd-first-row {
        display: flex;
        flex-direction: column;
    }
}
@media (max-width:600px) {
    .processService-content  {
        padding-top: 3rem;
    }   
    .processService-detail {
        /* max-height: calc(100vh - 270px); */
        /* max-height: calc(100vh - 180px); */
        /* max-height: calc(100vh - 192px); */

        /* max-height: calc(100vh - 210px); */
    }
    /* .processService-container {
        padding: 0px 10px;
    } */
}

/* @media (max-width:500px) { 
    .processService-detail {
        max-height: calc(100vh - 270px);
    }
} */