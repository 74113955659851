.modalComplete {
    position: fixed;
    z-index: 100;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.15); /* Black w/ opacity */
    backdrop-filter: blur(5px);
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.modalComplete-container {
    max-width: 53.75rem;
    margin: auto;
    padding: 30px;
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--white);
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    backdrop-filter: blur(20px);
    border-radius: 24px;
}

.modalComplete__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.modalComplete__heade-left {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.modalComplete-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.modalComplete-logo div {
    display: flex;
}

.modalComplete-logo div img {
    width: 37px;
    height: 26px;
}

.modalComplete-body {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modalComplete-img {
    display: flex;
    width: 30px;
    height: 30px;
}

.modalComplete-body-text {
    align-self: center;
}

.modalComplete-footer {
    text-align: end;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
    padding-top: 20px;
}
.modalComplete-footer .btn-normal {
    height: 45px;
    padding: 10px 50px;
    min-width: auto;
    font-size: 1.125rem;
}
@media (max-width: 600px) {
    .modalComplete-container {
        border-radius: 0px;
        padding: 30px 20px;
    }
    .modalComplete-header__tex-title {
        font-size: 1rem;
    }
    .modalComplete-body-text {
        font-size: 0.875rem;
    }
    .modalComplete-footer .btn-normal {
        font-size: 0.875rem;
        padding: 10px 40px;
        height: 40px;
    }
}
@media (max-width: 552px) {
    .modalComplete-img {
        width: 30px;
        height: 30px;
        align-items: center;
        align-self: center;
    }
}
@media (max-width: 470px) { 
    .modal-container {
        padding: 30px 15px;
    }
}