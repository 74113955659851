.modal-privacy-container {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    /*padding-top: 10px;
    padding-bottom: 10px; */
}

.modal-privacy-container::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.modal-privacy-p {
    display: flex;
    gap: 10px;
    flex-direction: column;
    /* padding: 10px 0px 10px 0px; */
    text-align: justify;
    margin: 0;
}

.modal-privacy-p-bottom {
    /* padding: 10px 0px 10px 0px; */
    text-align: justify;
    margin: 0;
}

.modal-privacy-p-one {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 10px 0px 10px 0px;
    text-align: justify;
    margin: 0;
}

.modal-privacy-ul {
    display: flex;
    gap: 10px;
    flex-direction: column;
    text-align: justify;
}

.modal-link-privacy {
    color: var(--black);
    cursor: pointer;
}