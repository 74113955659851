.typeTransportInput-container {
    width: 100%;
    height: 100%;
    /* padding: 20px; */

    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 24px;
}

.typeTransportInput-container__padding {
    padding: 20px;
}

.inputOriginDestiny-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.inputOriginDestiny-row div {
    display: flex;
}

.inputOriginDestiny-row-button .buttonTypeTransport-container .buttonTypeTransport-icon .icon-plane,
.inputOriginDestiny-row-button .buttonTypeTransport-container .buttonTypeTransport-icon .icon-boat {
    display: flex;
    align-items: center;
}

.inputOriginDestiny-row-button .buttonTypeTransport-container .buttonTypeTransport-icon .icon-plane img {
    display: flex;
    width: 30px;
    height: 15.684px;
}

.inputOriginDestiny-row-button .buttonTypeTransport-container .buttonTypeTransport-icon .icon-boat img {
    display: flex;
    width: 18.266px;
    height: 19.987px;
}

.inputOriginDestiny-row-button .buttonTypeTransport-container .buttonTypeTransport-icon .buttonTypeTransport-text {
    font-size: 0.875rem;
}



@media (max-width: 600px) { 
    .inputOriginDestiny-row-button .buttonTypeTransport-container {
        height: 40px;
    }
    .inputOriginDestiny-row-button .buttonTypeTransport-container .buttonTypeTransport-icon .icon-plane img {
        display: flex;
        width: 22.953px;
        height: 12px;
    }
    
    .inputOriginDestiny-row-button .buttonTypeTransport-container .buttonTypeTransport-icon .icon-boat img {
        display: flex;
        width: 13.708px;
        height: 15px;
    }
    .inputOriginDestiny-row-button .buttonTypeTransport-container .buttonTypeTransport-icon .buttonTypeTransport-text {
        font-size: 0.75rem;
    }
    .inputOriginDestiny-type_title {
        font-size: 0.875rem;
    }
}