.review-display {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.review-display-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.review-display-dropdown {
	min-width: 180px;
}

.review-display-ratings {
	display: flex;
	gap: 20px;
}

.review-display-list {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.review-display h1 {
	margin: 0;
}

.review-display-ratings-mobile {
	display: none;
}

.review-notFound {
	height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-display-ratings--button {
	max-height: 28px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 12px;
    padding: 5px 10px;
    cursor: pointer;
}

.review-display-content {
	padding-top: 20px; 
	width: 100%;
	max-width: 100%;
}

@media (max-width: 985px) {
	.review-display-ratings {
		gap: 10px;
	}
}

@media (max-width: 905px) {
	.review-display-ratings .rating_pointer span,
	.review-display-ratings--button {
		font-size: 0.8rem;
	}
	.review-display-ratings .rating_pointer div img {
		width: 8px;
	}
	.review-display-ratings .rating_pointer {
		padding: 5px;
	}
	.review-display-ratings--button {
		max-height: 24px;
		padding: 5px 8px;
	}
}

@media (max-width: 768px) {
	.review-display-content {
		padding-top: 0px;
	}
}

@media (max-width: 685px) {
	.review-display-dropdown {
		display: none;
	}
}

@media (max-width: 600px) {
	.review-name-text {
		font-size: 0.9rem;
	}
}

@media (max-width: 512px) {
	.review-display-ratings-mobile {
		display: flex;
		gap: 20px;
	}
}

@media (max-width: 450px) {
	.review-display-ratings .rating_pointer .rating_pointer-text {
		font-size: 0.7rem;
	}
}

@media (max-width: 428px) {
	.review-display {
		padding: 0 20px;
	}
	.review-display-ratings .rating_pointer {
		max-height: 24px;
	}
	.review-display-ratings--button {
		font-size: 0.7rem;
	}
}

@media (max-width: 400px) {
	.review-display-ratings {
		gap: 8px !important;
	}
	.review-display-ratings .rating_pointer .rating_pointer-text {
		font-size: 0.65rem;
	}
	.review-display-ratings--button {
		font-size: 0.65rem;
	}
}

@media (max-width: 376px) {
	.review-display-ratings .rating_pointer .rating_pointer-text {
		font-size: 0.6rem;
	}
	.review-display-ratings--button {
		font-size: 0.6rem;
	}
}

@media (max-width: 338px) {
	.review-display-ratings-mobile {
		gap: 10px;
	}
}

@media (max-width: 330px) {
	.review-display-top .rating-display .rating-display-container .rating-display-text .rating-display-text__ {
		font-size: 0.8rem;
	}
}