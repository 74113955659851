.invoceTranslationSupplier-product {
    display: flex;
    flex-direction: column;
    gap: 20px
}

@media (max-width: 425px) {
    .invoceTranslationSupplier-product-container {
        padding: 10px;
    }
}