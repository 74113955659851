.timeLine-container {
    position: absolute;
    z-index: 6;
    flex-direction: row;
    align-items: center;
    width: 100%;
    /*max-width: 840px;*/    
    display: flex;
    padding-left: 26.5px;
}
.timeLine-detail-dots {
    background: var(--red);
    z-index: 30px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}
.timeLine-detail-line {
    background: var(--red);
    z-index: 20;
    height: 1px;
    width: 100%;
    /*max-width: 835px;*/
}
@media (max-width: 554px) { 
    .timeLine-container {
        padding-left: 5rem;
        max-width: 96.5%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
@media (max-width: 541px) { 
    .timeLine-container {
        padding-left: 5rem;
        max-width: 96%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
@media (max-width: 481px) { 
    .timeLine-container {
        padding-left: 5rem;
        max-width: 95.5%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
@media (max-width: 430px) { 
    .timeLine-container {
        padding-left: 5rem;
        max-width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
@media (max-width: 420px) { 
    .timeLine-container {
        padding-left: 5rem;
        max-width: 94.5%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
@media (max-width: 386px) { 
    .timeLine-container {
        padding-left: 5rem;
        max-width: 94.5%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
@media (max-width: 351px) { 
    .timeLine-container {
        padding-left: 5rem;
        max-width: 94%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
@media (max-width: 322px) { 
    .timeLine-container {
        padding-left: 5rem;
        max-width: 93.5%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
@media (max-width: 299px) { 
    .timeLine-container {
        padding-left: 5rem;
        max-width: 93%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}