.company-card {
	display: flex;
	flex-direction: column;
	gap: 10px;
	backdrop-filter: blur(40px);
	border-radius: 24px;
	padding: 0px 0px 30px;
	max-width: 400px;
    background: var(--background);
    border: 1px solid;
    border-image-source: var(--border-image-source);
}

.company-card .verify-container {
	top: 21rem
}

.company-card-imgs {
	position: relative;
	height: 285px;
}

.company-card-bg {
	width: 100%;
	height: 220px;
	border-radius: 24px 24px 0 0;
	object-fit: cover;
	aspect-ratio: 16 / 9;
}

.company-card-body {
	display: flex;
	flex-direction: column;
	gap: 25px
}

.company-card .company-card-imgs .lazyload-wrapper {
	/* min-width: 400px; */
	min-width: 100%;
}

.company-card-main-img  {
	width: inherit;
	border: 2px solid var(--white);
	object-fit: scale-down;
	filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: calc(50% - 130px/2);
    top: 50%;
	width: 130px;
	height: 130px;
	border-radius: 100%;
	background: var(--white);
	aspect-ratio: 16 / 9;
}

.company-card-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px 30px;
	gap: 25px;
}

.company-card-main {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
}

.company-card-btns a {
	width: 100%;
}

.company-card-btns a .btn-small {
    min-width: 100%;
}

.company-card-title {
	display: flex;
	align-items: center;
	/* justify-content: space-between; */

	gap: 10px;
}

.company-card-aboutUs {
	text-align: center;
}

.company-card-work {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.company-card-social {
	display: flex;
	align-items: center;
	justify-content: center; 
	gap: 10px;
	width: 100%;
	flex-wrap: wrap;
}

.company-card-share-like {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 10px;
}

.company-card-btns {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.company-card-btns .custom-button {
	width: 100%;
	max-width: 148px;
	padding: 10px 0px;
}

.company-card-btns .custom-button-icon {
	width: 100%;
	max-width: 179px;
	padding: 10px 0px;
}

.company-card-btns .custom-button-icon div img {
	width: 20px;
	height: 20px;
}

.company-card h1 {
	margin: 0;
	text-align: center;
}
.company-card h2 {
	margin: 0;
}
.company-card h3 {
	margin: 0;
}
.company-card h4 {
	margin: 0;
}
.company-card p {
	margin: 0;
}

.company-card-social .city-country-display {
	max-width: 110px;
}

/* @media (max-width: 1037px) {
	.company-card-btns {
		display: flex;
		flex-direction: column;
	}
} */

@media (max-width: 1025px) {
	.company-card-main-img {
		width: 100px;
		height: 100px;
		left: calc(50% - 90px/2);
	}
}
@media (max-width: 1013px) {
	.company-card .verify-container {
		top: 17rem
	}
}

@media (max-width: 900px) {
	.company-card-title h1  {
		font-size: 0.9rem;
	}
	.company-card-main {
		gap: 10px
	}
	.company-card-imgs {
		height: 210px;
	}
	.company-card-bg {
		height: 160px !important;
	}
	.company-card-main-img {
		width: 90px;
		height: 90px;
		left: calc(50% - 70px/2);
		top: 50%
	}
	.company-card-main h2 {
		font-size: 0.75rem;
	}
	.company-card-body {
		gap: 15px;
	}
	.company-card-social .rating .rating_pointer-text,
	.company-card-social .commentCount span,
	.company-card-social .city-country-display span {
		font-size: 0.75rem;
	}
	.company-card-work h4,
	.company-card-aboutUs {
		font-size: 0.75rem;
	}
	.company-card-info {
		gap: 15px
	}
	.company-card-btns button {
		font-size: 0.75rem;
	}
}

@media (max-width: 370px) {
	.company-card-share-like {
		display: none;
	}

	.company-card-social {
		justify-content: center;
	}
}
