.mainRegisterNew-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* gap: 60px; */
    gap: 30px;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.mainRegisterNew-scroll-container {
    display: flex;
    flex-direction: column;
    gap: 60px;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
    height: 340px;
    overflow: scroll;
    padding: 0.5rem;
}

.mainRegisterNew-items {
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.mainRegisterNew-items .textfield,
.mainRegisterNew-items .mainRegister-item--password {
    width: 300px;
}

.mainRegisterNew-items .mainRegister-item--password {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.mainRegisterNew-container .smalltext {
    font-size: 1rem;
    font-family: 'Faktum';
}

.mainRegisterNew-top {
    padding-top: 30px;
}

.mainRegisterNew-bottom {
    padding-bottom: 20px;
}

.mainRegisterNew-link {
    color: #2D9CBD;
    cursor: pointer;
    font-size: 1rem;
}

.mainRegisterNew-text {
    color: var(--gray-1);
 }

 .mainRegisterNew-items-validate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 }

.mainRegisterNew-confirm {
    height: 54px;
    background: #333333;
    color: var(--white);
    justify-content: center;
    display: flex;
    align-self: center;
    align-items: center;
    width: 300px;
    border-radius: 30px;
    align-self: center;
    font-size: "Faktum-Medium";
    cursor: pointer;
    padding: 20px 0px;    
}

.mainRegisterNew-full-items {
    width: 620px;
    display: flex;
    flex-direction: column;
    gap: 20px
} 

.mainRegisterNew-full-items .phoneCountry-container {
    width: 130%;
}

.mainRegisterNew-full-items .phoneCountry-container .phoneCountry-item {
    gap: 20px
}

.mainRegisterNew-full-items .phoneCountry-container .phoneCountry-item .textfield {
    width: 100%;
}

.mainRegisterNew-full-items .textfield  {
    width: 100%;
}

.mainRegisterNew-full-items .phoneCountry-container .phoneCountry-item .phoneCountry-item-column-dropdown 
.phoneCountry-item--country .phoneCountry-list .ItemVessels-container .smalltext-header {
    text-align: start;
}

.mainRegisterNew-scroll-items {
    max-width: 620px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px
}

.mainRegisterNew-items .phoneCountry-container .phoneCountry-item 
.phoneCountry-item-column-dropdown {
    width: 50%;
}

.mainRegisterNew-items .phoneCountry-container .phoneCountry-item 
.phoneCountry-item-column-dropdown .phoneCountry-item-content {
    width: 100%;
}

.mainRegisterNew-item--row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}

.marinRegisterNewSeparator {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.mainRegisterNew-hr {
    height: 1px;
    width: 120px;
    background: var(--gray-5);
    margin: 0;
    border: 0;
}
.mainRegisterNew-o {
    display: flex;
    color: var(--gray-5);
    font-size: 1.25rem;
}

.mainRegister-item-codigo-validate {
    width: 390px;
}
.mainRegister-items-phoceCode {
    max-width: 300px;
    width: 100%;
}

.mainRegisterNew-scroll-items .dropdown {
    max-width: 220px;
}


@media (max-width: 658px) {
    .mainRegisterNew-scroll-container {
        height: 390px;
    }
    .mainRegisterNew-full-items .mainRegisterNew-scroll-items {
        flex-direction: column;
    }
    .mainRegisterNew-full-items {
        width: 400px;
    }
    .mainRegisterNew-full-items .phoneCountry-container {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .mainRegisterNew-confirm {
        color: var(--white) !important;
    }
    /* .mainRegisterNew-container {
        overflow: auto;
        height: 460px;
    } */
    .mainRegisterNew-full-items {
        width: 370px;
    }
    .ModalRegisterNew-body {
        gap: 10px;
        background: var(--white);
    }
    .mainRegisterNew-bottom {
        padding-bottom: 10px;
    }
}

@media (max-width: 524px) {
    .mainRegisterNew-container .smalltext,
    .mainRegisterNew-text {
        font-size: 1rem;
    }
}
@media (max-width: 445px) {
    .mainRegister-item-codigo-validate {
        width: 320px;
    }
    .mainRegisterNew-container .smalltext, 
    .mainRegisterNew-text,
    .mainRegisterNew-link {
        font-size: 0.875rem !important;
    }
    .mainRegisterNew-full-items {
        width: 350px;
    }
    .mainRegister-item-codigo-validate {
        width: 340px;
    }
}

@media (max-width: 430px) {
    .ModalRegisterNew-body {
        width: 100%;
    }
}

@media (max-width: 397px) {
    .mainRegisterNew-full-items {
        width: 330px;
    }
    .mainRegister-item-codigo-validate {
        width: 320px;
    }
    .mainRegisterNew-container .smalltext, 
    .mainRegisterNew-text,
    .mainRegisterNew-link {
        font-size: 0.875rem !important;
    }
}