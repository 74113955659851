.container-row {
	/* max-width: 730px; */
	display: flex;
	align-items: center;
	gap: 20px;
	/* padding: 10px 20px 10px 10px; */
	border-radius: 12px;
}

.container-row-info,
.container-row-textfields {
	width: 100%;
	max-width: 100%;
	display: flex;
	align-items: center;
	gap: 20px;
}

.container-row-list {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.container-row-trash {
	cursor: pointer;
}
.container-row-info {
	width: 100%;
}

.container-img img {
	width: 77px;
	height: 50px;
	border: 1px solid rgba(0, 0, 0, 0.25);
	object-fit: contain;

	border-radius: 15px;
	background: lightgray 17.207px -23.245px / 86.831% 133.586% no-repeat, #FFF;

}

.container-row-textfields-min {
	display: none;
}

.content-row-textfields-min {
	/* padding: 10px 20px 2.5px 10px; */
	backdrop-filter: blur(40px);
	border: 1px solid var(--black-25) !important;
    background-color: transparent !important;
    color: var(--gray-3) !important;
	border-radius: 12px;
	height: 50px;
	display: flex;
    justify-content: center;
    flex-direction: column;
	padding-left: 10px;
    padding-right: 10px;
}


@media (max-width: 768px) { 
	.container-row-textfields .textfield input:not(:placeholder-shown).textfield-input:not(:focus){
		padding: 22.5px 20px 7.5px 11px;
	}
}

@media (max-width: 662px) { 
	.container-row-textfields {
		gap: 10px
	}
}

@media (max-width: 633px) { 
	.container-row-info,
	.container-row-textfields {
		gap: 6px
	}
}

@media (max-width: 600px) {  
	.container-row-textfields {
		display: none;
	}
	.container-row-textfields-min {
		display: flex;
		flex-direction: row;
		gap: 5px;
		align-items: center;
		width: 100%;
	}
	.content-row-textfields-text {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 250px;
		width: 100%;
		font-size: 0.875rem;
		font-family: 'Faktum';
	}
	.content-row-textfields-min {
		width: 150%;
	}
	.container-img {
		display: flex;
	}

	.container-img img {
		border-radius: 12px;
	}

	.content-row-textfields-text_answer {
		font-size: 0.875rem;
		color: var(--gray-1) !important;
	}
	.container-row-list p {
		font-size: 0.875rem;
	}
}

@media (max-width: 566px) { 
	.container-row-textfields {
		flex-direction: column;
		width: 100%;
	}
	.container-row-textfields .textfield {
		width: 100%;
	}
}

@media (max-width: 504px) { 
	.content-row-textfields-min {
		width: 140%;
	}
}

@media (max-width: 452px) { 
	.content-row-textfields-text {
		max-width: 200px;
		width: 100%;
	}
}

@media (max-width: 408px) { 
	.container-img img {
		max-width: 70px;
		width: 100%;
	}
}

@media (max-width: 403px) { 
	.content-row-textfields-text {
		max-width: 160px;
	}
	.content-row-textfields-text_answer {
		overflow: hidden;
		max-width: 160px;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

@media (max-width: 375px) {
	.container-row-textfields {
		flex-wrap: wrap;
	}

	.container-row-textfields-min {
		width: 100%;
	}

	.content-row-textfields-min {
		width: 100%;
		max-width: 270px;
		min-width: 99px;
	}
	
	.container-row {
		max-width: 100%;
	}
}

@media (max-width: 348px) {
	.container-row-textfields-min {
		width: 100%;
		max-width: 270px;
		min-width: 99px;
	}
}