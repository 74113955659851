.panel-content {
    background: var(--white);
    width: 400px;
    height: fit-content;
    max-height: calc(100vh - 90px);
    position: absolute;
    z-index: 20;
    right: 22px;
    border-radius: 24px;
    padding: 30px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    gap: 30px;
    display: flex;
    flex-direction: column;
    top: 3.5rem;
    overflow: hidden;
}

.panel-content__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.panel-content-body {
    display: flex;
    flex-direction: row;
    gap: 5px
}

.panel-content-body img { 
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
}

.panel-close {
    display: flex;
    cursor: pointer;
}

.panel-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: 40rem;
    overflow: scroll;
    overflow-x: unset;
}

.panel-body::-webkit-scrollbar {
    width: 0px;
}

@media (max-width: 768px) { 
    .panel-content { 
        width: 100%;
        right: 0px;
        top: 0rem;
        border-radius: 0px;
        height: 100%;
        z-index: 10;
        padding: 30px 20px;
    }
    .panel-content .searchTextField-container   {
        max-width: 100%;
    }
    .panel-body {
        height: 100%;
        padding-bottom: 2rem;
    }
}