.absolute-container {
    width: 100%;
    
    display: inline-block;
    z-index: 999;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--white-50);
    padding: 10px 20px;
    backdrop-filter: blur(40px);
}

.absolute-container--button {
    width: 100%;
    display: flex;
    justify-content: center;
}

