.card {
    max-width: 396px;
    min-width: 100px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    padding: 0.6rem;
    border-radius: 24px;
    backdrop-filter: blur(40px);
    border-image-source: var(--border-image-source);
    /* background: var(--white); */
    /* padding: 20px; */
}
@keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
  
    100% {
      background-position: 468px 0;
    }
} 

.shimmer-animation {
    background-color: #f6f7f8;
    background-image: linear-gradient(
      to right,
      var(--white-50) 0%,
      var(--gray-6) 20%,
      var(--white-50) 40%,
      var(--white-50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
}

.loader-shimmer-service-banner {
    height: 180px;
    background-size: 800px 18rem;
    border-radius: 24px 24px 0px 0px;
    margin-bottom: 5px;
  }

  .loader-shimmer-service-content {
    display: flex;
    flex-direction: column;
    gap: 10px
  }
  
  .loader-shimmer-service-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 5px;
    gap: 0px;
  }

  .loader-shimmer-service-info {
    height: 30px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 5px;
    border-radius: 3px;
  }
  
  .loader-shimmer-service-info:last-child {
    margin-bottom: 0;
  }

  .loader-shimmer-service-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 5px;
    gap: 10px;
  }

  .loader-shimmer-service-action {
    height: 20px;
    width: 100%;
    max-width: 80px;
    border-radius: 3px;
  }

  @media (max-width: 700px) {
    .loader-shimmer-service-banner {
        height: 7rem;
    }
    .loader-shimmer-service-info {
      height: 30px;
    }
  }

  @media (max-width: 500px) {
    .loader-shimmer-service-content {
        gap: 6px;
    }
    .loader-shimmer-service-info {
      height: 20px;
    }
    .loader-shimmer-service-action {
        height: 15px;
    }
 }

 @media (max-width: 400px) {
    .loader-shimmer-service-banner {
        height: 5rem;
    }
 }