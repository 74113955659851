.dashboardElements-supplier {
    display: flex;
    flex-direction: column;
    width: 100%;
    backdrop-filter: blur(40px);
    border-radius: 12px;
    background: var(--background);
    border: 1px solid;
    border-image-source: var(--border-image-source);
}
.dashboardElements-supplier-header {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px 12px 0px 0px;
    /* border-radius: 24px 24px 0px 0px; */
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.dashboardElements-supplier-header__border {
    border-radius: 12px;
}
.dashboardElements-supplier-content-left {
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* min-width: 300px; */
    max-width: 553px;
    width: 100%;
    justify-content: flex-start;
    align-self: center;
    align-items: center;
}
.dashboardElements-supplier-title {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    max-width: 507px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dashboardElements-supplier__title {
    min-width: 200px;
    max-width: 450px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--gray-1);
}
.dashboardElements-supplier__detail {
    min-width: 200px;
    max-width: 450px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--gray-1);
}
.dashboardElements-supplier-content-right {
    min-width: 200px;
    max-width: 267px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
    align-self: center;
    align-items: center;
}

.dashboardElements-date-text {
    color: #333333;
    height: auto;
}

.dashboardElements-supplier-content {
    width: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
}
.dashboardElements-supplier-content-header {
    background: var(--white-50);
    display: flex;
    flex-direction: row;
    padding: 5px 20px;
    justify-content: space-between;
    color: var(--gray-2);
}

.dashboardElements-supplier-content-header-mobile .dashboardElements-mobile-content-header {
    width: 100%;
    padding: 0;
}

.dashboardElements-supplier-content-header-mobile, 
.dashboardElements-supplier-content-body-mobile {
    display: none;
}


.dashboardElements-supplier-content-title {
    min-width: 45px;
    width: 100%;
    display: flex;
    color: var(--gray-1);
}


.dashboardElements-supplier-content-body {
    background: var(--white-25);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dashboardElements-supplier-content 
.dashboardElementsRow-container .elementRow-container {
    padding: 0px;
    gap: 0px;
    justify-content: space-between;
}
.dashboardElements-supplier-content 
.dashboardElementsRow-container .elementRow-container 
.elementRow-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 280px;
    /* max-width: 200px; */
}
.dashboardElements-supplier-content 
.dashboardElementsRow-container .elementRow-container 
.elementRow-title, .dashboardElements-supplier-content 
.dashboardElementsRow-container .elementRow-container 
.elementRow-row {
    min-width: 45px;
    width: 100%;
}
.dashboardElements-supplier-content 
.dashboardElementsRow-container .elementRow-container 
.elementRow-row .elementRow-colorText .elementRow-alert{
    min-width: 10px;
    min-height: 10px;
}
.content-supplier-header {
    display: flex;
    flex-direction: row;
}

.dashboardElements-supplier-explain {
    display: none;
}

.dashboardElements-supplier-content .dashboardElementsRow-container:hover {
    background: var(--white-15);
}

@media (max-width: 890px) { 
    .dashboardElements-supplier-content .dashboardElementsRow-container .elementRow-container 
    .elementRow-title {
        font-size: 0.75rem;
    }
}

@media (max-width: 500px) { 
    .dashboardElements-supplier-explain {
        display: block;
    }
    .dashboardElements-supplier-title {
        max-width: 100%;
    }
    .dashboardElements-supplier__title {
        max-width: 70%;
    }
    .dashboardElements-supplier-content-right .content-quote 
    .content-quote-header div img{
        display: block;
    }
    .dashboardElements-supplier-content-right div img {
        display: none;
    }
    .dashboardElements-supplier-content-header {
        display: none;
    }
    .dashboardElements-supplier-header {
        flex-direction: column;
        display: flex;
        gap: 15px
    }
    .dashboardElements-supplier-content-header-mobile  {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 9px 15px;
        background: var(--gray-6);
        align-items: center;
    }
    .dashboardElements-supplier-content-header-mobile-tag {
        padding: 2px 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-gap: 5px;
        gap: 5px;
        background: #D9D9D9;
        backdrop-filter: blur(40px);
        border-radius: 24px;
    }
    .dashboardElements-supplier-content-header-mobile-tag img{
        width: 18px;
        height: 18px;
        border-radius: 24px;
    }
    .dashboardElements-supplier-content-header-mobile-tag-name {
        font-size: 0.625rem;
    }

    /** Detalle de la informacion */ 
    .dashboardElements-supplier-content .dashboardElementsRow-container {
        display: none;
    }
    .dashboardElements-supplier-content-body-mobile {
        display: inline-flex;
        flex-direction: column;
        gap: 15px;
        padding: 12px 15px 10px 15px;
        width: 100%;
    }
    .dashboardElements-supplier-content-body-mobile-container {
        flex-direction: column;
        display: flex;
        gap: 5px;
        width: 100%;
    }
    .dashboardElements-supplier-content-body-mobile-row__ {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
    }
    .dashboardElements-supplier-content-body-mobile__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px
    }
    .dashboardElements-supplier-content-body-mobile-text {
        color: var(--gray-1);
        width: 100%;
        max-width: 450px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .dashboardElements-supplier-content-body-mobile-text__ {
        color: var(--gray-1);
        width: 100%;
        max-width: 150px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .dashboardElements-supplier-content-body-mobile-alert {
        min-width: 10px;
        min-height: 10px;
        width: 10px;
        height: 10px;
        background: var(--red);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 48px;
    }
}

@media (max-width: 387px) { 
    .dashboardElements-supplier-content-body-mobile-text__ {
        color: var(--gray-1);
        width: 100%;
        max-width: 120px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

@media (max-width: 344px) { 
    .dashboardElements-supplier-content-body-mobile-text {
        max-width: 200px;
    }
    .dashboardElements-supplier-content-body-mobile__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 5px
    }
    .dashboardElements-supplier-content-body-mobile-text__ {
        color: var(--gray-1);
        width: 100%;
        max-width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}