.inputOriginDestiny {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.inputOriginDestiny_main {
    width: 100%;
    display: flex;
    gap: 30px;
    flex-direction: column;

}

.inputOriginDestiny-column {
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.inputOriginDestiny-row-button {
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0.8rem;
}

.inputOriginDestiny-row-button .btnIcon-large {
    max-width: 100%;
    min-width: none;
    width: 100%;
}

.inputOriginDestiny-row-button .btnIcon-large div {
    display: flex;
}

.inputOriginDestiny-btn-color {
    color: var(--gray-1) 
}


.inputOriginDestiny-finalDelivery {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.inputOriginDestiny-finalDelivery-inputs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.inputOriginDestiny-finalDelivery-inputs .textfield {
    width: 100%;
}
.inputOriginDestiny-finalDelivery .textfield {
    width: 100%;
}

@media (max-width: 715px) {
    .inputOriginDestiny-row-button .btnIcon-large {
        min-width: 150px;
    }
}

@media (max-width: 670px) {
    
    .inputOriginDestiny-finalDelivery .inputOriginDestiny-finalDelivery-inputs .textfield .textfield-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 406px;
        width: 100%;
    }

}

@media (max-width: 600px) {
    .inputOriginDestiny {
        gap: 15px;
    }
}

@media (max-width: 597px) {
    .inputOriginDestiny-row-button .btnIcon-large {
        font-size: 1rem;
        padding: 10px 3px;
        min-width: 100px;
    }
}

@media (max-width: 579px) {
    .inputOriginDestiny-finalDelivery .inputOriginDestiny-finalDelivery-inputs .textfield .textfield-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 350px;
        width: 100%;
    }
}

@media (max-width: 540px) {
    .inputOriginDestiny-row-button {
        gap: 15px
    }
    .inputOriginDestiny-row-button .btnIcon-large {
        font-size: 0.87rem;
        padding: 10px 3px;
        min-width: 70px;
    }
}

@media (max-width: 522px) {
    .inputOriginDestiny-finalDelivery .inputOriginDestiny-finalDelivery-inputs .textfield .textfield-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 300px;
        width: 100%;
    }
}

@media (max-width: 450px) { 
    .inputOriginDestiny-row-button {
        flex-direction: row;
        gap: 15px;
        justify-content: center;
        align-self: center;
        width: 100%;
        align-items: center;
    }
    .inputOriginDestiny-finalDelivery .inputOriginDestiny-finalDelivery-inputs .textfield .textfield-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 215px;
        width: 100%;
    }
}

@media (max-width: 397px) {
    .inputOriginDestiny-row-button {
        gap: 15px
    }
    .inputOriginDestiny-row-button .btnIcon-large {
        gap: 4px;
    }
}

@media (max-width: 373px) {
    .inputOriginDestiny-row-button .btnIcon-large {
        gap: 10px;
        /* min-width: 100%; */
    }
    .inputOriginDestiny-finalDelivery .inputOriginDestiny-finalDelivery-inputs .textfield .textfield-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 190px;
        width: 100%;
    }
}

@media (max-width: 363px) {
    .inputOriginDestiny-finalDelivery .inputOriginDestiny-finalDelivery-inputs .textfield .textfield-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 150px;
        width: 100%;
    }
}

@media (max-width: 328px) {
    .inputOriginDestiny-finalDelivery .inputOriginDestiny-finalDelivery-inputs .textfield .textfield-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 120px;
        width: 100%;
    }
}

@media (max-width: 303px) {
    .inputOriginDestiny-finalDelivery .inputOriginDestiny-finalDelivery-inputs .textfield .textfield-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100px;
        width: 100%;
    }
}