.phoneCountry-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.phoneCountry-item-error {
    border: 1px solid red !important;
}

.phoneCountry-item {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.phoneCountry-item--country {
    max-width: 300px;
    width: 100%;
    background: var(--white);
    z-index: 3;
    position: absolute;
    margin-top: 10px;
    padding: 10px;
    border-radius: 12px;
}

.phoneCountry-item-content-column {
    display: flex;
    flex-direction: column;
}

.phoneCountry-item-content-row {
    max-width: 100px;
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.phoneCountry-item-content-row .paragraph-header {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.875rem;
}

.phoneCountry-item-content-row img {
    height: 0.89rem;
}

.phoneCountry-item-content {
    height: 50px;
    display: flex;
    align-self: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 10px;
    width: 130px;
    border-radius: 24px;
    background: var(--white);
    outline: none;
    border: 0px solid var(--black-15);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

.phoneCountry-item-content-text-selected {
    top: 15px;
    font-family: "Faktum";
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 15px;
    color: var(--black);
    text-align: start;
}

.phoneCountry-item-content-text {
    color: var(--black);
    cursor: pointer;
}

/* .phoneCountry-item--country .dropdown{
    width: 100%;
} */

.dropdown-list {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: pointer;
}

/* .dropdown-list::-webkit-scrollbar {
    width: 0px;
    height: 0px;
} */

/* .ItemVessels-container:hover {
    background: var(--white-50);
} */

.phoneCountry-item--country .dropdown {
    max-width: 190px;
    width: 100%;
}

.phoneCountry-item--country .dropdown .dropdown-select .move-text {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.phoneCountry-item--country .textfield {
    cursor: pointer;
}

.phoneCountry-list {
    overflow-y: scroll;
    height: auto;
    max-height: 200px;
    box-sizing: border-box;
    margin-top: 10px; 
    width: 100%;
    cursor: pointer;
}

.phoneCountry-list .ItemVessels-container:hover {
    background: var(--gray-5);
    border-radius: 30px;
}

.phoneCountry-list::-webkit-scrollbar {
    width: 0px;
}

.phoneCountry-item-column-dropdown {
    cursor: pointer;
}

.phoneCountry-item-column-dropdown-disabled {
    border: 1px solid var(--black-25);
	color: var(--gray-3) !important; 
    width: 130px;
    box-sizing: border-box;
	backdrop-filter: blur(40px);
	border-radius: 24px;
    background: none !important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.phoneCountry-item-column-dropdown-disabled + span {
	color: var(--gray-3);
	font-weight: 500;
}

@media (max-width: 376px) {
    .phoneCountry-item--country .dropdown {
        max-width: 150px;
        width: 100%;
    }
    
    .phoneCountry-item--country .dropdown .dropdown-select .move-text {
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .phoneCountry-item .textfield .textfield-input {
        width: 100%;
    }
}

@media (max-width: 367px) {
    .phoneCountry-item--country,
    .phoneCountry-list {
        max-width: 100%;
    }
    .phoneCountry-list {
        height: 200px;
    }
}