.toggle-switch {
  position: relative;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid var(--black-15);
  background: white;
  border-radius: 20px;
  margin: 0;
  width: 40px;
  height: 26px;
}

.toggle-switch-label:focus {
  outline: none;
}

.toggle-switch-label:focus > span {
  /*background-color: blue;*/
}

.toggle-switch-label:focus > span:focus {
  outline: none;
}

.toggle-switch-disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.toggle-switch-disabled::before {
  background-color: #ddd;
  cursor: not-allowed;
}

.toggle-switch-switch::after {
  text-transform: uppercase;
  padding-right: 10px;
  color: white;
  text-align: right;
}

.toggle-switch-switch ::before {
  text-transform: uppercase;
  padding-right: 10px;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 24px;
  position: absolute;
  background: var(--green-2);
  top: 0px;
  height: 20px;
  width: 20px;
  bottom: 0;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.toggle-switch-switch_ {
  display: block;
  width: 24px;
  position: absolute;
  top: 0px;
  height: 20px;
  width: 20px;
  bottom: 0;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}


.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch,
.toggle-switch-checkbox:checked + .toggle-switch-label  .toggle-switch-switch_  {
  right: 0px;
}

.toggle-switch .small-switch {
  width: 40px;
}

.toggle-switch-switch, .toggle-switch-switch_ {
  right: 14px;
  margin: 3px;
}