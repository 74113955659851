.userVerify-container {
    position: absolute;
    z-index: 10;
    top: 8rem;
    right: 20rem;
}

.userVerify-content {
    background: var(--white);
    border-radius: 24px;
    box-shadow: 0px 1.5px 3px 0px rgba(0, 0, 0, 0.60);
   
    max-width: 280px;
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px; 
} 
.userVerify-line {
    display: none;
}
.userVerify-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.userVerify-status {
    color: var(--white);
    background: var(--black);
    padding: 5px 10px;
    border-radius: 24px;
    font-size: 0.875rem;
}
.userVerify-status-top {
    color: var(--white);
    background: var(--blue-2);
    padding: 5px 10px;
    border-radius: 24px;
    font-size: 0.875rem;
}
.userVerify-description {
    display: flex;
    flex-direction: row;
    gap: 10px; 
    align-items: flex-start;
}
.userVerify-description div img {
    width: 1.3rem;
}
.userVerify-description-title {
    font-size: 0.875rem;
}
.userVerify-more {
    color: var(--blue-2);
    font-size: 0.875rem;
    cursor: pointer;
}
.userVerify-content .custom-button {
    font-size: 0.875rem;
}

@media (max-width: 845px) {
    .userVerify-container {
        top: 8rem;
        right: 10rem;
    }
}

@media (max-width: 768px) {
    .userVerify-container {
        top: 10rem;
        right: 10rem;
        left: 10rem;
    }
}

@media (max-width: 600px) {
    .userVerify-container {
        /* background: var(--black-15); */
        /* top: 0rem;
        right: 0px; */
        background: var(--black-15);
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0rem;
        right: 0px;
        left: 0rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        border-radius: 0px;
    }
    .userVerify-content {
        /* width: auto;
        height: auto;
        display: flex;
        position: relative; */
        bottom: 0px;
        display: flex;
        position: relative;
        max-width: 100%;
        border-radius: 24px 24px 0px 0px;
        height: auto;
    
    }
    .userVerify-line {
        display: flex;
        height: 0.3rem;
        width: 30px;
        background: var(--black-15);
        align-items: center;
        align-self: center;
        border-radius: 10px;
    }
    .userVerify-title {
        font-size: 1.25rem;
    }
}