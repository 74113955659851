.quote-provider {
    display: flex;
    flex-direction: column;
    max-width: 27.5rem;
    padding: 20px;
    border: 1px solid;
    border-image-source: var(--border-image-source);
    /* gap: 30px; */
    gap: 10px;

    background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.09));
    backdrop-filter: blur(20px);
    border-radius: 24px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
}
.quote-provider-header {
    align-items: center;
}
.quote__row___ {
    gap: 10px;
}
.quote-provider-header, .quote__row___{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.quote-provider-body-content_row {
    max-width: 50%;
    width: 100%;
}

.quote-provider-header .content-quote .dropdown__disabled .dropdown_disable_color {
    color: var(--white);
}

.quote-provider-body, .quote-provider-footer {
    gap: 10px;
    display: flex;
    flex-direction: column;
}
.quote-provider-body-content {
    gap: 5px;
    display: flex;
    flex-direction: column;
    /* width: 50%; */
}

.quote-provider-colorText {
    /* width: 100%;
    min-width: 0; */
    color: var(--gray-1);
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
}
.quote-provider-body-content_row {
    display: flex;
    gap: 5px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.quote-provider-body-content_row_1 {
    width: 40%;
    cursor: pointer;
}

.quote-provider-colorTextBlue {
    color: var(--blue-2)
}
.quote-provider-body-header-check {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}
.quote-provider-body-header-check div {
    display: flex;
}
.quote-provider-body-header-check div img {
    width: 15px;
}
.quote-provider-body__email {
    max-width: 170px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.quote-provider-body__email .smalltext {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;  
    text-overflow: ellipsis;
}

@media (max-width: 1175px) { 
    .row__content_1 {
        display: flex;
        flex-direction: column;
        gap: 10px
    }
    .quote-provider-body-content_row_1 {
        width: 50%;
    }
}

@media (max-width: 880px) { 
    .quote-provider-header .content-quote {
        min-width: 100px;
    }
    .row__content_1 {
        display: flex;
        flex-direction: row;
        gap: 10px
    }
    .quote-provider-body-content_row_1 {
        width: 50%;
    }
    .quote-provider {
        border-radius: 12px;
        padding: 10px;
    }
}

@media (max-width: 490px) { 
    .row___1 {
        gap: 10px;
        display: flex;
        flex-direction: row;
    }
}

@media (max-width: 346px) { 
    .quote-provider-header .content-quote {
        max-width: 100%;
        gap: 10px;
    }
    .quote-provider-header .content-quote .dropdown{
        width: 55%;
    }
}

@media (max-width: 320px) { 
    .quote-provider-header {
        flex-direction: column;
        align-items: start;
        align-content: start;
        align-self: start;
        width: 100%;
        gap: 10px
    }
    .quote-provider-header .content-quote {
        justify-content: start;
    }
    .quote-provider-header .content-quote .dropdown{
        width: 45%;
    }
}
