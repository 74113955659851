.tableQuotation {
    flex-direction: column;
    display: flex;
    gap: 30px
}
.tableQuotation-container {
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
    border-radius: 12px;
    overflow: hidden;
    margin-top: 1rem;
}

.tableQuotation-currecy {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    border-radius: 24px;
} 

.tableQuotation-currecy .dropdown {
    max-width: 130px;
    width: 100%;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

.tableQuotation-currecy .dropdown .dropdown-select {
    backdrop-filter : blur(20px);
}

.tableQuotation-container__ {
    background: var(--white-90);
    height: 50px;
    padding: 15px 20px;
    border-radius: 12px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}

.tableQuotation-title {
    height: 50px;
    color: var(--gray-1);
    padding: 10px 20px;
    background: var(--white-90);
    border-radius: 12px 12px 0px 0px;
    align-items: center;
    display: flex
}

.tableQuotation-title__ {
    color: var(--gray-1);
}

.tableQuotation-rating-content {
    display: flex;
    flex-direction: row;
    position: absolute;
    gap: 10px
}

.tableQuotation-body {
    padding: 10px;
}

.tableQuotation-body #tableQuotation-body-textarea {
    width: 100%;
    border: 1px solid var(--black-25);
    padding: 15px 11px;
    border-radius: 24px;
    background: transparent;
}

.tableQuotation-rating {
    flex-direction: row;
    display: flex;
    gap: 10px
}

.tableQuotation-footer, .tableQuotation-footer_ {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 30px;
    /* padding-top: 61px; */
}

.tableQuotation-footer .custom-button-icon {
    color: var(--black-50);
    box-shadow: 0px 0px 00px rgb(0 0 0 / 0%);
    backdrop-filter: blur(0px);
    width: 100%;
    max-width: 160px;
}

.tableQuotation-footer .btn-normal {
    max-width: 610px;
    min-width: 200px;
    width: 100%;
    height: 43px;
}

.tableQuotation-container-restriction {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.tableQuotation-container-restriction .iteminfo-quotation {
    max-width: 100%;
}

.tableQuotation-footer--buttons {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
}

.tableQuotation-rating-icon {
    display: none;
}

.tableQuotation-container-restriction .iteminfo-quotation .iteminfo-quotation-restrictions .iteminfo-quotation-restrictions-contentCheck 
.iteminfo-quotation-restrictions-contentCheck___ .iteminfo-quotation-restrictions-contentCheck_ .iteminfo-quotation-restrictions-check 
.checkcircle-container label::before  {
    border: 1px solid var(--gray-6);
}

.tableQuotation-restrictions {
    width: 95%;
    padding: 5px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.tableQuotation-restrictions--check {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
}

.tableQuotation-footer-quotation {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

@media (max-width: 1001px) {  
    .tableQuotation-footer--buttons {
        gap: 10px
    }
    .tableQuotation-footer--buttons .custom-button {
        font-size: 1rem;
    }
}

@media (max-width: 1045px) { 
    .tableQuotation-body .textarea .textarea-title {
        font-size: 0.9rem;
    }
}

@media (max-width: 630px) {  
    .tableQuotation-title {
        font-size: 14px;
    }
}

@media (max-width: 525px) {  
    .tableQuotation-footer--buttons .btn-normal {
        padding: 10px 0px;
    }
}

@media (max-width: 500px) { 
    .tableQuotation-footer {
        gap: 20px
    }
    .tableQuotation-footer .custom-button-icon {
        font-size: 12px;
    }
    .tableQuotation-footer--buttons .btn-normal {
        min-width: 50px;
    }
}

@media (max-width: 480px) { 
    /* .tableQuotation-rating .rating-slider .rating-slider-active,
    .tableQuotation-rating .rating-slider .rating-slider-default {
        width: 2.64px;
    } */
    .tableQuotation-rating .rating-slider {
        display: none;
    }
    .tableQuotation-rating-icon {
        display: flex;
    }
    .tableQuotation-rating-content  .hover {
        display: none;
    }
}

@media (max-width: 442px) { 
    .tableQuotation-rating .rating-slider .rating-slider-active,
    .tableQuotation-rating .rating-slider .rating-slider-default {
        width: 2px;
    }
}

@media (max-width: 437px) { 
    .tableQuotation-footer .btn-normal {
        padding: 10px 20px;
        font-size: 1rem !important;
    }
}

@media (max-width: 425px) { 
    .tableQuotation-body .textarea .textarea-title {
        font-size: 0.85rem;
    }

    .tableQuotation-container__ {
        padding: 15px 10px;
    }

    .tableQuotation-footer--buttons .btn-normal {
        font-size: 0.875rem;
    }
    .tableQuotation-title__ {
        font-size: 0.875rem;
    }
}

@media (max-width: 400px) { 
    .tableQuotation-footer .btn-normal {
        min-width: 150px;
        padding: 10px 20px;
    }
    .tableQuotation-rating .rating-slider .rating-slider-active, 
    .tableQuotation-rating .rating-slider .rating-slider-default {
        width: 1.5px;
    }
}

@media (max-width: 385px) { 
    .tableQuotation-footer .btn-normal {
        padding: 10px;
        font-size: 0.85rem !important;
    }
}

@media (max-width: 380px) {
    .tableQuotation-rating .rating-slider {
        max-width: 65%;
        width: 100%;
    }
}

@media (max-width: 375px) {
    .tableQuotation-footer--buttons {
        flex-direction: column;
    }
}

@media (max-width: 366px) { 
    .tableQuotation-footer {
        gap: 10px
    }
    .tableQuotation-footer .btn-normal {
        min-width: 150px;
        padding: 10px 10px;
    }
    /* .tableQuotation-container__ {
        flex-direction: column;
        height: auto;
        gap: 15px;
    } */
    .tableQuotation-rating {
        gap: 15px
    }
    .tableQuotation-rating .rating-slider .rating-slider-active, 
    .tableQuotation-rating .rating-slider .rating-slider-default,
    .tableQuotation-rating .rating-slider .rating-slider-space {
        width: 3.64px;
    }
    .tableQuotation-footer .custom-button-icon, .tableQuotation-footer {
        gap: 5px
    }
    .tableQuotation-footer .btn-normal {
        font-size: 0.85rem !important;
        padding: 10px 5px;
    }
}

@media (max-width: 330px) {  
    .tableQuotation-footer .custom-button-icon{
        gap: 4px
    }
    .tableQuotation-footer .custom-button-icon div img{
        height: 10px;
    }
    .tableQuotation-footer .btn-normal {
        font-size: 0.85rem !important;
        padding: 10px 2px;
        min-width: 10px;
    }
}