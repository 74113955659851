.third-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 60px 120px;
}

.third-section-header {
    display: flex;
    flex-direction: column;
}

.third-section-text {
    font-size: 2.813rem;
    text-align: center;
    font-family: 'Faktum-SemiBold';
    color: var(--white);
    text-shadow: -6px 9px 20px rgba(255, 197, 37, 0.36), 6px -9px 20px rgba(255, 197, 37, 0.36);
}

.third-section-text-grandient {
    font-size: 2.813rem;
    font-family: 'Faktum-SemiBold';
    background: -webkit-linear-gradient(#F7E246, #FFC525);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.third-section-animation {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px
}

.third-section-header-mob {
    display: none;
}

@media (max-width: 1480px) {
    .third-section {
        padding: 60px;
    }
}

@media (max-width: 1194px) {
    .third-section {
        padding: 60px 30px;
    }
    .third-section-text,
    .third-section-text-grandient {
        font-size: 2.6rem;
    }
    .third-section-animation {
        gap: 20px
    }
}

@media (max-width: 1045px) {
    .third-section-text, 
    .third-section-text-grandient {
        font-size: 2rem;
    }
    .third-section {
        justify-content: center;
        align-items: center;
        gap: 30px
    }
    .third-section-animation {
        flex-direction: column;
        justify-content: center;
        gap: 30px
    }
}

@media (max-width: 665px) {
    .third-section {
        gap: 20px;
        padding: 60px 20px 30px 20px;
    }
    .third-section-header {
        display: none;
    }

    .third-section-header-mob {
        display: flex;
        flex-direction: column;
    }
    .third-section-text, 
    .third-section-text-grandient{
        font-size: 1.525rem;
    }
    .third-section-animation{
        gap: 20px
    }
}