

@media(max-width: 960px) {
	.suscriptionPage {
        padding: 20px;
        height: 100vh;
    }
}

@media(max-width: 600px) {
	.suscriptionPage {
        padding: 20px 10px;
        height: 100vh;
    }
}