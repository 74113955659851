.today {
    gap: 18px;
    display: flex;
    border-width: 1px;
    flex-direction: column;
    border-bottom-color: var(--gray-6);
    border-bottom-style: solid;
}
.today-list-event {
    gap: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 14px;
}
.today .eventCard-container
.eventCard-body .eventCard-title {
    font-size: 0.875rem;
}
.today .eventCard-container
.eventCard-body .eventCard-detail {
    color: var(--gray-2);
}
.today .eventCard-container
.eventCard-body .eventCard-date {
    color: var(--gray-3);
    font-size: 0.75rem;
}
.today-any-event-message {
    color: var(--gray-3)
}