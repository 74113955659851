.invoceTranslationSupplier-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 0.9rem;
}


.invoceTranslationSupplier-add-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
}

.invoceTranslationSupplier-add-container--item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--blue-2);
    padding-bottom: 10px;
    cursor: pointer;
}

.invoceTranslationSupplier-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    gap: 20px;
    backdrop-filter: blur(20px);
	border-radius: 24px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    border: 0px solid;
    z-index: 1;
    /* border: 1px solid;
    border-image-source: var(--border-image-source); */
}


.invoceTranslationSupplier-container .supplierInvoceTranslation-main .supplierInvoceTranslation-row .dropdown .dropdown-select .move-text,
.invoceTranslationSupplier-container  .transactionInvoceTranslation-main .transactionInvoceTranslation-row .dropdown .dropdown-select .move-text {
    left: 0px;
}

.invoceTranslationSupplier-container .supplierInvoceTranslation-main .supplierInvoceTranslation-row .dropdown .dropdown-list,
.invoceTranslationSupplier-main .invoceTranslationSupplier-container .transactionInvoceTranslation-main .transactionInvoceTranslation-row .dropdown .dropdown-list,
.invoceTranslationSupplier-container div .invoceTranslationSupplier-product-container .productInvoceTranslation-main .productInvoceTranslation-row-product .dropdown .dropdown-list { 
    max-height: 357px;
    height: auto;
    overscroll-behavior: revert;
    overflow-x: hidden;
}

.invoceTranslationSupplier-container .supplierInvoceTranslation-main .supplierInvoceTranslation-row .dropdown .dropdown-list .dropdown-list__item { 
    background: var(--white-90);
}

.invoceTranslationSupplier-container .supplierInvoceTranslation-main .supplierInvoceTranslation-row .dropdown .dropdown-list .dropdown-list__item:hover { 
    background: var(--gray-3);
    color: var(--white-90);
}

.invoceTranslationSupplier-container .supplierInvoceTranslation-main .supplierInvoceTranslation-row .dropdown .dropdown-list::-webkit-scrollbar { 
    width: 0px;
}

.invoceTranslationSupplier-container .transactionInvoceTranslation-main .transactionInvoceTranslation-row .dropdown .dropdown-list .dropdown-list__item {
    background: var(--white-90);
}

.invoceTranslationSupplier-container .transactionInvoceTranslation-main .transactionInvoceTranslation-row .dropdown .dropdown-list .dropdown-list__item:hover { 
    background: var(--gray-3);
    color: var(--white-90);
}

.invoceTranslationSupplier-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.invoceTranslationSupplier-width {
    width: 24.375rem !important;
}


.invoceTranslationSupplier-statement {
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    border: 0px solid;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    gap: 20px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 24px;
}

.invoceTranslationSupplier-span {
    color: var(--red);
}

.invoceTranslationSupplier-text {
    color: var(--gray-1);
}

.invoceTranslationSupplier-product-container {
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    /* margin: 0px 20px; */
    padding: 20px;
    gap: 20px;
    background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
    border-radius: 12px;
}

/* .invoceTranslationSupplier-product-container .productInvoceTranslation-main 
.productInvoceTranslation-row-product .textfield input {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
} */

.invoceTranslationSupplier-product-tabs-scroll {
    max-width: 50rem;
    width: 100%;
    height: 3.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.invoceTranslationSupplier-product-tabs-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.invoceTranslationSupplier-product-tabs-scroll::-webkit-scrollbar {
    width: 2px;
    height: 4px;
	background-color: #F5F5F5;
}
.invoceTranslationSupplier-product-tabs-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--gray-1);
}

.invoceTranslationSupplier-product-tabs {
    max-width: 50rem;
    width: 100%;
    /* height: 3.5rem; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding-bottom: 1rem;
}

.invoceTranslationSupplier-product-tabs .subheader-clicked {
    color: var(--black);
}

.invoceTranslationSupplier-btn-confirm {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 10px;
}

/* .invoceTranslationSupplier-btn-confirm .custom-button {
    box-shadow: 0px 0px 0px var(--black-15);
} */

.invoceTranslationSupplier-btns {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.invoceTranslationSupplier-sizeText {
    font-size: 0.75rem;
}

.invoceTranslationSupplier-btns .custom-button-icon {
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
    backdrop-filter: blur(0px);
}

.invoceTranslationSupplier-content-supplier-transaction {
    display: flex;
    flex-direction: column;
    gap: 30px
}

.supplierInvoceTranslation-main .textfield .textfield-input, 
.transactionInvoceTranslation-row .textfield .textfield-input,
.transactionInvoceTranslation-main .textfield .textfield-input,
.invoceTranslationSupplier-product-container .productInvoceTranslation-main .textfield .textfield-input,
.invoceTranslationImporter-input-m .textfield .textfield-input,
.invoceTranslationLegal-textField-m-row  .textfield .textfield-input,
.invoceTranslationLegal-textField-m .textfield .textfield-input
{
    background: var(--white);
}
@media (max-width: 880px) { 
    .invoceTranslationSupplier-statement {
        gap: 10px
    } 
    .invoceTranslationSupplier-statement .invoceTranslationSupplier-text{ 
        font-size: 0.9rem !important;
    }
}

@media (max-width: 727px) {  
    .invoceTranslationSupplier-container .invoceTranslationSupplier-product-tabs .subheader,
    .invoceTranslationSupplier-container .invoceTranslationSupplier-product-tabs-scroll .subheader {
        font-size: 0.9rem;
    }
}

@media (max-width: 700px) {  
    .invoceTranslationSupplier-container,
    .invoceTranslationSupplier-statement {
        border-radius: 12px;
    }
    .invoceTranslationSupplier-statement div .custom-button {
        font-size: 0.9rem;
    }
}

@media (max-width: 680px) { 
    .invoceTranslationSupplier-product-container {
        margin: 0px;
    }
}

@media (max-width: 425px) {
    .invoceTranslationSupplier-add-container .invoceTranslationSupplier-add-container--item {
        padding-top: 10px;
    }
    .invoceTranslationSupplier-container {
		padding: 10px;
        gap: 10px
	}
    .invoceTranslationSupplier-statement {
        padding: 10px;
    }
}

@media (max-width: 377px) { 
    .invoceTranslationSupplier-btns {
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }
}