/* contenedor principal */
.subheader {
	color: var(--black-50);
	cursor: pointer;
	position: relative;
}

.subheader:hover {
	color: var(--black);
}

.subheader_white {
	color: var(--white);
	cursor: pointer;
	position: relative;
}

.subheader_white:hover {
	color: var(--white-75);
}

.subheader::before {
	content: "";
	width: 20px;
	height: 4px;
	border-radius: 2px;
	background-color: var(--black);
	position: absolute;
	left: calc(50% - 20px/2);
	bottom: 0%;
	margin-bottom: -10px;

	transform: scaleX(0);
	transform-origin: left;
	transition: transform 250ms ease-in;
}

.subheader:hover::before {
	transform: scaleX(1);
}

.subheader-clicked {
	color: var(--white);
	position: relative;
}

.subheader-clicked-black {
	color: var(--black);
	position: relative;
}

.subheader-clicked-black::before,
.subheader-clicked::before {
	content: "";
	width: 20px;
	height: 4px;
	border-radius: 2px;
	background-color: var(--black);
	position: absolute;
	left: calc(50% - 20px/2);
	bottom: 0%;
	margin-bottom: -10px;
	transition: none;
	transform: none;
}
