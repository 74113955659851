@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}

.loaderText-container {
  margin-top: 80px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loaderText-animate {
  --d: 2500ms;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: rgba(79, 79, 79, 0.8);
  --c2: rgba(224, 224, 224, 0.1);

  max-width: 694px;
  width: 100%;
  max-height: 300px;
  height: fit-content;
  border: 0.35rem solid;
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--c1) 0.1turn,
      var(--c1) 0.15turn,
      var(--c2) 0.25turn
    )
    30;

  border-radius: 21px;

  animation: borderRotate var(--d) linear infinite forwards;
}

.loaderText {
  max-width: 694px;
  width: 100%;
  max-height: fit-content;
  height: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* align-items: center; */
  gap: 21px;

  background: var(--background);
  border: 1px solid;
  border-image-source: var(--border-image-source);
  /* border-radius: 21px; */
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.15));
  backdrop-filter: blur(22.9066px);
}

.loaderText-img {
  width: 90px;
  height: 90px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #181818 0%, #333333 100%);
}

.loaderText-img img {
  width: 52px;
  height: 52px;
}

.loaderText-text {
  max-width: 538px;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}

.loaderText-colorText {
  color: #181818;
}

@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}

@media (max-width: 750px) {
  .loaderText-img {
    width: 75px;
    height: 75px;
  }
  .loaderText-img img {
    width: 40px;
    height: 40px;
  }
  .loaderText-text {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .loaderText {
    padding: 15px;
  }

  .loaderText-img {
    width: 60px;
    height: 60px;
  }
  .loaderText-img img {
    width: 33px;
    height: 33px;
  }
  .loaderText-text {
    width: 85%;
    gap: 5px;
  }
  .loaderText-text .small-header {
    font-size: 1rem;
  }
  .loaderText-text .small-subheader {
    font-size: 0.875rem;
  }
}

@media (max-width: 450px) {
  .loaderText {
    padding: 10px;
    gap: 10px;
  }
}

@media (max-width: 420px) {
  .loaderText-img {
    width: 50px;
    height: 50px;
  }
  .loaderText-img img {
    width: 30px;
    height: 30px;
  }
}
