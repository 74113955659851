.compareQuotation-main {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    display: flex;
    gap: 20px;
}

.compareQuotation-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    position: relative;
    margin-bottom: 6rem;
    height: 100%;
}

.compareQuotation-container {
    /* max-width: 1280px;
    display: flex;
    flex-direction: column;
    padding: 30px 80px;
    gap: 30px;
    justify-content: center;
    align-self: center;
    width: 100%;
    margin-bottom: 15rem; */
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-self: center;
    gap: 15px;
    padding: 0px 20px 0px 20px;
    /* margin-top: 30px; */
    padding-bottom: 20px;
}

.compareQuotation-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px 50px;
}

.compareQuotation-table {
    overflow: hidden;
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
    border-radius: 13px;
}

.compareQuotation-table .row {
    padding: 17.01px 20px !important;
}

.compareQuotation-numQuotation {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.compareQuotation-logo {
    max-width: 13.313rem;
    max-height: 3.656rem;
}   

.compareQuotation-more .row .row__values .row__values-item .tagButton-main .smalltext-header {
    font-size: 0.95rem;
    font-family: 'Faktum';
    font-weight: 400;
}

.compareQuotation-more .row .row__values .row__values-item .tagButton-main {
    padding: 5.67px;
}

.compareQuotation-rating {
    display: flex;
    flex-direction: row;
    gap: 10px; 
    color: var(--gray-1);
    align-items: center;
}

.compareQuotation-rating .rating-slider {
    height: 11.3px;
    width: 122px;
    box-shadow: 0px 9.073524475097656px 22.68381118774414px 0px rgba(0, 0, 0, 0.15);
}

.quotationCompare-row {
    display: flex;
    align-items: center;
    color: var(--gray-1);
    gap: 5px;
}

.quotationCompare-row  div {
    display: flex;
}

.compareQuotation-content-button-msg {
    display: flex;
    gap: 20px;
    /* overflow: hidden; */
    border-radius: 13px;
}

.compareQuotation-content-button-msg .row {
    width: 100%;
}

.compareQuotation-main .operation-header {
    z-index: 70;
}

.compareQuotation-container .row .row__values .row__values-item {
    justify-content: center;
    display: flex;
    align-self: center;
}

.compareQuotation-rating-icon {
    display: none;
}

.compareQuotation-container .table-container {
    overflow: hidden;
}

.compareQuotation-message .buttonBadget div {
    display: flex;
}

.compareQuotation-message .buttonBadget div img {
    height: 15px;
}

.compareQuotation-more {
    display: flex;
    flex-direction: column;
    margin-bottom: -15px;

}

.compareQuotation-main .modal-modalAnimation .modal-container {
    max-width: 48rem;
}

.compareQuotation-shimmer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 20px;
    gap: 20px
}


/* @media (max-width: 1024px) {
    .compareQuotation-container {
        padding: 30px 20px;
    }
} */

@media (max-width: 1140px) {
    .compareQuotation-rating .rating-slider{
        width: 150px;
    }
}

@media (max-width: 920px) {
    /* .compareQuotation-container {
        padding: 30px 20px;
    } */
    .compareQuotation-rating .rating-slider{
        width: 100px;
    }
    /* .compareQuotation-rating {
        flex-direction: column;
    }   */
}

@media (max-width: 768px) {
    .compareQuotation-main {
        gap: 20px
    }
    .compareQuotation-content {
        gap: 20px
    }
    .compareQuotation-container {
        margin-bottom: 5rem;
        gap: 15px
    }
    .compareQuotation-rating {
        flex-direction: row-reverse;
        background: var(--yellow);
        padding: 5px 10px;
        border-radius: 24px;
    }
    .compareQuotation-rating div {
        display: flex;
        align-items: center;
    }

    .compareQuotation-rating .rating-slider {
        display: none;
        
    }
}

@media (max-width: 690px) {
    .compareQuotation-rating {
        gap: 5px;
    }
    .compareQuotation-rating .rating-slider{
        width: 80px;
    }
    .compareQuotation-rating .rating-slider div {
        width: 1.5px;
    }
}
@media (max-width: 630px) {
    .compareQuotation-table .row .row__title .quotationCompare-row {
        font-size: 13px;
    }
    .compareQuotation-rating div {
        font-size: 13px;
    }
    .compareQuotation-table .row {
        padding: 10px !important;
    }
    .compareQuotation-more .row .row__values .row__values-item .tagButton-main .smalltext-header {
        font-size: 12px;
    }
    .compareQuotation-more .row .row__values .row__values-item .tagButton-main {
        max-width: 64px;
        border-radius: 13.9px;
    }
}
@media (max-width: 626px) {
    .compareQuotation-content-button-msg .messages-btn {
        font-size: 0.9rem;
    }
}
@media (max-width: 600px) {
    .compareQuotation-container {
        padding: 0px 10px;
    }
}
@media (max-width: 580px) {
    .compareQuotation-table .row .row__title .quotationCompare-row {
        font-size: 12px;
        gap: 1px
    }
    .compareQuotation-rating div {
        font-size: 11px;
    }

    .compareQuotation-rating img {
        height: 13px;
    } 
}
@media (max-width: 565px) {
    .compareQuotation-rating .rating-slider{
       display: none;
    }
    .compareQuotation-rating .rating-slider div {
        width: 1px;
    }
    .compareQuotation-rating-icon {
        display: flex;
    }
    .compareQuotation-container .compareQuotation-table .row .row__title .quotationCompare-row div {
        display: none;
    }
    .compareQuotation-container .row .row__values .row__values-item .btn-normal {
        min-width: 50px;
        padding: 0px 15px;
        font-size: 0.8rem;
        height: 33px;
        border-radius: 9px;
    }
}
@media (max-width: 512px) {
    .compareQuotation-numQuotation .btn-normal {
        padding: 10px 18px;
        font-size: 0.8rem;
    }
}
@media (max-width: 487px) {
    .compareQuotation-content-button-msg {
        flex-direction: column;
        gap: 10px
    }
    .compareQuotation-content-button-msg .messages-btn {
        padding: 9px 5px;
    }.compareQuotation-content-button-msg {
        gap: 10px
    }
    .compareQuotation-content-button-msg .messages-btn {
        padding: 9px 3px;
    }
    /* .compareQuotation-table .row {
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        align-self: flex-start;
        align-items: flex-start;
        gap: 10px
    } */
    /* .compareQuotation-table .row .row__title,
    .compareQuotation-table .row .row__title .quotationCompare-row {
        width: 100%;
        max-width: 100%;
    } */
    .compareQuotation-table .row .row__values  {
        gap: 10px
    }
}
@media (max-width: 438px) { 
    .compareQuotation-container .row .row__values .row__values-item .btn-normal {
        min-width: 3px;
        padding: 0px 9px;
        font-size: 0.7rem;
        height: 30px;
        border-radius: 7px;
    }
}
@media (max-width: 429px) {
    .compareQuotation-numQuotation {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        width: 100%;
        justify-content: flex-start;
    }
}
@media (max-width: 408px) {
    /* .compareQuotation-table .row .row__values  {
        flex-direction: column;
    } */
}
@media (max-width: 373px) { 
    .compareQuotation-container .row .row__values .row__values-item .btn-normal {
        min-width: 3px;
        padding: 0px 6px;
        font-size: 0.7rem;
        height: 26px;
        border-radius: 6.5px;
    }
}