.option-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.chat-page-header-option{
    position: absolute;
    top: 4.4rem;
    z-index: 10;
    background: var(--white);
    border-radius: 24px;
    width: 177px; 
    right: 0;          
    overflow: hidden;                               
} 

.chat-list-option {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    cursor: pointer;
}
.chat-list-option:hover {
    background: var(--black-05);
}

@media (max-width: 768px) {
    .chat-page-header-option {
        top: 4rem;
        right: 23px;
    }
}