.saveDraft-container {
    display: flex;
    flex-direction: column;  
    width: 100%;
    backdrop-filter: blur(40px);
    border-radius: 12px;
    background: var(--background);
    border: 1px solid;
    border-image-source: var(--border-image-source);
    overflow: hidden;
}
.saveDraft-header {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px 12px 0px 0px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.saveDraft-header__border {
    border-radius: 12px;
}
.saveDraft-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.saveDraft-title {
    display: flex;
    flex-direction: column;
}
.saveDraft-text-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.saveDraft__title {
    max-width: 300px;
    width: 100%;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis; 
}
.saveDraft-date {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
}
.saveDraft-table  {
    width: 100%;
 }
.saveDraft-body {
    color: var(--blue-2);
    padding: 10px 20px;
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: space-between; 
}
.saveDraft-date-icon-mobile {
    display: none;
}

@media (max-width: 1029px){ 
    .saveDraft__title { 
        max-width: 200px;
        width: 100%;
    }
}
@media (max-width: 920px){ 
    .saveDraft-body,
    .saveDraft__title { 
        font-size: 0.8rem;
    }
    .saveDraft-date {
        gap: 10px
    }
}
@media (max-width: 900px){ 
}
@media (max-width: 890px){ 
    .saveDraft__title { 
        max-width: 300px;
        width: 100%;
    }
}
@media (max-width: 688px){ 
    .saveDraft__title { 
        max-width: 250px;
        width: 100%;
    }
}
@media (max-width: 654px){ 
    .saveDraft__title { 
        max-width: 200px;
        width: 100%;
    }
}
@media (max-width: 594px){ 
    .saveDraft__title { 
        max-width: 180px;
        width: 100%;
    }
    .dashboardElements-header {
        padding: 10px;
    }
}
@media (max-width: 516px){ 
    .saveDraft__title { 
        max-width: 160px;
        width: 100%;
    }
}
@media (max-width: 500px){
    .saveDraft-text {
        gap: 10px;
    }
    .saveDraft__title, .saveDraft__detail { 
        font-size: 0.8rem;
    }
    .saveDraft-body {
        align-items: center;
        font-size: 0.8rem;
    }
}
@media (max-width: 496px){ 
    .saveDraft__title { 
        max-width: 150px;
        width: 100%;
    }
    .saveDraft-header, .saveDraft-body {
        padding: 10px 12px;
    }
}
@media (max-width: 359px){ 
    .saveDraft-header {
        flex-direction: column;
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 10px
    }
    .saveDraft-date-icon {
        display: none;
    }
    .saveDraft-date {
        padding-left: 2rem;
    }
    .saveDraft-date-icon-mobile {
        display: flex; 
    }
    .saveDraft-text {
        justify-content: space-between;
        width: 100%;
    }
}