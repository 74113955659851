.planPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 140px 120px 120px 120px;
    background: linear-gradient(93deg, rgba(19, 19, 19, 0.05) 32.19%, rgba(255, 193, 87, 0.30) 96.43%);
}

.planPage-content {
    width: 100%;
    max-width: 1125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.planPage-title {
    color: var(--white);
    font-size: 2.813rem;
    font-family: 'Faktum-Bold';
    text-shadow: -6px 9px 20px rgba(255, 197, 37, 0.36), 6px -9px 20px rgba(255, 197, 37, 0.36);
}

.planPage-title span {
    font-size: 2.813rem;
    font-family: 'Faktum-Bold';
    background: -webkit-linear-gradient(#F7E246, #FFC525);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.planPage-title-section {
    max-width: 721px;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;

    border-radius: 24px;
    border: 1px solid var(--bn-white, #FFF);
    backdrop-filter: blur(20px);
}

.planPage-title-img {
    width: 70px;
}

.planPage-title-section__text {
    color: var(--white);
}

.planPage-t {
    display: none;
}


@media (max-width: 1360px) {
    .planPage {
        padding: 140px 60px 120px 60px
    }
}

@media (max-width: 1245px) {
    .planPage {
        padding: 140px 0px 50px 00px;
    }
}

@media (max-width: 1045px) {
    .planPage {
        padding: 100px 0px 50px 0px;
    }
    .planPage-title-section {
        flex-direction: column;
    }
    .planPage-title-img {
        display: none;
    }
}

@media (max-width: 968px) {

    .planPage-title-section {
        margin: 0px 10px;
       
    }

    .planPage-title-section__text {
        font-size: 1.2rem;
    }
}

@media (max-width: 740px) {
    .planPage-title-section {
        margin: 0px 10px;
        max-width: fit-content;
    }
    .planPage-title-section__text {
        font-size: 1.1rem;
    }
}

@media (max-width: 698px) {
    .planPage-title {
        padding: 0px 20px; 
        text-align: center;
    }

    .planPage-title-section {
      padding: 10px 20px;
    }

    .planPage-title-section__text {
        font-size: .9rem;
        text-align: center;
    }
    .planPage-title-img {
        width: 50px;
    }
    .planPage-w {
        display: none;
    }
    .planPage-t {
        display: flex;
        flex-direction: column;
        gap: 10px
    }
}

@media (max-width: 600px) {
    .planPage-content {
        gap: 30px
    }
    .planPage-title,
    .planPage-title span {
        font-size: 2rem;
        text-align: center;
    }
    .planPage-t {
        gap: 5px
    }
}

@media (max-width: 370px) {
    .planPage-title-section__text {
        font-size: .8rem;
    }
    .planPage-title-img {
        width: 40px;
    }
}