.headerCustomsPhases-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 65%;
}

.headerCustomsPhases-title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    position: relative;
    width: 100%;
}

.headerCustomsPhases-title--number {
    color: var(--white);
    background: var(--black-15);
    border-radius: 24px;
    position: absolute;
    height: 34px;
    width: 34px;
}

.headerCustomsPhases-title--number-content {
    color: var(--white);
    background: var(--black-75);
    height: 26px;
    width: 26px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0.25rem;
    top: 0.253rem;
}

.headerCustomsPhases-title--text {
    color: var(--gray-1);
    left: 2.5rem;
    display: flex;
    position: absolute;
}

.headerCustomsPhases-container .btn-normal {
    border-radius: 100px;
    box-shadow: none;
}

.headerCustomsPhases-container .custom-button {
    padding: 10.5px 13px;
}

@media (max-width: 400px) { 
    .headerCustomsPhases-title--text { 
        font-size: 0.9rem;
    }
    .headerCustomsPhases-title--number-content {
        font-size: 0.8rem;
    }
}