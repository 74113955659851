.modal-sendMessage {
    width: 100%
}

.modal-sendMessage .modal-container {
    max-width: 38.75rem;
    min-width: 10rem;
    width: 100%;
}

/*Modificaciones del header con el cuerpo del modal*/
/* .modal-sendMessage .modal-container {
    gap: 20px;
} */

/*Modificaciones del header en el modal*/
/* .modal-sendMessage .modal-container .modal__header {
    border-bottom: 1.5px solid var(--gray-4);
    padding-bottom: 16px;
} */

.modal-send-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
}

.modal-send-content_column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.modal-send-content_column_ {
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.modal-send-content__row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.modal-send-content__row__ {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    height: 25px;
}

.modal-send-content__row___ {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    height: 25px;
    cursor: pointer;
}

.modal-send-content a {
    text-decoration: none;
}

.modal-sendMessage .modal-container .modal__header .modal__heade-left .modal-logo {
    display: none;
}

.modal-send-btn-msg {
    background: var(--blue-2);
    backdrop-filter: blur(20px);
    box-shadow: 0px 8px 20px rgb(0 0 0 / 15%);
    border-radius: 8px;
    border-width: 0px;
    padding: 6px 30px;
}

.modal-send-btn-phone {
    background: var(--yellow);
    backdrop-filter: blur(20px);
    box-shadow: 0px 8px 20px rgb(0 0 0 / 15%);
    border-radius: 8px;
    border-width: 0px;
    padding: 6px 30px;
}

.modal-send-btn-whtp {
    background: var(--green-2);
    backdrop-filter: blur(20px);
    box-shadow: 0px 8px 20px rgb(0 0 0 / 15%);
    border-radius: 8px;
    border-width: 0px;
    padding: 6px 30px;
}

.modal-send-btn-msg a,
.modal-send-btn-phone a,
.modal-send-btn-whtp a {
    color: var(--white);
}

.icon-whatsapp img {
    height: 17px;
    display: flex;
}

.icon-message img {
    height: 13px;
    display: flex;
}

.icon-phone img {
    height: 16px;
    display: flex;
}

.btn-phone-color {
    color: var(--gray-1);
}

.modal-send-content-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media (max-width: 600px) {
    .modal-container:has(.modal-send-content) {
        width: 100%;
    }
    .modal-contact-info {
        font-size: 0.875rem;
        font-family: 'Faktum';
        font-style: normal;
        font-weight: 400;
    }
    .modal-sendMessage .modal-container {
        background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%);
        box-shadow: 0px 1.5px 6px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(45px);
        padding: 30px 20px;
    }
}


@media (max-width: 500px) {
    .modal-send-content_column_ .custom-button-icon {
        font-size: 0.75rem;
    }
}

@media (max-width: 400px) {
    
    .modal-send-content_column_ {
        gap: 18px
    }
    .modal-send-content_column {
        gap: 20px
    }
}

@media (max-width: 348px) { 
    /* .modal-send-content__row___ {
        flex-direction: column;
    } */

    /* .modal-send-content__row__ {
        gap: 18px;
    }

    .modal-send-content__row__, .modal-send-content__row___ { 
        height: 85px;
        width: 100%;
        align-items: self-start;
    } */
}