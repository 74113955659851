.trackingShipping-container {
    max-width: 840px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 1rem;
}

.trackingShipping__row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.trackingShipping-container .card-content {
    width: 100%;
}

.rackingShipping-title,
.trackingShipping-description {
    color: var(--gray-1)
}

.trackingShipping__column {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* TextField */
.trackingShipping__row .textfield .textfield-title {
    top: 50%;
}

.trackingShipping__row .textfield input:not(:placeholder-shown).textfield-input:not(:focus) {
    padding: 20.5px 20px 7.5px 19.5px;
}
.trackingShipping__row .textfield input:focus {
    padding: 20.5px 20px 7.5px 19.5px;
}

.trackingShipping-static {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    gap: 15px;
}

.trackingShipping-card {
    gap: 20px;
    display: flex;
    width: 266.667px;
    padding: 10px;
    flex-direction: row;
    align-items: center;
    padding: 10px 30px;
    justify-content: center;
    backdrop-filter: blur(20px);
    border-radius: 24px;
    background: var(--black-05);
    /* background: var(--background); */
    border-image-source: var(--border-image-source);
}

.trackingShipping-card-test {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 18px 7px;
    backdrop-filter: blur(40px);
    border-radius: 12px;
    background: var(--background);
    border-image-source: var(--border-image-source);
    width: 100%;
}

.trackingShipping-color {
    color: var(--gray-1);
}

.trackingShipping-icon img{
    display: flex;
}

.trackingShipping-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

.trackingShipping-custom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
    margin-bottom: 20px;
    margin-right: 40px;
}

.gmnoprint {
    display: none !important;
}

.trackingShipping-static-mobile {
    width: fit-content;
    padding: 14px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
}

.trackingShipping-static-mobile-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    padding: 0px 15px;
}

.trackingShipping-static-mobile-row--line {
    border-right: 1px solid #C4C4C4;;
}

.trackingShipping-static-mobile-text {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.trackingShipping-static-mobile {
    display: none;
}

.trackingShipping-icon-mobile img {
    width: 15px;
    height: 12px;

}

.trackingShipping-searchVessels {
    width: 100%;
    position: relative;
    max-width: 410px
}

.marinetraffic {
    border-radius: 24px;
    border-width: 0px;
    max-height: calc(100vh - 340px);
}

.marinetraffic .embed-footer {
    display: none;
}

.trackingShipping-notDataMsg {
    color: var(--red);
    margin-top: -25px;
}

.trackingShipping-notDataMsg-mb {
    display: none;
    color: var(--red);
    margin-top: -25px;
    width: 100%;
    justify-content: flex-start;
}

.trackingShipping__row  .textfield-input  {
	background: var(--white);
}

/* Desktop */
@media (max-width: 1320px) {
    .trackingShipping-static {
        gap: 10px;
    }

    .trackingShipping-card {
        padding: 10px 20px;
        gap: 10px;
    }

    .trackingShipping-card-test {
        padding: 10px 20px;
        gap: 10px;
    }
}

@media (max-width: 1245px) {
    .trackingShipping-notDataMsg {
        display: none;
    }

    .trackingShipping-notDataMsg-mb {
        display: flex;
    }

    /* .trackingShipping-static-mobile {
        display: flex;
        align-self: center;
    } */
    /* .trackingShipping-static {
        display: none;
    } */
}

@media (max-width: 1217px) {
    .trackingShipping-container {
        justify-content: center;
        align-items: center;
    }
    .trackingShipping-static-mobile {
        display: flex;
    }
    .trackingShipping-static {
        display: none;
    }
}

@media (max-width: 1125px) {
    /* TextField */
    .trackingShipping__row .textfield .textfield-title {
        font-size: 0.8rem;
    }
}

@media (max-width: 980px) {
    /* TextField */
    .trackingShipping__row .textfield .textfield-title {
        left: 12px;
    }
    .trackingShipping__row .textfield input:not(:placeholder-shown).textfield-input:not(:focus) {
        padding: 20.5px 20px 7.5px 10.5px;
    }
    .trackingShipping__row .textfield input:focus {
        padding: 20.5px 20px 7.5px 11.5px;
    }
    .trackingShipping__row {
        gap: 10px
    }
}

@media (max-width: 948px) { 
    /* .trackingShipping__row {
        gap: 10px
    } */
    /* .trackingShipping__row {
        flex-direction: column;
    } */
}

@media (max-width: 880px) { 
    .trackingShipping__row {
        flex-direction: row;
        gap: 10px
    }
    .trackingShipping-text {
        gap: 0px
    }
    .trackingShipping-card {
        width: 210px;
    }
    .trackingShipping-container {
        max-width: 100%;
    }
    .trackingShipping-static-mobile {
        display: none;
    }
    .trackingShipping-static {
        display: flex;
    }
    .trackingShipping-color {
        font-size:0.9rem;
    }
}

@media (max-width: 771px) {
    .trackingShipping-card {
        width: 190px;
    }
    .trackingShipping-color {
        font-size:0.75rem;
    }
}

@media (max-width: 733px) {
    .trackingShipping-card {
        width: 170px;
        padding: 10px 0px;
    }
}


@media (max-width: 720px) {
    /* .trackingShipping-static {
        flex-direction: column;
    } */
    
    .trackingShipping-custom {
        margin-bottom: 10px;
        margin-right: 20px;
    }
}

@media (max-width: 700px) {
    .trackingShipping-card,
    .marinetraffic {
        border-radius: 12px;
    }
    
    .trackingShipping-container {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media (max-width: 600px) { 
    .trackingShipping-color {
        font-size: 0.75rem;
    }
    .trackingShipping-text {
        gap: 0px
    }
}

@media (max-width: 566px) { 
    .trackingShipping__row .trackingShipping-searchVessels {
        max-width: 100%;
    }
}

@media (max-width: 530px) {
    .trackingShipping-static-mobile {
        padding: 10px;
    }
    .trackingShipping__row {
        flex-direction: column;
    }
}

@media (max-width: 473px) { 
    .trackingShipping-card {
        width: 175px;
    }
}

@media (max-width: 470px) {
    .trackingShipping-static {
        flex-wrap: nowrap;
        /* flex-direction: column; */
    }
   
    .trackingShipping-static-mobile-row .trackingShipping-static-mobile-text div {
        font-size: 12px;
    }
}


@media (max-width: 450px) {
    .trackingShipping-container {
        width: 95%;
    }

    .trackingShipping-static-mobile {
        width: 100%;
    }

    .trackingShipping-static {
        /* flex-direction: column; */
        gap: 10px;
    }

    .trackingShipping-icon {
        margin-right: 10px;    
    }

    .Maps__box {
        width: 100%;
        height: 440px;
    }

    .trackingShipping-static-mobile-row {
        width: 100%;
        gap: 5px;
        padding: 0px 5px;
    }

    .trackingShipping-static-mobile-row .trackingShipping-static-mobile-text div {
        font-size: 10px;
    }
}

@media (max-width: 400px) { 
    .trackingShipping-card {
        padding: 10px;
        width: 155px;
    }
    .trackingShipping-static-mobile-row {
        gap: 3px;
        padding: 0px 3px;
    }
}
