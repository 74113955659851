.sharedContainerDetailPage {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center; 
}

.sharedContainerDetailPage-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    padding: 20px 40px;
    width: 100%;
    max-width: 1500px;
}

.sharedContainerDetailPage-left {
    width: 100%;
    max-width: 810px;
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.sharedContainerDetailPage-left__img {
    width: 100%;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sharedContainerDetailPage-left__detail {
    width: 100%;
    max-width: 319px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sharedContainerDetailPage-right {
    width: 100%;
    max-width: 350px;
    height: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.sharedContainerDetailPage-scroll::-webkit-scrollbar {
    overflow: scroll;
}