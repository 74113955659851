.fclcd {
	max-width: 760px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 30px;

	border-radius: 24px;
	background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
	box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(20px);
}

.fclcd-container {
	display: flex;
	align-items: center;
	gap: 20px;
}

.fclcd-container .checkbox-container input[type="checkbox"] {
	width: 39px;
    height: 39px;
}

.fclcd-container .checkbox-container label::before {
	width: 39px;
    height: 39px;
}

.fclcd-title {
	display: flex;
	flex-direction: column;
	/* gap: 5px; */
}

.fclcd h2,
.fclcd p {
	margin: 0;
	color: var(--gray-1);
}

.fclcd-addContainer {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.fclcd-container-data {
	display: flex;
	/* align-items: center; */
	gap: 20px;
	width: 100%;
}

.fclcd-container-info-mobile {
	display: none;
}

.fclcd-container-mobile {
	display: none;
}

.fclcd-container .checkbox-container {
	background: var(--black-05);
	border: 0px solid var(--gray-3)
}

.fclcd-container-data_row {
	display: flex;
	flex-direction: row;
	gap: 20px;
	width: 70%;
}

.fclcd-container-data_row_ {
flex-direction: row;
    display: flex;
    gap: 20px;
    width: 150%;
}

.fclcd-container-data .custom-button {
	min-width: auto;
    padding: 10px 20px;
	align-self: center;
}


@media (max-width: 830px) {
	.fclcd-addContainer--select {
		flex-direction: row;
		gap: 5px;
	}
	.fclcd-addContainer--select span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.fclcd-container-data_row_ .dropdown .dropdown-select .paragraph-header {
		font-size: 0.9rem;
	}
}

@media (max-width: 730px) {
	/* .fclcd-container-data {
		flex-wrap: wrap;
	} */

	/* .fclcd-container-data .custom-button {
		width: 100%;
	} */
	.fclcd-container-data,
	.fclcd-container-data_row_  {
		gap: 10px
	}
}

@media (max-width: 700px) { 
	.fclcd {
		border-radius: 12px;
	}
}

@media (max-width: 690px) {
	.fclcd-container-data_row_ .dropdown .dropdown-select .paragraph-header {
		font-size: 0.88rem;
	}
}

@media (max-width: 684px) { 
	.fclcd-container-data {
		flex-direction: column;
	}
	.fclcd-container-data_row_ {
		width: 100%;
	}
	.fclcd-container-data_row {
		width: 100%;
	}
	.fclcd-container-data .btn-normal {
		display: none;
	}
	.fclcd-container-data_row_ .dropdown .dropdown-select .paragraph-header {
		font-size: 1rem;
	}
}

@media (max-width: 600px) {
	.fclcd-title h2 {
		font-size: 0.875rem;
	}
	.fclcd-title p {
		font-size: 0.75rem;
	}
	.fclcd .fclcd-addContainer .fclcd-container-data .fclcd-container-data_row 
	.dropdown .fclcd-addContainer--select span {
		font-size: 0.875rem;
	}
	.fclcd .fclcd-addContainer .fclcd-container-data .fclcd-container-data_row_ 
	.dropdown .dropdown-select span {
		font-size: 0.875rem;
	}
	.fclcd {
		padding: 10px;
	}
}

@media (max-width: 400px) {
	/* .fclcd-container {
		display: none;
	} */
	.fclcd-container-mobile {
		display: flex;
	}
	/* .fclcd-container-mobile {
		display: flex;
		flex-direction: column;
		gap: 10px
	} */
	.fclcd-title-mobile {
		display: flex;
		flex-direction: row;
		gap: 15px;
		align-items: center;
	}
	.fclcd-container-mobile  .fclcd-title-mobile h2 {
		align-self: center;
		text-align: start;
	}
	.fclcd-title-mobile .checkbox-container {
		width: 25px;
		height: 25px;
	}
}

@media (max-width: 375px) {
	.fclcd {
		max-width: 295px;
	}
	
	.fclcd-container-data {
		flex-wrap: wrap;
	}

	.fclcd-container-data .custom-button {
		width: 100%;
	}
}