.hoursGridCalendar-container {
    position: relative;
    max-width: 53px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}

.hoursGridCalendar-container--row {
    height: 136px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
}

.hoursGridCalendar-hour--minute {
    position: relative;
    height: 2.34px;
    width: 100%;
    display: flex; 
}

.hoursGridCalendar-hour--text {
    position: absolute;
    top: -5px;
    color: var(--black-75);
}

@media (max-width: 630px) {
    .hoursGridCalendar-hour--text {
        font-size: 0.75rem;
    }
}

@media (max-width: 570px) { 
    .hoursGridCalendar-container {
        width: 100%;
    }

    .hoursGridCalendar-container--row {
        padding: 0px 5px;
    }
    .hoursGridCalendar-hour--text {
        top: -11px;
        font-size: 0.7rem;
    }

    .hoursGridCalendar-hour--minute {
        width: fit-content;
    }

}

@media (max-width: 480px) { 
    .hoursGridCalendar-hour--text {
        font-size: 0.6rem;
    }
 }

 @media (max-width: 430px) { 
    .hoursGridCalendar-container {
        max-width: 35px;
    }

    .hoursGridCalendar-container--row {
        width: fit-content;
        padding: 0px 3px;
    } 

 }