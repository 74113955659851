.chat-page {
    width: 100%;
    height: calc(100vh - 50px);
    /* margin-top: 30px; */
    flex-direction: row;
    justify-content: center;

    width: 100%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    gap: 10px;
}

.chat-page-left {
    max-width: 320px;
    /* background: red; */
    display: flex;
    -webkit-flex: 1;
    flex: 1;
    padding: 15px 0px 10px 10px
}

.chat-page-right {
    max-width: 1090px;
    /* background: blue; */
    display: flex;
    -webkit-flex: 1;
    flex: 1;
    padding: 15px 3px 0px 0px;
    gap: 3px;
}

.chat-detail {
    /* background: green; */
    height: 100%;
    width: 100%;
    max-width: 800px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 10px; 
}

.chat-detail .chat-header {
    margin: 0rem 0.5rem;
}

.chat-detail-right-1155 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.chat-detail-1155 {
    display: none;
}

.chat-detail-right {
    display: flex;
    height: 100%;
    max-width: 280px;
    width: 100%;
    flex-direction: column;
    gap: 20px;
}

.chat-right-search {
    margin: 0rem 0.5rem;
}

.chat-page-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: scroll;
    padding: 0px 0.5rem 0px 0.5rem;
}

.chat-content-subTitle {
    margin: 0;
    padding-bottom: 10px;
}

.chat-page-content-orders,
.chat-page-content-orders-first {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.chat-right-search-responsive {
    height: 60px;
    padding: 10px 19px 10px 0px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    width: 100%;
}

.chat-right-search {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.chat-right-search-responsive .searchTextField-container,
.chat-right-search .searchTextField-container {
    width: 100%;
    max-width: 100%;
}

.chat-right-up-down {
    display: flex;
    flex-direction: row;
    gap: 20px; 
    align-items: center;
}

.chat-right-search_active-icon-search {
    display: none;
}

.chat-page-options::-webkit-scrollbar {
    display: none;
}

.chat-right-archive div .modal-notification--main,
.chat-right-archive div .modal-notification--main .modal-notification-container {
    /* background-color: transparent; */
    border-radius: 24px;
    backdrop-filter: blur(0px);
}

.chat-right-archive .modal-notification--main .modal-notification-container 
.modal-notificaciones-header .subheader {
    font-size: 0.875rem;
}

.chat-right-archive div .modal-notification--main .modal-notification-container 
.modal-notification__header img {
    display: none;
}

.chat-modal-archive-title {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.chat-right-archive div .modal-notification--main .modal-notification-container 
.modal-notification__header .modal-notification__heade-left .modal-notification-header__text
.small-bold .chat-modal-archive-title div img {
    display: flex;
}

.chat-right-archive div .modal-notification--main .modal-notification-container 
.modal-notificationes-content .modal-list-imagenes .modal-imagenes {
    width: 100px;
    height: 125px;
    object-fit: cover;
}

.chat-modal-archive-list-imgs {
    max-width: 1200px;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
}
.chat-modal-archive-list-imgs>*{
    flex: 0 1 3rem;
}

.chat-modal-archive-imagen img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
}

.chat-modal-archive-list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
}

.chat-modal-archive-list>*{
    flex: 1 1 78px;
}

.chat-modal-archive-documents {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    background: var(--black-05);
    padding: 10px;
    border-radius: 12px;
}

.chat-modal-archive-documents-img {
    width: 26.4px;
    height: 33px;
    object-fit: contain;
}

.chat-right-archive div .modal-notification--main .modal-notification-container 
.modal-notificationes-content {
    flex-direction: initial;
}

.chat-page-content-orders {
    background: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 12px;
    padding: 15px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

.chat-page-content-orders h2 {
    margin: 0;
}

.chat-page .chat-page-right .chat-detail-right .chat-page-options .chat-page-content-orders-first 
.chat-page-content-orders .phases-card-container div .phases-card-row .phases-card-line {
    box-shadow: 0px 3px 9px 0px rgb(0 0 0 / 54%);
}

@media (max-width: 1184px) {
    .chat-detail .chat-header .chat-header-content .chat-header-left 
    .chat-header-title-content .chat-header-title-order {
        min-width: 60px;
    }
    .chat-detail .chat-header .chat-header-content .chat-header-right 
    .chat-header-right-content .chat-header-text-right {
        min-width: 50px;
        max-width: 160px;
    }
    .chat-page-left .chat-left {
        min-width: 250px;
    }
}

@media (max-width: 1165px) { 
    .chat-page-right {
        gap: 2px
    }
    .chat-page-container {
        gap: 10px;
        padding: 10px 30px 5px 30px;
    }
}

@media (max-width: 1155px) {
    .chat-detail {
        max-width: 100%;
    }
    .chat-page {
        gap: 3px
    }
    .chat-detail-right-1155 {
        display: none;
    }
    .chat-right-search_active-icon-search {
        display: flex;
    }
    .chat-right-archive {
        max-width: 100%;
    }
    .chat-right-archive div .modal-notification--main,
    .chat-right-archive div .modal-notification--main .modal-notification-container {
        max-width: 100%;
    }
    .chat-detail .chat-header .chat-header-content-personal .chat-header-left-personal 
    .chat-header-left-users .chat-header-left-users-name {
        max-width: 350px;
    }
    .chat-detail .chat-header .chat-header-content .chat-header-right 
    .chat-header-right-content .chat-header-text-right {
        max-width: 360px;
    }
    .chat-right-archive div .modal-notification--main .modal-notification-container 
    .modal-notificationes-content {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }  
    .chat-right-archive div .modal-notification--main .modal-notification-container 
    .modal-notificationes-content .chat-modal-archive-list .chat-modal-archive-documents
    .modal-documents-text {
        font-size: 0.875rem;
        max-width: 550px;
    }
}

@media (max-width: 1102px) {
    .chat-modal-archive-imagen {
        width: 8.74rem;
        height: 10rem;
    }
    .chat-modal-archive-imagen img {
        width: 8.74rem;
        height: 10rem;
    }
}

@media (max-width: 1130px) {
    .chat-page {
        margin-top: 0px;
    }
    .chat-page-container {
        padding: 15px 20px 5px 20px
    }
    .chat-detail .chat-header .chat-header-content .chat-header-right 
    .chat-header-right-content .chat-header-text-right {
        max-width: 300px;
    }
}

@media (max-width: 1060px) {
    .chat-detail .chat-header .chat-header-content .chat-header-right 
    .chat-header-right-content .chat-header-text-right {
        max-width: 280px;
    }
}

@media (max-width: 1045px) {
    .chat-detail .chat-header .chat-header-content .chat-header-right 
    .chat-header-right-content .chat-header-text-right {
        max-width: 250px;
    }
}

@media (max-width: 1042px) {
    .chat-detail .chat-header .chat-header-content .chat-header-left 
    .chat-header-title-content .chat-header-title-order {
        min-width: 200px;
    }
}

@media (max-width: 958px) {
    .chat-detail .chat-header .chat-header-content .chat-header-right 
    .chat-header-right-content {
        width: 230px;
    }
}

@media (max-width: 916px) {
    .chat-detail .chat-header .chat-header-content .chat-header-left 
    .chat-header-title-content {
        max-width: 170px;
    }
    .chat-detail .chat-header .chat-header-content .chat-header-left 
    .chat-header-title-content .chat-header-title-order {
        min-width: 150px;
    }
}

@media (max-width: 862px) {
    .chat-detail .chat-header .chat-header-content-personal .chat-header-left-personal 
    .chat-header-left-users .chat-header-left-users-name {
        max-width: 298px;
    }
}

@media (max-width: 852px) {
    .chat-detail .chat-header .chat-header-content .chat-header-left {
        min-width: 185px;
        max-width: 100px;
    }
    .chat-detail .chat-header .chat-header-content .chat-header-right .chat-header-right-content 
    .chat-header-text-right {
        max-width: 175px;
    }
    .chat-detail .chat-header .chat-header-content .chat-header-left .chat-header-title-content 
    .chat-header-title-order {
        max-width: 100px;
        min-width: 19px;
    }
    .chat-detail .chat-header .chat-header-content .chat-header-right {
        padding: 4px 10px;
    }
    .chat-detail .chat-header .chat-header-content .chat-header-right .chat-header-right-content {
        width: 100%;
    }
    .chat-detail .chat-header .chat-header-content .chat-header-right .chat-header-right-content 
    .chat-header-right-dropdown .dropdown .dropdown-select .move-text {
        max-width: 320px;
        min-width: 19px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.875rem;
    }
}

@media (max-width: 834px) {
    .chat-detail .chat-header .chat-header-content .chat-header-right .chat-header-right-content 
    .chat-header-text-right {
        max-width: 140px;
    }
}

@media (max-width: 831px) {
    .chat-detail .chat-header .chat-header-content-personal .chat-header-left-personal 
    .chat-header-left-users .chat-header-left-users-name {
        max-width: 270px;
    }
}

@media (max-width: 801px) {
    .chat-detail .chat-header .chat-header-content-personal .chat-header-left-personal 
    .chat-header-left-users .chat-header-left-users-name {
        max-width: 250px;
    }
}

@media (max-width: 800px) {
    .chat-page-left {
        max-width: 300px;
    }
    .chat-left {
        max-width: 300px;
        min-width: 300px;
    }
}

@media (max-width: 782px) {
    .chat-detail .chat-header .chat-header-content-personal .chat-header-left-personal 
    .chat-header-left-users .chat-header-left-users-name {
        max-width: 230px;
    }
}

@media (max-width: 780px) {
    .chat-detail .chat-header .chat-header-content .chat-header-left {
        min-width: 166px;
    }
    .chat-detail .chat-header .chat-header-content .chat-header-left .chat-header-title-content {
        min-width: 100px;
        max-width: 111px;
    }
}

@media (max-width: 768px) {
    .chat-page-right {
        display: none;
    }
    .chat-page-left,
    .chat-left {
        width: 100%;
        max-width: 100%;
        padding: 0px;
    }
    .chat-page-container {
        padding: 0px;
    }
    .chat-page-left .chat-left {
        gap: 0px;
    }
}