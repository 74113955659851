.rowGroup-detail-container {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.rowGroup-taxes-color-white {
    color: var(--white);
}

.rowGroup-detail-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rowGroupDetail__ {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
}

.rowGroupDetail_text {
    width: 50%;
}

.rowGroup-detail-body .rowGroupDetail__:nth-child(even) {
    background-color: var(--white-25);
} 

/* .rowGroupDetail_transparent {
    background-color: transparent;
}

.rowGroupDetail_white {
    background-color: var(--white-15);
} */


.rowGroup-detail-body .textfield {
    max-width: 200px;
    min-width: 50px;
    width: 50%;
}

.rowGroup-detail-body .textfield input::placeholder {
    display: flex;
    justify-content: flex-end;
    text-align: end !important;
    right: 0px !important;
    font-size: 1rem;
}

.rowGroup-detail-body .textfield input:not(:placeholder-shown).textfield-input:not(:focus){
    padding: 10px 20px;
    height: 50px;
    text-align: end !important;
    right: 0px !important;
    font-family: 'Faktum-Medium';
    font-size: 1rem;
    height: 36px;
}

.rowGroupDetail__ .textfield input {
    padding: 10px 20px;
    height: 36px;
}   

.rowGroup-detail-body .textfield input:focus {
    padding: 0px 15px;
    text-align: end !important;
    right: 0px !important;
}

/* Responive */
@media (max-width: 1045px) { 
    .rowGroupDetail_text, .rowGroup-detail-body .textfield input::placeholder, 
    .rowGroup-detail-body .textfield input:focus,
    .rowGroup-detail-body .textfield input:not(:placeholder-shown).textfield-input:not(:focus) {
        font-size: 0.9rem;
    }
}

@media (max-width: 514px) {
    .rowGroupDetail_text {
        width: 60%;
    }
    .rowGroupDetail__ .textfield {
        max-width: 140px;
    }
}

@media (max-width: 425px) {
    .rowGroupDetail_text {
        font-size: 0.85rem;
        /* width: 30%; */
    }
    .rowGroup-detail-body .textfield input:not(:placeholder-shown).textfield-input:not(:focus) {
        padding: 7.5px 15px 7.5px 15px;
        font-size: 0.85rem;
    }
    .rowGroup-detail-body .textfield input:focus {
        font-size: 0.85rem;
    }
    .rowGroupDetail__ {
        padding: 0px 10px;
    }
    .rowGroup-detail-body .textfield input::-webkit-calendar-picker-indicator {
       color: var(--gray-3);
       font-size: 0.85rem;
    }
}