.searchTextField-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px 21px;
    max-width: 400px;
    border-radius: 24px;
    border-radius: 24px;
    background: var(--white);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15) !important;
    border: 0px solid rgba(0, 0, 0, 0.15);  
}

.searchTextField-input {
    background: transparent;
    border: transparent;
    width: 100%;
    color: #181818;
    border: 0px solid var(--black-15);
    font-size: 0.875rem;
    font-family: 'Faktum-Medium';
}

.searchTextField-input::placeholder { 
    color: #181818 ;
}

.searchTextField-input:focus {
    outline: none;
}

.searchTextField-min {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    backdrop-filter: blur(40px);
    border-radius: 48px;
    background: var(--background);
} 

.searchTextField-icon {
    cursor: pointer;
}

.searchTextField-icon img {
    width: 16px;
    height: 16px;
}


/* @media (max-width: 960px){ 
    .searchTextField-container {
        background: var(--background);
        
        border-image-source: var(--border-image-source);
    }
} */
@media (max-width: 375px){ 
    .searchTextField-container {
        padding: 5px 10px;
    }
}