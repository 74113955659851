.company-card-responsive__main {
    display: flex;
    gap: 20px;
    flex-direction: column;
	display: none;
	backdrop-filter: blur(40px);
	border-radius: 24px;
	padding: 15px 10px;
	width: 100%;
    background: var(--white-50);
}
.company-card-responsive_column {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.company-card-responsive {
    display: flex;
	flex-direction: row;
	gap: 15px;
	padding-bottom: 10px;
	align-items: center;
}
.company-card-responsive .company-card-info-responsive .company-card-responsive-social 
.city-country-display {
	max-width: 115px;
}
.company-card-responsive-title div img {
	width: 1.3rem;
	cursor: pointer;
}
.company-card-responsive-bg {
	object-fit: scale-down;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(50% - 130px/2);
    top: 50%;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: var(--white);
    aspect-ratio: 16 / 9;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
}

.company-card-responsive__main .verify-container {
	top: 7rem;
    left: 5rem;
}

.company-card-responsive__main .verify-container .verify-content {
	max-width: 335px;
}

.company-card-responsive__none {
	filter: none;
    backdrop-filter: none; 
}

.company-card-responsive-imgs {
	width: 74px;
    height: 74px;
    border-radius: 100px;
    background: white;
}

.company-card-responsive-imgs .lazyload-wrapper .useractive {
	background: var(--white);
	box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
	width: 74px;
    height: 74px;
}

.company-card-responsive-imgs .lazyload-wrapper .useractive img {
	width: 74px;
    height: 74px;
	object-fit: contain;
	border-radius: 50px;
}

.company-card-responsive-imgs .lazyload-wrapper .useractive-logged {
    top: 83%;
}

.company-card-info-responsive {
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 100%;
}

.company-card-info-responsive h2,
.company-card-responsive_column h3 {
	margin: 0;
}

.company-card-responsive-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px 30px;
    gap: 20px
}

.company-card-responsive-main {
	display: flex;
	flex-direction: column;
	align-items: center;
    gap: 5px;
    width: 100%;
}

.company-card-responsive-title {
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	width: 100%;
	gap: 10px;
}

.company-card-responsive-title h1, .company-card-responsive-row h2,
.company-card-responsive-info div h3, .company-card-responsive-work h4,
.company-card-responsive-work p{
    margin: 0;
}

.company-card-responsive-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.company-card-responsive-more {
    color: var(--blue-2);
    cursor: pointer;
    font-weight: 600;
}

.company-card-responsive-social {
	display: flex;
	gap: 10px;
	width: 100%;
	justify-content: flex-start;
}
.company-card-responsive-social .status {
    gap: 0px;
}
.company-card-responsive-social .status div img {
    display: none;
}

.company-card-responsive-work {
	display: flex;
	flex-direction: column;
	gap: 10px;
    /* padding-top: 15px; */
    padding-bottom: 10px;
}

.company-card-responsive-btns {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.company-card-responsive-btns .custom-button {
	min-width: 100%;
}

.company-card-btns button {
	width: 100%;
}

.company-card-responsive_min {
	display: none;
}

.company-card-responsive-social__{
	display: none;
}

@media (max-width: 1037px) {
	.company-card-responsive-title {
		gap: 5px;
	}
	.company-card-responsive-imgs .lazyload-wrapper .useractive img,
	.company-card-responsive-imgs .lazyload-wrapper .useractive,
	.company-card-responsive-imgs {
		height: 80px;
		width: 80px;
	}
	.company-card-responsive-title h1 {
		font-size: 0.9rem;
	}
	.company-card-responsive-title h2 {
		font-size: 0.8rem;
	}
	.company-card-responsive-social .rating .rating_pointer-icon img {
		height: 0.7rem;
	}
	.company-card-responsive-social .commentCount div img {
		width: 0.6rem;
	}
	.company-card-responsive-social .city-country-display .city-country--image img{
		width: 20px;
    	height: 11.5px;
	}
	.company-card-responsive-social .rating span,
	.company-card-responsive-social .commentCount span,
	.company-card-responsive-social .city-country-display span {
		font-size: 0.75rem;
	}
	.company-card-responsive-btns .custom-button {
		font-size: 0.8rem;
	}
}

@media (max-width: 850px) { 
	.company-card-responsive__main .verify-container {
		top: 7rem;
    	left: 1rem;
	}
} 

@media (max-width: 768px) { 
	.company-card-responsive-social { 
		width: 100%;
	}
	.company-card-responsive__main {
		display: flex;
		max-width: 400px;
		box-shadow: 0px 8px 20px var(--black-15);
	}
	.company-card-responsive-btns {
		display: flex;
	}
	.company-card-responsive-btns .btn-small{
		min-width: 40px;
		width: 100%;
	}
	.company-card-responsive-btns .btnIcon-small{
		min-width: 40px;
		width: 100%;
	}
	.company-card-info-responsive-row {
		display: flex;
		flex-direction: row;
		gap: 20px
	} 
	.company-card-responsive__main .verify-container {
		top: 16.5rem;
    	left: 14rem;
	}
}

@media (max-width: 600px) {
	.company-card-responsive__main .verify-container {
		top: 0rem;
		left: 0rem;
	}
	.company-card-responsive__main .verify-container .verify-content {
		max-width: 100%;
	}
	.company-card-responsive-btns .btnIcon-small {
		font-size: 0.875rem;
	}
	.company-card-responsive-title h1 {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 15rem
	}
}

@media (max-width: 520px) { 
	.company-card-responsive-imgs,
	.company-card-responsive-bg {
		width: 80px;
		height: 80px;
	}
}

@media (max-width: 461px) { 
	.company-card-responsive-title h1 {
		max-width: 15rem
	}
}
@media (max-width: 430px) { 
	.company-card-responsive-title h1 {
		max-width: 13rem
	}
}

@media (max-width: 428px) { 
	.company-card-responsive-info {
		padding: 0px 15px;
	}
}

@media (max-width: 406px) { 
	.company-card-info-responsive-row {
		display: flex;
		flex-direction: row;
		gap: 10px
	}
}

@media (max-width: 384px) { 
	.company-card-responsive-social {
		gap: 7px;
	}
}

@media (max-width: 381px) { 
	.company-card-responsive_min {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	/* .company-card-info-responsive .company-card-responsive-social {
		display: none;
	} */
	.company-card-responsive-social__{
		display: flex;
		flex-direction: row;
		gap: 5px;
	}
}

@media (max-width: 375px) {
	.company-card-responsive-row {
		gap: 5px;
	}
	.company-card-responsive-social {
		flex-wrap: wrap;
	}
}

@media (max-width: 361px) {
	.company-card-responsive-social {
		width: 89%;
	}
}

@media (max-width: 355px) { 
	.company-card-info-responsive-row {
		display: flex;
		flex-direction: column;
		gap: 5px
	}
}

@media (max-width: 346px) { 
	.company-card-responsive-btns .btnIcon-small,
	.company-card-responsive-btns .custom-button {
		font-size: 0.75rem;
	}
}

@media (max-width: 323px) { 
	.company-card-responsive-row {
		flex-direction: column;
	}
}