.dashboardElements-container {
    max-width: 880px;
    display: flex;
    flex-direction: column;  
    width: 100%;
    backdrop-filter: blur(40px);
    border-radius: 12px;
    background: var(--background);
    border: 1px solid;
    border-image-source: var(--border-image-source);
    overflow: hidden;
}
.dashboardElements-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 10px 20px;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    background: var(--white-90);
    border-radius: 12px 12px 0px 0px;
}
.dashboardElements-text {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.dashboardElements-right,
.dashboardElements-date {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}
.dashboardElements-all-quote .dashboardElementsRow-container:nth-child(2n) {
    background: var(--white-15);
}

.rating-container {
    display: flex;
    align-items: center;
}

.rating-container img {
    margin-right: 4px; /* Espacio entre la imagen y el texto */
    width: 12px;
}

.dashboardElements-quote {
    color: var(--blue-2);
    cursor: pointer;
}

@media (max-width: 920px){ 
    .dashboardElements__title { 
        font-size: 0.8rem;
    }
    .dashboardElements-right, 
    .dashboardElements-date,
    .dashboardElements-text {
        gap: 10px
    }
}

@media (max-width: 900px){ 
}

@media (max-width: 414px){ 
    .dashboardElements-container {
        min-width: 120px;
        max-width: calc(100vw);
        width: 100%;
    }
}