.buttonEditChange {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.buttonEditChange__content {
    border: none;
    border-radius: 12px;
    padding: 5px 15px;
}

.buttonEditChange__red {
    background: var(--red);
}

.buttonEditChange__text-white {
    color: var(--white);
}

.buttonEditChange .custom-button-icon {
    background-color: none;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
    backdrop-filter: blur(0px);
}

.buttonEditChange .custom-button-icon div { 
    display: flex;
}

.buttonEditChange .custom-button-icon .icon-button { 
    display: none;
}

.buttonEditChange .custom-button-icon div img {
    height: 12px;
}

@media (max-width: 500px) {
    .buttonEditChange .custom-button-icon {
        font-size: 0.75rem;
        width: 0%;
        height: 25px;
    } 
}

@media (max-width: 376px) { 
    /* .buttonEditChange {
        justify-content: center;
    } */
    .buttonEditChange  .btn-extra-small,  .buttonEditChange .btnIcon-extra-small {
        min-width: 7rem;
    }
}