.tableQuote {
    flex-direction: column;
    display: flex;
    gap: 30px;
    padding-bottom: 20px;
}

.tableQuote-container {
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
    border-radius: 12px;
    overflow: hidden;
}

.tableQuote-container .tableQuote-body div .textarea {
    background: transparent;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(0px);
}

.tableQuote-container__ {
    background: var(--white-90);
    height: 50px;
    padding: 15px 20px;
    border-radius: 12px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}

.tableQuote-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    color: var(--gray-1);
    padding: 10px 20px;
    background: var(--white-90);
    border-radius: 12px 12px 0px 0px;
}

.tableQuote-title__ {
    color: var(--gray-1);
}

.tableQuote-rating {
    flex-direction: row;
    display: flex;
    gap: 10px
}

.tableQuote-rating__ {
    flex-direction: row;
    display: flex;
    gap: 10px
}

.tableQuote-body {
    padding: 10px;
}

.tableQuote-rating-content {
    display: flex;
    flex-direction: row;
    position: absolute;
    gap: 10px
}

.tableQuote-container-restriction {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.tableQuote-container-restriction  .iteminfo-quotation {
    max-width: 100%;
}

.tableQuote-rating-icon {
    display: none;
}

.iteminfo-quotation-restrictions-contentCheck___unknown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.tableQuote-currecy {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    border-radius: 24px;
} 

.tableQuote-currecy .dropdown {
    width: 15%;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
}

@media (max-width: 968px) { 
    .tableQuote-title,
    .rowQuote_black .paragraph {
        font-size: 0.9rem;
    }
    .tableQuote .tableQuote-container .tableQuote-body 
    div span {
        font-size: 0.9rem;
    }
}
@media (max-width: 630px) { 
    .tableQuote-title {
        padding: 10px;
    }
}
@media (max-width: 480px) { 
    .tableQuote-title {
        font-size: 0.9rem;
    }
    .tableQuote-title__ {
        font-size: 0.9rem;
    }
}

@media (max-width: 470px) { 
    .tableQuote-rating .rating-slider {
        display: none;
    }
    .tableQuote-rating-icon {
        display: flex;
    }
    .tableQuote-rating-content .hover {
        display: none;
    }
}

/* @media (max-width: 365px) { 
    .tableQuote-container__  {
        flex-direction: column;
        height: 90px;
    }
} */