.taxSharedContainer-main {
    max-width: 52.5rem;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 24px;
    background: var(--background);
    border: 1px solid;
    border-image-source: var(--border-image-source);
}
.taxSharedContainer-main h1 {
    margin: 0;
    padding: 0;
}
.taxSharedContainer-main p {
    margin: 0;
    padding: 0;
    color: var(--gray-1);
}

.taxSharedContainer-change {
    height: 60px;
    padding: 5px 20px;
    background: var(--black-50);
    border-radius: 24px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.taxSharedContainer-change .textfield input {
    color: var(--white) !important
}

.taxSharedContainer-tableQuotation-container {
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
    border-radius: 24px;
}

.taxSharedContainer-tableQuotation-title {
    height: 50px;
    color: var(--gray-1);
    padding: 10px 20px;
    background: var(--white-90);
    border-radius: 24px 24px 0px 0px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    display: flex;
}

.taxSharedContainer-tableQuotation-container .rowGroupTaxesFooter__ {
    border-radius: 0px 0px 24px 24px;
}
.taxSharedContainer-tableQuotation-container .rowTaxesFooter__ {
    border-radius: 0px 0px 24px 24px;
}

.taxSharedContainer-row__ {
    display: flex;
    flex-direction: row;
    gap: 5px
}

.taxSharedContainer-row__ .toggle-switch {
    bottom: 4px;
}

.taxSharedContainer_text___ {
    align-items: center;
}

.taxSharedContainer-change {
    width: 100%;
}

.taxSharedContainer_white {
    color: var(--white);
    width: 70%;
}

.taxSharedContainer-change .textfield input:not(:placeholder-shown).textfield-input:not(:focus){
    padding: 7.5px 20px 7.5px 20px;
    text-align: end !important;
    right: 0px !important;
}

.taxSharedContainer-change .textfield input[type=number]::-webkit-inner-spin-button, 
.taxSharedContainer-change .textfield input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.taxSharedContainer-change .textfield input:disabled {
    padding: 7.5px 20px 7.5px 20px;
    text-align: end !important;
    right: 0px !important;
    backdrop-filter: blur(0px);
}

.taxSharedContainer-change .textfield input:focus {
    padding: 0px 15px;
    text-align: end !important;
    right: 0px !important;
}

.taxSharedContainer-change .textfield {
    max-width: 200px;
    min-width: 50px;
    width: 50%;
}

.taxSharedContainer-change .textfield input {
    font-size: 1rem;
}

.taxSharedContainer-change .textfield input::placeholder {
    display: flex;
    justify-content: flex-end;
    text-align: end !important;
    right: 0px !important;
}

.phaseSevenSupplier-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.phaseSevenSupplier-footer .custom-button-icon  {
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
    backdrop-filter: blur(0px);
}

.taxSharedContainer-main .uploadfile {
    backdrop-filter: blur(0px);
    background: none;
    border-image-source: none;
    border: 0px solid;
    padding: 0px;
}

.taxSharedContainer-main .uploadfile .uploadfile-container .uploadfile-bottom .updateText-content {
    max-width: 220px;
}

.taxSharedContainer-main .uploadfile {
    backdrop-filter: none
}

@media (max-width: 880px) {
    .taxSharedContainer-main {
        gap: 10px
    }
    .taxSharedContainer-main h1,
    .taxSharedContainer-main p,
    .taxSharedContainer-tableQuotation-title,
    .taxSharedContainer_text___,
    .taxSharedContainer-row__ .paragraph-header {
        font-size: 0.9rem;
    }
    .phaseSeven_white {
        font-size: 0.875rem;
    }
}

@media (max-width: 700px) {
    .taxSharedContainer-main,
    .taxSharedContainer-change {
        border-radius: 12px;
    }
    .taxSharedContainer-tableQuotation-title {
        border-radius: 12px 12px 0px 0px;
    }
    .taxSharedContainer-tableQuotation-container .rowTaxesFooter__,
    .taxSharedContainer-tableQuotation-container .rowGroupTaxesFooter__ {
        border-radius: 0px 0px 12px 12px;
    }
}

@media (max-width: 600px) {
    .taxSharedContainer-tableQuotation-title,
    .taxSharedContainer-tableQuotation-container .rowTaxesFooter__ .rowTaxesFooter__text,
    .taxSharedContainer-tableQuotation-container .rowGroupTaxesFooter__ .rowGroupTaxesFooter__text,
    .phaseSeven_white {
        font-size: 0.875rem;
    }
    .taxSharedContainer-change {
        padding: 0px 10px;
    }
}

@media (max-width: 500px) {
    .taxSharedContainer-main .taxSharedContainer-tableQuotation-container .rowGroup-taxes-supplier-container .rowGroupTaxesSupplier__ {
        flex-direction: row;
        height: 60px;
    }
}

@media (max-width: 480px) {
    .rowTaxes__text {
        width: 50%;
    }
    .taxSharedContainer-tableQuotation-container .rowTaxes-container .rowTaxes-body .rowTaxes__ .inputElement-container {
        width: 42%;
    }
}

@media (max-width: 425px) {
    .taxSharedContainer-main {
        padding: 10px;
    }
}

@media (max-width: 387px) {
    .rowTaxes__text {
        width: 50%;
    }
    .taxSharedContainer-tableQuotation-container .rowTaxes-container .rowTaxes-body .rowTaxes__ .inputElement-container .inputElement {
        min-width: 82px;
    }
}

@media (max-width: 362px) { 
    .taxSharedContainer-tableQuotation-title {
        flex-direction: column;
        gap: 15px;
        height: auto;
    }
}