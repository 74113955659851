.eventCard-container {
    backdrop-filter: blur(40px);
	border-radius: 0px 12px 12px 0px;
    background: var(--background);
    border-image-source: var(--border-image-source);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.eventCard-marker {
    width: 7px;
    background: var(--gray-1);
}

.eventCard-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px 10px 0px;
    gap: 10px;
}

.eventCard-body-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.eventCard-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.eventCard-row .useractive,
.eventCard-row .useractive img {
    width: 20px;
    height: 20px;
}

.eventCard-textColor {
    color: var(--gray-1);
}

.eventCard-verify div img {
    display: flex;
    width: 14.157px;
    height: 14px;
}

.eventCard-dots {
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 5px;
}

@media (max-width: 900px) {
    .eventSeccion-eventcard {
        max-width: 450px;
    }
}