.selectServiceType-container{
    position: relative;
    max-width: 250px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 10px;
    cursor: pointer;
    /* min-width: 167.5px; */
}

.selectServiceType-item {
    max-width: fit-content;
    min-width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    /* background-image: var(--border-image-source); */
    backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.05);
    padding: 10px 20px;
    border-radius: 24px;
    max-height: 50px;
    height: 100%;
    width: 100%;
    /* border: 1.5px solid #181818; */
 }

 .selectServiceType-item-row {
    display: flex;
    flex-direction: row;
    gap: 10px
 }

 .selectServiceType-logoMin {
    width: 24px !important;
 }

 .selectServiceType-select {
    display: flex;
    align-items: center;
    color: #2D9CDB;
    leading-trim: both; 
    text-edge: cap;
    font-family: Faktum;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
 }

 .selectServiceType-item div img {
    width: 8px; 
 }

 .selectServiceType-item div {
    display: flex;
    align-items: center;
 }

 .selectServiceType--options {
    max-width: 300px;
    width: 100%;
    position: absolute;
    /* top: 17vh; */
    top: 5vh;
    z-index: 9;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(40px);
    border-radius: 24px;
    border: 1px solid;
    border-image-source: var(--border-image-source);
    overflow: hidden;
    background: var(--white);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}

.selectServiceType--options-item{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    color: var(--black);
    cursor: pointer;
}

.selectServiceType--options-item img {
    width: 24px;
}

.selectServiceType--options-item:hover {
    background: var(--gray-5);
}

.selectServiceType--options-background {
    background: var(--white);
    display: flex;
    align-items: center;
    gap: 10px;
}

.filterSelectorExtended--options-background_selected {
    background: var(--gray-5);
}

.selectServiceType-loader {
    position: relative;
    width: 100%;
    height: 50px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 515px) {  
    .selectServiceType-container {
        display: flex !important;
        flex-direction: column !important;
    }

}