.inputElement-container {
    /* width: 100%; */
    position: relative;
    display: flex;
    flex-direction: column;
}

.inputElement-container .textfield-error {
    position: absolute;
    top: 45px;
}

.inputElement {
    display: flex; 
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    min-width: 120px;
    position: relative;
}

.inputElement-input-title {
 padding: 22.5px 20px 7.5px 20px !important;
} 

.inputElement-input-error  {
    border: 1px solid var(--red);
    border-radius: 24px;
}

.inputElement input {
    padding: 10px 20px;
    width: 100%;
    height: 50px;
    /* padding: 10px 12px; */
    box-sizing: border-box;
    outline: none;
    border: 0px solid var(--black-15);

    font-family: 'Faktum-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;

    border-radius: 24px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%); 
    /* background: var(--white); */
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15) !important;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
} 

.inputElement input[type=number]::-webkit-inner-spin-button, 
.inputElement input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.inputElement-title  {
    pointer-events: none;
    position: absolute;
    left: 20px;
    top: 50%;
    color: var(--gray-3);
    transform: translateY(-50%);
    transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
}

.inputElement input:focus ~ .inputElement-title, 
.inputElement input:not(:placeholder-shown).inputElement-input:not(:focus) ~ .inputElement-title {
    top: 15px;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 15px;
    color: var(--gray-1);
}

.inputElement input:focus {
    /* padding: 22.5px 20px 7.5px 20px; */
    font-size: 16px;
    line-height: 20px;
    border: 1px solid var(--blue-2);
    color: var(--gray-1);
}

.inputElement input:disabled {
	box-sizing: border-box;
	/* backdrop-filter: blur(40px); */
    /* background: rgba(0, 0, 0, 0.05) !important; */
    background: var(--transparent);
    border: 1px solid var(--black-25);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15) !important;
}

.inputElement input:focus + .inputElement-title {
    top: 15px;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 15px;
    color: var(--gray-1);
}

.inputElement input:not(:placeholder-shown).textfield-input:not(:focus){
    /* padding: 22.5px 20px 7.5px 20px; */
    font-size: 16px;
    line-height: 20px;
    color: var(--gray-1);
}

.inputElement-focus {

    color: var(--gray-1);
    font-family: 'Faktum';
    font-weight: normal;
    font-size: 12px;

    top: 15px;
    /* font-weight: normal;
    font-size: 0.75rem; */
    line-height: 15px;
    /* color: var(--gray-3); */
    pointer-events: none;
    position: absolute;
    left: 20px;
    transform: translateY(-50%);
    transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
    z-index: 1;
}

@media (max-width: 600px) {
    .inputElement input {
        font-size: 0.875rem;
    }
}