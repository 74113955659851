.dropdown {
  width: 100%;
  position: relative; 
  border-radius: 48px;

}

.dropdown__disabled {
  pointer-events: none;
  border: 1px solid var(--black-25);
}

.dropdown-list {
  max-width: 100%;
  min-width: 100px;
  width: 100%;
  max-height: 200px;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  /* top: 4rem; */
  z-index: 2;

  display: none;

  border-radius: 24px;
  background: var(--white);
  /* linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FFF; */
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(20px);
  box-sizing: border-box;
}

.dropdown_disable_color {
  /* font-family: 'Faktum'; */
  /* color: var(--gray-3) */
  color: var(--gray-1);
  font-family: 'Faktum-Medium';
} 

.dropdown_text {
  padding-top: 0.8rem;
  font-size: 0.875rem;
}

.dropdown-select false dropdown-select__click {
  border-radius: 12px;
}

.dropdown-select__disabled {
  /* border: 0px solid rgba(0, 0, 0, 0.25); */
	box-sizing: border-box;
	backdrop-filter: none !important;
	border-radius: 24px;
  background: none !important;
  /* background: rgba(0, 0, 0, 0.06); */
	/* background-color: transparent !important; */
}

.dropdown-select {
  max-height: 50px;
  height: 100%;
  padding: 20px;
  /* padding: 20px 10px;
  border-radius: 12px;
  background: var(--black-05);
  backdrop-filter: blur(40px); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Faktum';

  border-radius: 24px;
  background: rgba(0, 0, 0, 0.05);
  /* backdrop-filter: blur(20px); */
}

.dropdown-select__click {
  /*background: var(--white-50);
  border: 1px solid var(--blue-2);*/
  padding: 20px !important;
  border-radius: 24px;
  background: var(--negro-transparencia-black-5, rgba(0, 0, 0, 0.05));
  backdrop-filter: blur(20px);
}

.dropdown-select--error {
  border: 1px solid var(--red);
}

.dropdown-show {
  display: block !important;
}

.dropdown-show--top {
  bottom: 4rem;
}

.dropdown-show--bottom {
  top: 4rem;
}


.dropdown-list__item {
  padding: 10px 20px;
  /* height: 20px; */
  background: var(--white-50);
  /* backdrop-filter: blur(40px); */
  /* display: flex; */
  align-items: center;
  font-family: 'Faktum';
  cursor: pointer;

  overflow: hidden;
  /* max-width: 190px; */
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.drop-list__item-icon {
  gap: 10px;
  font-family: 'Faktum';
}

.list__item-icon div img{
  width: 24px;
  height: 24px;
}

.dropdown-list__item:first-child {
  border-radius:  24px 24px 0px 0px;
}

.dropdown-list__item:last-child {
  border-radius:  0px 0px 24px 24px;
}

.dropdown-list__item:hover {
  background: var(--black-05);
  backdrop-filter: blur(40px);
  /* border-radius: 24px; */
}

.select-title {
  font-family: 'Faktum';
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(20px, 50%);
  /* transform: translate(20px, 50%); */
  color: var(--gray-1);
}

.move-text {
  position: relative;
  /* left: 10px; */
  left: 0px;
  transform: translate(0, 50%);
  font-family: 'Faktum-Medium';
  color: var(--gray-1);
}


.dropdown-select-icon {
  padding: 15px 20px;
  border-radius: 12px;
  background: var(--black-05);
  backdrop-filter: blur(40px);
  display: flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  gap: 10px;
  font-family: 'Faktum';
}

.dropdown__icon div img{
  height: 24px;
  width: 24px;
}

.dropdown__content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.select-title-icon {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(55px, 50%);
}

.move-text-icon {
  transform: translate(0px, 50%);
  font-family: 'Faktum-Medium';
}

.dropdown-select__click-icon {
  background: var(--white-50);
  border: 1px solid var(--blue-2);
  padding: 14px 19px !important;
  box-sizing: border-box;
  border-radius: 12px 12px 0px 0px;
}

.dropdown-no-display {
  display: none;
}

.dropdown-no-transform {
  transform: none;
}

.dropdown-error {
  color: var(--red);
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

 .dropdown .dropdown-list::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--gray-1);
} 

.dropdown .dropdown-list::-webkit-scrollbar {
	width: 7px;
	height: 4px;
	background-color: #F5F5F5;
}

.dropdown-hr {
  display: none;
  height: 3rem;
  position: absolute;
  top: 64px;
}

@media (max-width: 600px) {
  .dropdown-select,
  .dropdown-list__item,
  .move-text { 
    font-size: 0.875rem;
  }
}
