.toastWarning {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.toastWarning-title {
    color: var(--black);
    line-height: 22px;
}

.toastWarning-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.toastWarning-buttons .btn-normal {
    min-width: 132px;
    height: auto;
    padding: 6px 0px;
    border-radius: 7px;
}