.services {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: fit-content;
    min-width: fit-content;
    /* min-width: 267px; */
}

/*Hover*/
.services .hover {
    width: 100%;
}
.services .hover .hover-text {
    left: 26rem;
    bottom: 12px;
    width: 141px;
    background: #181818;
    border-radius: 24px;
}
.services .hover .hover-content {
    width: 100%;
}
.services .hover .hover-text .hover-color {
    color: var(--white);
}

.services-main {
    width: 100%;
    max-width: fit-content;  
    min-width: fit-content;
    display: flex;
    /* margin: 5px 0px;   */
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;

    background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    border-radius: 12px;
    cursor: pointer;
    filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.15));
    width: 100%;
}

.services-main:hover {
    height: 44px;
}

.services-active {
    background: var(--gray-1);
    border-radius: 12px;
    width: 100%;
    height: 44px;
}

.services-main:hover {
    background: var(--gray-1);
    border-radius: 12px;
}

.services-main:hover .service-title {
    color: var(--white);
}

.services-active-text {
    color: var(--white) !important;
}

.service-title {
    color: var(--gray-1);
}

.services-active-text {
    color: var(--white) !important;
}

.services-icon {
    display: flex;
    align-items: center;
    width: 25px;
    height: auto;
}

.services-icon img{
    width: 35px;
    height: 35px;
}

.service-title {
    color: var(--gray-1);
}

@media (max-width: 600px) {  
    .services-icon {
        /* width: 30px;
        height: 30px; */
    }
    .service-title {
        font-size: 0.875rem;
    }
}

@media (max-width: 450px) {  
    .services-icon {
        width: 21px;
        height: 21px;
    }
    .service-title {
        font-size: 0.75rem;
    }
}