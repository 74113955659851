.modalThanks-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.modalThanks-colorText {
    color: var(--gray-1);
}

.modalThanks-icon {
    width: 30px;
    height: 30px;
}


.modalThanks-button {
    display: flex;
    justify-content: flex-end;
}