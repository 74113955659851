.cardBalance__main {
    padding: 15px;
    border-radius: 12px;
    backdrop-filter: blur(40px);
    background: var(--background);
    border-image-source: var(--border-image-source); 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.cardBalance__row {
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 438px) { 
    .cardBalance__text {
        font-size: 0.9rem;
    }
    .cardBalance__text-balance {
        font-size: 1rem;
    }
}

@media (max-width: 405px) { 
}