.bulto-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.bulto-colorText {
    color: var(--gray-1);
}

.bulto-item {
    /* max-width: 760px; */
    max-width: 100%;
    width: 100%;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    /* background: rgba(0, 0, 0, 0.05);
    border-radius: 12px; */
}

.bulto-item--row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.bulto-item--row-column .inputElement-container {
    width: 100%;
}

.bulto-item--row-second {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
}

.bulto-item--row-column-second .inputElement-container {
    width: 100%;
}

.bulto-item--row-column {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.bulto-item--row-column-second {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.bulto-item--row-column .textfield {
    width: 100%;
}

.bulto-item--footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}   

.bulto-item--footer-text {
    color: var(--blue-2);
    cursor: pointer;
}

.bulto-item--row .dropdown .dropdown-select .move-text {
    left: 10px;
}
/* 
@media (max-width: 670px) {
    .bulto-item--row-column {
        flex-direction: column;
    }
} */

.bulto-item--row-column-second-desktop {
    display: flex;
}

.bulto-item--row-column-second-mobile {
    display: none;
}
.bulto-item--row-second-mobile {
    display: none;
}

@media (max-width: 767px) { 
    .bulto-item--row-column-second-desktop .inputElement-container .inputElement {
        min-width: 50px;
    }
}

@media (max-width: 700px) { 
    .bulto-item--row-second {
        flex-direction: column;
        gap: 20px
    }
    .bulto-item--row-second {
        gap: 20px;
    }
}

@media (max-width: 658px) { 
    .bulto-item--row-second .bulto-item--row-column-second .inputElement-container {
        width: 100%;
    }
}


@media (max-width: 600px) { 
    .bulto-item--row-second {
        gap: 20px;
    }
    .bulto-item--row-column-second-desktop {
        display: none;
    }
    .bulto-item--row-column-second-mobile {
        display: flex;
    }
    .bulto-item--row-column-second-mobile .inputElement-container .inputElement {
        min-width: 50px;
    }
    .bulto-colorText,
    .bulto-item--footer-text {
        font-size: 0.875rem;
        font-family: 'Faktum';
    }
    .bulto-item--row-second__  {
        flex-direction: column;
    }
    .bulto-item--row-column-second-mobile .inputElement-container .inputElement 
    .inputElement-input-title {
        padding: 22.5px 20px 7.5px 20px !important;
    }
}

@media (max-width: 400px) { 
    .bulto-item--row-column-second-mobile .inputElement-container .inputElement 
    .inputElement-input-title {
        padding: 22.5px 20px 7.5px 13px !important;
    }
    .bulto-item--row-column-second-mobile .inputElement-container .inputElement 
    .inputElement-focus {
        left: 13px;
    }
    .bulto-item--row-column .dropdown .dropdown-select .move-text,
    .bulto-item--row-column-second-mobile .dropdown .dropdown-select .move-text {
        left: 0px;
    }
}