.modalstatement-content {
    display: flex;
    flex-direction: column;
    gap: 30px
}

.modalstatement-check-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
}

.modalstatement-check-row__ {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}