.animation-video {
    border-radius: 12px;
    overflow: hidden;
    height: 100%;
    max-width: 100%;
    padding-top: 0rem;
    display: flex;
    width: 100%;
    border-radius: 24px;
    background: var(--blanco-transparencia-white-90, rgba(255, 255, 255, 0.90));
    box-shadow: 0px 2.1967px 8.78679px 0px rgba(0, 0, 0, 0.15);
}