.galery-scroll {
    width: 100%;
    max-width: 800px;
    display: flex;
    gap: 2rem;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    width: 100%;
    padding: 25px;
}

.galery-scroll img {
    height: 450px;
    width: 253.5px;
    transition: transform 0.3s ease;
    cursor: pointer;
    border-radius: 18px;
}

.galery-scroll img:hover {
    transform: scale(1.1);
}

.galery-scroll::-webkit-scrollbar {
    display: none;
}

@media (max-width: 1194px) {
    .six-section-history-left {
        max-width: 620px;
    }
    .galery-scroll {
        gap: 1rem;
        padding: 17px;
    }
    .galery-scroll img {
        height: 435px;
        width: 240.5px;
    }
}

@media (max-width: 665px) {
    .galery-scroll {
        gap: 0.5rem;
    }
    .galery-scroll img {
        height: 341px;
        width: 205.5px;
    }
}