.navbar-root  {
	width: 100%;
	height: 100%;
}

.navbar-landing {
	width: 100%;
	height: 70px;
	gap: 60px;
	/* position: relative; */
	position: fixed;
	padding: 10px 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	backdrop-filter: blur(20px);
	z-index: 3;
}

.navbar {
	width: 100%;
	height: 50px;
	position: relative;
	/* position: fixed; */
	padding: 10px 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* backdrop-filter: blur(20px);
	z-index: 3; */
}

.navbar .navbar-items .subheader-clicked {
	color: var(--black)
}

.navbar-open {
	padding: 2px 30px;
	width: 100%;
	height: 50px;
}

.navbar-logo {
	height: 45px;
	width: fit-content;
	display: flex;
	flex-direction: row;
	gap: 20px;
	align-items: center;
}

.navbar a {
	text-decoration: none;
}

.navbar-login-container {
	max-width: 250px;
	width: 100%;
	height: 45px;
	display: flex;
}

.navbar-menu-item {
	width: 100%;
	height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
	align-items: center;
    gap: 60px;
}

.navbar-menu-item-column {
	display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    height: 32px;
    align-self: end;
}

.navbar-menu-item-header {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	gap: 10px;
}

.navbar-menu-item-header div img {
	width: 8.486px;
	height: 5.657px;
}

.navbar-menu-item-column .subheader-clicked::before {
	background-color: var(--white-90);
}

.navbar-menu-item-white {
	color: var(--white);
}

.navbar-menu-item span:hover {
	font-family: "Faktum-SemiBold";
	color: var(--white-90);
}

.navbar-menu-item--cursor {
	cursor: pointer;
	display: flex;
    flex-direction: column;
    gap: 5px;
}

.navbar-login-container--subMenu {
	height: 20px;
	width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.navbar-menu-sub {
	width: 471px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: #333;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 4.34rem;
    left: 14rem;
}

.navbar-menu-item-sub {
	display: flex;
	flex-direction: row;
	gap: 50px
}

.navbar-menu-sub-title {
	background: linear-gradient(92deg, #E4D66A 2.11%, #FFC525 26.72%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.navbar-login-container--buttons {
	height: 100%;
	width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
	align-items: center;
	gap: 30px;
}

.navbar-login-btnLogin-IsHome {
	max-width: 193px;
    width: 100%;
    max-height: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background: none;
    border-radius: 24px;
    color: white;
    cursor: pointer;
    border-width: 0px;
}

.navbar-login-btnLogin-NotHome {
	max-width: 86px;
	width: 100%;
	width: 148px;
    max-height: 45px;
	height: 100%;
	padding: 0px;

    display: flex;
	flex-direction: row;
    justify-content: center;
    align-items: center;   
    background: transparent;
    border-radius: 24px;
    color: var(--white);
    cursor: pointer;
	border-width: 0px;
	font-family: 'Faktum-Medium';
	font-size: 0.85rem;
	/* background: hsla(0,0%,100%,0.2);
    backdrop-filter: blur(50px); */
}

.navbar-login-container--buttons .custom-button {
	border-radius: 20px;
    box-shadow: 0px 0px 0px var(--black-15);
	padding: 0px 40px;
	height: 36px;
}

.navbar-login {
	cursor: pointer;
}

.navbar-signup {
	cursor: pointer;
	background: #F7E246;
	border-radius: 24px;
	padding: 6px 30px;
}

.navbar-items {
	display: flex;
	align-items: center;
	gap: 40px;
	width: 100%;
	justify-content: center;
}

.navbar-content--options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navbar-options {
	display: flex;
	align-items: center;
	gap: 40px;
	width: 50%;
    justify-content: flex-end;
}

.navbar-options-name {
	min-width: 200px;
    max-width: 450px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	text-align: end;
	color: var(--gray-1)
}

.navbar-options-name--white {
	color: var(--white);
}

.navbar-content--row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.navbar-content-profile{
    display: flex;
    justify-content: flex-start;
}

.navbar-content--options-logo {
	height: 25px;
	width: 165px;
}

.navbar-content--button {
	max-width: 128px;
	width: 100%;
	height: 36px;
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	cursor: pointer;

	border-radius: 12px;
	background: #5A4E39;
	backdrop-filter: blur(20px);
	color: var(--white);
}

.navbar-content-NoHome--button {
	max-width: 138px;
    width: 100%;
    max-height: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 50px;
	background: var(--yellow);
	border-radius: 24px;
	color: #181818;
	cursor: pointer;
	font-size: 1rem;
}

.navbar-content--button--singin {
	max-width: 138px;
    width: 100%;
    max-height: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
	background: linear-gradient(180deg, #333333 0%, #000000 100%);
	border-radius: 24px;
	color: white;
}

.navbar-content--row-profile {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: flex-start;
}
.navbar-content--row-circle {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(72.49deg, #ffa1416e -16.25%, #ffe34757 132.02%);
    border-radius: 50px;
}
.navbar-icons {
	display: flex;
	align-items: center;
	gap: 40px;
}

.navbar-icons div {
	cursor: pointer;
	display: flex;
}

.navbar-mob--icon {
	display: flex;
	flex-direction: row;
	gap: 30px;
}

.navbar-user-container {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.navbar-user {
	display: flex;
	align-items: center;
	gap: 10px;
	cursor:pointer
}

.navbar-user .useractive {
	min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
}

.navbar-user .useractive .useractive-text { 
	font-size: 15px;
}

.navbar-user .useractive .useractive-logged {
    width: 6px;
    height: 6px;
    left: 79%;
    top: 80%;
}

.navbar-user-options {
	max-width: 180px;
	width: 100%;
	position: absolute;
	z-index: 1;
	top: 40px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	border-radius: 6px; 
    background: var(--white);
    border: 0.5px solid var(--gray-2);

}

.navbar-user-options-colorText {
	padding: 5px 10px;
	width: 100%;
	cursor: pointer;
	color: var(--gray-1);
}

.navbar-user-options-colorText:hover {
	background-color: var(--gray-4);
}

.navbar-mob-btn {
	display: none;
	max-width: 36px;
	width: 100%;
	max-height: 31.2px;
	height: 100%;
}

#navbar-content-mobile {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 1001;	
	background: rgba(0, 0, 0, 0.05);
	top: 0px;
}

.navbar-content {
	width: 80%;
	height: 100%;
	padding: 30px 0px 30px 0px;
	display: flex;
	box-shadow: 3px 0px 9px rgba(0, 0, 0, 0.12);
	flex-direction: column;
	gap: 30px;
}

.navbar-content-login {
	width: 80%;
	height: 100%;
	padding: 30px 0px 30px 0px;
	display: flex;
	box-shadow: 3px 0px 9px rgba(0, 0, 0, 0.12);
	flex-direction: column;
	gap: 30px;
	background-color: var(--white);
	height: 100vh;
}

.navbar-content--row {
	display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.navbar-content-profile .useractive img {
	width: 60px;
	height: 60px;
}

.navbar-content-header {
	/*margin-bottom: 20px;  se descomenta cuando los demas items esten disponibles*/
	display: flex;
	flex-direction: row;
	gap: 10px; /* se descomenta cuando los demas items esten disponibles */
	padding: 0px 20px;
}

.navbar-content-header .useractive img {
	width: 90px;
    height: 90px;
    border-radius: 50px;
}

.navbar-content-header .useractive .useractive-logged {
	left: 78%;
	top: 82%;
	display: none;
}

.navbar-content-description-basic-name {
	font-family: 'Faktum-SemiBold';
}

.navbar-user-detail {
	display: flex;
	flex-direction: row;
	gap: 10px
}

.navbar-content-description {
	display: flex;
	flex-direction: column;
	gap: 6px
}

.navbar-content-description-basic-lastname {
	color: #181818;
}

.navbar-content-description-basic {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.navbar-content-body {
	display: flex;
	flex-direction: column;
	padding: 0px 20px;
}

.navbar-content--items {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.navbar-content--items-text {
	color: #394676;
}

.navbar-title-container {
	cursor: pointer;
}

.navbar-icon-mobiles {
	display: none;
}

.navbar-content-option-mobile {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

#navbar-logo--mobile, #navbar-logo--mobile-home {
	display: none;
}

.navbar-content-footer {
    width: 100%;
    bottom: 0rem;
    left: 0px;
	padding: 20px 10px 60px 10px;
	display: flex;
	border-top: 1px solid #D9D9D9;
	flex-direction: column;
	gap: 20px
}

.navbar-content-footer-login {
    width: 100%;
	height: 100%;
    bottom: 0rem;
    left: 0px;
	padding: 20px 20px 60px 20px;
	display: flex;
	/* border-top: 1px solid #D9D9D9; */
	flex-direction: column-reverse;
	gap: 20px
}

.navbar-content-footer .btnIcon-extra-small,
.navbar-content-footer-login .btnIcon-extra-small {
	min-width: 100%;
	align-items: center;
	justify-content: flex-start;
	box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
}


.navbar-content-footer .btnIcon-extra-small .custom-button-icon-text,
.navbar-content-footer-login .btnIcon-extra-small .custom-button-icon-text { 
	color: var(--white);
}

.navbar-content-header-login {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;
	justify-content: center;
	align-items: center;
}

.navbar-content-header-first {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 15px;
	justify-content: center;
	align-items: center;
}

.navbar-content-header-login div img {
	height: 80px;
	width: 80px;
}

.navbar-content-header .useractive {
	width: 80px;
	height: 80px;
}

.navbar-responsive-button {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 20px
}

.navbar-responsive-button-login,
.navbar-responsive-button-register {
	width: 100%;
}

.navbar-responsive-button-login .custom-button,
.navbar-responsive-button-register .custom-button {
	max-width: 100%;
	min-width: 100%;
	border-radius: 12px;
}

.navbar-responsive-button-login .custom-button {
	background: linear-gradient(94deg, #F7E246 0%, #FFC525 73.97%);
}

.navbar-responsive-button-register .custom-button {
	color: var(--black);
}

.navbar-content-header-text-login {
	font-size: 1.125rem;
	width: 13rem;
	text-align: center;
	color: var(--white)
}

.navbar-content-header-login .custom-button {
    box-shadow: 0px 0px 0px var(--black-15);
	border-radius: 21px;
	min-width: 100%;
	height: 45px;
}

.navbar-content-mobile-column {
	display: flex;
	flex-direction: column;
}

.navbar-content-body-not-login {
	display: none;
}

.navbar-content-mobile-header {
	display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 0px 20px;
    justify-content: space-between;
}

.navbar-list-tutorial-detail {
	display: flex;
	flex-direction: column;
	gap: 30px;
	height: calc(100vh - 460px);
	padding: 0px 30px;
	overflow: scroll;
}

.navbar-list-tutorial-detail .tutorial {
	border-bottom: 0.1rem solid var(--gray-5);
	padding-bottom: 1rem;
}

.navbar-content-header-items {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: self-start;
	gap: 30px;
}

.navbar-content-header-items .subheader {
	color: var(--white);
	text-shadow: -6px 9px 10px rgba(255, 197, 37, 0.12), 6px -9px 10px rgba(255, 197, 37, 0.12);
}

#navbar-logo--desktop {
	width: 139.65px;
	height: 21px;
}


/*SUB ITEM RESPONSIVE MOBILE*/
.navbar-content-header-column-item {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.navbar-content-header-column-item div {
	display: flex;
	align-items: center;
}

.navbar-content-header-column-item div img {
	width: 12px;
	height: 6px;
}

.navbar-content-header-column {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.navbar-content-header-column-sub {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-left: 20px;
}

.navbar-content-header-column-item-sub {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

@media (max-width: 1440px) {
	.navbar-options-name {
		min-width: 100px;
		max-width: 235px;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: end;
	}
}

@media (max-width: 1157px) {
	.navbar-menu-item {
		gap: 2rem;
	}
	.navbar-login-container--buttons {
		gap: 20px;
	}
}

@media(max-width: 1100px) {
	.navbar-landing {
		gap: 30px;
	}
	.navbar-menu-sub {
		left: 12rem;
	}
}

@media (max-width: 1040px) {
	.navbar-content--button {
		/* max-width: 115px; */
		padding: 10px 15px;
	}
}

@media (max-width: 1024px) {
	.navbar-root .navbar {
		padding: 15px;
	}
	.navbar-icons, .navbar-options {
		gap: 20px;	
	}
}

@media (max-width: 1012px) {
	.navbar-menu-item {
		gap: 1rem;
	}
	.navbar-menu-item .paragraph{
		font-size: 0.875rem;
	}
	/* .navbar-login-btnLogin-NotHome {
		padding: 10px 15px;
		width: 135px;
	} */
	.navbar-login-container--buttons {
		gap: 15px;
	}
	.navbar-landing {
		gap: 20px;
	}
	.navbar-menu-sub {
		left: 11.5rem;
	}
}

@media (max-width: 960px){ 
	.navbar-login-container--buttons {
		width: 100%;
	}
	.navbar-menu-item {
		display: none;
	}
	.navbar-mob-btn {
		display: flex;
	}
	.navbar-login-container, 
	.navbar-options {
		display: none;
	}
	.navbar-logo {
		width: 100%;
	}
	.navbar-items {
		display: none;
	}
	.navbar {
		position: relative;
    	width: 100%;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
	}

	.navbar-title-container { 
		padding: 0;
		display: flex;
    	align-items: center;
	}

	.navbar-landing {
		height: 70px;
		padding: 15px;
		justify-content: space-between;
	}

	.navbar {
		height: 40px;
		padding: 10px;
		justify-content: space-between;
	}

	.navbar .navbar-logo {
		width: 100%;
		gap: 15px;
	}

	.navbar-content,
	.navbar-content-login {
		background-color: #333333;
		width: 85%;
	}

	.navbar-content--items-text {
		color: var(--black);
	}

	/* .navbar-login-container,
	.navbar-options {
		display: none;
	} */

	.navbar-content-profile .useractive img {
		width: 100px;
		height: 100px;
		border-radius: 100px
	}

	.navbar-content-profile .useractive .useractive-logged {
		width: 12px;
		height: 12px;
		top: 77%;
		left: 84%;
	}

	.navbar-icons {
		display: none;
	}

	

	.navbar-icon-mobiles {
		display: flex;
		flex-direction: row;
		gap: 10px
	}

	#navbar-logo--desktop {
		display: flex;
	}

	#navbar-logo--mobile {
		display: none;
	}

	#navbar-logo--mobile-home {
		display: none;
		width: 36px;
		height: 24px;
	}
	
	.navbar-landing {
		/* position: relative; */
    	width: 100%;
	}
	.navbar-open {
		position: absolute;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
	}
	/* .navbar-logo {
		width: 100%;
	} */

	.nav-up {
		top: -40px;
	}

	.navbar-content-body {
		gap: 20px;
	}

	.navbar-content-header-items .subheader::before {
		background-color: var(--white);
		width: 14px;
    	height: 2px;
		margin-bottom: -5px;
	}
}

@media (max-width: 890px) {
	.navbar-options-name {
		min-width: 50px;
		max-width: 150px;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: end;
	}
}

@media (max-width: 768px) {
	
}

@media (max-width: 900px) {
	.navbar-banner,
	.navbar-banner span {
		font-size: 1rem;
	}
}

@media (max-width: 960px) and (max-height: 3052px) { 
	.navbar-content,
	.navbar-content-mobile{
		height: 100vh;
	}
	.navbar-open {
		padding: 15px;
		height: 50px;
	}
}

@media (max-width: 700px) {
	.navbar-banner,
	.navbar-banner span {
		font-size: 0.9rem;
	}
}

@media (max-width: 600px) {
	.navbar-root .navbar-landing {
		padding: 10px 30px;
	}
	.navbar-root .navbar {
		padding: 10px;
	}
	.navbar-open {
		padding: 10px 30px;
	}
	.navbar-content-body {
	}
	.sectionNavbar-left .paragraph-header {
		font-size: 0.9rem;
	}
	.navbar-content-footer,
	.navbar-content-footer-login {
		gap: 10px;
	}
	.navbar-banner {
		gap: 0.3rem;
	}
	.navbar-banner,
	.navbar-banner span {
		font-size: 0.9rem;
	}
	.navbar-banner img {
		width: 18px !important;
		height: 18px !important;
	}
	#navbar-logo--desktop {
		width: 119.7px;
		height: 18px;
	}
	.navbar-content-header-column-item-sub_description {
		font-size: 0.625rem;
	}
}

@media (max-width: 520px) {
	.navbar-banner {
		height: 35px;
	}
	.navbar-banner img {
		width: 16px !important;
		height: 16px !important;
	}
}

@media (max-width: 500px) {
	.navbar-content,
	.navbar-content-login {
		width: 95%;
	}
	.navbar-list-tutorial-detail {
		padding: 0px 20px;
	}
	.navbar-list-tutorial-detail .tutorial {
		gap: 10px
	}
	.navbar-content-header-text-login {
		font-size: 1rem;
	}
	.navbar-responsive-button .custom-button {
		font-size: 0.9rem;
	}
	.navbar-content-header-items .subheader {
		font-size: 0.9rem;
	}
}

@media (max-width: 460px) {
	.navbar-banner img {
		width: 15px !important;
		height: 15px !important;
	}
}

@media (max-width: 425px) { 
	.navbar-content-profile .useractive img {
		width: 80px;
		height: 80px;
		border-radius: 100px
	}

	.navbar-content-profile .useractive .useractive-logged {
		width: 10px;
		height: 10px;
		top: 77%;
		left: 83%;
	}
}

@media (max-width: 400px) { 
	.navbar-list-tutorial-detail {
		padding: 0px 10px;
	}
	.navbar-content-footer,
	.navbar-content-footer-login {
		margin-bottom: 0.8rem;
		-webkit-margin-bottom-collapse: 8.8rem
	}
	.navbar-list-tutorial-detail .tutorial .tutorial-left .tutorial-left-header .tutorial-left-title,
	.navbar-list-tutorial-detail .tutorial .tutorial-left .tutorial-left-description, 
	.navbar-list-tutorial-detail .tutorial .tutorial-left .tutorial-left-duration {
		font-size: 0.9rem !important;
	}
	.navbar-list-tutorial-detail .tutorial .tutorial-right img {
		width: 75px;
		height: 50px;
		object-fit: cover;
	}
	.navbar-banner img {
		width: 13px !important;
		height: 13px !important;
	}
	.navbar-responsive-button-login .custom-button {
		padding: 10px 30px;
	}
}