.tabs-main {
	width: 100%;
    display: flex;
	flex-direction: column;
	align-items: center;
	gap: 22px;
}

.tabs-header {
	width: 100%;
	display: flex;
    flex-direction: row;
	justify-content: center;
    gap: 40px;
}

.tabs-header > span {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.tabs-container {
	margin: 20px 0px;
	display: flex;
	justify-content: center;
}

.tabs-main .tabs-header .subheader-clicked {
    color: var(--black);
}