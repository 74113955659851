.commentCount {
	max-height: 28px;
	min-width: 45px;
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 5px 10px;
	background: var(--white-50);
	border-radius: 12px;
}

.commentCount span {
	color: var(--gray-1);
}

.commentCount div img {
	display: flex;
	width: 0.8rem;
}


