.content-quote {
    min-width: auto;
    max-width: 220px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-end;
    align-self: center;
    align-items: center;
}
.content-quote-header {
    height: 30px;
    border-radius: 12px;
    /* background: var(--yellow); */
    /* color: var(--gray-1); */
    align-self: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 0px 7px;
}
.content-quote-header-number {
    height: 30px;
    border-radius: 10px;
    border: 1px solid var(--gray-2);
    color: var(--gray-2);
    align-self: center;
    align-items: center;
    display: flex;
    padding: 0px 7px;
    width: auto;
    justify-content: center;
}
.content-quote .dropdown {
    width: 50%;
    border-radius: 12px;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
}
.content-quote .dropdown .content-quote-leida .paragraph-header,
.content-quote .dropdown .content-quote-no-leida .paragraph-header {
    font-size: 0.75rem;
    font-family: 'Faktum-Medium';
    color: var(--white-90);
}
.content-quote .dropdown .content-quote-leida .select-title,
.content-quote .dropdown .content-quote-no-leida .select-title {
    display: none;
}
.content-quote .dropdown .content-quote-leida .move-text,
.content-quote .dropdown .content-quote-no-leida .move-text {
    transform: translate(0, 0%);
    font-size: 0.75rem;
    font-family: 'Faktum-Medium';
    color: var(--white);
}
.content-quote .dropdown .dropdown-list .dropdown-list__item {
    font-size: 0.75rem;
    font-family: 'Faktum-Medium';
}

.content-quote .dropdown .dropdown-select__click {
    border-radius: 12px 12px 0px 0px;
}

.content-quote .dropdown .dropdown-show {
    top: 31px
}

.content-quote .dropdown .dropdown-list {
    border-radius: 0px 0px 24px 24px;
    min-width: 100%;
}

.content-quote-leida {
    background: var(--blue-2);
    font-size: 0.75rem;
    font-family: 'Faktum-Medium';
    border-radius: 12px;
    color: var(--white);
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30px;
    justify-content: space-around;
    align-items: center;
    align-self: center;
    padding: 16px 9px !important;
}

.content-quote-no-leida {
    background: var(--black-25);
    color: var(--white);
    font-size: 0.75rem;
    font-family: 'Faktum-Medium';
    border-radius: 12px;
    display: flex;
    padding: 16px 9px !important;
    flex-direction: row;
    width: 100%;
    height: 30px;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
    align-self: center;
}

@media (max-width: 500px) {
    .content-quote-leida,
    .content-quote-no-leida,
    .content-quote-header {
        height: 20px;
        padding: 15px 9px !important;
    }
    .content-quote-header {
        /* width: 100px; */
        text-align: center;
    }
    .content-quote-header-number {
        height: 20px;
        padding: 14px 7px;
    }
    /* .content-quote,
    .content-quote  .dropdown {
        width: 25%;
    } */
}