.cardContentPlan {
    display: flex;
    gap: 20px !important;
    width: 100%;
    max-width: 400px !important;
    /* padding: 30px !important; */
    padding: 10px 20px !important;
}
.cardContentPlan-Price {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0.2rem;
    gap: 1px
}
.cardContentPlan-moneda {
    padding-top: 0.3rem;
}
.cardContentPlan-tabs {
    display: flex;
    flex-direction: row;
    gap: 45px;
    justify-content: center;
    padding-top: 10px;
}
.cardContentPlan-tabs .subheader {
    font-size: 1rem;
}

.cardContentPlan-tabs .subheader-clicked {
    color: var(--black);
}
.cardContentPlan__row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
}
.cardContentPlan__row__min {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: fit-content;
}
.cardContentPlan__column {
    display: flex;
    flex-direction: column;
    gap: 15px
}
.cardContentPlan-detail-column {
    display: flex;
    flex-direction: column;
    gap: 10px
}
.cardContentPlan-button .custom-button {
    background: var(--black-50);
    padding: 10px 20px;
}

.cardContentPlan__row .custom-button,
.cardContentPlan-button .custom-button {
    min-width: 30px;
    width: 100%;
}

@media (max-width: 1037px) { 
    .cardContentPlan__row .btn-normal {
        padding: 10px 20px;
    }
    .cardContentPlan .cardContentPlan__row .cardContentPlan-button .custom-button {
        padding: 10px 12px;
    }
}

@media (max-width: 980px) {  
    .cardContentPlan {
        gap: 15px !important;
    }
    .cardContentPlan-tabs {
        gap: 35px
    }
    .cardContentPlan-tabs .subheader {
        font-size: 0.875rem;
    }
    .cardContentPlan__row .paragraph-header {
        font-size: 0.9rem;
    }
    .cardContentPlan-Price {
        font-size: 1.1rem;
    }
    .cardContentPlan-moneda {
        font-size: 0.65rem;
        padding-top: 0.1rem;
    }
    .cardContentPlan__row .paragraph {
        font-size: 0.875rem;
    }
    .cardContentPlan__row__min  {
        font-size: 0.75rem;
    }
    .cardContentPlan__row {
        font-size: 0.9rem;
    }
    .cardContentPlan .cardContentPlan__row .cardContentPlan-button .custom-button {
        padding: 10px 20px;
    }
    .cardContentPlan__row .cardContentPlan-button .btn-normal {
        font-size: 0.75rem;
    }
    .cardContentPlan__row .btn-normal {
        font-size: 0.75rem;
    }
}

@media (max-width: 950px) {   
    .cardContentPlan .cardContentPlan__row .cardContentPlan-button .custom-button,
    .cardContentPlan__row  .custom-button  {
        font-size: 0.8rem;
    }
}

@media (max-width: 944px) {  
    .cardContentPlan-button .custom-button {
        padding: 10px 35px;
    }
    .cardContentPlan {
        padding: 10px 20px !important;
    }
}

@media (max-width: 850px) {  
    .cardContentPlan-button .custom-button,
    .cardContentPlan__row .custom-button {
        padding: 10px 20px;
    }
    .cardContentPlan .cardContentPlan-tabs {
        gap: 35px
    }
}

@media (max-width: 768px) {  
    .cardContentPlan-tabs { 
        padding-top: 0px;
    }
    .cardContentPlan-button .custom-button, 
    .cardContentPlan__row .custom-button {
        padding: 10px 40px;
    }
}

@media (max-width: 409px) {  
    .cardContentPlan-button .custom-button, 
    .cardContentPlan__row .custom-button {
        padding: 10px 35px;
    }
}

@media (max-width: 390px) {  
    .cardContentPlan-button .custom-button, 
    .cardContentPlan__row .custom-button {
        padding: 10px 30px;
    }
}