.cardSummaryPay {
    height: 100%;
}

.cardSummaryPay-summary {
    max-width: 300px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    border-radius: 12px;
    border: 1px solid var(--bn-gray-3, #828282);
    background: #2C2C2C;
}

.cardSummaryPay-summary-white {
    max-width: 300px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    border-radius: 12px;
    background: linear-gradient(111deg, rgba(255, 255, 255, 0.50) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    -webkit-backdrop-filter: blur(4.5px);
    backdrop-filter: blur(4.5px);
}

.cardSummaryPay-summary__title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: var(--white);
}
.cardSummaryPay-summary__title_black {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: var(--black);
}

hr.cardSummaryPay-summary__hr {
    width: 100%;
    height: 0.1px;
    border-top: .1px solid  rgba(255, 255, 255, 0.30);
    opacity: 0.3;
}

hr.cardSummaryPay-summary__hr_white {
    width: 100%;
    height: 0.1px;
    border-top: .1px solid rgb(9 9 9 / 76%);
    opacity: 0.3;
}

.cardSummaryPay-summary__col {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cardSummaryPay-summary__col-text {
    display: flex;
    flex-direction: row;
    color: var(--white);
}

.cardSummaryPay-summary__col-text_black {
    display: flex;
    flex-direction: row;
    color: var(--black);
}

.cardSummaryPay-summary__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
}
.cardSummaryPay-summary__row-icon, .cardSummaryPay-summary__row-icon > img {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.cardSummaryPay-couponText {
    color: #0989D2;
}

input.cardSummaryPay-input  {
    max-width: 164px;
    width: 100%;
    padding: 10px 15px;
    align-items: center;
    display: flex;
    gap: 5px;
    flex: 1 0 0;
    
    color: var(--gray-3);
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.09);
    backdrop-filter: blur(20px);
    border: 0px;
}

.cardSummaryPay-couponBtn {
    max-width: 76px;
    width: 100%;   
    color: var(--black);
    border-radius: 12px;
    border: 0px;
    background: linear-gradient(94deg, #F7E246 0%, #FFC525 73.97%);
    box-shadow: 0px 2.935px 8.804px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(19.565217971801758px);
}

.cardSummaryPay-summary__total{
    width: 100%;
    height: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

.cardSummaryPay-summary__total--col {
    align-items: center;
}

.cardSummaryPay-summary__total span {
    display: flex;
    font-family: "Faktum-SemiBold";
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.cardSummaryPay-summary__currency {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
}

.cardSummaryPay-summary__total-button {
    height: 45px;
    padding: 12px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: linear-gradient(94deg, #F7E246 0%, #FFC525 73.97%);

    font-size: 1.125rem;
    cursor: pointer;
}

.cardSummaryPay-summary__total-button_black {
    height: 45px;
    padding: 12px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    color: var(--white);

    font-size: 1.125rem;
    cursor: pointer;
}

.cardSummaryPay-summary .textfield input {
    background: var(--white);
}  

.cardSummaryPay-summary .phoneCountry-container .phoneCountry-item 
.phoneCountry-item-column-dropdown .phoneCountry-item-content {
    width: 100px;
}

@media (max-width: 1045px) { 
    .cardSummaryPay-summary {
        max-width: 800px;
    }
}

@media (max-width: 860px) { 
    .cardSummaryPay-summary {
        max-width: 600px;
    }
}

@media (max-width: 665px) { 
    .cardSummaryPay-summary {
        max-width: 500px;
    }
}

@media (max-width: 542px) { 
    .cardSummaryPay-summary {
        max-width: 400px;
    }
}

@media (max-width: 440px) { 
    .cardSummaryPay-summary {
        max-width: 330px;
    }
}