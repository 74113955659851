.rowQuote-container {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.rowQuote-header-container {
    height: 40px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white-50);
}

.rowQuote-header--title {
    color: var(--gray-1);
}

.rowQuote-header--pointer {
    cursor: pointer;
}

.rowQuote-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rowQuote__ {
    height: 35px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
}

.rowQuote-body .rowQuote__:nth-child(even) {
    background-color: var(--white-15);
} 

/* .rowQuote_transparent {
    background-color: transparent;
}

.rowQuote_white {
    background-color: var(--white-25);
} */

.rowQuote_black {
    background-color: var(--black-50);
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.rowQuote__text {
    /* width: 30%; */
}

.rowQuoteFooter__ {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background: var(--white-90);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

/* responsive */
@media (max-width: 968px) { 
    .rowQuote__text {
        font-size: 0.9rem;
    }
    .rowQuote-header--title {
        font-size: 0.9rem;
    }
   .rowQuoteFooter__ .paragraph-header {
    font-size: 0.9rem;
   }
}

@media (max-width: 630px) {
  
    .rowQuote-header-container {
        padding: 10px;
    }
    .rowQuote-header--title  {
        font-size: 14px;    
    }
}

@media (max-width: 480px) { 
    .rowGroup_black div, .rowQuoteFooter__ div {
        font-size: 0.9rem;
    }
}

@media (max-width: 425px) { 
    .rowQuote__, 
    .rowQuoteFooter__,
    .rowQuote_black {
        padding: 0px 10px;
    }
    .rowQuote__text {
        font-size: 0.85rem;
    }
}

@media (max-width: 415px) { 
    .rowQuote-body .textfield {
        max-width: 170px;
    }
}

@media (max-width: 390px) { 
    .rowQuote-body .textfield {
        max-width: 150px;
    }
}

@media (max-width: 347px) { 
    /* .rowQuote__text {
        width: 40%;
    } */
    .rowQuote-body .textfield {
        max-width: 100px;
        width: 60%;
    }
}