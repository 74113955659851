.body-month-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    justify-content: center;
    align-items: center;
}

.body-month-cell {
    height: 100px;
    width: 100%;
    display: flex;
    padding-right: 7px;
    padding-top: 4px;
    align-items: flex-start;
    justify-content: flex-end;
    cursor: pointer;
    /*border-bottom: 1px solid var(--black-15);
    border-right: 1px solid var(--black-15);*/
}

.body-month-colorCell {
    color: var(--gray-2);
    font-size: 0.9rem;
}

.body-month-colorDesactive {
    color: var(--gray-4);
    font-size: 0.9rem;
}
.body-month-DaySelected {
    width: 23px;
    height: 23px;
    border-radius: 45px; 
    background: var(--black);
    display: flex;
    align-items: center;
    justify-content: center; 
    color: var(--white);
}
.body-month-today {
    width: 23px;
    height: 23px;
    border-radius: 45px; 
    border: 1px var(--black) solid;
    display: flex;
    align-items: center;
    justify-content: center;  
}
.body-month-event {
    display: flex;
    flex-direction: column;
    gap: 3px;
    position: absolute;
    height: 45px;
    width: 13.1%;
}
.body-month-event-more {
    color: var(--gray-3);
    font-size: 0.7rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 15px;
}
/*Colores del background intercalado*/
.body-month-container .body-month-cell:nth-child(2n+2) {
    background: var(--white-25);
}

.body-month-container:nth-child(2n+2) {
    background: var(--white-15);
}

@media (max-width: 450px) { 
    .body-month-cell{
        width: 100%;
        height: 40px;
    }

    .body-month-DaySelected  {
        width: 40px;
        height: 40px; 
    }
    
    .body-month-today  {
        width: 40px;
        height: 40px;
    }
}