.rating {
	max-height: 28px;
	min-width: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	border-radius: 12px;
	padding: 5px 10px;
}

.rating_pointer {
	max-height: 28px;
	display: flex;
	align-items: center;
	gap: 5px;
	border-radius: 12px;
	padding: 5px 10px;
	cursor: pointer;
}

.rating span {
	color: var(--gray-1);
}

.rating_pointer span {
	color: var(--gray-1);
}

.rating div {
	display: flex;
} 

.rating_pointer div {
	display: flex;
}

.rating_pointer-icon {
	height: 13px;
	/* width: 13px; */
}

.rating_pointer--start {
	display: flex;
}

.rating_pointer--start img{
	display: flex;
    height: 18px;
}
/* .rating div img {
	display: flex;
	width: 1.2rem;
	min-width: 1.2rem;
} */
