.searchListVessels-container {
    position: absolute;
    left: 0;
    /* margin-top: 10px; */
    width: -webkit-fill-available;
    padding: 2px;
    z-index: 1;
    background: var(--white);
    border-radius: 12px;
    overflow-x: hidden;
}