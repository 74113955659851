.userCard {
    display: flex;
    flex-direction: row;
    gap: 20px
}

.userCard .useractive,
.userCard .useractive img {
    width: 100%;
    height: 100%;
    min-width: 51px;
    max-width: 51px;
    min-height: 51px;
    max-height: 51px;
}

.userCard .useractive .useractive-text {
    font-size: 20px;
}

.userCard-detail {
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 9px
}

.userCard-detail-first { 
    display: flex;
    flex-direction: column;
}

/* .userCard-detail-first-text {
    font-family: "Faktum-Bold";
} */

.userCard-detail-first-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.userCard-detail-first-name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.userCard-detail-first-row div img {
    width: 18.2px;
    height: 18px;
}

@media (max-width: 860px) {
    .userCard {
        gap: 10px
    }
}