.searchUserItem-container {
    max-width: 800px;
    width: 100%;
    padding: 15px 15px 15px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    background: rgba(255, 255, 255, 0.9);
    /* Negro Transparencia/Black 15% */

    /* border: 1px solid rgba(0, 0, 0, 0.15); */
    border-radius: 12px;

}

.searchUserItem-content {
    max-width: 740px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.searchUserItem-img {
    width: 70px;
    height: 70px;
    background: transparent;
    border-radius: 100%;
}
.searchUserItem-textcolor {
    color: var(--gray-1);
}

.searchUserItem-container:hover {
    background: var(--gray-6);
    cursor: pointer;
}

.searchUserItem-selected {
    background: var(--gray-4);
}

@media (max-width: 878px) {
    .searchUserItem-container  {
        padding: 10px;

    }
}