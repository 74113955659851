.defineRoute-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.defineRoute-row {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.defineRoute-row_bottom {
    padding-bottom: 0.7rem;
}

.defineRoute-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.defineRoute-from {
    width: 86%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    gap: 10px;
}

.defineRoute-from .defineRoute-column .shipment .ports-content .row .row__title .portrow .portIcon img{
    padding-top: 0rem;
    margin-top: 0rem;
    width: 16px;
    height: 12px;
}

.defineRoute-from div img {
    padding-top: 0.5rem;
}

.defineRoute-row__ {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.defineRoute-row__ div {
    display: flex;
}

.defineRoute-from .shipment {
    width: 100%;
}

.defineRoute-to {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    gap: 10px
}

.defineRoute-to div img {
    padding-top: 0.5rem;
}

.defineRoute-to .defineRoute-textColor {
    padding-top: 1rem;
}

.defineRoute-to .shipment {
    width: 100%;
}

.defineRoute-icon {
    width: 40px;
}

.defineRoute-to .defineRoute-column .shipment .ports-content .row .row__title .portrow .portIcon img{
    padding-top: 0rem;
}


.defineRoute-textColor {
   color: var(--gray-3);
}

.defineRoute-column .finalDirection-container .finalDirection-row 
.finalDirection-text .defineRoute-row__ .icon-factory img {
    margin-top: 0rem;
    height: 16px;
    width: 16px;
}
.defineRoute-column .finalDirection-container-placeholder .finalDirection-row 
.finalDirection-text .defineRoute-row__ .icon-factory img {
    margin-top: 0rem;
    height: 16px;
    width: 16px;
}

.finalDirection-row div .finalDirection-textArea {
}

@media (max-width: 890px) {
    .finalDirection-text {
        font-size: 0.75rem;
    }
}

@media (max-width:734px) {
    .defineRoute-row {
        flex-direction: column;
    }
}

@media (max-width:670px) {
    .defineRoute-text {
        display: none;
    }

    .defineRoute-from {
        width: 100%;
    }

    .defineRoute-from .shipment, .defineRoute-to .shipment {
        max-width: 100%;
    }
}

@media (max-width:600px) {
    .defineRoute-row .defineRoute-from > div > img {
        margin-top: 0.5rem;
        padding-top: 0px;
        width: 39.364px;
        height: 39.364px;
        display: flex;
    }
    .defineRoute-column .shipment .textfield input {
        height: 54px;
    }
    .defineRoute-column .finalDirection-container .finalDirection-row 
    .finalDirection-text .defineRoute-row__ .icon-factory img {
        margin-top: 0rem;
        height: 16px;
        width: 16px;
    }
    .defineRoute-column .finalDirection-container-placeholder .finalDirection-row 
    .finalDirection-text .defineRoute-row__ .icon-factory img {
        margin-top: 0rem;
        height: 16px;
        width: 16px;
    }
    .defineRoute-container .defineRoute-title {
        font-size: 0.875em;
    }
    .defineRoute-row {
        flex-direction: column;
    }
}

@media (max-width: 480px) { 
    .defineRoute-to .defineRoute-text {
        display: none;
    }
}


@media (max-width: 450px) { 
    .defineRoute-from {
        width: 100%;
    }
}