.countryDropdown-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
}

.countryDropdown-item {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.countryDropdown-item--country {
    max-width: 300px;
    width: 100%;
    background: var(--gray-5);
    z-index: 3;
    position: absolute;
    margin-top: 10px;
    /* padding: 10px; */
    border-radius: 12px;
}

.countryDropdown-item--country .textfield {
    padding: 10px;
}

.countryDropdown-item-content-column {
    display: flex;
    flex-direction: column;
}

.countryDropdown-item-content-row {
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;
}

.countryDropdown-item-content-row img {
    height: 0.89rem;
    box-shadow: 0px 3px 9px 0px rgb(0 0 0 / 3%);
}

.countryDropdown-item-content-row .paragraph-header {
    font-size: 0.875rem;
}

.countryDropdown-item-content {
    height: 50px;
    display: flex;
    align-self: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 10px;
    width: 100%;
    border-radius: 24px;
    background: var(--white);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    outline: none;
    /* backdrop-filter: blur(20px); */
}

.countryDropdown-item-content-text-selected {
    top: 15px;
    font-family: "Faktum";
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: var(--gray-1);
}

.countryDropdown-item-content-text {
    color: var(--black);
}

.dropdown-list {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: pointer;
    min-width: 120px;
}

/* .dropdown-list::-webkit-scrollbar {
    width: 0px;
    height: 0px;
} */

/* .ItemVessels-container:hover {
    background: var(--white-50);
} */

.countryDropdown-item--country .dropdown {
    max-width: 190px;
    width: 100%;
}

.countryDropdown-item--country .dropdown .dropdown-select .move-text {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.countryDropdown-list {
    overflow-y: scroll;
    height: 200px;
    margin-top: 10px; 
    width: 100%;
    cursor: pointer;
}

.countryDropdown-list .ItemVessels-container:hover {
    background: var(--gray-5);
    border-radius: 0px;
}

.countryDropdown-list::-webkit-scrollbar {
    width: 0px;
}

.countryDropdown-item-column-dropdown {
    width: 100%;
}

.countryDropdown-item-column-dropdown-disabled {
    border: 0px solid rgba(0, 0, 0, 0.25);
	color: var(--gray-3) !important; 
    width: 100%;
    box-sizing: border-box;
	/* backdrop-filter: blur(40px); */
	border-radius: 24px;
    background: transparent !important;
    cursor: pointer;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid var(--black-25);
    outline: none;
}

.countryDropdown-item-column-dropdown-disabled + span {
	color: var(--gray-3);
	font-weight: 500;
}

@media (max-width: 700px) { 
    .countryDropdown-container .countryDropdown-item .countryDropdown-item-column-dropdown 
    .countryDropdown-item-content .paragraph {
        font-size: 0.9rem;
    }
}

@media (max-width: 376px) {
    .countryDropdown-item--country .dropdown {
        max-width: 150px;
        width: 100%;
    }
    
    .countryDropdown-item--country .dropdown .dropdown-select .move-text {
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .countryDropdown-item .textfield .textfield-input {
        width: 100%;
    }
}

@media (max-width: 367px) {
    .countryDropdown-item--country,
    .countryDropdown-list {
        max-width: 100%;
    }
    .countryDropdown-list {
        height: 200px;
    }
}