.ModalRegisterNew {
    position: fixed;
    z-index: 9000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0,0,0);
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.ModalRegisterNew-container {
    max-width: 100%;
    height: 100%;
    width: 100%;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    gap: 10px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.81) 0%, rgba(255, 255, 255, 0.9) 100%), #F3F3F3;
    /* box-shadow: 0px 1.5px 6px rgba(0, 0, 0, 0.15); */
    -webkit-backdrop-filter: blur(45px);
    backdrop-filter: blur(45px);
    border-radius: 0px;
}

.ModalRegisterNew-header {
    left: 1.5rem;
    top: 1.5rem;
    display: flex;
    position: absolute;
}

.ModalRegisterNew-header img {
    display: flex;
    width: 159.6px;
    height: 24px;
}

.ModalRegisterNew-content-all {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
    height: calc(100vh - 120px);
    margin-top: 3rem;
}

.ModalRegisterNew-content-all::-webkit-scrollbar {
    display: none;
}

.ModalRegisterNew-body {
    width: 391px;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.ModalRegisterNew-body-pay {
    width: 100%;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.ModalRegisterNew-body .mainLogin-container .mainLogin-items {
    width: 300px;
}

.ModalRegisterNew-body-summary {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.ModalRegisterNew-body-summary .cardSummaryPay {
    height: 100%;
    max-height: 290px;
    margin-top: 0.5rem;
}

.ModalRegisterNew-body-summary .creditCard-form .creditCard 
.creditCard-body {
    height: 70vh;
    border-radius: 12px;
    background: linear-gradient(111deg, rgba(255, 255, 255, 0.50) -4.87%, rgba(255, 255, 255, 0.00) 103.95%);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    -webkit-backdrop-filter: blur(4.5px);
    backdrop-filter: blur(4.5px);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.ModalRegisterNew--title {
    color: #181818;
    text-align: center;
    font-size: 2.125rem;
    line-height: 42px;
}

.ModalRegisterNew-prevButton {
    width: 391px;
    cursor: pointer;
    position: absolute;
    z-index: 11;
    left: 15.5rem;
    top: 5.5rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.ModalRegisterNew-prevButton div {
    display: flex;
}

@media (max-width: 1165px) { 
    .ModalRegisterNew-prevButton {
        left: 10.5rem;
    }
}

@media (max-width: 982px) { 
    .ModalRegisterNew-prevButton {
        left: 6.5rem;
    }
}

@media (max-width: 720px) {
    .ModalRegisterNew-prevButton {
        left: 2.5rem;
    }
    .ModalRegisterNew-body-summary {
        max-width: 430px;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .ModalRegisterNew-body-summary .creditCard-form .creditCard .creditCard-body {
        margin-top: 0rem;
        margin-bottom: 1rem;
        height: 570px;
    }
}

@media (max-width: 600px) { 
    .ModalRegisterNew {
        background: var(--white);
    }
    .ModalRegisterNew-container {
        justify-content: flex-start;
        /* max-height: fit-content;
        height: fit-content;
        padding-top: 6rem; */
        gap: 20px;
    }
    .ModalRegisterNew-header {
        width: 100%;
        position: relative;
        top: 0rem;
        left: 0rem;
    }
    .ModalRegisterNew-header img {
        width: 119.7px;
        height: 18px;
    }
    .ModalRegisterNew-prevButton {
        width: 320px;
    }
    .ModalRegisterNew-content-all {
        height: calc(100vh - 100px);
    }
    .ModalRegisterNew--title {
        font-size: 1.525em !important;
    }
}