.modal-influencer {
    z-index: 100;
    padding: 10px 0px;
}
.modal-influencer .modal-main .modal-container {
    gap: 20px
}
.modal-influencer .modal-main .modal-container .modal__header
.modal__heade-left .modal-logo {
    display: none;
}

.modal-influencer-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.modal-influencer__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px
}
.modal-influencer .modal-influencer__row
.dropdown .dropdown-select {
    padding: 20px;
}
.modal-influencer  .modal-influencer__row .textarea {
    width: 100%;
}
.modal-influencer___check {
    display: flex;
    gap: 10px;
    align-items: center;
}
.modal-influencer-terms {
    color: var(--gray-3)
}
.modal-influencer-link {
    color: var(--blue-2);
    text-decoration: auto;
    cursor: pointer;
}

.modal-influencer__row__2, .modal-influencer__row__1 {
    display: none;
}

@media (max-width: 600px) {  
    .modal-influencer__row__3 {
        display: none;
    }
    .modal-influencer__row__2, .modal-influencer__row__1 {
        display: flex;
    }
}

@media (max-width: 544px) {  
    .modal-influencer__row {
        flex-direction: column;
    }
}