.message-notification {
	position: relative;
}

.message-notification-count {
	position: absolute;
	left: 21px;
	top: -6.5px;
	background-color: var(--red);
	border-radius: 24px;
	border: 2px solid var(--white-50);
	color: var(--white);
	width: 22px;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
}