.membersChat-container-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.membersChat-container-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.membersChat-colorText {
    color: var(--black);
}

.membersChat-container .useractive,
.membersChat-container .useractive img {
    width: 40px;
    height: 40px;
}

.membersChat-container .useractive .useractive-text {
    font-size: 18px;
}

.membersChat-moreUser {
    cursor: pointer;
}

.membersChat-moreUser img {
    height: 40px;
    width: 40px;
}

.membersChat_member {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.modal-list-members {
    display: flex;
    flex-direction: column;
}

.list-members-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    height: 60px;
}
.list-members-card-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}
.list-members-card-info .useractive-white,
.list-members-card-info .useractive {
    width: 45px;
    height: 45px;
}
.list-members-card-info .useractive .useractive-text,
.list-members-card-info .useractive-white .useractive-text {
    font-size: 18px;
}
.list-members-card-info .useractive-white img,
.list-members-card-info .useractive img {
    object-fit: contain;
    width: 45px;
    height: 45px;
}
.list-members-card-info-name {
    color: var(--black-75);
    max-width: 290px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.list-members-card-info-detail {
    color: var(--black-50);
    
}

.membersChat_member-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.membersChat_member-description {
    color: var(--black-50)
}

@media (max-width: 505px) {
    .list-members-card-info-name {
        max-width: 260px;
    }
}
@media (max-width: 471px) {
    .list-members-card-info-name {
        max-width: 220px;
    }
}
@media (max-width: 425px) {
    .list-members-card-info-name {
        max-width: 200px;
    }
}
@media (max-width: 404px) {
    .list-members-card-info-name {
        max-width: 160px;
    }
}
@media (max-width: 377px) {
    .list-members-card-info-name {
        max-width: 140px;
    }
}